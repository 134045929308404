import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { faIcon } from 'src/app/core/icon/fa.icon';
import {ManpowerService} from '../../../core/services/manpower.service';
import {RequestManpower} from '../../../core/models/requestManpower';
import {Router} from '@angular/router';
import { PagerModel } from 'ngx-bootstrap/pagination/models';
import { PageChangedEvent } from 'ngx-bootstrap';

@Component({
  selector: 'app-man-power-list',
  templateUrl: './man-power-list.component.html',
  styleUrls: ['./man-power-list.component.scss'],
})
export class ManPowerListComponent implements OnInit {
    manPowerList: RequestManpower[] = []
    allStatus = ['Draft', 'Pending', 'Approved', 'All status', 'Rejected']
    selectedStatus = 'All status';
    faIcon = faIcon;
    keyword = '';
    @Output() onNewManPowerListData = new EventEmitter();
    currentPage = 1;
    paginationConfig: PagerModel = {
      itemsPerPage: 10,
      previousText: 'ย้อนกลับ',
      nextText: 'ถัดไป',
      pageBtnClass: 'btn btn-warning',
      align: true,
    };
    manPowerListPerPage: RequestManpower[] = []

    constructor(
      private manpowerService: ManpowerService,
      private router: Router
    ) {

    }

    async ngOnInit() {
      let d = await this.manpowerService.getMyRequest().toPromise()
      if (this.selectedStatus !== 'All status') {
        d = d.filter(it => it.status === this.selectedStatus.toUpperCase())
      }

      if ('' !== this.keyword) {
        d = d.filter(it => it.position.positionName.includes(this.keyword))
      }

      this.manPowerList = d
      const startItem = (this.currentPage - 1) * 10;
      const endItem = this.currentPage * 10;
      this.manPowerListPerPage = this.manPowerList.slice(startItem, endItem);
    }

    handleOnViewRequisition(manPower: RequestManpower) {
      this.router.navigateByUrl(`man-power/req/${manPower.id}`)
    }

    searchRequest() {
      this.ngOnInit()
    }

    async onChangeStatus(event) {
      this.ngOnInit()
    }

    onNewRequest() {
        this.onNewManPowerListData.emit('');
    }

    handlePaging({ page, itemsPerPage }: PageChangedEvent) {
      const startItem = (page - 1) * itemsPerPage;
      const endItem = page * itemsPerPage;
      this.currentPage = page;
      this.manPowerListPerPage = this.manPowerList.slice(startItem, endItem);
    }
}
