import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { TimesheetKeyInfo } from 'src/app/core/models/timesheetInfo';
import {faIcon} from '../../core/icon/fa.icon';

@Component({
  selector: 'app-outsource-timesheet',
  templateUrl: './outsource-timesheet.component.html',
  styleUrls: ['./outsource-timesheet.component.scss']
})
export class OutsourceTimesheetComponent implements OnInit {
  display = 'list'
  displayName: string;
  faIcon = faIcon
  date;
  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router
  ) {

  }


  ngOnInit(): void {
    const {empId, month, year} = this.activateRoute.snapshot.params;
    if (empId && month && year) {
      this.display = 'detail'
      this.date = new Date(+year, +month)
    }
  }

  info(data: TimesheetKeyInfo) {
    this.router.navigate(['/outsource-timesheet/' + data.empID +'/' + data.month + '/' + data.year], {queryParams: {projectName: `${data.projectName}`}})
  }

  setName(name) {
    this.displayName = name;
  }

}
