import {Component, OnInit} from '@angular/core';

import {faIcon} from 'src/app/core/icon/fa.icon';
import {EmployeeService} from 'src/app/core/services/employee.service';
import {Employee} from 'src/app/core/models/employee';
import {FastFeedbackService} from 'src/app/core/services/fast-feedback.service';
import {FastFeedback} from 'src/app/core/models/fast-feedback';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {PagerModel} from 'ngx-bootstrap/pagination/models';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';

@Component({
  selector: 'app-vendor-evaluation',
  templateUrl: './vendor-evaluation.component.html',
  styleUrls: ['./vendor-evaluation.component.scss'],
})

export class VendorEvaluationComponent implements OnInit {
    faIcon = faIcon;
    searchValue: string = '';
    vendorEvaluationListPerPage: FastFeedback[] = [];
    vendorEvaluationList: FastFeedback[] = [];
    selectedEmployee: Employee;
    paginationConfig: PagerModel = {
        itemsPerPage: 10,
        previousText: 'ย้อนกลับ',
        nextText: 'ถัดไป',
        pageBtnClass: 'btn btn-warning',
        align: true,
    };
    currentPage = 1;
    displayItem: 'list' | 'detail' = 'list';
    fastFeedbackTitle: string = '';
    fastFeedbackDetailData: FastFeedback;

    constructor(
        private employeeService: EmployeeService,
        public fastFeedbackService: FastFeedbackService,
        private activateRoute: ActivatedRoute,
        private router: Router
    ) { 
        let id = this.activateRoute.snapshot.params.id;
        id = Number(id);
        if(id > 0) {
          this.fastFeedbackService.venforEvaluationSearch('').toPromise()
          .then((res: FastFeedback[]) => {
            if(res.length > 0) {
                let fastFeedback: FastFeedback  = res.find(item => item.id === id);
                if(fastFeedback.id) {
                    this.fastFeedbackTitle = fastFeedback.referenceName;
                    this.fastFeedbackDetailData = fastFeedback;
                    this.displayItem = 'detail';
                } else {
                    this.router.navigate(['/vendor-evaluation'])
                }
            }
          })
          .catch(err => {
            console.log('err', err);
            this.router.navigate(['/vendor-evaluation'])
          })
        }
    }

    ngOnInit() {
        this.getUserDetail();
        this.onSearch();
    }

    getUserDetail() {
      this.employeeService.me().toPromise()
        .then(res => {
          this.selectedEmployee = res;
        })
        .catch(err => {
          console.log('err', err);
  
        })
    }

    onSearch() {
        this.fastFeedbackService.venforEvaluationSearch(this.searchValue).toPromise()
        .then((res: FastFeedback[]) => {
          this.vendorEvaluationList = res;
          this.vendorEvaluationListPerPage = this.vendorEvaluationList.slice(0, this.paginationConfig.itemsPerPage);
        })
        .catch(err => {
          console.log('err', err);
        })
    }

    checkResponsePermission(data) {      
        let checkPermission = false;
        if (Array.isArray(data.tags)) {
          if (data.tags.some(item => (item.targetId === this.selectedEmployee.empID && item.targetType === 'EMP') 
          || (
                (item.targetId === this.selectedEmployee?.deptRelation.deptID 
                || item.targetId === this.selectedEmployee?.deptRelation.department
                || item.targetId === this.selectedEmployee?.deptRelation.subDiv
                || item.targetId === this.selectedEmployee?.deptRelation.division
                || item.targetId === this.selectedEmployee?.deptRelation.company
                ) && item.targetType === 'DEPT'
              )
          ) || data.tags.some(item => (item.targetType.toUpperCase() === 'ALL'))) {
            checkPermission = true;
          }
        }
    
        if (Array.isArray(data.whoCanSeeResult)) {
          if (data.whoCanSeeResult.some(item => item.targetId === this.selectedEmployee.empID)) {
            checkPermission = true;
          }
        }
    
        if (data.createBy) {
          if (this.selectedEmployee.empID === data.createBy) {
            checkPermission = true;
          }
        }

        if(data.isAllowAnyone) {
          checkPermission = true;
        }
    
        return checkPermission;
      }

    handlePaging({ page, itemsPerPage }: PageChangedEvent) {
        const startItem = (page - 1) * itemsPerPage;
        const endItem = page * itemsPerPage;
        this.currentPage = page;
        this.vendorEvaluationListPerPage = this.vendorEvaluationList.slice(startItem, endItem);
      }

      onBack() {
          this.router.navigate(['/vendor-evaluation'])
          this.displayItem = 'list';
          this.currentPage = 1;
          this.onSearch();
      }

      onChangeToDetialPage(fastFeedback: FastFeedback) {
        this.displayItem = 'detail';
        this.fastFeedbackTitle = fastFeedback.referenceName;
        this.fastFeedbackDetailData = fastFeedback;
      }

      switchDisplayItem(event: ({state: 'list' | 'detail', page: number})) {
        this.displayItem = event.state;
        this.currentPage = event.page ? event.page : 1;
        if (this.displayItem === 'list') {
          this.router.navigate(['/vendor-evaluation'])
          this.onSearch();
        }
      }
}