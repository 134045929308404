import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StrategicProjectTracking } from '../models/strategicProjectTracking';
import { map } from 'rxjs/operators';
import { StrategicProjectHistory } from '../models/strategic-project-history.model';


@Injectable({
  providedIn: 'root',
})
export class StrategicProjectTrackingService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getStrategicProjectTrackingYear(): Observable<number[]> {
    return this.http.get<number[]>(`${environment.api_endpoint}/strategic-project-tracking/years`);
  }

  getAllStrategicProjectTracking(isAdmin, isSelf, year, period): Observable<StrategicProjectTracking[]> {
    let path = '';
    if (isAdmin) {
      path = `${environment.api_endpoint}/strategic-project-tracking?isAdmin=${isAdmin}&year=${year}&period=${period}`;
    } else {
      path = `${environment.api_endpoint}/strategic-project-tracking?year=${year}&period=${period}`;
    }
    return this.http.get<StrategicProjectTracking[]>(path);
  }

  updateCorporateKPIScore(id, body): Observable<any> {
    return this.http.put<any>(`${environment.api_endpoint}/strategic-project-tracking/${id}/kpi-score`, body);
  }

  updateMileStone(id, body): Observable<any> {
    return this.http.put<any>(`${environment.api_endpoint}/milestone/${id}`, body);
  }

  createStrategicProject(body): Observable<any> {
    return this.http.post<any>(`${environment.api_endpoint}/strategic-project-tracking`, body);
  }

  getStrategicProjectTrackingById(id): Observable<StrategicProjectTracking> {
    return this.http.get<StrategicProjectTracking>(`${environment.api_endpoint}/strategic-project-tracking/${id}`);
  }

  getNameByEmail(): Observable<any> {
    return this.http.get<any>(`${environment.api_endpoint}/strategic-project-tracking/email-name-mapping`);
  }

  getNameByEmpID(): Observable<any> {
    return this.http.get<any>(`${environment.api_endpoint}/strategic-project-tracking/id-name-mapping`);
  }

  workflow(id, action, quarter, body?): Observable<any> {
    let path = '';
    // if(action === 'REJECT' || action === 'APPROVE'|| action === 'DELETE') {
    //   path = `${environment.api_endpoint}/strategic-project-tracking/${id}?action=${action}`
    // } else {
    path = `${environment.api_endpoint}/strategic-project-tracking/${id}?action=${action}&q=${quarter}`;
    // }

    return this.http.put<any>(path, body ? body : {});
  }

  updateStrategicPlan(id, body): Observable<any> {
    return this.http.put<any>(`${environment.api_endpoint}/strategic-project-tracking/update/${id}`, body);
  }

  importStrategicProject(file: File, year): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    return this.http
      .post(`${environment.api_endpoint}/strategic-project-tracking/import/${year}`, formData, {
        reportProgress: true,
        observe: 'events',
        // responseType: 'blob' as 'json',
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: "progress", progress: progress };

            case HttpEventType.DownloadProgress:
              const downloadProgress = Math.round((100 * event.loaded) / event.total);
              return { status: "done", progress: downloadProgress };

            case HttpEventType.Response:
              return { status: "done", progress: event };

            default:
              return { event: event.type };
          }
        })
      );
  }

  deleteProjectTargetByID(id): Observable<any> {
    return this.http.delete<any>(`${environment.api_endpoint}/strategic-project-tracking/project-target/${id}`);
  }

  getStrategicProjectByYear(year): Observable<any> {
    return this.http.get<any>(`${environment.api_endpoint}/strategic-project-tracking/budget-project/${year}`);
  }

  getBudgetByProject(year, period, project): Observable<any> {
    let params = new HttpParams();
    params = params.append('year', year);
    params = params.append('period', period);
    params = params.append('projects', project);

    return this.http.get<any>(`${environment.api_endpoint}/strategic-project-tracking/budget`, { params: params });
  }

  updateBudgetReason(id, quarter, body): Observable<any> {
    return this.http.put<any>(`${environment.api_endpoint}/strategic-project-tracking/budget-reason/${id}/quarter/${quarter}`, body);
  }

  getStrategicProjectHistoryById(strategicProjectId: number): Observable<StrategicProjectHistory[]> {
    return this.http.get<StrategicProjectHistory[]>(`${environment.api_endpoint}/strategic-project-tracking/history/${strategicProjectId}`);
  }
}
