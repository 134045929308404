import { Injectable } from '@angular/core';
import {
  Attachment,
  PDPARequestModel,
  PDPAResponseDocucmentInfoModel,
  PDPAResponseModel,
} from '../models/pdpaAtttachsModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import {PdpaDocument} from '../models/pdpaDocument';
import {PdpaAgreement} from '../models/pdpaAgreement';
import {PDPAEmployeeReport} from '../models/pDPAEmployeeReport';

@Injectable({
  providedIn: 'root',
})
export class PdpService {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  // private files: Attachment[];
  pdpaApi = environment.pdpa.api;
  dnaToken

  private static extractBase64(
    s: string | ArrayBuffer,
    { type, size, name }
  ): Attachment {
    if (typeof s === 'string') {
      const [_, base64] = s.split(',');
      return {
        uni: new Date().getTime().toString(),
        fileName: name,
        data: base64,
        description: '',
        fileSize: size,
        fileType: type,
      };
    }
  }
  public saveBody(
    body: PDPARequestModel,
    documentId?: number
  ): Observable<PDPAResponseModel> {
    if (documentId) {
      return this.putBody(body, documentId);
    } else {
      return this.postBody(body);
    }
  }
  public postBody(body: PDPARequestModel): Observable<PDPAResponseModel> {
    return this.http.post<PDPAResponseModel>(
      `${this.pdpaApi}/external/document`,
      body
    );
  }

  public deleteDocumentByid(docId) {
    return this.http.delete(`${this.pdpaApi}/external/document/${docId}`);
  }
  public download(base64: string, fileType: string, fileName) {
    var blob = new Blob([this._base64ToArrayBuffer(base64)], {
      type: fileType,
    });
    saveAs(blob, fileName);
  }
  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  public putBody(
    body: PDPARequestModel,
    documentId: number
  ): Observable<PDPAResponseModel> {
    return this.http.put<PDPAResponseModel>(
      `${this.pdpaApi}/external/document/${documentId}`,
      body
    );
  }
  public getDocumentId(id: string): Observable<PDPAResponseDocucmentInfoModel> {
    return this.http.get<PDPAResponseDocucmentInfoModel>(
      `${this.pdpaApi}/external/document/${id}`
    );
  }

  public toBase64 = (file: File): Promise<Attachment> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve(PdpService.extractBase64(reader.result, file));
      reader.onerror = (error) => reject(error);
    });
  };

  getPDPALastDocument(): Observable<PdpaDocument> {
    if (this.dnaToken) {
      const header: HttpHeaders = new HttpHeaders()
        .set('Authorization', `dna ${this.dnaToken}`)
      ;
      return this.http.get(`${this.pdpaApi}/doc`, {
        headers: header
      })
    } else {
      return this.http.get(`${this.pdpaApi}/doc`)
    }
  }

  getPDPALastSubmit(): Observable<PdpaAgreement> {
    if (this.dnaToken) {
      const header: HttpHeaders = new HttpHeaders()
        .set('Authorization', `dna ${this.dnaToken}`)
      ;
      return this.http.get(`${this.pdpaApi}/last-submit`, {
        headers: header
      })
    } else {
      return this.http.get(`${this.pdpaApi}/last-submit`)
    }
  }

  savePDPALastDocument(body: PdpaDocument): Observable<PdpaDocument> {
    return this.http.post(`${this.pdpaApi}/doc`, body)
  }

  savePDPAAgreement(body: PdpaAgreement): Observable<PdpaDocument> {
    if (this.dnaToken) {
      const header: HttpHeaders = new HttpHeaders()
        .set('Authorization', `dna ${this.dnaToken}`)
      ;
      return this.http.post(`${this.pdpaApi}/agreement`, body, {
        headers: header
      })
    } else {
      return this.http.post(`${this.pdpaApi}/agreement`, body)
    }
  }

  exportPDPAAgreement(): Observable<PDPAEmployeeReport[]> {
    return this.http.get<PDPAEmployeeReport[]>(`${this.pdpaApi}/export-agreement`)
  }
}
