import { Component, OnInit, Input, OnChanges, TemplateRef, Output, EventEmitter } from '@angular/core';

import { faIcon } from 'src/app/core/icon/fa.icon';
import Swal from 'sweetalert2';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { Employee } from 'src/app/core/models/employee';
import { TypeaheadMatch, BsModalService, BsModalRef } from 'ngx-bootstrap';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { StrategicProjectTracking, StrategicProjectWorkflowStatus } from 'src/app/core/models/strategicProjectTracking';
import { StrategicProjectTrackingService } from 'src/app/core/services/strategic-project-tracking.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-strategic-project-detail',
    templateUrl: './strategic-project-detail.component.html',
    styleUrls: ['./strategic-project-detail.component.scss'],
})

export class StrategicProjectDetailComponent implements OnInit, OnChanges {
    faIcon = faIcon;
    modalRef: BsModalRef;
    @Input() strategicProjectData: StrategicProjectTracking;
    @Input() quarterData: String;
    @Input() allName;
    @Input() strategicProjectName: string;
    @Output() onEmitData = new EventEmitter();
    @Input() pageData: String;

    confirmationWording: string = '';
    confirmationStatus: string = '';
    milestoneList: FormArray;
    targetIndex: number = 0;
    strategicForm = this.fb.group({
        id: [{ value: 0, disabled: false }],
        selectedMilestonePeriod: [{ value: '', disabled: false }],
        selectedEstimatePeriod: [{ value: '', disabled: false }],
        estimateQuarterScore: [{ value: 0, disabled: false }, [Validators.min(0), Validators.max(5)]],
        estimateYearScore: [{ value: 0, disabled: false }, [Validators.min(0), Validators.max(5)]],
        // corporateKPIs: [{ value: false, disabled: false }],
        status: [{ value: '', disabled: false }],
        reasonForApproveOrReject: [{ value: '', disabled: false }],
        milestoneList: this.fb.array([]),
        target: [{ value: '', disabled: false }],
        budgetReason: [{ value: '', disabled: false }],
        // selectedBudgetReasonPeriod: [{ value: '', disabled: false }],
    });
    approverTag = [];
    coApproverTag = [];
    reviewerTag = [];
    coReviewerTag = [];
    creatorTag = [];
    employeeList: Employee[] = [];
    budgetProjectTag = [];
    budgetProjectList = [];
    asOfDate;
    months = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];

    reasonForm = this.fb.group({
        reasonForApproveOrReject: [{ value: '', disabled: false }]
    });

    allQuarter: string[] = [];
    allYear: number[] = [];
    currentQuarter: string = '';
    isEditable: boolean = true;
    pastPeriod: string[] = [];
    currentYear: number;
    employeeDetail: Employee;
    isApprover: boolean = false;
    isReviewer: boolean = false;
    isCreator: boolean = false;
    isAdminOfStrategicProject: boolean = false;
    isShowEstimateCorporateKPI: boolean = false;
    isStakeHolder: boolean = false;
    constructor(
        private fb: FormBuilder,
        private modalService: BsModalService,
        private strategicProjectTrackingService: StrategicProjectTrackingService,
        private router: Router,
        private employeeService: EmployeeService,
        private datepipe: DatePipe,
    ) {

    }

    ngOnInit() { }

    ngOnChanges() {
        console.log('this.strategicProjectData', this.strategicProjectData);
        console.log('quarterData', this.quarterData);
        if (this.strategicProjectData && !this.strategicForm.getRawValue().id) {
            this.initStrategicPlan();
        }
    }

    initStrategicPlan() {
        this.getPeriod();
        this.getUserDetail();
        this.getAllEmployee();
        this.getBudgetProjectByYear(this.strategicProjectData.year - 543);
        if (this.strategicProjectData.budgetProject.length > 0) {
            this.getBudgetByProject();
        }
        this.milestoneList = this.strategicForm.get('milestoneList') as FormArray;

        let status = '';
        if (this.strategicProjectData.strategicProjectWorkflowStatus) {
            if (Array.isArray(this.strategicProjectData.strategicProjectWorkflowStatus)) {
                status = this.strategicProjectData.strategicProjectWorkflowStatus.find((data) => { return data.quarter === this.quarterData; }).status;
            }
        }

        this.strategicForm.patchValue({
            id: this.strategicProjectData.id,
            reasonForApproveOrReject: this.strategicProjectData.reasonForApproveOrReject ? this.strategicProjectData.reasonForApproveOrReject : '',
            status: status ? status : ''
        });


        if (Array.isArray(this.strategicProjectData.strategicProjectWorkflowStatus)) {
            let workflow: Array<StrategicProjectWorkflowStatus> = this.strategicProjectData.strategicProjectWorkflowStatus;
            if (workflow.some((data) => data.status ? data.status.toUpperCase() === 'REJECT' && data.quarter === this.quarterData : false) || workflow.some((data) => data.status ? data.status.toUpperCase() === 'APPROVE' && data.quarter === this.quarterData : false) ||
                workflow.some((data) => data.status ? data.status.toUpperCase() === 'DELETE' && data.quarter === this.quarterData : false)) {
                this.isEditable = false;
            }
        }

        if (Array.isArray(this.strategicProjectData.targets)) {
            this.strategicProjectData.targets.forEach(item => {
                let progressData = {
                    progressDetail: '',
                };
                let statusData = {
                    status: '',
                };
                let mileStoneData = {
                    milestoneDetail: '',
                };
                if (item.milestoneQ1 && !item.milestoneQ1.progressDetail) { Object.assign(item.milestoneQ1, progressData); }
                if (item.milestoneQ2 && !item.milestoneQ2.progressDetail) { Object.assign(item.milestoneQ2, progressData); }
                if (item.milestoneQ3 && !item.milestoneQ3.progressDetail) { Object.assign(item.milestoneQ3, progressData); }
                if (item.milestoneQ4 && !item.milestoneQ4.progressDetail) { Object.assign(item.milestoneQ4, progressData); }
                if (item.milestoneQ1 && !item.milestoneQ1.status) { Object.assign(item.milestoneQ1, statusData); }
                if (item.milestoneQ2 && !item.milestoneQ2.status) { Object.assign(item.milestoneQ2, statusData); }
                if (item.milestoneQ3 && !item.milestoneQ3.status) { Object.assign(item.milestoneQ3, statusData); }
                if (item.milestoneQ4 && !item.milestoneQ4.status) { Object.assign(item.milestoneQ4, statusData); }
                if (item.milestoneQ1 && !item.milestoneQ1.milestoneDetail) { Object.assign(item.milestoneQ1, mileStoneData); }
                if (item.milestoneQ2 && !item.milestoneQ2.milestoneDetail) { Object.assign(item.milestoneQ2, mileStoneData); }
                if (item.milestoneQ3 && !item.milestoneQ3.milestoneDetail) { Object.assign(item.milestoneQ3, mileStoneData); }
                if (item.milestoneQ4 && !item.milestoneQ4.milestoneDetail) { Object.assign(item.milestoneQ4, mileStoneData); }

                let milestoneDetailData = {
                    milestoneDetail: "",
                    new: true,
                    progressDetail: "",
                    status: "",
                    target: ""
                };

                this.milestoneList.push(this.fb.group({
                    id: item.id,
                    createBy: item.createBy ? item.createBy : '',
                    createDate: item.createDate ? item.createDate : '',
                    detail: item.detail ? item.detail : '',
                    corporateKPIs: item.corporateKPIs ? item.corporateKPIs : false,
                    milestoneQ1: item.milestoneQ1 ? this.fb.group(item.milestoneQ1) : this.fb.group(milestoneDetailData),
                    milestoneQ2: item.milestoneQ2 ? this.fb.group(item.milestoneQ2) : this.fb.group(milestoneDetailData),
                    milestoneQ3: item.milestoneQ3 ? this.fb.group(item.milestoneQ3) : this.fb.group(milestoneDetailData),
                    milestoneQ4: item.milestoneQ4 ? this.fb.group(item.milestoneQ4) : this.fb.group(milestoneDetailData),
                    _new: item._new ? item._new : '',
                    project$: item.project ? this.fb.group(item.project) : '',
                    updateBy: item.updateBy ? item.updateBy : '',
                    updateDate: item.updateDate ? item.updateDate : '',
                }));

                if (item.corporateKPIs) {
                    this.isShowEstimateCorporateKPI = true;
                }
            });
        }
    }

    initialTagName() {
        if (Array.isArray(this.strategicProjectData.approver)) {
            if (this.strategicProjectData.approver.length > 0) {
                this.strategicProjectData.approver.forEach(item => {
                    let empData = this.employeeList.find(data => { return data.email.toUpperCase() === item.toUpperCase(); });
                    if (empData) {
                        let labeltemp = {
                            empID: empData.empID,
                            email: empData.email,
                            nameEn: empData.nameEn
                        };
                        this.approverTag.push(labeltemp);
                    }
                });
            }
        }

        if (Array.isArray(this.strategicProjectData.coApprover)) {
            if (this.strategicProjectData.coApprover.length > 0) {
                this.strategicProjectData.coApprover.forEach(item => {
                    let empData = this.employeeList.find(data => { return data.email.toUpperCase() === item.toUpperCase(); });
                    if (empData) {
                        let labeltemp = {
                            empID: empData.empID,
                            email: empData.email,
                            nameEn: empData.nameEn
                        };
                        this.coApproverTag.push(labeltemp);
                    }
                });
            }
        }

        if (Array.isArray(this.strategicProjectData.reviewer)) {
            if (this.strategicProjectData.reviewer.length > 0) {
                this.strategicProjectData.reviewer.forEach(item => {
                    let empData = this.employeeList.find(data => { return data.email.toUpperCase() === item.toUpperCase(); });
                    if (empData) {
                        let labeltemp = {
                            empID: empData.empID,
                            email: empData.email,
                            nameEn: empData.nameEn
                        };
                        this.reviewerTag.push(labeltemp);
                    }
                });
            }
        }

        if (Array.isArray(this.strategicProjectData.coReviewer)) {
            if (this.strategicProjectData.coReviewer.length > 0) {
                this.strategicProjectData.coReviewer.forEach(item => {
                    let empData = this.employeeList.find(data => { return data.email.toUpperCase() === item.toUpperCase(); });
                    if (empData) {
                        let labeltemp = {
                            empID: empData.empID,
                            email: empData.email,
                            nameEn: empData.nameEn
                        };
                        this.coReviewerTag.push(labeltemp);
                    }
                });
            }
        }

        if (Array.isArray(this.strategicProjectData.creator)) {
            if (this.strategicProjectData.creator.length > 0) {
                this.strategicProjectData.creator.forEach(item => {
                    let empData = this.employeeList.find(data => { return data.email.toUpperCase() === item.toUpperCase(); });
                    if (empData) {
                        let labeltemp = {
                            empID: empData.empID,
                            email: empData.email,
                            nameEn: empData.nameEn
                        };
                        this.creatorTag.push(labeltemp);
                    }
                });
            }
        }
    }

    getBudgetProjectByYear(year) {
        this.strategicProjectTrackingService.getStrategicProjectByYear(year).toPromise()
            .then(res => {
                console.log('res', res);
                this.budgetProjectList = res;
                if (Array.isArray(this.budgetProjectList)) {
                    this.budgetProjectList = this.budgetProjectList.map(d => {
                        d.projectName = `${d.project} : ${d.projectName}`;
                        return d;
                    });

                    let notAvailableProject = {
                        fiscalYear: 0,
                        id: 0,
                        project: "N/A",
                        projectName: "N/A",
                    };
                    this.budgetProjectList.push(notAvailableProject);
                }

                if (Array.isArray(this.strategicProjectData.budgetProject)) {
                    if (this.strategicProjectData.budgetProject.length > 0) {
                        console.log('this.strategicProjectData.budgetProject.length', this.strategicProjectData.budgetProject.length);

                        this.strategicProjectData.budgetProject.forEach(item => {
                            let project = this.budgetProjectList.find(data => { return data.project.toUpperCase() === item.toUpperCase(); });
                            if (project) {
                                let labeltemp = {
                                    project: project.project,
                                    projectName: project.projectName
                                };
                                this.budgetProjectTag.push(labeltemp);
                            }
                        });
                    }
                }

            })
            .catch(err => {
                console.log('err', err);
            });
    }

    getBudgetByProject() {
        let period = this.calculatePeriod();
        let year = this.strategicProjectData.year - 543;

        this.strategicProjectTrackingService.getBudgetByProject(year, period, this.strategicProjectData.budgetProject).toPromise()
            .then(res => {
                console.log('res', res);
                this.strategicProjectData.budgetUtilization = res.budgetUtilization;
                this.strategicProjectData.capex = res.capex;
                this.strategicProjectData.opex = res.opex;
            })
            .catch(err => { console.log('err', err); });
    }

    getAllEmployee() {
        this.employeeService.employeeList().toPromise()
            .then(res => {
                this.employeeList = res;
                this.employeeList = this.employeeList.filter(item => { return item.email; });
                this.initialTagName();
            })
            .catch(err => {
                console.log('err', err);
            });
    }

    get strategicFormGroup() {
        return this.strategicForm.controls;
    }

    get formDataList() {
        let data = <FormArray>this.strategicForm.get('milestoneList');

        return data;
    }

    getPeriod() {
        this.currentYear = new Date().getFullYear() + 543;
        let month = new Date().getMonth();

        this.currentQuarter = `Q${this.calculateQuarter(month)}/${this.currentYear.toString().substr(-2)}`;
        let projectPeriod = `Q${this.calculateQuarter(month)}/${this.strategicProjectData.year.toString().substr(-2)}`;
        if (this.strategicProjectData.year < this.currentYear) {
            this.isEditable = false;
        }
        for (let i = 1; i < 5; i++) {
            let quarter = `Q${i}/${this.strategicProjectData.year.toString().substr(-2)}`;
            this.allQuarter.push(quarter);
        }
        let estimateQuarter = 0;
        let budgetReason = '';

        if (month === 0 && this.currentYear - this.strategicProjectData.year === 1) {
            estimateQuarter = this.strategicProjectData.corporateKPIScoreQ4;
            projectPeriod = `Q4/${this.strategicProjectData.year.toString().substr(-2)}`;
            this.isEditable = true;
        } else {
            if (!this.quarterData) {
                if (this.calculateQuarter(month) === 1) {
                    estimateQuarter = this.strategicProjectData.corporateKPIScoreQ1;
                } else if (this.calculateQuarter(month) === 2) {
                    estimateQuarter = this.strategicProjectData.corporateKPIScoreQ2;
                } else if (this.calculateQuarter(month) === 3) {
                    estimateQuarter = this.strategicProjectData.corporateKPIScoreQ3;
                } else if (this.calculateQuarter(month) === 4) {
                    estimateQuarter = this.strategicProjectData.corporateKPIScoreQ4;
                }
            } else {
                if (this.quarterData === 'Q1') {
                    estimateQuarter = this.strategicProjectData.corporateKPIScoreQ1;
                    projectPeriod = `Q1/${this.strategicProjectData.year.toString().substr(-2)}`;
                    budgetReason = this.strategicProjectData.budgetReasonQ1;
                } else if (this.quarterData === 'Q2') {
                    estimateQuarter = this.strategicProjectData.corporateKPIScoreQ2;
                    projectPeriod = `Q2/${this.strategicProjectData.year.toString().substr(-2)}`;
                    budgetReason = this.strategicProjectData.budgetReasonQ2;
                } else if (this.quarterData === 'Q3') {
                    estimateQuarter = this.strategicProjectData.corporateKPIScoreQ3;
                    projectPeriod = `Q3/${this.strategicProjectData.year.toString().substr(-2)}`;
                    budgetReason = this.strategicProjectData.budgetReasonQ3;
                } else if (this.quarterData === 'Q4') {
                    estimateQuarter = this.strategicProjectData.corporateKPIScoreQ4;
                    projectPeriod = `Q4/${this.strategicProjectData.year.toString().substr(-2)}`;
                    budgetReason = this.strategicProjectData.budgetReasonQ4;
                }
            }
        }

        this.strategicForm.patchValue({
            selectedMilestonePeriod: projectPeriod,
            selectedEstimatePeriod: projectPeriod,
            estimateQuarterScore: estimateQuarter,
            estimateYearScore: this.strategicProjectData.corporateKPIScoreQ4,
            budgetReason: budgetReason
        });
    }

    getUserDetail() {
        this.employeeService.me().toPromise()
            .then(res => {
                this.employeeDetail = res;
                this.checkPermission();
                this.isStakeHolder = this.checkStakeHolder();
                this.onChangeMilestonePeriod();
                this.onChangeEstimatePeriod();
            })
            .catch(err => {
                console.log('err', err);
            });
    }

    checkPermission() {
        if (this.employeeDetail && this.employeeDetail.deptRelation) {
            if ((this.employeeDetail.deptRelation.deptID === '100046' || this.employeeDetail.deptRelation.deptID === '100060' || this.employeeDetail.deptRelation.deptID === '600029') && this.router.url.includes('administrative-strategic')) {
                this.isAdminOfStrategicProject = true;
            }
        }

        if (Array.isArray(this.strategicProjectData.approver)) {
            if (this.strategicProjectData.approver.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()))) {
                this.isApprover = true;
            } else {
                this.isApprover = false;
            }
        } else {
            this.isApprover = false;
        }

        if (Array.isArray(this.strategicProjectData.reviewer)) {
            if (this.strategicProjectData.reviewer.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()))) {
                this.isReviewer = true;
            } else {
                this.isReviewer = false;
            }
        } else {
            this.isReviewer = false;
        }

        if (Array.isArray(this.strategicProjectData.creator)) {
            if (this.strategicProjectData.creator.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()))) {
                this.isCreator = true;
            } else {
                this.isCreator = false;
            }
        } else {
            this.isCreator = false;
        }

    }

    checkStakeHolder(): boolean {
        let isApprover = false;
        let isCoApprover = false;
        let isReviewer = false;
        let isCoReviewer = false;
        let isCreator = false;

        if (!this.employeeDetail.email) {
            return false;
        }

        if (Array.isArray(this.strategicProjectData.approver)) {
            isApprover = this.strategicProjectData.approver.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()));
        }
        if (Array.isArray(this.strategicProjectData.coApprover)) {
            isCoApprover = this.strategicProjectData.coApprover.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()));
        }

        if (Array.isArray(this.strategicProjectData.reviewer)) {
            isReviewer = this.strategicProjectData.reviewer.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()));
        }

        if (Array.isArray(this.strategicProjectData.coReviewer)) {
            isCoReviewer = this.strategicProjectData.coReviewer.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()));
        }

        if (Array.isArray(this.strategicProjectData.approver)) {
            isCreator = this.strategicProjectData.creator.some((data) => data.toUpperCase().includes(this.employeeDetail.email.toUpperCase()));
        }

        return isApprover || isCoApprover || isReviewer || isCoReviewer || isCreator;
    }

    onChangeMilestonePeriod() {
        let selectedQuarterData = +this.strategicFormGroup.selectedMilestonePeriod.value.substr(1, 1);
        if (!this.isAdminOfStrategicProject) {
            let currentQuarterData = +this.currentQuarter.substr(1, 1);
            if (this.strategicProjectData.year === this.currentYear) {
                if (selectedQuarterData >= currentQuarterData) {
                    this.strategicForm.controls.milestoneList.enable();
                    this.strategicForm.controls.budgetReason.enable();
                } else if (selectedQuarterData < currentQuarterData) {
                    this.strategicForm.controls.milestoneList.disable();
                    this.strategicForm.controls.budgetReason.disable();
                }
            } else if (selectedQuarterData === 4 && new Date().getMonth() === 0 && this.currentYear - this.strategicProjectData.year === 1) {
                this.strategicForm.controls.milestoneList.enable();
                this.strategicForm.controls.budgetReason.enable();
            } else if (this.strategicProjectData.year < this.currentYear) {
                this.strategicForm.controls.milestoneList.disable();
                this.strategicForm.controls.budgetReason.disable();
            }

            if (this.strategicForm.controls.milestoneList.enabled) {
                if (Array.isArray(this.strategicProjectData.strategicProjectWorkflowStatus)) {
                    if (this.strategicProjectData.strategicProjectWorkflowStatus.some((data) => data.status ? (data.status.toUpperCase() === 'APPROVED' || data.status.toUpperCase() === 'REJECT' || data.status.toUpperCase() === 'DELETE') && data.quarter === this.strategicFormGroup.selectedMilestonePeriod.value.substr(0, 2) : false)) {
                        this.strategicForm.controls.milestoneList.disable();
                        this.strategicForm.controls.budgetReason.disable();
                    }
                }
            }

            if (!this.checkStakeHolder()) {
                this.strategicForm.controls.milestoneList.disable();
            }
        }


        if (this.strategicProjectData.budgetProject.length > 0) {
            let budgetReason = '';

            if (selectedQuarterData === 1) {
                budgetReason = this.strategicProjectData.budgetReasonQ1;
            } else if (selectedQuarterData === 2) {
                budgetReason = this.strategicProjectData.budgetReasonQ2;
            } else if (selectedQuarterData === 3) {
                budgetReason = this.strategicProjectData.budgetReasonQ3;
            } else if (selectedQuarterData === 4) {
                budgetReason = this.strategicProjectData.budgetReasonQ4;
            }

            let period = 0;
            let month = new Date().getMonth();
            let quarter = selectedQuarterData;
            if (this.strategicProjectData.year - 543 == new Date().getFullYear()) {
                if (quarter >= this.calculateQuarter(+month)) {
                    period = +month + 1;
                    let current = new Date();
                    let previousMonth = new Date(current.getFullYear(), current.getMonth(), 0);
                    this.asOfDate = `${previousMonth.getDate()} ${this.months[previousMonth.getMonth()]} ${this.strategicProjectData.year}`;
                } else {
                    period = (this.calculateQuarter(+month) * 3);
                    if (period == 3) {
                        this.asOfDate = `31 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
                    } else if (period == 6) {
                        this.asOfDate = `30 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
                    } else if (period == 9) {
                        this.asOfDate = `30 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
                    } else if (period == 12) {
                        this.asOfDate = `31 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
                    }
                }

            } else if (this.strategicProjectData.year - 543 > new Date().getFullYear()) {
                period = 0;
            } else {
                // period = 12;
                period = quarter * 3;
                budgetReason = this.strategicProjectData.budgetReasonQ4;

                if (quarter == 1) {
                    this.asOfDate = `31 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
                } else if (quarter == 2) {
                    this.asOfDate = `30 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
                } else if (quarter == 3) {
                    this.asOfDate = `30 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
                } else if (quarter == 4) {
                    this.asOfDate = `31 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
                }
            }

            this.strategicForm.patchValue({
                budgetReason: budgetReason,
            });

            let year = this.strategicProjectData.year - 543;
            this.strategicProjectTrackingService.getBudgetByProject(year, period, this.strategicProjectData.budgetProject).toPromise()
                .then(res => {
                    console.log('res', res);
                    this.strategicProjectData.budgetUtilization = res.budgetUtilization;
                    this.strategicProjectData.capex = res.capex;
                    this.strategicProjectData.opex = res.opex;
                })
                .catch(err => { console.log('err', err); });
        }

    }

    onChangeEstimatePeriod() {
        let selectedQuarterData = +this.strategicFormGroup.selectedEstimatePeriod.value.substr(1, 1);
        let currentQuarterData = +this.currentQuarter.substr(1, 1);
        let estimateQuarter = 0;

        if (selectedQuarterData === 1) {
            estimateQuarter = this.strategicProjectData.corporateKPIScoreQ1;
        } else if (selectedQuarterData === 2) {
            estimateQuarter = this.strategicProjectData.corporateKPIScoreQ2;
        } else if (selectedQuarterData === 3) {
            estimateQuarter = this.strategicProjectData.corporateKPIScoreQ3;
        } else if (selectedQuarterData === 4) {
            estimateQuarter = this.strategicProjectData.corporateKPIScoreQ4;
        }

        this.strategicForm.patchValue({
            estimateQuarterScore: estimateQuarter,
        });

        if (!this.isAdminOfStrategicProject) {
            if (this.strategicProjectData.year > this.currentYear) {
                this.strategicForm.controls.estimateQuarterScore.enable();
                this.strategicForm.controls.estimateYearScore.enable();
            } else if (this.strategicProjectData.year === this.currentYear) {
                if (selectedQuarterData >= currentQuarterData) {
                    this.strategicForm.controls.estimateQuarterScore.enable();
                    this.strategicForm.controls.estimateYearScore.enable();

                    if (this.strategicFormGroup.selectedEstimatePeriod.value.includes('Q4')) {
                        this.strategicForm.patchValue({
                            estimateQuarterScore: this.strategicFormGroup.estimateYearScore.value,
                        });
                    }
                } else {
                    this.strategicForm.controls.estimateQuarterScore.disable();
                    this.strategicForm.controls.estimateYearScore.disable();
                }
            } else if (selectedQuarterData === 4 && new Date().getMonth() === 0 && this.currentYear - this.strategicProjectData.year === 1) {
                this.strategicForm.controls.estimateQuarterScore.enable();
                this.strategicForm.controls.estimateYearScore.enable();
                this.strategicForm.patchValue({
                    estimateQuarterScore: this.strategicFormGroup.estimateYearScore.value,
                });
            } else if (this.strategicProjectData.year < this.currentYear) {
                this.strategicForm.controls.estimateQuarterScore.disable();
                this.strategicForm.controls.estimateYearScore.disable();
            }

            if (this.strategicForm.controls.estimateQuarterScore.enabled) {
                if (Array.isArray(this.strategicProjectData.strategicProjectWorkflowStatus)) {
                    if (this.strategicProjectData.strategicProjectWorkflowStatus.some((data) => data.status ? (data.status.toUpperCase() === 'APPROVED' || data.status.toUpperCase() === 'REJECT' || data.status.toUpperCase() === 'DELETE') && data.quarter === this.strategicFormGroup.selectedEstimatePeriod.value.substr(0, 2) : false)) {
                        this.strategicForm.controls.estimateQuarterScore.disable();
                    }
                }
            }

            if (this.strategicForm.controls.estimateYearScore.enabled) {
                if (Array.isArray(this.strategicProjectData.strategicProjectWorkflowStatus)) {
                    if (this.strategicProjectData.strategicProjectWorkflowStatus.some((data) => data.status ? (data.status.toUpperCase() === 'APPROVED' || data.status.toUpperCase() === 'REJECT' || data.status.toUpperCase() === 'DELETE') && data.quarter === 'Q4' : false)) {
                        this.strategicForm.controls.estimateYearScore.disable();
                    }
                }
            }

            if (!this.checkStakeHolder()) {
                this.strategicForm.controls.estimateQuarterScore.disable();
                this.strategicForm.controls.estimateYearScore.disable();
            }
        }
    }

    onShowConfirmationModal(template: TemplateRef<any>, status, index) {
        this.confirmationStatus = status;
        this.targetIndex = index;
        if (status.toUpperCase() === 'SAVE') {
            if (this.validateForm()) {
                this.confirmationWording = 'Do you want to save this strategic project plan?';
                this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
            }
        } else if (status.toUpperCase() === 'APPROVE') {
            if (this.validateForm()) {
                this.confirmationWording = 'Reason for approve this strategic project plan?';
                this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
            }
        } else if (status.toUpperCase() === 'SUBMITFORAPPROVAL') {
            if (this.validateForm()) {
                this.confirmationWording = 'Do you want to submit for approval on this strategic project plan?';
                this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
            }
        } else if (status.toUpperCase() === 'SUBMIT') {
            if (this.validateForm()) {
                this.confirmationWording = 'Do you want to submit this strategic project plan?';
                this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
            }
        } else if (status.toUpperCase() === 'REJECT') {
            this.confirmationWording = 'Reason for reject this strategic project plan?';
            this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });

        } else if (status.toUpperCase() === 'BACK') {
            this.confirmationWording = 'Do you want to go back?';
            this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
        } else if (status.toUpperCase() === 'DELETETARGET') {
            this.confirmationWording = 'Do you want to delete this target?';
            this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
        }
    }

    confirm() {
        if (this.confirmationStatus.toLocaleUpperCase() === 'SAVE' ||
            this.confirmationStatus.toLocaleUpperCase() === 'APPROVE' ||
            this.confirmationStatus.toLocaleUpperCase() === 'SUBMITFORAPPROVAL' ||
            this.confirmationStatus.toLocaleUpperCase() === 'SUBMIT') {
            this.modalRef.hide();
            this.onSaveStrategicProject();

        } else if (this.confirmationStatus.toLocaleUpperCase() === 'REJECT') {
            if (this.reasonForm.controls.reasonForApproveOrReject.value === '') {
                this.alert(false, 'Please fill in the reason for reject.');
                return;
            }
            this.onRejectStrategicProject();
            this.modalRef.hide();

        } else if (this.confirmationStatus.toLocaleUpperCase() === 'BACK') {
            this.modalRef.hide();
            this.onEmitData.emit({ displayItem: 'list', page: this.pageData, quarter: this.quarterData });
        } else if (this.confirmationStatus.toLocaleUpperCase() === 'DELETETARGET') {
            this.modalRef.hide();
            this.onDeleteTarget();
        }
    }

    onDeleteTarget() {
        let milestoneListData = this.milestoneList.getRawValue();
        if (Array.isArray(milestoneListData)) {
            if (milestoneListData.length > 0) {
                let milestoneData = milestoneListData[this.targetIndex];
                if (milestoneData.id) {
                    this.strategicProjectTrackingService.deleteProjectTargetByID(milestoneData.id).toPromise()
                        .then(res => {
                            this.alert(true, 'This target has been deleted.');
                            this.milestoneList.removeAt(this.targetIndex);
                        })
                        .catch(err => {
                            console.log('err', err);
                            this.alert(false, 'Can not delete this target, Please try agian.');
                        });
                }
            }
        }
    }

    async onSaveStrategicProject() {
        let selectedEstimateCorporateQuarterData = +this.strategicFormGroup.selectedEstimatePeriod.value.substr(1, 1);
        let selectedMilestoneQuarterData = +this.strategicFormGroup.selectedMilestonePeriod.value.substr(1, 1);

        let isAleardyShowSuccessAlert = false;
        if (this.strategicForm.controls.estimateQuarterScore.status !== 'DISABLED' && this.strategicForm.controls.estimateYearScore.status !== 'DISABLED' && this.isShowEstimateCorporateKPI) {
            let coporateKPIScore = {
                corporateKPIScoreQ1: selectedEstimateCorporateQuarterData === 1 ? this.strategicFormGroup.estimateQuarterScore.value : this.strategicProjectData.corporateKPIScoreQ1,
                corporateKPIScoreQ2: selectedEstimateCorporateQuarterData === 2 ? this.strategicFormGroup.estimateQuarterScore.value : this.strategicProjectData.corporateKPIScoreQ2,
                corporateKPIScoreQ3: selectedEstimateCorporateQuarterData === 3 ? this.strategicFormGroup.estimateQuarterScore.value : this.strategicProjectData.corporateKPIScoreQ3,
                corporateKPIScoreQ4: Number.isInteger(this.strategicFormGroup.estimateYearScore.value) ? this.strategicFormGroup.estimateYearScore.value : this.strategicProjectData.corporateKPIScoreQ4
            };

            await this.strategicProjectTrackingService.updateCorporateKPIScore(this.strategicProjectData.id, coporateKPIScore).toPromise()
                .then(res => {
                    if (this.strategicForm.controls.milestoneList.status !== 'DISABLED' && this.confirmationStatus.toLocaleUpperCase() === 'SAVE') {
                        this.alert(true, 'This strategic project has been saved.');
                        isAleardyShowSuccessAlert = true;
                    }

                    this.strategicProjectData.corporateKPIScoreQ1 = coporateKPIScore.corporateKPIScoreQ1;
                    this.strategicProjectData.corporateKPIScoreQ2 = coporateKPIScore.corporateKPIScoreQ2;
                    this.strategicProjectData.corporateKPIScoreQ3 = coporateKPIScore.corporateKPIScoreQ3;
                    this.strategicProjectData.corporateKPIScoreQ4 = coporateKPIScore.corporateKPIScoreQ4;
                })
                .catch(err => {
                    console.log('err', err);
                    this.alert(false, 'Cannot update this strategic project tracking, Please try again.');
                });
        }

        if (this.strategicForm.controls.milestoneList.status !== 'DISABLED') {
            let formDataList = this.formDataList.getRawValue();
            if (Array.isArray(formDataList)) {
                formDataList.forEach((item, index) => {
                    let milestoneBody = {
                        progressDetail: '',
                        status: null
                    };

                    let id = 0;


                    if (selectedMilestoneQuarterData === 1) {
                        id = item.milestoneQ1 && item.milestoneQ1.id ? item.milestoneQ1.id : 0;
                        milestoneBody.progressDetail = item.milestoneQ1 ? item.milestoneQ1.progressDetail : '';
                        milestoneBody.status = item.milestoneQ1 ? item.milestoneQ1.status : null;
                    } else if (selectedMilestoneQuarterData === 2) {
                        id = item.milestoneQ2 && item.milestoneQ2.id ? item.milestoneQ2.id : 0;
                        milestoneBody.progressDetail = item.milestoneQ2 ? item.milestoneQ2.progressDetail : '';
                        milestoneBody.status = item.milestoneQ2 ? item.milestoneQ2.status : null;
                    } else if (selectedMilestoneQuarterData === 3) {
                        id = item.milestoneQ3 && item.milestoneQ3.id ? item.milestoneQ3.id : 0;
                        milestoneBody.progressDetail = item.milestoneQ3 ? item.milestoneQ3.progressDetail : '';
                        milestoneBody.status = item.milestoneQ3 ? item.milestoneQ3.status : null;
                    } else if (selectedMilestoneQuarterData === 4) {
                        id = item.milestoneQ4 && item.milestoneQ4.id ? item.milestoneQ4.id : 0;
                        milestoneBody.progressDetail = item.milestoneQ4 ? item.milestoneQ4.progressDetail : '';
                        milestoneBody.status = item.milestoneQ4 ? item.milestoneQ4.status : null;
                    }


                    if (!milestoneBody.status) {
                        milestoneBody.status = null;
                    }


                    // for(let i = 1; i < 5 ; i++) {
                    //     let id = 0;

                    //     let milestoneBody = {
                    //         progressDetail: '',
                    //         status: null
                    //     }

                    //     id = item[`milestoneQ${i}`] && item[`milestoneQ${i}`].id ? item[`milestoneQ${i}`].id : 0;
                    //     milestoneBody.progressDetail = item[`milestoneQ${i}`] ? item[`milestoneQ${i}`].progressDetail : '';
                    //     milestoneBody.status = item[`milestoneQ${i}`] ? item[`milestoneQ${i}`].status : null;


                    //     if(!milestoneBody.status) {
                    //         milestoneBody.status = null;
                    //     }

                    //     if(id !== 0) {
                    //         this.strategicProjectTrackingService.updateMileStone(id, milestoneBody).toPromise()
                    //             .then(res => {
                    //             })
                    //             .catch(err => {
                    //                 console.log('err', err);
                    //             })
                    //             // if(this.confirmationStatus.toLocaleUpperCase() === 'SAVE' && !isAleardyShowSuccessAlert && this.isAdminOfStrategicProject) {
                    //             //     this.alert(true,'This strategic project has been saved.');
                    //             // }
                    //     }
                    // }



                    if (id !== 0) {
                        this.strategicProjectTrackingService.updateMileStone(id, milestoneBody).toPromise()
                            .then(res => {
                            })
                            .catch(err => {
                                console.log('err', err);
                                if (!this.isAdminOfStrategicProject) {
                                    this.alert(false, 'Cannot update this strategic project tracking, Please try again.');
                                }
                            });
                        if (this.confirmationStatus.toLocaleUpperCase() === 'SAVE' && !isAleardyShowSuccessAlert && this.isAdminOfStrategicProject) {
                            this.alert(true, 'This strategic project has been saved.');
                        }
                    }
                });
                // if(this.confirmationStatus.toLocaleUpperCase() === 'SAVE' && !isAleardyShowSuccessAlert && this.isAdminOfStrategicProject) {
                //     this.alert(true,'This strategic project has been saved.');
                // }
            }

        }

        let action = '';

        if (this.confirmationStatus.toLocaleUpperCase() === 'APPROVE') {
            action = 'APPROVE';
        } else if (this.confirmationStatus.toLocaleUpperCase() === 'SUBMITFORAPPROVAL') {
            action = 'SUBMIT_FOR_APPROVE';
        } else if (this.confirmationStatus.toLocaleUpperCase() === 'SUBMIT') {
            action = 'SUBMIT_FOR_REVIEW';
        }

        if (this.confirmationStatus.toLocaleUpperCase() !== 'SAVE') {
            let body = undefined;
            if (action === 'APPROVE') {
                body = {
                    reasonForApproveOrReject: this.reasonForm.controls.reasonForApproveOrReject.value
                };
            }
            this.strategicProjectTrackingService.workflow(this.strategicProjectData.id, action, this.quarterData, body).toPromise()
                .then(res => {
                    if (action === 'SUBMIT_FOR_REVIEW') {
                        this.alert(true, 'Successfully submit this strategic project plan.');
                    } else if (action === 'SUBMIT_FOR_APPROVE') {
                        this.alert(true, 'Successfully submit for approval on this strategic project plan.');
                    } else if (action === 'APPROVE') {
                        this.alert(true, 'Successfully approve this strategic project plan.');
                    }

                    this.onEmitData.emit({ displayItem: 'list', page: this.pageData, quarter: this.quarterData });
                })
                .catch(err => {
                    console.log('err', err);
                    this.alert(false, 'Can not send an update to users, Please try again.');
                });
        } else if (this.confirmationStatus.toLocaleUpperCase() === 'SAVE' && this.isAdminOfStrategicProject) {

            let formdata = this.strategicForm.getRawValue();
            let allApprover = [];
            if (Array.isArray(this.approverTag)) {
                if (this.approverTag.length > 0) {
                    this.approverTag.forEach(data => { allApprover.push(data.email); });
                }
            }

            let allCoApprover = [];
            if (Array.isArray(this.coApproverTag)) {
                if (this.coApproverTag.length > 0) {
                    this.coApproverTag.forEach(data => { allCoApprover.push(data.email); });
                }
            }

            let allReviewer = [];
            if (Array.isArray(this.reviewerTag)) {
                if (this.reviewerTag.length > 0) {
                    this.reviewerTag.forEach(data => { allReviewer.push(data.email); });
                }
            }

            let allCoReviewer = [];
            if (Array.isArray(this.coReviewerTag)) {
                if (this.coReviewerTag.length > 0) {
                    this.coReviewerTag.forEach(data => { allCoReviewer.push(data.email); });
                }
            }

            let allCreator = [];
            if (Array.isArray(this.creatorTag)) {
                if (this.creatorTag.length > 0) {
                    this.creatorTag.forEach(data => { allCreator.push(data.email); });
                }
            }

            let allBudgetProject = [];
            if (Array.isArray(this.budgetProjectTag)) {
                if (this.budgetProjectTag.length > 0) {
                    this.budgetProjectTag.forEach(data => { allBudgetProject.push(data.project); });
                }
            }

            let targetList = [];

            if (Array.isArray(formdata.milestoneList)) {
                if (formdata.milestoneList.length > 0) {
                    formdata.milestoneList.forEach(data => {
                        let milestoneQ1;
                        let milestoneQ2;
                        let milestoneQ3;
                        let milestoneQ4;

                        if (!data.id) {
                            milestoneQ1 = {
                                id: data.milestoneQ1.id,
                                milestoneDetail: data.milestoneQ1.milestoneDetail,
                                progressDetail: data.milestoneQ1.progressDetail,
                                status: data.milestoneQ1.status ? data.milestoneQ1.status : null
                            };

                            milestoneQ2 = {
                                id: data.milestoneQ2.id,
                                milestoneDetail: data.milestoneQ2.milestoneDetail,
                                progressDetail: data.milestoneQ2.progressDetail,
                                status: data.milestoneQ2.status ? data.milestoneQ2.status : null
                            };

                            milestoneQ3 = {
                                id: data.milestoneQ3.id,
                                milestoneDetail: data.milestoneQ3.milestoneDetail,
                                progressDetail: data.milestoneQ3.progressDetail,
                                status: data.milestoneQ3.status ? data.milestoneQ3.status : null
                            };

                            milestoneQ4 = {
                                id: data.milestoneQ4.id,
                                milestoneDetail: data.milestoneQ4.milestoneDetail,
                                progressDetail: data.milestoneQ4.progressDetail,
                                status: data.milestoneQ4.status ? data.milestoneQ4.status : null
                            };
                        } else {

                            if (data.milestoneQ1.id) {
                                milestoneQ1 = {
                                    id: data.milestoneQ1.id,
                                    milestoneDetail: data.milestoneQ1.milestoneDetail
                                };
                            } else {
                                milestoneQ1 = {
                                    milestoneDetail: data.milestoneQ1.milestoneDetail,
                                    progressDetail: data.milestoneQ1.progressDetail,
                                    status: data.milestoneQ1.status ? data.milestoneQ1.status : null
                                };
                            }

                            if (data.milestoneQ2.id) {
                                milestoneQ2 = {
                                    id: data.milestoneQ2.id,
                                    milestoneDetail: data.milestoneQ2.milestoneDetail
                                };
                            } else {
                                milestoneQ2 = {
                                    milestoneDetail: data.milestoneQ2.milestoneDetail,
                                    progressDetail: data.milestoneQ2.progressDetail,
                                    status: data.milestoneQ2.status ? data.milestoneQ2.status : null
                                };
                            }

                            if (data.milestoneQ3.id) {
                                milestoneQ3 = {
                                    id: data.milestoneQ3.id,
                                    milestoneDetail: data.milestoneQ3.milestoneDetail
                                };
                            } else {
                                milestoneQ3 = {
                                    milestoneDetail: data.milestoneQ3.milestoneDetail,
                                    progressDetail: data.milestoneQ3.progressDetail,
                                    status: data.milestoneQ3.status ? data.milestoneQ3.status : null
                                };
                            }

                            if (data.milestoneQ4.id) {
                                milestoneQ4 = {
                                    id: data.milestoneQ4.id,
                                    milestoneDetail: data.milestoneQ4.milestoneDetail
                                };
                            } else {
                                milestoneQ4 = {
                                    milestoneDetail: data.milestoneQ4.milestoneDetail,
                                    progressDetail: data.milestoneQ4.progressDetail,
                                    status: data.milestoneQ4.status ? data.milestoneQ4.status : null
                                };
                            }
                        }

                        let targetDetail = {
                            id: data.id ? data.id : null,
                            detail: data.detail,
                            corporateKPIs: data.corporateKPIs,
                            milestoneQ1: milestoneQ1,
                            milestoneQ2: milestoneQ2,
                            milestoneQ3: milestoneQ3,
                            milestoneQ4: milestoneQ4,
                        };

                        targetList.push(targetDetail);
                    });
                }
            }

            let body = {
                approver: allApprover,
                coApprover: allCoApprover,
                reviewer: allReviewer,
                coReviewer: allCoReviewer,
                creator: allCreator,
                projectName: this.strategicProjectName,
                targets: targetList,
                budgetProject: allBudgetProject,
            };

            this.strategicProjectTrackingService.updateStrategicPlan(this.strategicProjectData.id, body).toPromise()
                .then(res => {
                    this.onUpdateBudgetReason();
                    this.alert(true, 'This strategic project has been saved');
                    this.onEmitData.emit({ displayItem: 'list', page: this.pageData, quarter: this.quarterData });
                })
                .catch(err => {
                    console.log('err', err);
                    if (this.isAdminOfStrategicProject) {
                        this.alert(false, 'Cannot update this strategic project tracking, Please try again.');
                    }
                });
        }
        this.onUpdateBudgetReason();
    }

    onUpdateBudgetReason() {
        if (this.strategicProjectData.budgetProject.length > 0) {
            let formdata = this.strategicForm.getRawValue();
            let selectedQuarterData = +this.strategicFormGroup.selectedMilestonePeriod.value.substr(1, 1);
            let body;
            if (selectedQuarterData === 1) {
                body = { budgetReasonQ1: formdata.budgetReason };
            } else if (selectedQuarterData === 2) {
                body = { budgetReasonQ2: formdata.budgetReason };
            } else if (selectedQuarterData === 3) {
                body = { budgetReasonQ3: formdata.budgetReason };
            } else if (selectedQuarterData === 4) {
                body = { budgetReasonQ4: formdata.budgetReason };
            }

            this.strategicProjectTrackingService.updateBudgetReason(this.strategicProjectData.id, selectedQuarterData, body).toPromise()
                .then(res => {
                })
                .catch(err => {
                    console.log('err', err);
                });
        }
    }

    onRejectStrategicProject() {
        const body = {
            reasonForApproveOrReject: this.reasonForm.controls.reasonForApproveOrReject.value
        };
        this.strategicProjectTrackingService.workflow(this.strategicProjectData.id, 'REJECT', this.quarterData, body).toPromise()
            .then(res => {
                this.alert(true, 'Successfully reject this strategic project plan.');
                this.onEmitData.emit({ displayItem: 'list', page: this.pageData, quarter: this.quarterData });
            })
            .catch(err => {
                console.log('err', err);
                this.alert(false, 'Can not reject this strategic project plan, Please try agaian.');
            });
    }

    onChangeStatus(status, quarter, index) {
        switch (quarter) {
            case 'Q1':
                this.formDataList.at(index).get("milestoneQ1").patchValue({ status: status });
                return;
            case 'Q2':
                this.formDataList.at(index).get("milestoneQ2").patchValue({ status: status });
                return;
            case 'Q3':
                this.formDataList.at(index).get("milestoneQ3").patchValue({ status: status });
                return;
            case 'Q4':
                this.formDataList.at(index).get("milestoneQ4").patchValue({ status: status });
                return;
            default:
                return '';
        }
    }

    estiamateYear(data) {
        if (this.strategicFormGroup.selectedEstimatePeriod.value.includes('Q4')) {
            this.strategicForm.patchValue({
                estimateQuarterScore: data,
            });
        }
    }

    estiamateQuarter(data) {
        if (this.strategicFormGroup.selectedEstimatePeriod.value.includes('Q4')) {
            this.strategicForm.patchValue({
                estimateYearScore: data,
            });
        }
    }

    addTarget() {
        let strategicFormDetail = this.strategicForm.getRawValue();

        if (strategicFormDetail.target) {
            let milestoneDetailData = {
                id: null,
                milestoneDetail: "",
                new: true,
                progressDetail: "",
                status: "",
                target: ""
            };

            this.milestoneList.push(this.fb.group({
                id: null,
                detail: strategicFormDetail.target,
                corporateKPIs: false,
                milestoneQ1: this.fb.group(milestoneDetailData),
                milestoneQ2: this.fb.group(milestoneDetailData),
                milestoneQ3: this.fb.group(milestoneDetailData),
                milestoneQ4: this.fb.group(milestoneDetailData),
                new: true
            }));

            this.strategicForm.patchValue({
                target: '',
            });
        }
    }

    addApproverTag(item) {
        if (!item.status) item.labelId = null;
        let labeltemp = {
            empID: item.empID,
            email: item.email,
            nameEn: item.nameEn
        };
        this.approverTag.push(labeltemp);
    }

    removeApproverTag(item) {
        this.approverTag = this.approverTag.filter((obj) => obj.empID !== item.empID);
    }

    addCoApproverTag(item) {
        if (!item.status) item.labelId = null;
        let labeltemp = {
            empID: item.empID,
            email: item.email,
            nameEn: item.nameEn
        };
        this.coApproverTag.push(labeltemp);
    }

    removeCoApproverTag(item) {
        this.coApproverTag = this.coApproverTag.filter((obj) => obj.empID !== item.empID);
    }

    addReviewerTag(item) {
        if (!item.status) item.labelId = null;
        let labeltemp = {
            empID: item.empID,
            email: item.email,
            nameEn: item.nameEn
        };
        this.reviewerTag.push(labeltemp);
    }

    removeReviewerTag(item) {
        this.reviewerTag = this.reviewerTag.filter((obj) => obj.empID !== item.empID);
    }

    addCoReviewerTag(item) {
        if (!item.status) item.labelId = null;
        let labeltemp = {
            empID: item.empID,
            email: item.email,
            nameEn: item.nameEn
        };
        this.coReviewerTag.push(labeltemp);
    }

    removeCoReviewerTag(item) {
        this.coReviewerTag = this.coReviewerTag.filter((obj) => obj.empID !== item.empID);
    }

    addCreatorTag(item) {
        if (!item.status) item.labelId = null;
        let labeltemp = {
            empID: item.empID,
            email: item.email,
            nameEn: item.nameEn
        };
        this.creatorTag.push(labeltemp);
    }

    removeCreatorTag(item) {
        this.creatorTag = this.creatorTag.filter((obj) => obj.empID !== item.empID);
    }

    addBudgetProjectTag(item) {
        let labeltemp = {
            project: item.project,
            projectName: item.projectName
        };
        this.budgetProjectTag.push(labeltemp);
    }

    removeBudgetProjectTag(item) {
        this.budgetProjectTag = this.budgetProjectTag.filter((obj) => obj.project !== item.project);
    }

    calculateQuarter(month) {
        switch (Number.isInteger(month)) {
            case (month <= 3):
                return 1;
            case (month > 3 && month <= 6):
                return 2;
            case (month > 6 && month <= 9):
                return 3;
            case (month > 9 && month <= 11):
                return 4;
            default:
                return 1;
        }
    }

    getName(email) {
        if (this.allName[email.toLowerCase()]) {
            return this.allName[email.toLowerCase()];
        } else {
            return email;
        }
    }

    checkStatusApprove(strategicProject): boolean {
        if (this.isAdminOfStrategicProject) {
            return false;
        } else {
            if (Array.isArray(strategicProject.strategicProjectWorkflowStatus)) {
                if (strategicProject.strategicProjectWorkflowStatus.some((data) => data.status ? data.status.toUpperCase() === 'APPROVED' && data.quarter === this.quarterData : false)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    checkStatusNeedApprove(strategicProject): boolean {
        if (Array.isArray(strategicProject.strategicProjectWorkflowStatus)) {
            if (strategicProject.strategicProjectWorkflowStatus.some((data) => data.status ? data.status.toUpperCase() === 'NEED_APPROVAL' && data.quarter === this.quarterData : false)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    checkStatusNeedUpdate(strategicProject): boolean {
        if (Array.isArray(strategicProject.strategicProjectWorkflowStatus)) {
            if (strategicProject.strategicProjectWorkflowStatus.find((data) => { return data.status ? data.status.toUpperCase() === 'NEED_UPDATE' && data.quarter === this.quarterData : null; })) {
                return true;
            } else if (strategicProject.strategicProjectWorkflowStatus.find((data) => { return !data.status && data.quarter === this.quarterData; })) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    checkStatusNeedReview(strategicProject): boolean {
        if (Array.isArray(strategicProject.strategicProjectWorkflowStatus)) {
            if (strategicProject.strategicProjectWorkflowStatus.some((data) => data.status ? data.status.toUpperCase() === 'NEED_REVIEW' && data.quarter === this.quarterData : false)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    checkMileStoneData(target): boolean {
        let form = this.strategicForm.getRawValue();
        let targetDetail = target.getRawValue();
        let isShowMileStone = false;

        if (form.selectedMilestonePeriod.includes('Q1')) {
            if (targetDetail.milestoneQ1) {
                if (targetDetail.milestoneQ1.milestoneDetail) {
                    isShowMileStone = true;
                }
            }
        } else if (form.selectedMilestonePeriod.includes('Q2')) {
            if (targetDetail.milestoneQ2) {
                if (targetDetail.milestoneQ2.milestoneDetail) {
                    isShowMileStone = true;
                }
            }
        } else if (form.selectedMilestonePeriod.includes('Q3')) {
            if (targetDetail.milestoneQ3) {
                if (targetDetail.milestoneQ3.milestoneDetail) {
                    isShowMileStone = true;
                }
            }
        } else if (form.selectedMilestonePeriod.includes('Q4')) {
            if (targetDetail.milestoneQ4) {
                if (targetDetail.milestoneQ4.milestoneDetail) {
                    isShowMileStone = true;
                }
            }
        }

        return isShowMileStone;
    }

    validateForm() {
        let valid: boolean = true;
        let strategicFormDetail = this.strategicForm.getRawValue();
        let selectedMilestoneQuarterData = +this.strategicFormGroup.selectedMilestonePeriod.value.substr(1, 1);

        if (strategicFormDetail.milestoneList.length < 1) this.strategicForm.controls.milestoneList.setErrors({ null: true });

        if (this.confirmationStatus.toUpperCase() === 'SUBMIT' || this.confirmationStatus.toUpperCase() === 'APPROVE' || this.confirmationStatus.toUpperCase() === 'SUBMITFORAPPROVAL') {
            if (this.strategicProjectData.budgetUtilization < 30 && this.strategicProjectData.budgetProject.length > 0 && !strategicFormDetail.budgetReason) {
                if (this.strategicProjectData.budgetProject.every(item => item === 'N/A')) {
                    this.strategicForm.controls.budgetReason.setErrors(null);
                } else {
                    valid = false;
                    this.strategicForm.controls.budgetReason.setErrors({ null: true });
                }
            } else {
                this.strategicForm.controls.budgetReason.setErrors(null);
            }
        }

        this.milestoneList.controls.forEach((item, index) => {
            item.get('detail').setValidators(Validators.required);
            item.get('milestoneQ1').get('milestoneDetail').setValidators(Validators.required);
            item.get('milestoneQ2').get('milestoneDetail').setValidators(Validators.required);
            item.get('milestoneQ3').get('milestoneDetail').setValidators(Validators.required);
            item.get('milestoneQ4').get('milestoneDetail').setValidators(Validators.required);


            if (this.confirmationStatus.toUpperCase() === 'SUBMIT' || this.confirmationStatus.toUpperCase() === 'APPROVE' || this.confirmationStatus.toUpperCase() === 'SUBMITFORAPPROVAL') {
                item.get('milestoneQ1').get('status').setValidators(Validators.required);
                item.get('milestoneQ2').get('status').setValidators(Validators.required);
                item.get('milestoneQ3').get('status').setValidators(Validators.required);
                item.get('milestoneQ4').get('status').setValidators(Validators.required);

                item.get('milestoneQ1').get('progressDetail').setValidators(Validators.required);
                item.get('milestoneQ2').get('progressDetail').setValidators(Validators.required);
                item.get('milestoneQ3').get('progressDetail').setValidators(Validators.required);
                item.get('milestoneQ4').get('progressDetail').setValidators(Validators.required);
            }

            if (!item.get('detail').value) {
                valid = false;
                item.get('detail').updateValueAndValidity();
            } else {
                item.get('detail').setValidators(null);
                item.get('detail').updateValueAndValidity();
            }

            if (item.get('corporateKPIs').value) {
                if (item.get('milestoneQ1').get('milestoneDetail').value && item.get('milestoneQ2').get('milestoneDetail').value && item.get('milestoneQ3').get('milestoneDetail').value && item.get('milestoneQ4').get('milestoneDetail').value) {
                    item.get('milestoneQ1').get('milestoneDetail').setValidators(null);
                    item.get('milestoneQ2').get('milestoneDetail').setValidators(null);
                    item.get('milestoneQ3').get('milestoneDetail').setValidators(null);
                    item.get('milestoneQ4').get('milestoneDetail').setValidators(null);

                    item.get('milestoneQ1').get('milestoneDetail').updateValueAndValidity();
                    item.get('milestoneQ2').get('milestoneDetail').updateValueAndValidity();
                    item.get('milestoneQ3').get('milestoneDetail').updateValueAndValidity();
                    item.get('milestoneQ4').get('milestoneDetail').updateValueAndValidity();
                } else {
                    valid = false;

                    item.get('milestoneQ1').get('milestoneDetail').updateValueAndValidity();
                    item.get('milestoneQ2').get('milestoneDetail').updateValueAndValidity();
                    item.get('milestoneQ3').get('milestoneDetail').updateValueAndValidity();
                    item.get('milestoneQ4').get('milestoneDetail').updateValueAndValidity();
                }

                if (this.confirmationStatus.toUpperCase() === 'SUBMIT' || this.confirmationStatus.toUpperCase() === 'APPROVE' || this.confirmationStatus.toUpperCase() === 'SUBMITFORAPPROVAL') {
                    if (selectedMilestoneQuarterData === 1) {
                        if (item.get('milestoneQ1').get('progressDetail').value) {
                            item.get('milestoneQ1').get('progressDetail').setValidators(null);
                        } else {
                            valid = false;
                        }

                        if (item.get('milestoneQ1').get('status').value) {
                            item.get('milestoneQ1').get('status').setValidators(null);
                        } else {
                            valid = false;
                        }

                    } else {
                        item.get('milestoneQ1').get('progressDetail').setValidators(null);
                        item.get('milestoneQ1').get('status').setValidators(null);
                    }

                    if (selectedMilestoneQuarterData === 2) {
                        if (item.get('milestoneQ2').get('progressDetail').value) {
                            item.get('milestoneQ2').get('progressDetail').setValidators(null);
                        } else {
                            valid = false;
                        }

                        if (item.get('milestoneQ2').get('status').value) {
                            item.get('milestoneQ2').get('status').setValidators(null);
                        } else {
                            valid = false;
                        }

                    } else {
                        item.get('milestoneQ2').get('progressDetail').setValidators(null);
                        item.get('milestoneQ2').get('status').setValidators(null);
                    }

                    if (selectedMilestoneQuarterData === 3) {
                        if (item.get('milestoneQ3').get('progressDetail').value) {
                            item.get('milestoneQ3').get('progressDetail').setValidators(null);
                        } else {
                            valid = false;
                        }

                        if (item.get('milestoneQ3').get('status').value) {
                            item.get('milestoneQ3').get('status').setValidators(null);
                        } else {
                            valid = false;
                        }

                    } else {
                        item.get('milestoneQ3').get('progressDetail').setValidators(null);
                        item.get('milestoneQ3').get('status').setValidators(null);
                    }

                    if (selectedMilestoneQuarterData === 4) {
                        if (item.get('milestoneQ4').get('progressDetail').value) {
                            item.get('milestoneQ4').get('progressDetail').setValidators(null);
                        } else {
                            valid = false;
                        }

                        if (item.get('milestoneQ4').get('status').value) {
                            item.get('milestoneQ4').get('status').setValidators(null);
                        } else {
                            valid = false;
                        }

                    } else {
                        item.get('milestoneQ4').get('progressDetail').setValidators(null);
                        item.get('milestoneQ4').get('status').setValidators(null);
                    }

                    item.get('milestoneQ1').get('status').updateValueAndValidity();
                    item.get('milestoneQ1').get('progressDetail').updateValueAndValidity();

                    item.get('milestoneQ2').get('status').updateValueAndValidity();
                    item.get('milestoneQ2').get('progressDetail').updateValueAndValidity();

                    item.get('milestoneQ3').get('status').updateValueAndValidity();
                    item.get('milestoneQ3').get('progressDetail').updateValueAndValidity();

                    item.get('milestoneQ4').get('status').updateValueAndValidity();
                    item.get('milestoneQ4').get('progressDetail').updateValueAndValidity();
                }

                // if(item.get('milestoneQ1').get('progressDetail').value && item.get('milestoneQ2').get('progressDetail').value && item.get('milestoneQ3').get('progressDetail').value && item.get('milestoneQ4').get('progressDetail').value ) {
                //     console.log('Here have all progress detail');

                //     item.get('milestoneQ1').get('progressDetail').setValidators(null)
                //     item.get('milestoneQ2').get('progressDetail').setValidators(null)
                //     item.get('milestoneQ3').get('progressDetail').setValidators(null)
                //     item.get('milestoneQ4').get('progressDetail').setValidators(null)

                //     item.get('milestoneQ1').get('progressDetail').updateValueAndValidity();
                //     item.get('milestoneQ2').get('progressDetail').updateValueAndValidity();
                //     item.get('milestoneQ3').get('progressDetail').updateValueAndValidity();
                //     item.get('milestoneQ4').get('progressDetail').updateValueAndValidity();
                // } else {
                //     valid = false

                //     console.log('Here no have progress detail');


                //     item.get('milestoneQ1').get('progressDetail').updateValueAndValidity();
                //     item.get('milestoneQ2').get('progressDetail').updateValueAndValidity();
                //     item.get('milestoneQ3').get('progressDetail').updateValueAndValidity();
                //     item.get('milestoneQ4').get('progressDetail').updateValueAndValidity();
                // }

                // if(item.get('milestoneQ1').get('status').value && item.get('milestoneQ2').get('status').value && item.get('milestoneQ3').get('status').value && item.get('milestoneQ4').get('status').value ) {
                //     console.log('Here have all status');

                //     item.get('milestoneQ1').get('status').setValidators(null)
                //     item.get('milestoneQ2').get('status').setValidators(null)
                //     item.get('milestoneQ3').get('status').setValidators(null)
                //     item.get('milestoneQ4').get('status').setValidators(null)

                //     item.get('milestoneQ1').get('status').updateValueAndValidity();
                //     item.get('milestoneQ2').get('status').updateValueAndValidity();
                //     item.get('milestoneQ3').get('status').updateValueAndValidity();
                //     item.get('milestoneQ4').get('status').updateValueAndValidity();
                // } else {
                //     valid = false

                //     console.log('Here no have status');

                //     item.get('milestoneQ1').get('status').updateValueAndValidity();
                //     item.get('milestoneQ2').get('status').updateValueAndValidity();
                //     item.get('milestoneQ3').get('status').updateValueAndValidity();
                //     item.get('milestoneQ4').get('status').updateValueAndValidity();
                // }



            } else if (!item.get('milestoneQ1').get('milestoneDetail').value && !item.get('milestoneQ2').get('milestoneDetail').value && !item.get('milestoneQ3').get('milestoneDetail').value && !item.get('milestoneQ4').get('milestoneDetail').value) {
                valid = false;

                item.get('milestoneQ1').get('milestoneDetail').updateValueAndValidity();
                item.get('milestoneQ2').get('milestoneDetail').updateValueAndValidity();
                item.get('milestoneQ3').get('milestoneDetail').updateValueAndValidity();
                item.get('milestoneQ4').get('milestoneDetail').updateValueAndValidity();
            } else {
                item.get('milestoneQ1').get('milestoneDetail').setValidators(null);
                item.get('milestoneQ2').get('milestoneDetail').setValidators(null);
                item.get('milestoneQ3').get('milestoneDetail').setValidators(null);
                item.get('milestoneQ4').get('milestoneDetail').setValidators(null);

                item.get('milestoneQ1').get('milestoneDetail').updateValueAndValidity();
                item.get('milestoneQ2').get('milestoneDetail').updateValueAndValidity();
                item.get('milestoneQ3').get('milestoneDetail').updateValueAndValidity();
                item.get('milestoneQ4').get('milestoneDetail').updateValueAndValidity();
            }

            if (!item.get('corporateKPIs').value && (this.confirmationStatus.toUpperCase() === 'SUBMIT' || this.confirmationStatus.toUpperCase() === 'APPROVE' || this.confirmationStatus.toUpperCase() === 'SUBMITFORAPPROVAL')) {
                if (item.get('milestoneQ1').get('milestoneDetail').value && (!item.get('milestoneQ1').get('status').value || !item.get('milestoneQ1').get('progressDetail').value) && selectedMilestoneQuarterData === 1) {
                    valid = false;
                } else {
                    item.get('milestoneQ1').get('status').setValidators(null);
                    item.get('milestoneQ1').get('progressDetail').setValidators(null);
                }

                if (item.get('milestoneQ2').get('milestoneDetail').value && (!item.get('milestoneQ2').get('status').value || !item.get('milestoneQ2').get('progressDetail').value) && selectedMilestoneQuarterData === 2) {
                    valid = false;
                } else {
                    item.get('milestoneQ2').get('status').setValidators(null);
                    item.get('milestoneQ2').get('progressDetail').setValidators(null);
                }

                if (item.get('milestoneQ3').get('milestoneDetail').value && (!item.get('milestoneQ3').get('status').value || !item.get('milestoneQ3').get('progressDetail').value) && selectedMilestoneQuarterData === 3) {
                    valid = false;
                } else {
                    item.get('milestoneQ3').get('status').setValidators(null);
                    item.get('milestoneQ3').get('progressDetail').setValidators(null);
                }

                if (item.get('milestoneQ4').get('milestoneDetail').value && (!item.get('milestoneQ4').get('status').value || !item.get('milestoneQ4').get('progressDetail').value) && selectedMilestoneQuarterData === 4) {
                    valid = false;
                } else {
                    item.get('milestoneQ4').get('status').setValidators(null);
                    item.get('milestoneQ4').get('progressDetail').setValidators(null);
                }

                item.get('milestoneQ1').get('status').updateValueAndValidity();
                item.get('milestoneQ1').get('progressDetail').updateValueAndValidity();

                item.get('milestoneQ2').get('status').updateValueAndValidity();
                item.get('milestoneQ2').get('progressDetail').updateValueAndValidity();

                item.get('milestoneQ3').get('status').updateValueAndValidity();
                item.get('milestoneQ3').get('progressDetail').updateValueAndValidity();

                item.get('milestoneQ4').get('status').updateValueAndValidity();
                item.get('milestoneQ4').get('progressDetail').updateValueAndValidity();
            }
        });

        console.log('valid ===>', valid);


        if (!valid) {
            valid = false;
            this.alert(false, 'Please fill out the form completely');
            return valid;
        }

        return valid;
    }

    calculatePeriod(): number {
        let period = 0;
        let month = new Date().getMonth();
        let quarter = this.quarterData.charAt(this.quarterData.length - 1) ? +this.quarterData.charAt(this.quarterData.length - 1) : 0;
        if (this.strategicProjectData.year - 543 == new Date().getFullYear()) {
            if (quarter >= this.calculateQuarter(+month)) {
                period = +month + 1;
                let current = new Date();
                let previousMonth = new Date(current.getFullYear(), current.getMonth(), 0);
                this.asOfDate = `${previousMonth.getDate()} ${this.months[previousMonth.getMonth()]} ${this.strategicProjectData.year}`;
            } else {
                period = (this.calculateQuarter(+month) * 3);
                if (period == 3) {
                    this.asOfDate = `31 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
                } else if (period == 6) {
                    this.asOfDate = `30 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
                } else if (period == 9) {
                    this.asOfDate = `30 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
                } else if (period == 12) {
                    this.asOfDate = `31 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
                }
            }

        } else if (this.strategicProjectData.year - 543 > new Date().getFullYear()) {
            period = 0;
        } else {
            period = quarter * 3;
            if (quarter == 1) {
                this.asOfDate = `31 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
            } else if (quarter == 2) {
                this.asOfDate = `30 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
            } else if (quarter == 3) {
                this.asOfDate = `30 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
            } else if (quarter == 4) {
                this.asOfDate = `31 ${this.months[period - 1]} ${this.strategicProjectData.year}`;
            }
            // period = 12;
        }

        console.log('period', period);

        return period;
    }

    checkDisplayBudgetProject(strategicProject): boolean {
        // return strategicProject.budgetProject.length > 0 && !strategicProject.budgetProject.every(item => item === 'N/A');
        return false; //hide bugget project
    }

    alert(isSuccess: boolean, msg: string) {
        return Swal.fire({
            scrollbarPadding: false,
            text: msg,
            title: isSuccess ? "Successfully" : "Failed",
            icon: isSuccess ? "success" : "error",
            confirmButtonText: "OK",
            focusCancel: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-info btn-lg mr-2 text-light",
            },
        });
    }
}
