import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrap-intern',
  templateUrl: './wrap-intern.component.html',
  styleUrls: ['./wrap-intern.component.scss']
})
export class WrapInternComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
