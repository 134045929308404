export interface SpecialHoliday {
  id: number;
  holiday: Date;
  descEng: string;
  descTh: string;
}

export interface Pagination<T> {
  content: T[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  sort: Sort2;
  numberOfElements: number;
  first: boolean;
  size: number;
  number: number;
  empty: boolean;
}

export interface Pageable {
  sort: Sort;
  pageNumber: number;
  pageSize: number;
  offset: number;
  unpaged: boolean;
  paged: boolean;
}

export interface Sort2 {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

export class PaginationClass<T> implements Pagination<T> {
  content: T[] = [];
  empty = true;
  first = false;
  last = true;
  number: number;
  numberOfElements: number;
  pageable: Pageable;
  size = 10;
  sort: Sort2;
  totalElements = 0;
  totalPages = 0;
}
