<div class="container-fluid mt-4 pl-5 pr-5" style="min-height: 100vh">
    <div class="row mb-2">
        <div class="col">
            <h4>User Role Settings</h4>
        </div>
        <div class="col-auto"> <button class="btn btn-info btn-lg" (click)="openModal(addUserComponent.template,null)">
                <fa-icon [icon]="faIcon.faPlus"></fa-icon>
                Add
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-row">
                <div class="col">
                    <table class="table">
                        <thead class="bg-ca text-white-smoke">
                            <tr>
                                <td></td>
                                <td>Name</td>
                                <td>Role</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of roleList; index as i">
                                <td>{{i+1}}</td>
                                <td> <a style="cursor: pointer; text-decoration: underline; color: deepskyblue"
                                        (click)="openModal(addUserComponent.template,row)">{{row?.employee?.nameTh}}</a>
                                </td>
                                <td>{{row.role}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<app-add-user #addUserComponent [modalRef]="modalRef" [employeeListFiltered]="employeeFiltered"
    (loadData)="relaodEvent()" [currentData]="rowData">
</app-add-user>
