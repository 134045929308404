<form action="" [formGroup]="optionForm">
  <div class="form-row align-items-center">
    <div class="col-md-auto my-1">
      <div class="form-check">
        <label class="form-check-label" for="date">
          Month
        </label>
      </div>
    </div>
    <div class="col-md-2 my-1">
      <select name="" [compareWith]="compareWith()" formControlName="date" class="form-control" id="date">
        <option [ngValue]="d" *ngFor="let d of options.date">{{d.display}}</option>
      </select>
    </div>
    <div class="col-md-auto my-1">
      <div class="form-check">
        <label class="form-check-label" for="project">
          Project
        </label>
      </div>
    </div>
    <div class="col-md-2 my-2">
      <select name="" id="project" formControlName="project" class="form-control">
        <option value="all">All project</option>
        <option [value]="p" *ngFor="let p of options.project$ | async">{{p}}</option>
      </select>
    </div>
    <div class="col-md-auto my-1">
      <div class="form-check">
        <label class="form-check-label" for="vendor">
          Vendor
        </label>
      </div>
    </div>
    <div class="col-md-2 my-2">
      <select name="" id="vendor" formControlName="vendor" class="form-control">
        <option value="all">All Vendor</option>
        <option [value]="v" *ngFor="let v of options.vendor$ | async">{{v}}</option>
      </select>
    </div>
    <div class="col-md-auto my-1">
      <div class="form-check">
        <label class="form-check-label" for="vendor">
          Type
        </label>
      </div>
    </div>
    <div class="col-md-2 my-2">
      <select name="" id="vendor" formControlName="type" class="form-control">
        <option value="all">All Type</option>
        <option value="IT_OUTSOURCE">IT Outsource</option>
        <option value="INTERN">Intern</option>
        <option value="INTERN_COOP">Intern Coop</option>
      </select>
    </div>
  </div>
  <div class="row">
    <table class="table">
      <colgroup>
        <col span="1" style="width: 5%;">
        <col span="1" style="width: 14%;">
        <col span="1" style="width: 15%;">
        <col span="1" style="width: 9%;">
        <col span="1" style="width: 8%;">
        <col span="1" style="width: 6%;">
        <col span="1" style="width: 6%;">
        <col span="1" style="width: 5%;">
        <col span="1" style="width: 5%;">
        <!--        <col span="1" *ngIf="!isProduction" style="width: 6%;">-->
        <col span="1" style="width: 8%;">
        <col span="1" style="width: 8%;">
        <col span="1" style="width: auto;">
        <col span="1" style="width: 2px">

      </colgroup>
      <thead class="thead-dark">
      <tr>
        <th scope="col" [appSort]="timesheetList" data-order="desc" data-name="sortEmpId">ID</th>
        <th scope="col" [appSort]="timesheetList" data-order="desc" data-name="sortEmpName">NAME</th>
        <th scope="col" [appSort]="timesheetList" data-order="desc" data-name="projectName">PROJECT</th>
        <th scope="col">PO</th>
        <th scope="col" [appSort]="timesheetList" data-order="desc" data-name="vendor">VENDOR</th>
        <th scope="col" [appSort]="timesheetList" data-order="desc" data-name="rateType">TYPE</th>
        <!--        <th scope="col" [appSort]="timesheetList" data-order="desc" data-name="type">TYPE</th>-->
        <th scope="col" [appSort]="timesheetList" data-order="desc" data-name="manDay">WORK-DAYS</th>
        <th>OT</th>
        <th>LEAVE</th>
        <!--        <th>CAL-DAYS</th>-->
        <!--        <th *ngIf="!isProduction">AMOUNT</th>-->
        <th scope="col" [appSort]="timesheetList" data-order="desc" data-name="status">STATUS</th>
        <th scope="col" [appSort]="timesheetList" data-order="desc" data-name="updateDate" sort-type="date">LAST
          UPDATED
        </th>
        <th scope="col" [appSort]="timesheetList" data-order="desc" data-name="sortSupervisor">SUPERVISOR</th>
        <th scope="col"></th>

      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="!loadList">
        <tr *ngFor="let t of timesheetList">
          <td scope="row"><small>{{t.employee.empID}}</small></td>
          <td>
            <a (click)="onClickList(t)" class="clickable"><small>{{t.employee.nameEn}}</small></a>

            <small style="margin-left: 3px">
            <span class="badge badge-info"
                  *ngIf="t.foc"
                  title="Free Of Charge">FOC</span></small>
          </td>
          <td><small>{{t.projectName}}</small></td>
          <td [innerHTML]="'<small>'+ (t.poList | joinWith :'<br>')+' </small>'"></td>
          <td><small>{{t.vendor}}</small></td>
          <td><small>{{t.rateType }}</small></td>
          <!--          === 'MD' ? 'Man-day' : 'Man-month'-->
          <!--          <td><small>{{displayType(t.type)}}</small></td>-->
          <td><small>{{t.actualManDay}}</small></td>
          <td><small> {{t.ot}}</small></td>
          <td><small> {{t.leave}}</small></td>
          <!--          <td> <small> {{t.manDay}}</small></td>-->
          <!--          <td *ngIf="!isProduction"> <small>{{t.amount | number : '1.1-2'}}</small> </td>-->
          <td><small [class.text-danger]="t.status === 'NEED_REVIEWING'">{{rewriteStatus(t)}}</small></td>
          <td ><small>{{ t.updateDate ? (t.updateDate | date: 'd MMM yyyy') : '-' }}</small></td>
          <td><small>{{separateName(t.reviewer.nameEn)}}</small></td>

          <td>
            <!--          [routerLink]="['/outsource-timesheet/',t.employee.empID,this.dateSelected.value.month,this.dateSelected.value.year]"-->
            <!--          [queryParams]="{ projectName:t.projectName}"-->
            <a
              (click)="onReview(t)" class="clickable"
              *ngIf="t.status ==='NEED_REVIEWING' && showReviewBTN$ | async"><small>review</small></a>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="timesheetList.length == 0 && !loadList">
        <td colspan="13" class="text-center" style="opacity: 0.6">
          <fa-icon [icon]="faIcon.faFolderOpen"></fa-icon>
          Empty list
        </td>
      </tr>
      <tr *ngIf="loadList">
        <td colspan="13" class="text-center">
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="7"></td>
        <td colspan="6" class="item-right text-right">
          <!--          <div *ngIf="showApproveBTN$ | async">-->
          <!--            <button (click)="actionAll('REJECTED')"-->
          <!--                    class="btn btn-danger btn-lg mr-3">-->
          <!--              REJECT ALL-->
          <!--            </button>-->
          <!--            <button (click)="actionAll('APPROVED')"-->
          <!--                    class="btn btn-success btn-lg">-->
          <!--              APPROVE ALL-->
          <!--            </button>-->
          <!--          </div>-->
          <div *ngIf="showReviewBTN$ | async">
            <button (click)="actionAll('REVIEWED')"
                    class="btn btn-success btn-lg">
              REVIEW ALL
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</form>
<app-loading-overlay [display]="loading"></app-loading-overlay>
