<div class="p-4">
  <div class="row py-2">
    <div class="col-md-4">
      <div class="row">
    <div class="col">
      <select class="form-control" (change)="onYearChange($event.target.value)">
        <option *ngFor="let year of yearOptions" [value]="year">{{ year }}</option>
      </select>
    </div>
      </div>
    </div>

    <div class="col-md-2">
      <!-- <button class="btn btn-warning app-btn-style" (click)="onSearch()">
        Search
      </button> -->
    </div>

    <div class="col-md-4">

      </div>
    <div class="col-md-2 text-right">
        <button class="btn btn-primary app-btn-style" (click)="onAdd()">Add</button>
    </div>
  </div>



  <form [formGroup]="form">
    <div class="body-element" *ngIf="form">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>No</th>
            <th style="width: 10%">Date</th>
            <th style="width: 30%">Details En</th>
            <th style="width: 30%">Details Th</th>
            <th style="width: 20%"></th>
          </tr>
        </thead>
        <tbody
          *ngFor="let item of entityFormArray.controls; let i = index"
          formArrayName="holidays"
        >
          <ng-container [formGroupName]="i">
            <tr class="my-auto" *ngIf="item.get('editable').value">
              <td >
                {{ i + 1 }}
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  formControlName="holiday"
                  [attr.disabled]="item.get('id').value ? true : null"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  formControlName="descEng"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  formControlName="descTh"
                />
              </td>
              <td class="text-right">
                <div class="d-inline-flex">
                  <div class="col-auto px-1">
                    <fa-icon
                      [icon]="faIcon.faCheck"
                      class="text-warning cursor-pointer"
                      (click)="onSave(item)"
                    ></fa-icon>
                  </div>
                  <div class="col-auto px-1">
                    <fa-icon
                      [icon]="faIcon.faTimes"
                      class="text-danger cursor-pointer"
                      (click)="onCancel(item)"
                    ></fa-icon>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="m-auto" *ngIf="!item.get('editable').value">
              <td>{{ i + 1 }}</td>
              <td>{{ item.get("holiday").value }}</td>
              <td>{{ item.get("descEng").value }}</td>
              <td>{{ item.get("descTh").value }}</td>
              <td class="text-right">
                <div class="d-inline-flex">
                  <div class="col-auto px-1">
                    <fa-icon
                      [icon]="faIcon.faEdit"
                      class="text-warning cursor-pointer"
                      (click)="onEdit(item)"
                    ></fa-icon>
                  </div>
                  <div class="col-auto px-1">
                    <fa-icon
                      [icon]="faIcon.faTrash"
                      class="text-danger cursor-pointer"
                      (click)="onDelete(item)"
                    ></fa-icon>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div class="py-2">
            <pagination [totalItems]="holidaysPage.totalElements" [itemsPerPage]="paginationConfig.itemsPerPage"
                (pageChanged)="onPageChange($event.page)">
            </pagination>
        </div>
    </div>
  </form>
</div>
