import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {faIcon} from '../../core/icon/fa.icon';
import {EmployeeService} from '../../core/services/employee.service';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Employee} from '../../core/models/employee';
import {TimesheetKeyInfo} from '../../core/models/timesheetInfo';

@Component({
  selector: 'app-intern-timesheet',
  templateUrl: './intern-timesheet.component.html',
  styleUrls: ['./intern-timesheet.component.scss']
})
export class InternTimesheetComponent implements OnInit {
  readonly ALLOW_ROLE = ['HRAdmin', 'UserAdmin', 'OutsourceAdmin'];
  display: 'list' | 'detail' = 'list'
  displayName: string;
  faIcon = faIcon;
  isHr$: Observable<boolean> = of(false);
  emp$: Observable<Employee> = new Observable<Employee>(undefined);
  timesheetKeyInfo: TimesheetKeyInfo;
  constructor(private activateRoute: ActivatedRoute, private employeeService: EmployeeService) {
  }

  ngOnInit(): void {
    this.emp$ = this.employeeService.me();
    this.isHr$ = this.emp$.pipe(
      map(e => e.userRoles_?.some(r => this.ALLOW_ROLE.includes(r.role))),
    )
    // const {empId, month, year} = this.activateRoute.snapshot.params;
    // if (empId && month && year) {
    //   this.display = 'detail'
    // }
  }

  info(event: TimesheetKeyInfo) {
    this.display = 'detail'
    this.timesheetKeyInfo = event;
  }

  back() {
    this.display = 'list';
    this.displayName = undefined;
    this.timesheetKeyInfo = undefined;
  }

  setName(name) {
    this.displayName = name;
  }
}
