import {Pipe, PipeTransform} from '@angular/core';
import {Dataset} from '../../modules/outsource-time-sheet/core/services/project.service';

@Pipe({
  name: 'sumOutsourceReport'
})
export class SumOutsourceReportPipe implements PipeTransform {

  transform(datasets: Dataset[], index: number): unknown {
    if (!datasets || datasets.length === 0) {
      return 0
    }
    if (index < 0) {
      return 0;
    }
    return datasets.map(d => d.data[index]).reduce((a, b) => a + b, 0);
  }

}
