import {Component, OnDestroy, OnInit} from '@angular/core';
import {Dataset, ProjectService, SummaryData} from '../../modules/outsource-time-sheet/core/services/project.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {OutsourceSummaryEnum} from '../../core/enums/outsource.summary.enum';
import {merge, Subscription} from 'rxjs';
import * as dayjs from 'dayjs'
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker/bs-datepicker.config';
import {chartConfig} from './config/chart.config';
import {animate, style, transition, trigger} from '@angular/animations';
import {ExportToCsv} from 'export-to-csv';

@Component({
  selector: 'app-outsource-summary-report',
  templateUrl: './outsource-summary-report.component.html',
  styleUrls: ['./outsource-summary-report.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.2s ease-out',
              style({height: 300, opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({height: 300, opacity: 1}),
            animate('.5s ease-in',
              style({height: 0, opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class OutsourceSummaryReportComponent implements OnInit, OnDestroy {

  constructor(
    private projectService: ProjectService,
    private fb: FormBuilder) {
  }

  get getTypeValue(): string {
    return this.optionForm.get('type').value;
  }

  datePickerConfig: Partial<BsDatepickerConfig> = {
    minMode: 'month',
    adaptivePosition: true,
    dateInputFormat: 'MMM YYYY',
    containerClass: 'theme-orange'
  };

  subs: Subscription[] = [];
  chartType = 'bar';
  chartData: SummaryData;
  chartOptions = chartConfig;
  possibleValueOption = {
    types: Object.keys(OutsourceSummaryEnum),
    filterOptions: []
  }
  optionForm: FormGroup = this.fb.group({
    type: 'DEPARTMENT',
    filterOption: 'All Departments',
    startDate: dayjs().year(2021).startOf('year').toDate(),
    endDate: dayjs().add(1, 'year').month(2).toDate()
  })

  renderSummary: Dataset[] = []

  private static cloneObj(r: SummaryData) {
    return Object.assign({}, r);
  }

  private static sortDataSet(a: Dataset, b: Dataset) {
    const aa = a.data.reduce((a1, b1) => a1 + b1, 0);
    const bb = b.data.reduce((a2, b2) => a2 + b2, 0);
    return bb - aa;
  }


  ngOnInit(): void {
    this.getDataChart();
    this.subs.push(merge(
      this.optionForm.get('startDate').valueChanges,
      this.optionForm.get('endDate').valueChanges,
    ).subscribe(() => {
      // this.getDataChart();
      this.filter();
    }))
    this.subs.push(this.optionForm.get('type').valueChanges.subscribe(() => {
      this.getDataChart();
      this.optionForm.get('filterOption').patchValue(this.buildAllOptionText());
    }))

  }

  exportExcel() {
    const {s, e} = this.formatDateOption();
    const csvName = `outsource-report-${s}-to-${e}`;
    const optionsJsonToEx = {
      filename: csvName,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    if (!this.chartData) {
      return;
    }
    const keyCols = [this.getTypeValue, ...this.chartData.labels];
    const keyRows = this.chartData.datasets.map(a => a.label);
    const csvExporter = new ExportToCsv(optionsJsonToEx);

    const resultList = []
    for (let row = 0; row < keyRows.length; row++) {
      const result: CsvCol = {}
      for (let col = 0; col < keyCols.length; col++) {
        if (col === 0) {
          result[this.getTypeValue] = this.chartData.datasets[row].label;
        } else {
          result[keyCols[col]] = this.chartData.datasets[row].data[col - 1];
        }
      }
      resultList.push(result)
    }
    csvExporter.generateCsv(resultList);
  }

  private formatDateOption(format: string = 'YYYY-MM') {
    const s = dayjs(this.optionForm.get('startDate').value).format(format);
    const e = dayjs(this.optionForm.get('endDate').value).format(format);
    return {s, e};
  }

  filter() {
    const matchAll = new RegExp('(All\\s\\w+)');
    const key = matchAll.test(this.optionForm.get('filterOption').value) ? '' : this.optionForm.get('filterOption').value;
    const {s, e} = this.formatDateOption('YYYY-MM-DD');
    this.projectService.getReport(this.getTypeValue, s, e, key)
      .toPromise()
      .then(r => {
        this.chartData = r;
        this.renderSummary =  r.datasets.sort((a: Dataset, b: Dataset) => OutsourceSummaryReportComponent.sortDataSet(a, b))

      })
      .catch(console.error)

  }

  private getDataChart() {
    const {s, e} = this.formatDateOption('YYYY-MM-DD');
    this.projectService.getReport(this.getTypeValue, s, e)
      .toPromise()
      .then(r => {
        this.chartData = r;
        this.renderSummary = r.datasets.sort((a: Dataset, b: Dataset) => OutsourceSummaryReportComponent.sortDataSet(a, b))
        this.possibleValueOption.filterOptions = [this.buildAllOptionText(), ...r.datasets.map(d => d.label)]
      })
      .catch(console.error)
  }

  private buildAllOptionText() {
    return `All ${this.capitalizeFirstLetter(this.getTypeValue.toLowerCase())}s`;
  }

  capitalizeFirstLetter(data: string): string {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe())
  }

}

interface CsvCol {
  [name: string]: any;
}
