<div class="form-row align-items-center">
  <div class="col-sm-6">
    <div class="row">
      <div class="col-sm-3  col-6">
        <label class="form-check-label col-form-label-sm">
          Project
        </label>
      </div>
      <div class="col-sm-5 col-6 text-truncate">
        <label class="form-check-label col-form-label-sm ">
          <b>{{this.detail?.projectName}}</b>
        </label>
      </div>
    </div>
  </div>

  <div class="col-sm-6">
    <div class="row">
      <div class="col-sm-2  col-6">
        <label class="form-check-label col-form-label-sm">
          Vendor
        </label>
      </div>
      <div class="col-sm-8  col-6">
        <label class="form-check-label col-form-label-sm">
          <b>{{projectInfo?.vendor}}</b>
        </label>
      </div>
      <div *ngIf="projectInfo?.foc" class="col-sm-2 ">
        <span class="badge badge-info">{{projectInfo?.foc ? 'Free Of Charge' : ''}}</span>
      </div>
    </div>
  </div>
</div>

<div class="form-row align-items-center">
  <div class="col-sm-6">
    <div class="row">
      <div class="col-sm-3 col-4 ">
        <label class="form-check-label col-form-label-sm">
          Supervisor
        </label>
      </div>
      <div class="col-sm-9 col-6 text-truncate">
        <label class="form-check-label col-form-label-sm">
          <b>{{projectInfo?.supervisor}}</b>
        </label>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="row">
      <div class="col-sm-2  col-6">
        <label class="form-check-label col-form-label-sm">
          Start Date
        </label>
      </div>
      <div class="col-sm-3  col-6">
        <label class="form-check-label col-form-label-sm">
          <b>{{(this.projectInfo?.startDate | date:'dd MMM yyyy') || '-'}}</b>
        </label>
      </div>
      <div class="col-sm-2  col-6">
        <label class="form-check-label col-form-label-sm">
          End Date
        </label>
      </div>
      <div class="col-sm-3  col-6">
        <label class="form-check-label col-form-label-sm">
          <b>{{(this.projectInfo?.endDate | date:'dd MMM yyyy') || '-'}}</b>
        </label>
      </div>
    </div>
  </div>
</div>


<!--<div class="form-row align-items-center">-->
<!--  <div class="col-sm-6">-->
<!--    <div class="row">-->

<!--    </div>-->
<!--  </div>-->
<!--  <div class="col-sm-6">-->
<!--    <div class="row">-->

<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<div class="form-row align-items-center">
  <div class="col-sm-6">
    <div class="row">
      <div class="col-sm-3  col-6">
        <label class="form-check-label col-form-label-sm">
          Working-Days
        </label>
      </div>
      <div class="col-sm-9  col-6">
        <label class="form-check-label col-form-label-sm">
          <b>{{ projectInfo?.actualManDayThisMonth}} (OT: <span title="ot">{{projectInfo?.ot}}</span>, Leave: <span
            title="leave">{{projectInfo?.leave}}</span>)</b>
        </label>
      </div>
    </div>
  </div>
  <div class="col-sm-6 ">
    <div class="row">
      <div class="col-sm-2">
        <label class="form-check-label col-form-label-sm" *ngIf="calAmount?.view">
          Cal-Days:
        </label>
      </div>
      <div class="col-sm-3">
        <label class="form-check-label col-form-label-sm" *ngIf="calAmount?.view">
          <b>{{ calAmount?.calDays}}</b>
        </label>
      </div>
      <div class="col-sm-2">
        <label class="form-check-label col-form-label-sm" *ngIf="calAmount?.view">
          Amount:
        </label>
      </div>

      <div class="col-sm-2">
        <label class="form-check-label col-form-label-sm" *ngIf="calAmount?.view">
          <b>{{calAmount ? (calAmount?.amount | number : '1.2-2'): '0.00'}}</b>
        </label>
      </div>
      <div class="col-sm-3 text-right">
        <button class="btn btn-outline-success btn-excel mr-2" (click)="openTimesheetCSV()">
          <img src="assets/icon/excel.svg" class="exelIcon " alt="">
        </button>
        <button class="btn btn-outline-danger btn-excel"
                [class.pdf-loader]="pdfLoader"
                (click)="openTimesheet()">
          <img src="assets/icon/pdf.svg" class="exelIcon " alt="">
        </button>
      </div>
    </div>

  </div>
</div>

<!--<div class="form-row align-items-center">-->
<!--  <div class="col-sm-1 my-1 col-6">-->
<!--    <label class="form-check-label col-form-label-sm">-->
<!--      Project-->
<!--    </label>-->
<!--  </div>-->

<!--  <div class="col-sm-3 my-1 col-6 text-truncate">-->
<!--    <label class="form-check-label col-form-label-sm ">-->
<!--      <b >{{this.detail?.projectName}}</b>-->
<!--    </label>-->
<!--  </div>-->

<!--  <div class="col-sm-2 my-1 col-4">-->
<!--    <label class="form-check-label col-form-label-sm">-->
<!--      Supervisor-->
<!--    </label>-->
<!--  </div>-->
<!--  <div class="col-sm-3 my-1 col-6 ">-->
<!--    <label class="form-check-label col-form-label-sm">-->
<!--      <b>{{projectInfo?.supervisor}}</b>-->
<!--    </label>-->
<!--  </div>-->
<!--  <div class="col-sm-1   my-1 col-6">-->
<!--    <label class="form-check-label col-form-label-sm">-->
<!--      Vendor-->
<!--    </label>-->
<!--  </div>-->
<!--  <div class="col-sm-2 my-1 col-6">-->
<!--    <label class="form-check-label col-form-label-sm">-->
<!--      <b>{{projectInfo?.vendor}}</b>-->
<!--    </label>-->
<!--  </div>-->
<!--  <div *ngIf="projectInfo?.foc" class="col-sm-2 badge badge-info">-->
<!--    {{projectInfo?.foc ? 'Free Of Charge' : ''}}-->
<!--  </div>-->
<!--</div>-->

<!--row-->

<!--<div class="form-row align-items-center">-->
<!--  <div class="col-sm-1 my-1 col-6">-->
<!--    <label class="form-check-label col-form-label-sm">-->
<!--      Start Date-->
<!--    </label>-->
<!--  </div>-->
<!--  <div class="col-sm-1 my-1 col-6">-->
<!--    <label class="form-check-label col-form-label-sm">-->
<!--      <b>{{(this.projectInfo?.startDate | date:'dd MMM yyyy') || '-'}}</b>-->
<!--    </label>-->
<!--  </div>-->
<!--  <div class="col-sm-1 my-1 col-6">-->
<!--    <label class="form-check-label col-form-label-sm">-->
<!--      End Date-->
<!--    </label>-->
<!--  </div>-->
<!--  <div class="col-sm-1 my-1 col-6">-->
<!--    <label class="form-check-label col-form-label-sm">-->
<!--      <b>{{(this.projectInfo?.endDate | date:'dd MMM yyyy') || '-'}}</b>-->
<!--    </label>-->
<!--    &lt;!&ndash;    <input type="text" id="end-project" class="form-control" [value]="projectInfo?.supervisor" readonly>&ndash;&gt;-->
<!--  </div>-->
<!--  <div class="col-sm-2 my-1 col-6">-->
<!--    <label class="form-check-label col-form-label-sm">-->
<!--      Working-Days-->
<!--    </label>-->
<!--  </div>-->
<!--  <div class="col-sm-1 my-1 col-6">-->
<!--    <label class="form-check-label col-form-label-sm">-->
<!--      <b>{{ projectInfo?.actualManDayThisMonth}} (OT <span title="ot">{{projectInfo?.ot}}</span>, Leave <span-->
<!--        title="leave">{{projectInfo?.leave}}</span>)</b>-->
<!--    </label>-->
<!--  </div>-->

<!--  <div class="col-sm-2 my-1 col-6">-->
<!--    &lt;!&ndash;    <label class="form-check-label col-form-label-sm">&ndash;&gt;-->
<!--    &lt;!&ndash;      Working-Days&ndash;&gt;-->
<!--    &lt;!&ndash;    </label>&ndash;&gt;-->
<!--  </div>-->
<!--  <div class="col-sm-2 my-1 col-6">-->
<!--    <label class="form-check-label col-form-label-sm" *ngIf="calAmount?.view">-->
<!--      Cal-Days: <b>{{ calAmount?.calDays}}</b>, Amount: <b>{{calAmount?.amount}}</b>-->
<!--    </label>-->
<!--  </div>-->

<!--  <div class="col-sm-1 text-right col-2">-->
<!--    <button class="btn btn-outline-success btn-excel mr-2" (click)="openTimesheetCSV()">-->
<!--      <img src="assets/icon/excel.svg" class="exelIcon " alt="">-->
<!--    </button>-->
<!--    <button class="btn btn-outline-danger btn-excel" (click)="openTimesheet()">-->
<!--      <img src="assets/icon/pdf.svg" class="exelIcon " alt="">-->
<!--    </button>-->
<!--  </div>-->
<!--</div>-->
<hr>
<div class="row">
  <div class="col-md-3"></div>
  <div class="col-md-3">
    <h6>
      <b>
        Actual Man-Days In This Month {{projectInfo?.actualManDayThisMonth}} Days
      </b>
    </h6>
  </div>
  <div class="col-md-4 ">
    <h6>
      <b>
        Total Actual Man-Days {{projectInfo?.totalActualManDays}}/{{projectInfo?.totalManDays}} Days
      </b>
    </h6>
  </div>

</div>

<div class="row">
  <div class="col-md-6">

  </div>
  <div class="col-md-6 text-center"><b>Job Assign</b></div>
</div>
<form action="" [formGroup]="reviewForm">

  <div class="mt-1" formArrayName="days">
    <div *ngFor="let t of arrayDays.controls; index as i">
      <div class="row" [formArrayName]="i">
        <div class="col-md-1"></div>
        <div class="col-md-2"
             [class.text-danger]="isWeekend(getDay(detail?.days[i]?.date)) || t.get('daily').value === 'HOLIDAY'"
             style="padding-top: 5px">
          {{ getDay(detail?.days[i]?.date)}}
          ,
          {{ detail?.days[i]?.date | date: 'd MMM yyyy'}}
        </div>
        <div class="col-md-3">
          <select name="" class="form-control" formControlName="daily">
            <option value="{{d.value}}" *ngFor="let d of options">{{d.display}}</option>
          </select>
        </div>
        <div class="col-md-6">
          <!--          <input type="text" class="form-control" formControlName="remark">-->
          <textarea name="" id="" cols="30" rows="1" class="form-control" formControlName="remark"></textarea>
        </div>
      </div>
      <hr style="margin-top: 5px">
    </div>
  </div>
</form>
<!-- <div class="col-md-4" *ngFor="let t of roundAsArray(3); index as col">
   <div class="row item-b"
        *ngFor="let t of roundAsArray(arrayDays?.controls.slice(col*config.columnLength, (col+1)*config.columnLength).length); index as r">
     <ng-container *ngIf="r == 0">
       <div class="col-md-6 item-c text-light mb-3" >Day</div>
       <div class="col-md-6 item-c text-light mb-3">Working day</div>
     </ng-container>

     <ng-container [formGroupName]="col * config.columnLength + r">
       <div class="col-md-6"
            [class.text-danger]="isWeekend(getDay(detail?.days[col * config.columnLength + r]?.date))"
       >

         {{ getDay(detail?.days[col * config.columnLength + r]?.date)}}
         ,
         {{ detail?.days[col * config.columnLength + r]?.date | date: 'd MMM yyyy'}}
       </div>
       <div class="col-md-6">
         <select name="" class="form-control" formControlName="daily">
           <option value="{{d.value}}" *ngFor="let d of options">{{d.display}}</option>
         </select>
       </div>
     </ng-container>
   </div>
 </div>-->


<hr>
<div class="row pb-3 mb-4">
  <div class="col-md-6"></div>
  <!--  *ngIf="detail?.status === 'PENDING_REVIEW'"-->
  <div class="col-md-6 text-right">
    <ng-container>
      <button class="btn text-light btn-info mr-3" *ngIf="isReviewer && !IsInternPage" (click)="submit(true)">
        REVIEW
      </button>
      <button class="btn text-light btn-success mr-1" *ngIf="showSaveBTN" (click)="submit(false)">
        SAVE
      </button>
      <button *ngIf="hrCanSave"
              (click)="openModal(logs)"
              class="btn btn-info mr-2">
        Logs
      </button>
      <button class="btn btn-outline-primary text-center align-items-center mr-1"
              *ngIf="hrCanSave && detail.status != 'NOT_SUBMIT'"
              (click)="openModal(editReviewer)">
        Edit Reviewer/Approval
      </button>
      <button class="btn text-light btn-warning mr-3"
              *ngIf="showRetreat"
              (click)="retreat()">
        RETREAT STATUS
      </button>
    </ng-container>

    <ng-container *ngIf="isApproval && !IsInternPage && ['INTERN','INTERN_COOP'].includes(projectInfo?.type)">
      <button class="btn text-light btn-danger mr-3" (click)="reject()">
        REJECT
      </button>

      <button class="btn text-light btn-success" (click)="approve()">
        APPROVE
      </button>
    </ng-container>

  </div>
</div>
<app-loading-overlay [display]="loading"></app-loading-overlay>

<ng-template #editReviewer>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Edit reviewer/approval</h4>
    <button type="button" class="btn-close close pull-right" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="updateApprovalReviewerForm" *ngIf="!onLoadApprovalForm">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="month">Month</label>
          <input type="text" class="form-control" [value]="months[updateApprovalReviewerForm.get('month').value]"
                 readonly id="month" placeholder="month">
        </div>
        <div class="form-group col-md-6">
          <label for="year">Year</label>
          <input type="text" class="form-control" formControlName="year" readonly id="year" placeholder="year">
        </div>
      </div>
      <div class="form-group">
        <label for="reviewer">Project</label>
        <input type="text"
               formControlName="projectName"
               readonly
               class="form-control" aria-describedby="emailHelp">
      </div>
      <div class="form-group">
        <label for="reviewer">Employee Name</label>
        <input type="text"
               formControlName="employeeName"
               readonly
               class="form-control" aria-describedby="emailHelp">
      </div>
      <div class="form-group">
        <label for="reviewer">Reviewer</label>
        <input type="email"
               [typeahead]="employeeList"
               [typeaheadScrollable]="true"
               [typeaheadOptionsInScrollableView]="6"
               [typeaheadMinLength]="0"
               [(ngModel)]="typeAheadReviewer"
               [ngModelOptions]="{standalone: true}"
               typeaheadOptionField="nameEn"
               (typeaheadOnSelect)="onSelectTypeAhead($event, 'reviewer')"
               class="form-control" id="reviewer" aria-describedby="emailHelp">
      </div>
      <div class="form-group ">
        <label for="approval">Approval</label>
        <input type="text" class="form-control"
               [typeahead]="employeeList"
               [typeaheadScrollable]="true"
               [typeaheadOptionsInScrollableView]="6"
               [typeaheadMinLength]="0"
               [(ngModel)]="typeAheadApproval"
               [ngModelOptions]="{standalone: true}"
               typeaheadOptionField="nameEn"
               (typeaheadOnSelect)="onSelectTypeAhead($event,'approval')"
               id="approval">
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <button type="submit" class="btn btn-primary" (click)="onUpdateApprovalReviewer()">Update</button>

        </div>
      </div>
    </form>
    <div *ngIf="onLoadApprovalForm" style="height: 80px">
      <div class="d-flex justify-content-center ">
        <div class="spinner-border text-warning " role="status">
          <span class="sr-only ">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #logs>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Logs</h4>
    <button type="button" class="btn-close close pull-right" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Date</th>
        <th scope="col">EmpId</th>
        <th scope="col">Status</th>
        <!--        <th scope="col" width="40%">Detail</th>-->
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let l of logsList">
        <th scope="row"><small (click)="toggle(l)" class="clickable">{{l.date | date: 'dd MMM yyyy HH:mm:ss'}}</small>
        </th>
        <td><small>{{l.actor}}</small></td>
        <td><small>{{l.oldStatus}} -> {{l.toStatus}}</small></td>
        <!--        <td>-->
        <!--          <ng-container *ngIf="l.logShowDetail; else more">-->
        <!--            <ul *ngFor="let daily of l.days">-->
        <!--              <li scope="row"><small>{{daily.date | date: 'dd MMM yyyy HH:mm:ss'}} / {{daily.daily}} / {{daily.remark}}</small></li>-->
        <!--            </ul>-->
        <!--          </ng-container >-->
        <!--          <ng-template #more>...</ng-template>-->
        <!--        </td>-->
      </tr>
      </tbody>
    </table>
  </div>
</ng-template>
