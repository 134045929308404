import { Component, OnInit } from '@angular/core';
import {AdditionalInformationService} from '../../core/services/additional-information.service';
import {EmployeeService} from '../../core/services/employee.service';

@Component({
  selector: 'app-admin-additional-information',
  templateUrl: './admin-additional-information.component.html',
  styleUrls: ['./admin-additional-information.component.scss']
})
export class AdminAdditionalInformationComponent implements OnInit {

  displayEl
  id
  name = 'NEW'
  havePermission = undefined

  constructor(
    private additionalInformationService: AdditionalInformationService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    this.employeeService.me().toPromise().then(
      s => {
        const r = s.userRoles_.map(it => it.role)
        // this.havePermission = r.includes('UserAdmin') || r.includes('HRAdmin')
        this.havePermission = r.includes('AdditionalAdmin')
      }
    )
  }

  onChangeDisplay(type: string) {
    this.name = 'NEW'
    this.id = null
    if (type) {
      this.displayEl = 'edit'

      if ('new' !== type) {
        this.id = type
      }
    } else {
      this.displayEl = undefined
    }
  }

  onSetName(name) {
    this.name = name
  }

}
