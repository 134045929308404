<app-layout>
  <div class="container-fluid mt-4 pl-5 pr-5">
    <div class="row">
      <div class="col">
        <h4>
            <span *ngIf="displayItem == 'list'">
                <b>
                 RECRUIT
                </b>
            </span>
        </h4>
      </div>
      <div class="col-1" *ngIf="isAdmin">
        <a class="btn btn-info" routerLink="/man-power/report" target="_blank">REPORT</a>
      </div>
    </div>
    <hr *ngIf="displayItem == 'list'" class="set">

    <ng-container [ngSwitch]="displayItem">
      <ng-container *ngSwitchCase="'list'">
        <app-man-power-pending-approve-list *ngIf="!isHrAdmin" (onNewManPowerListData)="routeToManPowerView($event)"></app-man-power-pending-approve-list>
        <app-man-power-hr-admin-pending *ngIf="isHrAdmin" (onNewManPowerListData)="routeToManPowerView($event)"></app-man-power-hr-admin-pending>
        <hr class="set mt-4 mb-4 ">
        <app-man-power-list (onNewManPowerListData)="routeToManPowerView($event)"></app-man-power-list>
      </ng-container>
      <ng-container *ngSwitchCase="'new'">
        <app-new-man-power (onEmitData)="onBack($event)"></app-new-man-power>
      </ng-container>
      <ng-container *ngSwitchCase="'edit'">
        <app-new-man-power (onEmitData)="onBack($event)" [id]="id" [forAction]="forAction" ></app-new-man-power>
      </ng-container>
    </ng-container>
  </div>
</app-layout>
