export enum DailyOptionEnum {
  WEEK_END = 'WEEK_END',
  FULL_DAY = 'FULL_DAY',
  HALF_DAY = 'HALF_DAY',
  LEAVE = 'LEAVE',
  HOLIDAY = 'HOLIDAY',
  NOT_SELECT = 'NOT_SELECT',
  ONE_HOUR = 'ONE_HOUR',
  TWO_HOURS = 'TWO_HOURS',
  THREE_HOURS = 'THREE_HOURS',
  FOUR_HOURS = 'FOUR_HOURS',
  FIVE_HOURS = 'FIVE_HOURS',
  SIX_HOURS = 'SIX_HOURS',
  SEVEN_HOURS = 'SEVEN_HOURS',
}

export const dailyOptions: DailyModel[] = [
  {
    value: DailyOptionEnum.WEEK_END,
    display: 'Weekend'
  }, {
    value: DailyOptionEnum.FULL_DAY,
    display: 'Full day'
  }, {
    value: DailyOptionEnum.HALF_DAY,
    display: 'Half day'
  }, {
    value: DailyOptionEnum.LEAVE,
    display: 'Leave'
  }, {
    value: DailyOptionEnum.HOLIDAY,
    display: 'Holiday'
  }, {
    value: DailyOptionEnum.ONE_HOUR,
    display: '1 Hour'
  }, {
    value: DailyOptionEnum.TWO_HOURS,
    display: '2 Hours'
  }, {
    value: DailyOptionEnum.THREE_HOURS,
    display: '3 Hours'
  }, {
    value: DailyOptionEnum.FIVE_HOURS,
    display: '5 Hours'
  }, {
    value: DailyOptionEnum.SIX_HOURS,
    display: '6 Hours'
  }, {
    value: DailyOptionEnum.SEVEN_HOURS,
    display: '7 Hours'
  }
]

export interface DailyModel {
  value: DailyOptionEnum;
  display: string;
}
