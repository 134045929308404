import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TimesheetInfo} from '../models/timesheetInfo';
import {
  CalculateAmount,
  Log,
  OutsourceTimesheetInfo,
  OutsourceWeeklyModel,
  SimpleResponse, UpdateApprovalReviewer
} from '../../modules/outsource-time-sheet/core/models/outsource.weekly.model';
import {map} from 'rxjs/operators';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class OutsourceTimesheetService {
  e = environment.api_endpoint + '/outsource-timesheet';

  constructor(private http: HttpClient) {
  }

  /**
   * @deprecated The method should not be used
   */
  getTimesheetForSupervisor(month: number, year: number): Observable<TimesheetInfo[]> {
    return this.http.get<TimesheetInfo[]>(`${this.e}/list/${month}/${year}`)
  }

  /**
   * @deprecated The method should not be used
   */
  getTimesheetForVP(month: number, year: number): Observable<TimesheetInfo[]> {
    return this.http.get<TimesheetInfo[]>(`${this.e}/list/vp/${month}/${year}`)
  }

  getTimesheetForIntern(month: number, year: number): Observable<TimesheetInfo[]> {
    return this.http.get<TimesheetInfo[]>(`${this.e}/list/intern/${month}/${year}`)
  }

  getTimesheet(month: number, year: number): Observable<TimesheetInfo[]> {
    return this.http.get<TimesheetInfo[]>(`${this.e}/v-list/${month}/${year}`)
  }

  getTimesheetWithHr(month: number, year: number): Observable<TimesheetInfo[]> {
    return this.http.get<TimesheetInfo[]>(`${this.e}/v-list/hr/${month}/${year}`)
  }

  canReview(month: number, year: number): Observable<any> {
    return this.http.get<any>(`${this.e}/reviewable/${month}/${year}`)
  }

  getCalculateAmount(empId: string, month: number, year: number, projectName: string) {
    return this.http.get<CalculateAmount>(`${this.e}/amount/${empId}/${month}/${year}`, {
      params: {projectName},
    })
  }


  downloadTimesheet(empId: string, month: number, year: number, projectName: string): Observable<any> {
    return this.http.get(`${this.e}/download-timesheet/${empId}/${month}/${year}`,
      {
        params: {projectName},
        observe: 'response', responseType: 'blob' as 'json'
      })
  }

  downloadTimesheetCSV(empId: string, month: number, year: number, projectName: string) {
    return this.http.get(`${this.e}/download-timesheet-csv/${empId}/${month}/${year}`,
      {
        params: {projectName},
        responseType: 'blob'
      })
  }


  getMonthlyTimesheetDetail(empId: string, month: number, year: number, projectName: string): Observable<OutsourceWeeklyModel> {
    return this.http.get<OutsourceWeeklyModel>(`${this.e}/detail/${empId}/${month}/${year}`, {params: {projectName}})
      .pipe(map(d => {
        const days = d.days;
        d.days = days.filter(e => {
          const m = dayjs(e.date).month();
          return +m === +month
        })
        return d;
      }))
  }

  getMonthlyTimesheetInfo(empId: string, month: number, year: number, projectName: string): Observable<OutsourceTimesheetInfo> {
    return this.http.get<OutsourceTimesheetInfo>(`${this.e}/info/${empId}/${month}/${year}`, {params: {projectName}});
  }

  logs(empId: string, month: number, year: number, projectName: string): Observable<Log[]> {
    return this.http.get<Log[]>(`${this.e}/logs/${empId}/${month}/${year}`, {params: {projectName}});
  }

  save(body: OutsourceWeeklyModel, isReview = false): Observable<any> {
    return this.http.post(`${this.e}/${isReview ? 'review' : 'save'}`, body)
  }


  isApproval(month: number, year: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.e}/is-approval/${month}/${year}`)
  }

  // approve reject
  saveAction(body: OutsourceWeeklyModel): Observable<any> {
    return this.http.post(`${this.e}/action`, body)
  }

  saveActionAll(body: OutsourceWeeklyModel) {
    return this.http.post(`${this.e}/action/all`, body)
  }

  payment(body: OutsourceWeeklyModel, filterDept: string): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.e}/create-pr`, body, {
      params: {
        filterDept
      }
    })
  }

  updateStatusPayment(body, filterDept: string): Observable<any> {
    return this.http.post<any>(`${this.e}/payment/update`, body, {
      params: {
        filterDept
      }
    })
  }

  saveReviewAll(body: OutsourceWeeklyModel, filterProject: string, filterVendor: string) {
    filterProject = filterProject.replace('all', '')

    let params = new HttpParams()
    if (filterProject && filterProject !== 'all') {
      params = params.set('filterProject', filterProject)
    }

    if (filterVendor && filterVendor !== 'all') {
      params = params.set('filterVendor', filterVendor)
    }

    return this.http.post(`${this.e}/review/all`, body, {
      params
    })
  }

  retreatStatus(empId: string, projectName: string, month: number, year: number): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.e}/retreat`, {
      empId,
      month,
      year,
      projectName
    })
  }

  changeApprovalReviewer(body: UpdateApprovalReviewer) {
    return this.http.post(`${this.e}/change-approval`, body)
  }

}
