/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AdditionalInformation {
    active?: boolean;
    disable?: boolean;
    createBy?: string;
    createDate?: Date;
    id?: string;
    name?: string;
    possibleValue?: Array<string>;
    targetGroup?: AdditionalInformation.TargetGroupEnum;
    type?: AdditionalInformation.TypeEnum;
    updateBy?: string;
    updateDate?: Date;
    sequenceNo?: number
    defaultValue?: string;
}
export namespace AdditionalInformation {
  export type TargetGroupEnum = 'ALL_USERS' | 'EMPLOYEE_ONLY' | 'OUTSOURCE_ONLY';
  export const TargetGroupEnum = {
    ALLUSERS: 'ALL_USERS' as TargetGroupEnum,
    EMPLOYEEONLY: 'EMPLOYEE_ONLY' as TargetGroupEnum,
    OUTSOURCEONLY: 'OUTSOURCE_ONLY' as TargetGroupEnum
  };
  export type TypeEnum = 'CHOICE' | 'TEXT';
  export const TypeEnum = {
    CHOICE: 'CHOICE' as TypeEnum,
    TEXT: 'TEXT' as TypeEnum
  };
}
