<div>
    <table class="table respnsive-table-style">
        <thead class="dt-head">
            <tr>
                <th></th>
                <th>Quarter</th>
                <th>Time</th>
                <th>User</th>
                <th>Action</th>
                <th>Comment</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of histories; let i=index;">
                <td>{{i + 1}}</td>
                <td>{{item.quarter}}</td>
                <td>{{item.createDate | date: 'dd MMM yyyy, HH:mm:ss'}}</td>
                <td>{{item.user}}</td>
                <td>{{item.action}}</td>
                <td>{{item.comment}}</td>
            </tr>
    </table>
</div>