import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyPipe } from './currency.pipe';
import { NumberFormatDirective } from './number-format.directive';
import { SortDirective } from './sort.directive';



@NgModule({
  declarations: [CurrencyPipe, NumberFormatDirective, SortDirective],
  imports: [
    CommonModule
  ],
  exports: [CurrencyPipe, NumberFormatDirective, SortDirective]
})
export class ShareModule { }
