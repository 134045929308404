<div class="container-fluid mt-4 pl-5 pr-5">
  <form [formGroup]="searchForm">
    <div class="row mb-2">
      <div class="col">
        <h4>External training</h4>
      </div>
      <!-- <div class="col-auto">
        <button class="btn btn-info btn-lg">
                <fa-icon [icon]="faIcon.faPlus"></fa-icon>
                Add
            </button>
      </div> -->
    </div>
    <div class="row">
      <div class="col-2 text-right">
        <label for="">Search For: </label>
      </div>
      <div class="col-4">
        <div class="form-group">
          <div class="input-group">
            <input
              type="text"
              class="ml-2 form-control custom-input-2"
              formControlName="keyword"
            />
            <span class="input-group-btn">
              <button class="btn btn-info" type="submit" (click)="debug()">
                <fa-icon [icon]="faIcon.faSearch"></fa-icon>
              </button>
            </span>
            <button
              class="btn btn-outline-danger ml-2"
              type="submit"
              (click)="clearFilter()"
            >
              <fa-icon [icon]="faIcon.faTimes"></fa-icon> Reset all
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-2 text-right">
        <label for=""> Filtered By: </label>
      </div>
      <div class="col">
        <div class="form-group">
          <div class="form-inline">
            <input
              type="text"
              class="ml-2 form-control custom-input mr-2"
              formControlName="start"
              bsDatepicker
              [bsValue]="formatDate(today)"
              [bsConfig]="datePickerFormat"
            />
            <label for="">to</label>
            <input
              type="text"
              class="ml-2 form-control custom-input"
              formControlName="end"
              bsDatepicker
              [bsValue]="formatDate(today)"
              [bsConfig]="datePickerFormat"
            />

            <select
              name=""
              id=""
              class="ml-2 form-control custom-input"
              formControlName="status"
            >
              <option value="">All Status</option>
              <option value="pending approve">Pending Approve</option>
              <option value="draft">Draft</option>
              <option value="canceled">Canceled</option>
              <option value="rejected">Rejected</option>
              <option value="completed">Completed</option>
            </select>

            <select
              name=""
              id=""
              class="ml-2 form-control custom-input"
              formControlName="deptId"
            >
              <option value="">All Departments</option>
              <option
                value="{{ dept.deptID }}"
                *ngFor="let dept of departmentList"
              >
                {{ dept.deptName }}
              </option>
            </select>
            <select
              name=""
              id=""
              class="ml-2 form-control custom-input"
              formControlName="competenciesId"
            >
              <option value="">All Competecies</option>
              <option [ngValue]="com.id" *ngFor="let com of competencyList">
                {{ com.competencyDescr }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-2 text-right"></div>
      <div class="col">
        <div class="form-inline">
          <select
            name=""
            id=""
            class="ml-2 form-control custom-input-2"
            formControlName="staffGrade"
          >
            <option value="">All</option>
            <option value="l4">All Management (M6-M7)</option>
            <option value="l3">All Senior Management (M4-M5)</option>
            <option value="l2">All Middle Management (M1-M3)</option>
            <option value="l1">All Staff (S1-S4)</option>
          </select>
          <input
            type="text"
            class="ml-2 form-control custom-input"
            [typeahead]="employeeList"
            typeaheadOptionField="nameTh"
            [(ngModel)]="attendeeObj"
            [ngModelOptions]="{ standalone: true }"
            (typeaheadOnSelect)="onSelectAttendee($event)"
            (focus)="clearAttendee()"
          />
          <input
            type="text"
            class="ml-2 form-control custom-input"
            [typeahead]="employeeList"
            typeaheadOptionField="nameTh"
            [(ngModel)]="requesterObj"
            (focus)="clearRequester()"
            (typeaheadOnSelect)="onSelectRequester($event)"
            [ngModelOptions]="{ standalone: true }"
          />
          <button
            class="ml-2 btn btn-success text-uppercase float-right"
            (click)="exportData()"
          >
            Export data
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <app-external-request-list
          [requestTrainingList]="requestTrainingList"
          (reload)="loadList()"
        >
        </app-external-request-list>
      </div>
    </div>
  </form>
</div>
