import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {EmployeeService} from '../../../core/services/employee.service';
import {Employee} from '../../../core/models/employee';
import {combineLatest, forkJoin, of, Subscription} from 'rxjs';
import {ProjectDetail, ProjectService} from '../../../modules/outsource-time-sheet/core/services/project.service';
import {pickBy, identity} from 'lodash'
import {catchError, map} from 'rxjs/operators';
import {faIcon} from '../../../core/icon/fa.icon';
import Swal from 'sweetalert2';
import {CustomValidator} from '../../../core/Validators/custom-validator';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent implements OnInit, OnDestroy {

  constructor(
    private fb: FormBuilder,
    private employeeService: EmployeeService,
    private projectService: ProjectService
  ) {
  }

  load = true;

  @Input('project')
  set prepareForm(data: ProjectDetail) {
    if (data) {
      this.form.patchValue(data);
      if (data.poList) {
        this.patchCurrentData(data);
      }
    } else {
      this.form.reset({
        startDate: new Date(),
        endDate: new Date()
      });
      this.addPo();
    }
  }

  get poListControl(): FormArray {
    return this.form.get('poList') as FormArray;
  }

  get typeControl() {
    return this.form.get('type') as FormControl;
  }

  get empIdControl() {
    return this.form.get('empId') as FormControl;
  }

  get projectNameControl() {
    return this.form.get('projectName') as FormControl;
  }

  get vendorControl() {
    return this.form.get('vendor') as FormControl;
  }

  get vendorEmailControl() {
    return this.form.get('vendorEmail') as FormControl;
  }

  get outsourceRate() {
    return this.form.get('rate') as FormControl;
  }

  get getRateType() {
    return this.form.get('rateType') as FormControl;
  }


  public get startDateControl() {
    return this.form.get('startDate') as FormControl;
  }

  public get getEndDateControl() {
    return this.form.get('endDate') as FormControl;
  }

  public get citiZenIdControl() {
    return this.form.get('citizenId') as FormControl;
  }

  public get oldProjectControl() {
    return this.form.get('oldProjectName') as FormControl;
  }

  public get getOldVendor() {
    return this.form.get('oldVendor') as FormControl;
  }

  public get getOldEmpId() {
    return this.form.get('oldEmpId') as FormControl;
  }

  faIcon = faIcon;

  bsConfig = {
    containerClass: 'theme-orange',
    dateInputFormat: 'DD MMM YYYY'
  }
  employeeList: Employee[] = []
  employeeSelected: string;
  sub: Subscription[] = []
  form = this.fb.group({
    empId: ['', [Validators.required]],
    oldEmpId: [null],
    projectName: ['', [Validators.required]],
    oldProjectName: [null],
    vendor: ['', [Validators.required]],
    oldVendor: [null],
    citizenId: ['', {validators: [Validators.required, CustomValidator.thaiNationalID]}],
    startDate: [new Date(), [Validators.required]],
    endDate: [new Date(), [Validators.required]],
    foc: false,
    active: false,
    type: ['', [Validators.required]],
    oldType: [null],
    poList: this.fb.array([]),
    vendorEmail: ['', Validators.required],
    rate: [0, Validators.required],
    rateType: ['MD', Validators.required]
    // manDay: [0],
    // manDaySpent: [0]
  });
  deletePoList = this.fb.array([]);


  private static buildFormPO(isNew: boolean, manDay: number, manDaySpent: number, po = '') {
    return {
      po: [po ?? '', [Validators.required]],
      isChange: false,
      oldPo: po,
      isNew,
      manDay: [manDay, [Validators.min(1), Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]],
      manDaySpent: [manDaySpent, [Validators.pattern(/^[0-9]+(\.[0-9]{1,2})?$/)]]
    };
  }

  private doSave(shouldSave) {
    combineLatest(shouldSave)
      .toPromise()
      .then(r => {
        for (const e of r) {
          const result = e as any;
          if (!result.error) {
            this.changeIsNewFlag(result);
          }
        }
        const hasError = r.some(v => {
          const result = v as any;
          return !!result.error
        })

        if (hasError) {
          this.alert(false, 'Transaction failed')
        } else {
          this.alert(true, 'Save the data successfully')
        }

      })
      .catch(console.error)
      .finally(() => this.load = false)
  }

  alert(isSuccess: boolean, msg) {
    return Swal.fire({
      scrollbarPadding: false,
      title: isSuccess ? 'Successfully' : 'Failed',
      icon: isSuccess ? 'success' : 'error',
      text: msg,
      buttonsStyling: false,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-warning text-light',
      },
    });
  }


  private changeIsNewFlag(result) {
    const project = (result as ProjectDetail);
    for (const poControl of this.poListControl.controls) {
      if (poControl.get('po').value === project.po) {
        poControl.get('isNew').patchValue(false)
      }
    }
  }

  private handleError(e) {
    if (e.status === 500) {
      return of({error: 'transactional error'})
    }
  }

  onClearEmp() {
    this.employeeSelected = '';
    this.empIdControl.patchValue('');
  }

  private patchCurrentData(data: ProjectDetail) {
    this.form.patchValue({
      oldEmpId: data.empId,
      oldProjectName: data.projectName,
      oldType: data.type,
      startDate: new Date(data.startDate),
      endDate: new Date(data.endDate),
      oldVendor: data.vendor
    })
    data.poList.forEach(({po, manDay, manDaySpent}) => {
      this.poListControl.push(this.fb.group(ProjectFormComponent.buildFormPO(false, manDay, manDaySpent, po)))
      this.doSubscribePoOldChange();
    })
  }

  public onSelectEmp({item}) {
    this.form.get('empId').patchValue(item.empID);
  }

  public onChaneType() {
    const type = this.typeControl.value
    if (['INTERN', 'INTERN_COOP'].includes(type)) {
      this.forceChangeProjectAndVendor();
    } else {
      this.resetProjectAndVendor();
    }
  }

  private forceChangeProjectAndVendor() {
    this.form.patchValue({
      projectName: 'Intern',
      vendor: 'Intern'
    })
    this.projectNameControl.disable();
    this.vendorControl.disable();
  }

  private resetProjectAndVendor() {
    this.projectNameControl.enable();
    this.vendorControl.enable();
  }

  ngOnInit(): void {
    this.employeeService.employeeList()
      .pipe(map(e => e.filter(emp => emp.empType === 'Outsource')))
      .toPromise()
      .then(e => {
        this.employeeList = e;
      })
      .finally(() => {
        if (this.empIdControl.value) {
          const emp = this.employeeList.find(e => e.empID === this.empIdControl.value);
          if (emp) {
            this.employeeSelected = emp.empID;
          }
        }
        this.load = false;
      })
  }

  ngOnDestroy(): void {
    this.sub.forEach(s => s.unsubscribe())
  }

  addPo() {
    this.poListControl.push(this.fb.group(ProjectFormComponent.buildFormPO(true, 0, 0)))
  }

  confirmAction(action: 'delete') {
    return Swal.fire({
      scrollbarPadding: false,
      icon: 'question',
      text: `Are you sure you want to ${action} this PO.`,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: `${action.toUpperCase()}`,
      focusCancel: true,
      customClass: {
        confirmButton: 'btn btn-success text-light mr-2',
        cancelButton: 'btn btn-secondary text-light',
      },
    });
  }

  async deletePo(index: number) {
    const value = this.poListControl.at(index).value

    const confirmed = !value.isNew && await this.confirmAction('delete')
    if (!confirmed.value && !value.isNew) { // not confirm
      return;
    }

    if (!value.isNew) {
      const project = this.oldProjectControl.value;
      const empId = this.getOldEmpId.value;
      this.deletePoList.push(this.fb.group({
        projectName: project,
        empId: [empId],
        po: [value.oldPo || '']
      }))
    }
    this.poListControl.removeAt(index);
  }

  arrayIsDup(arr: any[]) {
    const vs = [...new Set(arr)]
    return arr.length !== vs.length;
  }

  onSave() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    const poIsDup = this.arrayIsDup(this.poListControl.controls.map(poC => poC.get('po').value.toLowerCase()))
    if (poIsDup) {
      this.poListControl.controls.forEach(p => p.get('po').setErrors({isDup: 'PO should not be duplicated.'}))
      return;
    }
    this.load = true;
    const {poList, ...data} = this.form.getRawValue();
    const shouldSave = poList
      .map(({po, oldPo, isChange, isNew, manDay, manDaySpent}) => this.mapOldData(data, po, isNew, isChange, oldPo, manDay, manDaySpent))
      .map(d => pickBy(d, v => v != null))
      .map(v => {
        // todo handle error msg case
        if (v.isNew) {
          return this.projectService.saveProject(v).pipe(catchError((e) => this.handleError(e))).toPromise()
        }
        return this.projectService.updateProject(v).pipe(catchError((e) => this.handleError(e))).toPromise()
      })

    const shouldDelete = this.deletePoList.getRawValue()
      .map(({projectName, po, empId}) => this.projectService.deleteProject(po, empId, projectName).toPromise())


    combineLatest(shouldDelete)
      .toPromise()
      .finally(() => {
        this.doSave(shouldSave);
      })

    console.log('after', shouldSave)
  }

  private mapOldData(data: Pick<any, string | number | symbol>, po, isNew, isChange, oldPo, manDay, manDaySpent) {
    return {
      ...data,
      po,
      oldPo: isNew ? null : isChange ? oldPo : null,
      isNew,
      oldProjectName: this.buildOldProject(isNew),
      oldEmpId: this.buildOldEmpId(isNew),
      manDay,
      manDaySpent
    };
  }

  private buildOldEmpId(isNew) {
    return isNew ? null :
      this.getOldEmpId.value !== this.empIdControl.value ? this.getOldEmpId.value : null;
  }

  private buildOldProject(isNew) {
    return isNew ? null :
      this.oldProjectControl.value !== this.projectNameControl.value ? this.oldProjectControl.value : null;
  }

  private doSubscribePoOldChange() {
    this.sub.push(this.poListControl.at(this.poListControl.length - 1).get('po').valueChanges.subscribe((newPo) => {
      const {oldPo} = this.poListControl.at(this.poListControl.length - 1).value;
      if (oldPo != null && newPo !== oldPo) {
        // console.log('po IS Change')
        this.poListControl.at(this.poListControl.length - 1).get('isChange').patchValue(true);
      }
    }));
  }
}
