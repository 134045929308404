import {
  faPlus,
  faTrash,
  faTrashAlt,
  faSave,
  faWindowClose,
  faChartBar,
  faTimesCircle,
  faSync,
  faBars,
  faPhone,
  faChartLine,
  faEdit,
  faPlusCircle,
  faMobileAlt,
  faHome,
  faCircle,
  faMapMarkerAlt,
  faClock,
  faDollarSign,
  faStickyNote,
  faUser,
  faPaperclip,
  faCheck,
  faTimes,
  faSearch,
  faPen,
  faDesktop,
  faChevronRight,
  faFileImage,
  faCaretDown,
  faCaretUp,
  faFolderOpen,
  faQuestion,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';
export const faIcon = {
  faPlus,
  faTrash,
  faTrashAlt,
  faSave,
  faSync,
  faWindowClose,
  faChartBar,
  faTimesCircle,
  faBars,
  faPhone,
  faChartLine,
  faEdit,
  faPlusCircle,
  faMobileAlt,
  faHome,
  faCircle,
  faMapMarkerAlt,
  faClock,
  faDollarSign,
  faStickyNote,
  faUser,
  faPaperclip,
  faCheck,
  faTimes,
  faSearch,
  faPen,
  faDesktop,
  faChevronRight,
  faFileImage,
  faCaretDown,
  faCaretUp,
  faFolderOpen,
  faQuestion,
  faQuestionCircle,
};
