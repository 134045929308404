import { DeptRelation } from './../../core/models/deptRelation';
import { ParallelPosition } from './../../core/models/parallelPosition';
import { ParallelPositionService } from './../../core/services/parallel-position.service';
import { Position } from './../../core/models/position';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { faIcon } from 'src/app/core/icon/fa.icon';
import Swal from 'sweetalert2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {any} from "codelyzer/util/function";

@Component({
  selector: 'app-parallel-position',
  templateUrl: './parallel-position.component.html',
  styleUrls: ['./parallel-position.component.scss']
})
export class ParallelPositionComponent implements OnInit {
  allStatus = ['Open', 'Hired', 'Completed', 'Canceled']
  searhStatus = ['All status','Open', 'Hired', 'Completed', 'Canceled']
  selectedStatus = 'All status';
  keyword = ''
  faIcon = faIcon;
  modalRef: BsModalRef;
  allPosition: Position[] =[];
  isValidData: boolean = false;
  allDepartment: DeptRelation[] = [];
  employees =[];
  rows: ParallelPosition[] = [];
  parallelPositionList: ParallelPosition[] = [];
  editRowId: any;
  disStatus: boolean = false;
  enableEdit: boolean = false;
  enableSave: boolean = false;
  confirmationWording: string = '';
  confirmationStatus: string = '';
  enableEditIndex = null;
  deleteId = 0;
  deleteIndex = null;
  updateList;
  positionList= null;
  statusNotFound: boolean = false;
  updateIndex = null;
  emptyEmp= null;
  disableStatus: boolean = false;
  list=[];
  public maxSize: number = 7;
  currentPage = 1;
  itemsPerPage = 10;
  config = {
    currentPage: 0,
    itemsPerPage: 10,
    totalItems: 0
  } ;
  public labels: any = {
    previousLabel: '<',
    nextLabel: '>',
};
  constructor(
    private parallelPositionService: ParallelPositionService,
    private modalService: BsModalService
    ) {
    this.rows = [];
  }

  ngOnInit() {
    this.parallelPositionService.getDepartment().subscribe(res => {
     this.allDepartment = res
     this.getAllParallelPosition();
    })
  }

  getAllParallelPosition(){
    this.parallelPositionService.getAllParallelPosition().subscribe(res => {
      this.rows = res
      this.config = {
        currentPage: 1,
        itemsPerPage:  10,
        totalItems: this.rows.length
      };
      this.parallelPositionList = res
  })
  }

  searchRequest(){
    if (this.keyword == '') {
      return this.getAllParallelPosition();
    }
    this.rows = this.parallelPositionList;
    let word = this.keyword.toLowerCase();
      let d = this.rows.filter(i => i.positionName.toLowerCase().includes(word)   || i.departmentName.toLowerCase().includes(word) || i.employeeName.toLowerCase().includes(word))
      if (d.length > 0) {
        this.rows = d
      } else {
        this.rows = this.list;
      }
  }

  onChangeDepartment(event,row:ParallelPosition) {
    console.log("row in department: ", row);
    console.log("event", event);

    let data = this.allDepartment.find(item=> item.department==event.department);
    if (data) {
      row.departmentId = data.department
      row.departmentName = data.deptName;
      console.log("data on department change: ", data);
    }
    this.findPosition(row.departmentId || data.deptID)
  }
handlePaging(event) {
  this.config.currentPage = event;
}
findPosition(departmentId) {
  this.parallelPositionService.getPositionByDepartment(departmentId).subscribe(res => {
    if(res.length > 0 ) {
      this.allPosition = res;
    }
  })
}
  onChangePosition(row, index) {
    let p = this.allPosition.find(item => item.positionCode == row.positionId);
    row.positionName = p.positionName
  this.findEmployee(row.departmentId, index,row);
  }
  findEmployee(department, index,item) {
    this.parallelPositionService.getEmployeeByDepartment(department).subscribe(res => {
        this.employees[index] = res;
        if (res.length == 1) {
          item.employeeName = res.map(item=> item.nameEn)
          // item.employeeId = res.map(item=> item.empID)
        }
    })

  }

  onChangeStatus(status){
    if( status == 'All status') {
      this.statusNotFound= false;
      return this.getAllParallelPosition();
    }
    this.rows = this.parallelPositionList;
    let d = this.rows.filter(item=> item.status == status);
    if (d.length > 0) {
      this.rows = d
    } else {
      this.rows = this.list;
    }
  }
  onStatus(event){
    if (event) {
      this.disStatus = true
    }
  }
  editRow(status,index,item) {
    this.enableEdit = true;
    this.enableEditIndex = index;
    this.enableSave = true;

    this.findPosition(item.departmentId)
    this.findEmployee(item.departmentId, index,item)

  }
  update(index, item) {
    console.log('update', this.updateList);
    this.parallelPositionService.update(item.id,item).toPromise()
    .then((data) => {
      this.enableEdit = false;
      this.enableEditIndex = null;
      if(Array.isArray(this.rows)) {
        this.rows.splice(index,1,data)
      }
      this.enableSave= false;
      this.modalRef.hide();
      this.alertModal(true, 'Parallel Position has been update.')
      this.ngOnInit();
    })
  }
  onEmployee(item,index) {
    console.log("row : ", item)
    let empList = this.employees[index]
    console.log("empList: ", empList);
    if (empList) {
      let e = empList.find(data=> data.empID === item.employeeId)
      console.log("emp:", e);
      item.employeeName = e.nameTh
    }
    console.log("empList: ", empList);

  }


  addParallelPosition() {
    let d: ParallelPosition =({
      id: 0,
      positionId: '',
      positionName:'',
      departmentId: null,
      departmentName:'',
      employeeId: '',
      employeeName:'',
      status: 'Open'
    })
    this.disableStatus = true
    this.rows.unshift(d);
    this.config.totalItems = this.rows.length;
  }
  save(){
    let body: ParallelPosition = this.rows[0];
    console.log(JSON.stringify(body));
    console.log("save: " ,body);
    this.disableStatus = false;
    this.parallelPositionService.save(body).toPromise()
    .then(res => {
      this.alertModal(true, 'Parallel Position has been saved!')
      this.modalRef.hide();
      this.ngOnInit();
    })
  }
  onConfirmationModal(template: TemplateRef<any>, status,item, index) {
    if (status === 'delete') {
      this.confirmationWording =
        'Do you want to remove this parallel position ?';
      this.confirmationStatus = status;
      this.deleteId = item.id;
      this.deleteIndex = index;
      this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
    } else if (status === 'update') {
      this.confirmationWording = 'Do you want to update this parallel position ?';
      this.confirmationStatus = status;
      this.updateIndex = index;
      this.updateList = item;
      this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
    } else if (status == 'save') {
      this.confirmationWording = 'Do you want to save this parallel position ?';
      this.confirmationStatus = status;
      this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
    }
  }
  confirm() {
    console.log("confirm", this.confirmationStatus);
    if (this.confirmationStatus === 'delete') {
      console.log(this.deleteId);

      this.deleteRow(this.deleteId, this.deleteIndex);
    } else if (this.confirmationStatus === 'save') {
      this.save();
    } else if (this.confirmationStatus === 'update') {
      this.update(this.updateIndex, this.updateList);
    }
  }
  deleteRow(id,index) {
    console.log("id in delete : ", id)
      if (index === 0 && id === 0) {
        console.log("do if")
        // this.rows.splice(index, 1);
        this.ngOnInit();
        this.alertModal(true, 'Parallel Position has been removed.')
        this.modalRef.hide();
      }
      else {
        console.log('else', id);
        this.parallelPositionService.delete(id).toPromise()
        .then(res => {
          this.alertModal(true, 'Parallel Position has been removed.');
          this.modalRef.hide();
          this.ngOnInit();
        })
      }
  }
  alertModal(isSuccess: boolean, msg: string) {
    return Swal.fire({
      scrollbarPadding: false,
      text: msg,
      title: isSuccess ? "Successfully" : "Failed",
      icon: isSuccess ? "success" : "error",
      confirmButtonText: "OK",
      focusCancel: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-info btn-lg mr-2 text-light",
      },
    });
  }
}
