<form [formGroup]="form">
  <div class="form-group row">
    <label class="col-md-2 col-form-label " for="emp">Employee ID</label>
    <div class=" col-md-10">
      <div class="input-group">
        <input type="text" class="form-control"
               id="emp"
               [typeahead]="employeeList"
               [(ngModel)]="employeeSelected"
               typeaheadOptionField="empID"
               [typeaheadOptionsLimit]="8"
               [typeaheadScrollable]="true"
               (typeaheadOnSelect)="onSelectEmp($event)"
               [ngModelOptions]="{ standalone: true}"
               [class.is-invalid]="empIdControl.invalid && empIdControl.touched"
               autocomplete="off">
        <fa-icon
          [icon]="faIcon.faTimes"
          id="clearIn"
          class="text-danger"
          (click)="onClearEmp()"
          *ngIf="employeeSelected">
        </fa-icon>


      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class=" col-md-2 col-form-label " for="project">ProjectName</label>
    <div class=" col-md-10">
      <div class="input-group">
        <input type="text" class="form-control"
               id="project"
               [class.is-invalid]="projectNameControl.invalid && projectNameControl.touched"
               formControlName="projectName" autocomplete="off">
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class=" col-md-2 col-form-label " for="vendor">Vendor</label>
    <div class=" col-md-4">
      <div class="input-group">
        <input type="text" class="form-control"
               id="vendor"
               formControlName="vendor"
               [class.is-invalid]="vendorControl.invalid && vendorControl.touched"
               autocomplete="off">
      </div>
    </div>
    <label class="col-md-2 col-form-label " for="citizen">CitizenId</label>
    <div class=" col-md-4">
      <div class="input-group">
        <input type="text"
               id="citizen"
               class="form-control"
               formControlName="citizenId"
               [class.is-invalid]="citiZenIdControl.invalid && citiZenIdControl.touched "
               autocomplete="off">
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class=" col-md-2 col-form-label " for="vendorEmail">Vendor Email</label>
    <div class=" col-md-4">
      <div class="input-group">
        <input type="text" class="form-control"
               id="vendorEmail"
               formControlName="vendorEmail"
               [class.is-invalid]="vendorEmailControl.invalid && vendorEmailControl.touched"
               autocomplete="off">
      </div>
    </div>
    <label class="col-md-2 col-form-label " for="rate">Rate</label>
    <div class=" col-md-4">
      <div class="input-group">
        <input type="text"
               id="rate"
               class="form-control"
               formControlName="rate"
               [class.is-invalid]="outsourceRate.invalid && outsourceRate.touched "
               autocomplete="off">
      </div>
    </div>
  </div>


  <!--  <div class="form-group row">-->
  <!--   -->

  <!--  </div>-->


  <div class="form-group row">
    <label for="type" class="col-sm-2 col-form-label">Type</label>
    <div class="col-sm-4">
      <select name="" id="type" formControlName="type" (change)="onChaneType()" class="form-control">
        <option value="IT_OUTSOURCE">IT_OUTSOURCE</option>
        <option value="INTERN">INTERN</option>
        <option value="INTERN_COOP">INTERN_COOP</option>
      </select>
    </div>
    <div class="col-md-2"></div>
    <div class="form-check mb-2 mr-sm-2 ml-3">
      <input class="form-check-input" type="checkbox" formControlName="active" id="active">
      <label class="form-check-label" for="active">
        Active
      </label>
    </div>
    <div class="col-md-1"></div>
    <div class="form-check mb-2 mr-sm-2">
      <input class="form-check-input" type="checkbox" formControlName="foc" id="foc">
      <label class="form-check-label" for="foc">
        Free Of Charge
      </label>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-2 col-form-label " for="start">Start Date</label>
    <div class=" col-md-4">
      <div class="input-group">
        <input type="text" class="form-control"
               id="start"
               formControlName="startDate"
               bsDatepicker
               [bsConfig]="bsConfig"
               [maxDate]="getEndDateControl.value"
               autocomplete="off">
      </div>
    </div>
    <label class="col-md-2 col-form-label " for="end">End Date</label>
    <div class=" col-md-4">
      <div class="input-group">
        <input type="text" class="form-control"
               id="end"
               bsDatepicker
               formControlName="endDate"
               [bsConfig]="bsConfig"
               [minDate]="startDateControl.value"
               autocomplete="off">
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-2 col-form-label " for="start">Rate Type</label>
    <div class=" col-md-4">
      <div class="input-group">
        <select name="" class="form-control" formControlName="rateType" id=""
                [class.is-invalid]="getRateType.invalid && getRateType.touched"
        >
          <option value="MD">Man-day</option>
          <option value="MM">Man-month</option>
        </select>
      </div>
    </div>
  </div>

  <div class="row">
    <label for="" class="col-form-label col-md-1"><b>PO List</b> </label>
  </div>
  <div class="form-group row" formArrayName="poList" *ngFor="let po of poListControl.controls;let i = index">
    <ng-container [formGroupName]="i">
      <label for="po{{i}}" class="col-sm-2 col-form-label">PO</label>
      <div class="col-sm-2">
        <input type="text" id="po{{i}}" formControlName="po"
               oninput="this.value = this.value.toUpperCase()"
               [class.is-invalid]="(poListControl.controls[i].get('po').invalid &&
               poListControl.controls[i].get('po').touched) ||
               poListControl.controls[i].get('po').hasError('isDup')"
               class="form-control">
        <small class="text-danger" *ngIf="poListControl.controls[i].get('po').hasError('isDup')">
          {{poListControl.controls[i].get('po').getError('isDup')}}
        </small>
      </div>
      <label class=" col-md-1 col-form-label " for="manDay{{i}}">Man-days</label>
      <div class=" col-md-1">
        <div class="input-group">
          <input
            type="text"
            id="manDay{{i}}"
            class="form-control"
            [class.is-invalid]="poListControl.controls[i].get('manDay').invalid && poListControl.controls[i].get('manDay').touched"
            formControlName="manDay">
        </div>
      </div>
      <label class=" col-md-2 col-form-label " for="mds{{i}}">Man-days Spent</label>
      <div class="col-md-1">

        <div class="input-group">
          <input
            type="text"
            id="mds{{i}}"
            class="form-control"
            [class.is-invalid]="(poListControl.controls[i].get('manDaySpent').invalid &&
            poListControl.controls[i].get('manDaySpent').touched)"
            formControlName="manDaySpent">
        </div>


      </div>
      <div class="col-md-2">
        <button class="btn-row btn-custom-add " (click)="addPo()">
          <fa-icon [icon]="faIcon.faPlus"></fa-icon>
        </button>
        <button class="btn-row btn-custom-delete ml-n2" *ngIf=" poListControl.controls.length>1" (click)="deletePo(i)">
          <fa-icon [icon]="faIcon.faTrashAlt"></fa-icon>
        </button>
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-md-12 text-right">
      <button class="btn btn-info"
              (click)="onSave()">SAVE
      </button>
    </div>
  </div>
</form>
<app-loading-overlay [display]="load"></app-loading-overlay>
