import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AdditionalInformationService} from '../../../core/services/additional-information.service';
import {AdditionalInformation} from '../../../core/models/additionalInformation';
import Swal from 'sweetalert2';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-additional-information-form',
  templateUrl: './admin-additional-information-form.component.html',
  styleUrls: ['./admin-additional-information-form.component.scss']
})
export class AdminAdditionalInformationFormComponent implements OnInit {

  @Output() showForm = new EventEmitter()
  @Output() name = new EventEmitter()
  possibleValueTxt
  loading = false
  isEdit = false
  file
  targetGroup = Object.values(AdditionalInformation.TargetGroupEnum)
  @Input()
  set id(id: string) {
    this.isEdit = false
    if (!id) {
      return
    }

    if (id === 'new') {
      return;
    }

    this.additionalInformationService.findById(id).subscribe(
      s => {
        this.fg.patchValue(s)
        this.isEdit = true
        this.name.emit(s.name)
      }
    )
  }

  fg = this.fb.group({
    id: [null],
    name: [null, Validators.required],
    sequenceNo: [],
    description: [],
    possibleValue: [null],
    type: [null, Validators.required],
    active: [false],
    disable: [false],
    defaultValue: [null],
    targetGroup: [AdditionalInformation.TargetGroupEnum.ALLUSERS]
  })

  constructor(
    private fb: FormBuilder,
    private additionalInformationService: AdditionalInformationService
  ) { }

  ngOnInit(): void {
  }

  onFileChange(file: FileList, dFileName: Element) {
    if (file) {
      const item = file.item(0);
      dFileName.innerHTML = item.name
      this.file = item
    } else {
      dFileName.innerHTML = 'Choose file'
    }
  }

  onClearFile(dFileName: Element) {
    this.file = null
    dFileName.innerHTML = 'Choose file'
  }

  onUploadData(id: string, isSuccess: boolean) {
    if (!this.file) {
      this.alert(isSuccess, '')
      this.loading = false
    } else {
      const fd = new FormData()
      fd.append('file', this.file)
      this.additionalInformationService.uploadFile(id, fd).subscribe(
        s => {
          this.alert(true, '')
          this.loading = false
        }, () => {
          this.alert(false, '')
          this.loading = false
        }, () => {
          this.loading = false
        }
      )
    }
  }

  onBack() {
    this.showForm.emit(null)
  }

  onSubmit() {
    if (this.fg.invalid) {
      this.fg.markAllAsTouched()
      return
    }

    const rawValue: AdditionalInformation = this.fg.getRawValue();
    if (rawValue.type === 'TEXT') {
      rawValue.possibleValue = null
      rawValue.defaultValue = null
    }


    if (this.isEdit) {
      if (!this.fg.dirty) {
        if (this.file) {
          this.loading = true
          this.onUploadData(rawValue.id, null)
          return;
        } else {
          return;
        }
      }

      this.loading = true
      this.additionalInformationService.update(rawValue.id, rawValue).subscribe(
        s => {
          this.fg.patchValue(s)
          this.onUploadData(s.id, true)
        }, () => {
          this.alert(false, '')
          this.loading = false
        })

    } else {
      this.loading = true
      this.additionalInformationService.save(rawValue).subscribe(
        s => {
          this.fg.patchValue(s)
          this.onUploadData(s.id, true)
        }, () => {
          this.alert(false, '')
          this.loading = false
        })
    }
  }

  onDelete() {
    const callBack = (result) => {
      if (result.value) {
        this.additionalInformationService.delete(this.fg.get('id').value)
          .subscribe(
            s => {
              this.alert(true, 'Delete success')
                .then(() => this.onBack())
            },
            e => this.alert(false, 'Delete fail.')
          )
      }
    }
    this.confirm('Do you want to save the changes?', 'Delete', 'Close', callBack)
  }

  onAddPossibleValue() {
    if (!this.possibleValueTxt || '' === this.possibleValueTxt) {
      return
    }

    const possibleValue = this.fg.get('possibleValue');
    let v: string[] = possibleValue.value
    console.log('Before: ', v)
    if (!v) {
      v = []
    }
    v.push(this.possibleValueTxt)
    possibleValue.patchValue(v)
    possibleValue.markAsDirty()
    console.log('After: ', possibleValue.value)

    this.possibleValueTxt = null
  }

  onRemovePossibleValue(index: number) {
    const possibleValue = this.fg.get('possibleValue');
    const v: string[] = possibleValue.value
    v.splice(index, 1);
    possibleValue.markAsDirty()
  }

  alert(isSuccess, msg) {
    return Swal.fire({
      scrollbarPadding: false,
      title: isSuccess ? 'Successfully' : 'Error',
      icon: isSuccess ? 'success' : 'error',
      text: msg,
      buttonsStyling: false,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-warning text-light',
      },
    });
  }

  confirm(title, confirmButtonText, cancelButtonText, callback) {
    Swal.fire({
      scrollbarPadding: false,
      title,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText
    }).then(callback)
  }
}
