import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {WhatNew} from '../models/what-new';
import {LearningCategory} from '../models/hr-learning-center';
import { TargetGroup } from '../models/target-group';

@Injectable({
  providedIn: 'root'
})
export class WhatNewService {

  private readonly END_POINT = environment.api_endpoint;

  constructor(private http: HttpClient) { }

  loadLearningCategory(): Observable<LearningCategory[]> {
    const url = `${this.END_POINT}/hr-learning-category`
    return this.http.get<LearningCategory[]>(url)
  }

  loadNews(keyword = ''): Observable<WhatNew[]> {
    const url = `${this.END_POINT}/what-new`
    return this.http.get<WhatNew[]>(url, {
      params: {
        keyword
      }
    })
  }


  getThumbnail(id, fileName) {
    fileName = encodeURIComponent(fileName)
    const url = `${this.END_POINT}/loadThumbnail`
    return this.http.get(url, {
      params: {
        id, fileName
      },
      responseType: 'blob'
    })
  }

  getWhatNewSearch(keyword, isOnlyPostedByMe): Observable<WhatNew[]> {
    let params = new HttpParams();
    params = params.append('keyword', keyword);
    params = params.append('isOnlyPostedByMe', isOnlyPostedByMe);
    params = params.append('viewAsAudience', 'false');

    return this.http.get<WhatNew[]>(`${this.END_POINT}/what-new/search`, { params: params });
  }

  getAllHashtag(): Observable<string[]> {
    return this.http.get<string[]>(`${this.END_POINT}/what-new/hashtag/ALL`);
  }

  getWhatNewTarget(): Observable<TargetGroup[]> {
    return this.http.get<TargetGroup[]>(`${this.END_POINT}/what-new/tags/ALL`);
  }

  createWhatNew(body, thumbnail, attachment): Observable<any> {
    const formData: FormData = new FormData();

    if (thumbnail) {
      formData.append('thumbnail', thumbnail);
    }

    if (attachment) {
      formData.append('attachment', attachment);
    }

    let bodyData = JSON.stringify(body);
    const bodyBlob = new Blob([bodyData], {type: 'application/json'});
    formData.append('body', bodyBlob);

    return this.http.post<any>(`${this.END_POINT}/what-new`, formData);
  }

  updateWhatNew(body, thumbnail, attachment): Observable<any> {
    const formData: FormData = new FormData();

    if (thumbnail) {
      formData.append('thumbnail', thumbnail);
    }

    if (attachment) {
      formData.append('attachment', attachment);
    }

    let bodyData = JSON.stringify(body);
    const bodyBlob = new Blob([bodyData], {type: 'application/json'});
    formData.append('body', bodyBlob);

    return this.http.put<any>(`${this.END_POINT}/what-new`, formData);
  }

  deleteWhatNew(id): Observable<any> {
    return this.http.delete<any>(`${this.END_POINT}/what-new/${id}`);
  }

  createWhatNewLog(body): Observable<any> {
    return this.http.post<any>(`${this.END_POINT}/what-new/log`, body);
  }

  getWhatNewFile(id, fileName, fileType): Observable<HttpResponse<Blob>> {
    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('fileName', fileName);
    params = params.append('fileType', fileType);

    return this.http.get<any>(`${this.END_POINT}/what-new/file`,
      {params: params, observe: 'response', responseType: 'blob' as 'json'}
    );
  }

  findById(id): Observable<any> {
    return this.http.get<any>(`${this.END_POINT}/what-new/${id}`);
  }
}
