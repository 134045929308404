import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Input() body = '';
  @Input() btnTextConfirm = 'ใช่';
  @Input() btnTextDecline = 'ไม่ใช่';
  @Input() btnConfirmColor = 'btn-primary';
  @Input() showTextInput = false;
  @Input() placeholder = 'กรุณาระบุเหตุผล';

  form: FormGroup;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm() {
    this.form = new FormGroup({
      reason: new FormControl('', Validators.required)
    });
  }

  submitForm() {
    this.activeModal.close({ confirm: true, reason: this.form.value.reason });
  }


  confirm() {
    this.activeModal.close({ confirm: true });
  }

}
