import {Component, OnInit} from '@angular/core';
import {UserRole} from 'src/app/core/models/userRole';
import {RequestTrainingService} from 'src/app/core/services/request-training.service';
import Swal from 'sweetalert2';
import {pagePermission} from './config/page.permission.config';

@Component({
  selector: 'app-administrative',
  templateUrl: './administrative.component.html',
  styleUrls: ['./administrative.component.scss'],
})
export class AdministrativeComponent implements OnInit {
  menuSwitch = 'external';
  allowAccess = true;
  pagePermission = pagePermission;

  constructor(private requestTrainingService: RequestTrainingService) {
  }

  ngOnInit(): void {
    this.checkPermission();
  }

  selectTap(tapName: string) {
    this.menuSwitch = tapName;
    this.checkPermission();
  }

  checkPermission() {

    this.requestTrainingService.getUserRole().toPromise()
      .then(r => {
        const find = this.pagePermission.find(p => p.page === this.menuSwitch);
        if (find && r.some(role => find.permission[role.role])) {
          this.allowAccess = true;
          // this.permissionLevel = find.permission[r.role];
        } else {
          this.clearPermission();
        }
      })
      .catch(() => {
        this.clearPermission();
      })

  }

  private clearPermission() {
    this.menuSwitch = 'noPermission'
    this.allowAccess = false;
  }

  noPermissionAlert() {
    Swal.fire({
      scrollbarPadding: false,
      icon: 'error',
      title: 'Oops...',
      text: 'No Permission!',
      // footer: '<a href>Why do I have this issue?</a>'
    });
  }
}
