import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';

import {faIcon} from 'src/app/core/icon/fa.icon';
import {BsModalRef, BsModalService, TypeaheadMatch} from 'ngx-bootstrap';
import {AbstractControl, FormBuilder, FormControl, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {EmployeeService} from '../../../core/services/employee.service';
import {IsHRVP, ManpowerService} from '../../../core/services/manpower.service';
import {Employee, SpecialRole, UserRole} from '../../../core/models/employee';
import {Manpower} from '../../../core/models/manpower';
import {DeptRelation} from '../../../core/models/deptRelation';
import {Position} from '../../../core/models/position';
import {RequestManpower} from '../../../core/models/requestManpower';
import {Router} from '@angular/router';
import {RequestManpowerAction} from '../../../core/enums/RequestManpowerAction';
import {ThrowStmt} from '@angular/compiler';
import {RequestManpowerLog} from 'src/app/core/models/requestManpowerLog';
import {UserManagementService} from '../../../core/services/user-management.service';
import {RequestTrainingService} from '../../../core/services/request-training.service';

declare var jQuery;

@Component({
  selector: 'app-new-man-power',
  templateUrl: './new-man-power.component.html',
  styleUrls: ['./new-man-power.component.scss'],
})
export class NewManPowerComponent implements OnInit {
  faIcon = faIcon;
  modalRef: BsModalRef;
  @Output() onEmitData = new EventEmitter();
  confirmationWording = '';
  confirmationStatus = '';
  allPosition: Position[] = []
  filterPosition: Position[] = []
  filterPositionV2: Position[] = []
  filterDept
  filterUnit: DeptRelation[]
  filterLevel = []
  divName
  isHrAdmin = false;

  selectedReplacePosition: Position
  editReplacePosition: Position

  @Input() id: string
  @Input() forAction: string

  currentUser: Employee
  requester: Employee
  manpowerDept: Manpower
  division: DeptRelation
  allDept: DeptRelation[] = []

  reject
  approveReason: string;
  isPendingMe = false
  adminApprove = false;
  empInDept: Employee[]
  manPowerLogs: RequestManpowerLog[] = [];

  readonly allowList = ['00117', '00155', '00511', '00074', '00370']

  previousLevel;

  status: RequestManpower.StatusEnum = 'DRAFT';
  isPresident = false;
  allCommandChain: Employee[] = [];
  indexOfApprover = 0;

  @ViewChild('template') confirmationModalTemplate: TemplateRef<any>;

  manPowerForm = this.fb.group({
    // position: [{ value: '', disabled: false }, [Validators.required]],
    position: this.fb.group({
      positionCode: [null],
      positionName: [null, Validators.required],
    }),
    level: ['', [Validators.required]],
    positionName: ['', [Validators.required]],
    // department: [{ value: '', disabled: false }, [Validators.required]],
    department: this.fb.group({
      id: [null, Validators.required],
      deptName: [null, Validators.required],
      deptID: [null, Validators.required]
    }),
    unit: this.fb.group({
      id: [null, Validators.required],
      deptName: [null, Validators.required],
      deptID: [null, Validators.required]
    }),
    reason: [{value: 'อัตราทดแทน', disabled: false}, [Validators.required]],
    replaceName: [{value: '', disabled: true}, [Validators.required]],
    amount: [{value: 1, disabled: false}, [Validators.required]],
    detail: [{value: '', disabled: false}, [Validators.required]],
    responsibility: [{value: '', disabled: false}, [Validators.required]],
    qualification: [{value: '', disabled: false}, [Validators.required]],
    englishSkill: [{value: '', disabled: false}, [Validators.required]],
    englishScore: [{value: '', disabled: true}],
    status: [],
    hrvpComment: [],
    mdComment: [],
    requestRemark: [],
    reject: ['']
  });

  loading = false

  now = new Date();
  isAdmin: boolean
  hrvp: Employee
  hrAdminActionModel: string;
  hrAdminActionReason: string;
  specialRole: SpecialRole[] = []
  userRoles: UserRole[] = []
  isHRVPObj: IsHRVP

  modalConfig = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-lg1'
  };

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private employeeService: EmployeeService,
    private manpowerService: ManpowerService,
    private router: Router,
    private requestTrainingService: RequestTrainingService
  ) {

  }

  async ngOnInit() {
    this.loading = true
    try {
      this.currentUser = await this.employeeService.me().toPromise()
      this.userRoles = await this.requestTrainingService.getUserRole().toPromise()
      this.isHrAdmin = this.userRoles.some(a => a.role === 'HRAdmin')
      this.specialRole = await this.manpowerService.getSpecialRole().toPromise();

      this.isHRVPObj = await this.manpowerService.isHRVPOrHRVPManager().toPromise()

      if (!this.forAction) {
        this.allPosition = await this.manpowerService.getPositionVacantFlag().toPromise();
      } else {
        this.allPosition = await this.manpowerService.getPosition().toPromise();
      }
      this.filterPosition = this.allPosition
      this.filterPositionV2 = this.allPosition
      this.selectedReplacePosition = null


      const ad = await this.manpowerService.getAllDepartment().toPromise()
      this.allDept = ad.filter(it => it.isActive)
      this.filterDept = this.isHRVPOrAAA() ? this.allDept.filter(o => o.treeLevel < 6) : this.allDept.filter(o =>
        o.treeLevel <= 5
        && (o.department === this.currentUser.deptRelation.deptID
          || o.subDiv === this.currentUser.deptRelation.deptID
          || o.division === this.currentUser.deptRelation.deptID))

      this.isAdmin = this.checkIsHRVP();
      this.isPresident = this.checkIsPresident();
      this.employeeService.hrvp().subscribe(
        s => {
          this.hrvp = s
        }
      )
    } catch (e) {
      this.loading = false;
      console.error(e)
    }

    if (this.id) {
      console.warn('Edit')
      const d = await this.manpowerService.getRequestById(this.id).toPromise()

      if (d.requestManpowerLogs) {
        this.manPowerLogs = d.requestManpowerLogs;
      }

      if (this.forAction === 'approve' && d.requester.empID === d.pendingAt?.empID && this.currentUser.empID === d.pendingAt?.empID) {
        this.router.navigateByUrl('man-power')
      }

      this.requester = d.requester
      this.division = await this.manpowerService.getDivisionByCode(this.requester.deptRelation.division).toPromise()
      if (d.requesterDate) {
        this.now = d.requesterDate
      }


      const m = await this.manpowerService.getManpowerByDept(this.requester.deptRelation.department || this.requester.deptRelation.deptID).toPromise()
      this.manpowerDept = m[0]

      d.position = this.allPosition.find(it => it.positionCode === d.position.positionCode)
      d.department = this.allDept.find(it => it.id === d.department.id)
      this.divName = this.getDeptName(d.department.division).deptName

      if (d.position) {
        const p = this.allPosition.filter(it => it.positionCode === d.position.positionCode);
        if (p && p.length !== 0) {
          this.editReplacePosition = p[0]
        }
      }

      this.manPowerForm.patchValue(d)

      if (d.status !== 'DRAFT' && d.status !== 'REJECTED') {
        this.manPowerForm.disable()
      } else {
        // submit
        if (d.status === 'REJECTED') {
          if (d.pendingAt.empID !== this.currentUser.empID) {
            this.manPowerForm.disable()
          } else {
            if (this.manPowerForm.controls.englishSkill.value === 'other') {
              this.manPowerForm.controls.englishScore.enable()
            }
            if (this.manPowerForm.controls.reason.value === 'อัตราทดแทน') {
              this.manPowerForm.controls.replaceName.enable()
            }
          }
        } else {
          if (this.manPowerForm.controls.englishSkill.value === 'other') {
            this.manPowerForm.controls.englishScore.enable()
          }
          if (this.manPowerForm.controls.reason.value === 'อัตราทดแทน') {
            this.manPowerForm.controls.replaceName.enable()
          }

          if (this.currentUser.empID !== this.requester.empID) {
            this.manPowerForm.disable()
          }

        }
      }

      // if (this.displayEdit) {
      //   this.manPowerForm.enable()
      // }

      let pendingAt = ''
      if (d.pendingAt) {
        if (d.pendingAt.empID) {
          pendingAt = d.pendingAt.empID
        }
      }
      if (d.pendingAt?.empID === this.currentUser.empID) {
        // this.manPowerForm.enable()
        this.isPendingMe = true;
      }

      if (((this.isHRVPObj?.isHRVP || this.isHRVPObj?.isHRVPManager) && pendingAt === this.currentUser?.empID) || (d.requester.empID == this.currentUser.empID && d.status == 'DRAFT')) {
        this.manPowerForm.get('hrvpComment').enable()
      } else {
        this.manPowerForm.get('hrvpComment').disable()
      }

      if (this.checkIsPresident() && (pendingAt === this.currentUser.empID)) {
        this.manPowerForm.disable()
        this.manPowerForm.get('mdComment').enable()
      } else {
        this.manPowerForm.get('mdComment').disable()
      }

      this.onChangDept(this.manPowerForm.getRawValue().department, true);

      this.status = d.status;

      if (d.requester.empID && d.pendingAt) {
        const commandChain = await this.manpowerService.findCommandChain(d.id).toPromise();
        if (commandChain) {
          this.allCommandChain = commandChain;

          const index = this.allCommandChain.findIndex((item) => item.empID === d.pendingAt.empID);
          this.indexOfApprover = index;

        }
      }


      // pending_president
      // if (d.pendingAt && 'AAA' === d.pendingAt.grade) {
      //   const hrAdmin = this.currentUser?.userRoles?.some(r => r.role === 'HRAdmin');
      //   if (hrAdmin) {
      //     this.adminApprove = true;
      //   }
      // }

      // this.isPendingMe = d.pendingAt?.empID === this.currentUser.empID
    } else {
      console.warn('New -> ', this.currentUser)
      this.requester = this.currentUser
      this.onChangDept(this.getDeptName(this.requester.deptRelation.department || this.currentUser.deptRelation.deptID))
      this.unit.patchValue(this.getDeptName(this.requester?.deptRelation?.deptID))
      this.division = await this.manpowerService.getDivisionByCode(this.currentUser.deptRelation.division).toPromise()
      const m = await this.manpowerService.getManpowerByDept(this.currentUser.deptRelation.department || this.currentUser.deptRelation.deptID).toPromise()
      this.manpowerDept = m[0]
      this.manPowerForm.get('replaceName').enable();

      (this.isHRVPObj?.isHRVP || this.isHRVPObj?.isHRVPManager) ?
        this.manPowerForm.get('hrvpComment').enable()
        : this.manPowerForm.get('hrvpComment').disable()

      if (this.checkIsPresident()) {
        this.manPowerForm.get('mdComment').enable()
      } else {
        this.manPowerForm.get('mdComment').disable()
      }
    }

    // this.empInDept = this.isHRVPOrAAA() ? await this.employeeService.employeeList().toPromise()
    //   : await this.employeeService.deepMyDept(this.currentUser.deptRelation.department ? this.currentUser.deptRelation.department : this.currentUser.deptRelation.deptID).toPromise()
    this.empInDept = await this.employeeService.employeeFilterType('Employee').toPromise()
    // this.empInDept = this.empInDept.filter(it => it.empType)
    this.empInDept.forEach(it => {
      it.extraName = it.nameTh + ` (${it.nameEn}) `
    })

    this.loading = false

    this.manPowerForm.controls.englishSkill.valueChanges.subscribe(s => {
      // console.log('englishSkill change ', s)
      if (s === 'other') {
        this.manPowerForm.controls.englishScore.enable()
      } else {
        this.manPowerForm.controls.englishScore.disable()
        this.manPowerForm.controls.englishScore.patchValue(null)
      }
      this.manPowerForm.controls.englishScore.updateValueAndValidity()
    })

    if (!this.isHRVPOrAAA()) {
      console.log('isHRVPOrAAA--> ', this.currentUser.deptRelation.deptID, this.isHRVPOrAAA())
      if (this.filterDept.length <= 1) {
        this.department.disable()
      }
    }

    this.reason.valueChanges.subscribe(data => {
      if (data === 'อัตราทดแทน') {
        this.manPowerForm.controls.replaceName.enable();
      } else {
        this.manPowerForm.controls.replaceName.disable();
        this.manPowerForm.patchValue({
          replaceName: null
        })
      }
    });

    this.level.valueChanges.subscribe(l => {
      const item = l
      // this.onChangeLevel({item})
    })

  }

  toggleEdit() {
    if (this.manPowerForm.disabled) {
      this.manPowerForm.enable()
      this.manPowerForm.get('department').disable()
      // this.manPowerForm.get('unit').disable()
      // this.manPowerForm.get('reason').disable()
      // this.manPowerForm.get('positionName').disable()
      // this.manPowerForm.get('level').disable()
    } else {
      this.reject = '#Edit';
      this.onShowConfirmationModal(this.confirmationModalTemplate, 'save')
      this.manPowerForm.disable()
    }
  }

  get displayEdit() {
    return this.isHrAdmin && ['PENDING', 'PENDING_HR', 'PENDING_PRESIDENT', 'REJECTED'].includes(this.manPowerForm?.get('status').value)
  }

  private filterPostion() {
    const filterConditions = {
      unit: (a) => a.unitCode === this.currentUser.deptRelation.unit,
      department: (a) => a.departmentCode === this.currentUser.deptRelation.department,
      subDiv: (a) => a.groupCode === this.currentUser.deptRelation.subDiv,
      default: (a) => a.divisionCode === this.currentUser.deptRelation.division
    };

    const selectedFilter = Object.keys(filterConditions).find(key => this.currentUser.deptRelation[key]);

    return this.allPosition.filter(a => {
      const condition = filterConditions[selectedFilter] || filterConditions.default;
      return condition(a);
    });
  }

  public isHRVPOrAAA() {
    return ('100276' === this.currentUser.deptRelation.department || '100071' === this.currentUser.deptRelation.department) ||
      'AAA' === this.currentUser.grade || this.allowList.includes(this.currentUser.empID);
  }

  private checkIsHRVP() {
    return ('100276' === this.currentUser.deptRelation.department || this.isHRVPObj?.isHRVPManager || this.isHRVPObj?.isHRVP) //'100071' === this.currentUser.deptRelation.department) && (this.currentUser.grade === 'M4' || this.currentUser.grade === 'M5');
  }

  public checkIsPresident() {
    // console.log('currentuser --> ', this.currentUser)
    return 'AAA' === this.currentUser?.grade;
  }

  get position() {
    return this.manPowerForm.get('position') as FormControl;
  }

  get level() {
    return this.manPowerForm.get('level') as FormControl;
  }

  get department() {
    return this.manPowerForm.get('department') as FormControl;
  }

  get unit() {
    return this.manPowerForm.get('unit') as FormControl;
  }

  get reason() {
    return this.manPowerForm.get('reason') as FormControl;
  }

  getDeptName = (deptid) => deptid !== null ? this.allDept?.find(o => o.deptID === deptid) : null;

  routeToManPowerView(data) {

  }

  async onChangDept(item: DeptRelation, fromEdit = false) {
    // this.department.patchValue(item)
    const ev = item
    ev.deptID = item?.department || item?.deptID
    this.department.patchValue(ev)

    const event = item?.department || item?.deptID
    const m = await this.manpowerService.getManpowerByDept(event).toPromise()
    this.manpowerDept = m[0]
    this.divName = this.getDeptName(this.allDept.find(o => o.deptID === event).division).deptName || '-'
    this.filterWithDeptId(item);
    const vacant = this.forAction === undefined ? true : undefined // empty vacant will null
    console.log('vacant, this.forAction', vacant, this.forAction)
    this.filterPositionV2 = await this.manpowerService.getPositionByDept(item.deptID, vacant).toPromise();


    if (fromEdit) {
      this.unit.patchValue(this.manPowerForm.getRawValue().unit)

      if (this.editReplacePosition) {

        const isContain = this.filterPositionV2.find(it => it.positionCode === this.editReplacePosition.positionCode)
        if (isContain !== 0) {
          this.selectedReplacePosition = isContain
        } else {
          this.selectedReplacePosition = null
        }
      } else {
        this.selectedReplacePosition = null
      }

    } else if (this.isHRVPOrAAA()) {
      this.manPowerForm.get('positionName').patchValue('')
      const u = this.filterUnit.length > 0 ? this.filterUnit[0] : {}
      this.unit.patchValue(u)
      await this.onChangUnit({item})
    }

  }

  private filterWithDeptId(item: DeptRelation) {
    const properties = ['division', 'subDiv', 'department'];

    for (const prop of properties) {
      const foundItem = this.allDept.filter(a => item.deptID === a[prop]);
      if (foundItem) {
        this.filterUnit = foundItem;
        break;
      }
    }
  }

  onChangePosition({item}) {
    const {responsibility, qualification, positionName, positionCode} = item
    console.log('position', item)
    this.position.patchValue(item)
    this.manPowerForm.get('positionName').patchValue(positionName)
    this.manPowerForm.get('responsibility').patchValue(responsibility)
    this.manPowerForm.get('qualification').patchValue(qualification)
  }

  // onPositionNoValue(event) {
  //   if (event && this.manPowerForm.get('reason').value === 'อัตราใหม่') {
  //     const positionCode = this.manPowerForm.get('position').get('positionCode');
  //     if (positionCode.value !== null && !positionCode.value.startsWith('NEW')) {
  //       console.log('อัตราใหม่ position change')
  //       this.manPowerForm.get('position').get('positionCode').patchValue(null)
  //     }
  //   }
  // }

  async onChangUnit({item}) {
    console.log('change')
    this.manPowerForm.get('position').get('positionCode').patchValue(null)
    this.manPowerForm.get('position').get('positionName').patchValue('')
    this.manPowerForm.get('responsibility').patchValue(null)
    this.manPowerForm.get('qualification').patchValue(null)
    this.manPowerForm.get('replaceName').patchValue(null)

    this.manPowerForm.get('level').patchValue('')

    this.unit.patchValue(item)
    this.empInDept = await this.employeeService.employeeList().toPromise();
    this.empInDept.forEach(it => {
      it.extraName = it.nameTh + ` (${it.nameEn}) `
    })
  }


  onChangeLevel({item}) {

    // if (this.manPowerForm.get('reason').value === 'อัตราใหม่') {
    //   this.filterPosition = [];
    //
    //   const positionCode = this.manPowerForm.get('position').get('positionCode');
    //   if (positionCode.value !== null && !positionCode.value.startsWith('NEW')) {
    //     console.log('อัตราใหม่')
    //     positionCode.patchValue(null)
    //     this.manPowerForm.get('position').get('positionName').patchValue('')
    //   }
    // } else {
    // this.filterPosition = this.allPosition.filter(o => o.level == item)
    //
    // if (this.filterPosition.length > 0) {
    //
    //   this.position.patchValue(this.filterPosition[0])
    //   this.manPowerForm.patchValue({
    //     responsibility: this.filterPosition[0].responsibility ? this.filterPosition[0].responsibility : '',
    //     qualification: this.filterPosition[0].qualification ? this.filterPosition[0].qualification : ''
    //   })
    // } else {
    //   this.manPowerForm.get('position').get('positionCode').patchValue(null)
    //   this.manPowerForm.get('position').get('positionName').patchValue('')
    // }
    // }
  }

  async checkPosition(event) {
    // console.log('checkPosition -- >' , event)
    const status = this.manPowerForm.get('status');
    try {
      this.allPosition = await this.manpowerService.getPositionByDept(event).toPromise();
      let position: Position[] = this.allPosition.filter(p => p.departmentCode == event)
      if (position.length == 0) {
        position = this.allPosition.filter(p => p.divisionCode == event || p.groupCode == event)
      }
      const mapPosition = {};
      position.forEach(it => {
        mapPosition[it.positionCode] = it;
      })
      this.filterPosition = Object.keys(mapPosition).map(it => mapPosition[it]); // position.map(p => p.positionName).filter((item, index, arr) => arr.indexOf(item) === index)
      this.filterPosition = this.filterPosition.filter(it => it.vacantFlag === true && it.workflowFlag === false && !it.positionCode.toLowerCase().startsWith('new'))
      this.filterLevel = position.map(p => p.level).filter((item, index, arr) => arr.indexOf(item) === index)
      if (this.filterPosition.length > 0) {
        this.filterPosition.sort((a, b) => a.positionName.localeCompare(b.positionName));
      }

      if (!this.manPowerForm.get('reason').disabled) {
        this.filterPositionV2 = this.filterPosition
      }
      this.setupDisplayPosition(status);

      // if (this.filterLevel.length > 0) {
      //   this.filterLevel.sort((a, b) => a.localeCompare(b));
      // }

      this.previousLevel = this.filterLevel;
    } catch (e) {
      this.allPosition = []
      this.filterPosition = []
      this.filterPositionV2 = []
      this.selectedReplacePosition = null
      this.filterLevel = []
      console.error('error: ', e)

      this.setupDisplayPosition(status);
    }

    if (this.manPowerForm.get('reason').value === 'อัตราใหม่') {
      this.filterLevel = ['S1', 'S2', 'S3', 'S4', 'M1', 'M2', 'M3', 'M4', 'M5'];
    }
  }

  private setupDisplayPosition(status: AbstractControl) {
    if (status && status.value !== 'DRAFT' && this.editReplacePosition) {
      if (this.manPowerForm.get('reason').value === 'อัตราใหม่') {
        this.filterPositionV2 = []
      } else {
        this.filterPositionV2 = [this.editReplacePosition]
      }
    }

    if (this.editReplacePosition) {
      const isContain = this.filterPositionV2.find(it => it.positionCode === this.editReplacePosition.positionCode)
      if (isContain !== 0) {
        this.selectedReplacePosition = isContain
      } else {
        this.selectedReplacePosition = null
      }
    } else {
      this.selectedReplacePosition = null
    }
  }

  onChangeReason() {
    this.selectedReplacePosition = null
    this.manPowerForm.get('position').get('positionCode').patchValue(null)
    this.manPowerForm.get('position').get('positionName').patchValue('')
    this.manPowerForm.get('level').patchValue('')
    this.manPowerForm.get('positionName').patchValue('')
    if (this.manPowerForm.get('reason').value === 'อัตราใหม่') {
      this.filterLevel = ['S1', 'S2', 'S3', 'S4', 'M1', 'M2', 'M3', 'M4', 'M5'];
    } else {
      this.filterLevel = this.previousLevel;
    }
  }

  onShowModalForHrAdmin(template: TemplateRef<any>, status) {
    this.hrAdminActionModel = status;
    this.modalRef = this.modalService.show(template, this.modalConfig)
  }

  onShowConfirmationModal(template: TemplateRef<any>, status) {
    this.confirmationStatus = status;

    if (this.manPowerForm.get('reason').value === 'อัตราใหม่') {
      if (this.manPowerForm.get('position').get('positionCode').value === null) {
        this.manPowerForm.get('position').get('positionName').patchValue(this.manPowerForm.get('positionName').value)
      }
    }

    if (status.toLowerCase() === 'back') {
      this.confirmationWording = 'Do you want to go back?';
      this.modalRef = this.modalService.show(template, this.modalConfig)
    } else if (status.toLowerCase() === 'save') {
      // if (this.onValidation()) {
      this.confirmationWording = 'Do you want to save ?';
      this.modalRef = this.modalService.show(template, this.modalConfig)
      // }
    } else if (status.toLowerCase() === 'submit') {
      if (this.onValidation()) {
        this.confirmationWording = 'Do you want to submit?';
        this.modalRef = this.modalService.show(template, this.modalConfig)
      }
    } else if (status.toLowerCase() === 'approve') {
      if (this.onValidation()) {
        this.confirmationWording = 'Do you want to approve?';
        this.modalRef = this.modalService.show(template, this.modalConfig)
      }
    } else if (status.toLowerCase() === 'reject') {
      if (this.onValidation()) {
        this.confirmationWording = '';
        if (!this.checkIsPresident()) {
          this.modalRef = this.modalService.show(template, this.modalConfig)
        } else {
          this.modalRef = this.modalService.show(template, this.modalConfig)
        }
      }
    } else if (status.toLowerCase() === 'cancel') {
      this.confirmationWording = 'Do you want to cancel?';
      this.modalRef = this.modalService.show(template, this.modalConfig)
    }

  }

  get isSpecialRole() {
    return !!this.specialRole.length;
  }

  isHrCommentValid() {
    let v: string = this.manPowerForm.get('hrvpComment').value

    if (v) {
      v = v.trim();

      return !!v
    } else {
      return false
    }
  }

  confirm() {
    if (this.confirmationStatus.toLowerCase() === 'back') {
      this.modalRef.hide();
      if (this.id) {
        this.router.navigateByUrl('man-power')
      } else {
        this.onEmitData.emit('');
      }
    } else if (this.confirmationStatus.toLowerCase() === 'save') {
      this.modalRef.hide();
      // this.onEmitData.emit('');
      if (this.id) {
        this.onUpdateManPower();
      } else {
        this.onSaveManPower(RequestManpower.StatusEnum.DRAFT, RequestManpowerAction.SAVE);
      }
    } else if (this.confirmationStatus.toLowerCase() === 'submit') {
      this.modalRef.hide();
      // this.onEmitData.emit('');
      if (this.id) {
        this.onUpdateManPower(RequestManpowerAction.SUBMIT);
      } else {
        this.onSaveManPower(RequestManpower.StatusEnum.PENDING, RequestManpowerAction.SUBMIT);
      }
    } else if (this.confirmationStatus.toLowerCase() === 'approve') {
      this.modalRef.hide();
      // this.onEmitData.emit('');
      this.onUpdateManPower(RequestManpowerAction.APPROVE);
    } else if (this.confirmationStatus.toLowerCase() === 'reject') {
      if (this.modalRef) {
        this.modalRef.hide();
      }
      // this.onEmitData.emit('');
      this.onUpdateManPower(RequestManpowerAction.REJECT);
    } else if (this.confirmationStatus.toLowerCase() === 'cancel') {
      if (this.modalRef) {
        this.modalRef.hide();
      }
      // this.onEmitData.emit('');
      this.onUpdateManPower(RequestManpowerAction.CANCEL);
    }
  }

  async onConfirmHrAdminApproval() {
    this.modalRef.hide();
    this.loading = true;
    const action = this.hrAdminActionModel === 'approve' ? RequestManpowerAction.APPROVE : RequestManpowerAction.REJECT
    const data: RequestManpower = this.manPowerForm.getRawValue()
    data.reject = this.hrAdminActionReason
    try {
      await this.manpowerService.updateRequest(data, this.id, action).toPromise()
      this.hrAdminActionReason = undefined;
      this.hrAdminActionModel = undefined;
      this.modalRef.hide();
      this.alert(true, 'Save success.').then(() => this.router.navigateByUrl('man-power'))
    } catch (e) {
      this.alert(false, e)
    } finally {
      this.loading = false
    }
  }

  onCancelHrAdminApproval() {
    this.modalRef.hide();
    this.hrAdminActionReason = ''
  }

  presidentConfirmReject() {
    this.reject = this.manPowerForm.get('mdComment').value
    this.manPowerForm.get('mdComment').patchValue(null)
    this.confirm()
  }

  async onUpdateManPower(action = RequestManpowerAction.SAVE) {
    this.loading = true;
    const data: RequestManpower = this.manPowerForm.getRawValue()
    if ([RequestManpowerAction.REJECT, RequestManpowerAction.APPROVE, RequestManpowerAction.SAVE].includes(action)) {
      data.reject = this.reject
    }
    if (RequestManpowerAction.APPROVE === action && this.adminApprove) {
      data.reject = `${this.approveReason} Approve by HR Admin  (${this.currentUser.nameTh || '-'}) `
    }
    try {
      await this.manpowerService.updateRequest(data, this.id, action).toPromise()
      this.alert(true, 'Save success.').then(() => this.router.navigateByUrl('man-power'))
    } catch (e) {
      this.alert(false, e)
    } finally {
      this.loading = false
    }
    // this.onEmitData.emit('');
  }

  async onSaveManPower(status: RequestManpower.StatusEnum, action: RequestManpowerAction) {
    const data: RequestManpower = this.manPowerForm.getRawValue()
    data.requester = this.currentUser
    data.status = status
    // console.log('Data', data);
    this.loading = true;
    try {
      await this.manpowerService.saveRequest(data, action).toPromise()
      this.alert(true, 'Save success.').then(() => this.onEmitData.emit(''))
    } catch (e) {
      this.alert(false, e)
    } finally {
      this.loading = false
    }
    // this.onEmitData.emit('');
  }

  onValidation(): boolean {
    let valid = true;

    if (this.manPowerForm.invalid) {
      this.alert(false, 'Please fill out the form completely.')
      this.manPowerForm.markAllAsTouched()
      Object.keys(this.manPowerForm.controls).forEach(it => {
        const c = this.manPowerForm.controls[it]
        if (c.invalid) {
          console.log(it, c.errors)
        }
      })
      return false
    }

    if (this.manPowerForm.controls.position?.invalid) {
      this.manPowerForm.controls.position?.setErrors({null: true});
    }

    if (this.manPowerForm.controls.position.invalid) {
      valid = false;
      this.alert(false, 'Please fill out the form completely.')
    }

    if (this.manPowerForm.get('englishSkill').value === 'other' && (this.manPowerForm.get('englishScore').value === null || this.manPowerForm.get('englishScore').value === '')) {
      this.alert(false, 'Please enter TOEIC score')
      return false
    }

    return valid;
  }

  alert(isSuccess: boolean, msg: string) {
    return Swal.fire({
      scrollbarPadding: false,
      text: msg,
      title: isSuccess ? 'Successfully' : 'Failed',
      icon: isSuccess ? 'success' : 'error',
      confirmButtonText: 'OK',
      focusCancel: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-info btn-lg mr-2 text-light',
      },
    });
  }

  get canCancel() {
    return !this.loading
      && this.currentUser.empID === this.requester.empID
      && this.manPowerForm?.get('status')?.value === 'DRAFT'
  }

  get isCanceled() {
    return this.manPowerForm?.get('status')?.value === 'CANCELED'
  }

  get canDraft() {
    let isRejectToMe;
    if (this.manPowerForm?.controls.status?.value === 'REJECTED') {
      isRejectToMe = this.isPendingMe
    } else {
      isRejectToMe = true;
    }
    return !this.loading
      && this.currentUser.empID === this.requester.empID
      && 'approve' !== this.forAction
      && this.manPowerForm?.controls.status?.value !== 'PENDING'
      && this.manPowerForm?.controls.status?.value !== 'PENDING_HR'
      && this.manPowerForm?.controls.status?.value !== 'PENDING_PRESIDENT'
      && this.manPowerForm?.controls.status?.value !== 'APPROVED'
      && isRejectToMe
  }

  stripTag(html: string): string {
    return jQuery(html).text();
  }

  get isPendingPresident() {
    return this.checkIsPresident() && this.isPendingMe;
  }

  get baseButtonCondition() {
    return !this.isCanceled && !this.isPendingPresident
  }

  onBack(template: TemplateRef<any>) {
    this.confirmationStatus = 'back';
    this.confirmationWording = 'Do you want to go back?';
    this.modalRef = this.modalService.show(template, this.modalConfig)
    // this.router.navigateByUrl('man-power')
    // this.onEmitData.emit('');
  }

  async onSelectPosition() {
    this.unit.patchValue({});

    this.patchUnitWithPositionSelected();
    this.manPowerForm.get('level').patchValue(this.selectedReplacePosition?.level)
    this.onChangePosition({item: this.selectedReplacePosition})
  }

  private patchUnitWithPositionSelected() {
    if (!this.selectedReplacePosition) {
      return;
    }
    const codesToCheck = [
      this.selectedReplacePosition.unitCode,
      this.selectedReplacePosition.departmentCode,
      this.selectedReplacePosition.groupCode,
      this.selectedReplacePosition.divisionCode
    ];

    let found = false;

    for (const code of codesToCheck) {
      if (code && !found) {
        const dept = this.filterUnit.find(a => a.deptID === code);
        if (dept) {
          found = true;
          this.unit.patchValue(dept);
        }
      }
    }

    if (this.selectedReplacePosition.divisionCode && !found) {
      const dept = this.filterUnit.find(a => a.deptID === this.selectedReplacePosition.divisionCode);
      if (dept) {
        this.unit.patchValue(dept);
      }
    }
  }

  onDeptBlur({item}: TypeaheadMatch) {
    this.onChangDept(item)
  }
}


export class LevelPosition {
  constructor(public level: string, public positionName: string) {
  }
}
