<div class="container-fluid mt-4">
    <form [formGroup]="whatNewForm">
        <div>
            <div class="form-group row">
                <h6 class="col-2 text-center"><b>Title <label class="text-danger"> *</label></b></h6>
                <textarea  class="col-8 form-control" formControlName="title" rows="2"
                [ngClass]="{'is-invalid': whatNewForm.controls['title'].hasError('null')}"
                ></textarea>
            </div>
            <div class="form-group row">
                <h6 class="col-2 text-center"><b>Detail <label class="text-danger"> *</label></b></h6>
                <textarea  class="col-8 form-control" formControlName="detail" rows="10"
                [ngClass]="{'is-invalid': whatNewForm.controls['detail'].hasError('null')}"
                ></textarea>
            </div>
            <div class="form-group row">
                <h6 class="col-2 text-center"><b>URL</b></h6>
                <input type="text" formControlName="url"  class="col-8 form-control" />
            </div>
            <div class="form-group row">
                <h6 class="col-2 text-center"><b>Thumbnail <label class="text-danger"> *</label></b></h6>
                <!-- <textarea  class="col-8 form-control" formControlName="thumbnail" rows="2"></textarea> -->
                <div id="upload_button" class="col-5">
                    <label>
                        <input type="file" class="form-control" multiple (change)="onSelectThumbnail($event)" accept="image/png, image/jpeg , image/jpg">
                        <span class="btn btn-info text-uppercase">Choose files</span>
                    </label>
                    <div class="form-group mt-2 row" *ngIf="selectedThumbnail">
                        <label class="col-sm-10 pr-0"
                                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ">{{selectedThumbnail.name}}</label>
                        <fa-icon [icon]="faIcon.faTimes" class="close col-sm-2 mb-auto pl-2" style="color: red;" (click)="onRemoveThumbnail()">
                        </fa-icon>
                    </div>
                    <div class="form-group mt-2 row" *ngIf="!selectedThumbnail && whatNewForm.get('thumbnail').value">
                        <label class="col-sm-10 pr-0"
                                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ">{{whatNewForm.get('thumbnail').value}}</label>
                        <fa-icon [icon]="faIcon.faTimes" class="close col-sm-2 mb-auto pl-2" style="color: red;" (click)="onRemovePreviousThumbnail()">
                        </fa-icon>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <h6 class="col-2 text-center"><b>Attachment</b></h6>
                <div id="upload_button" class="col-5">
                    <label>
                        <input type="file" class="form-control" multiple (change)="onSelectAttachment($event)" accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*,
                        .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .pages, text/plain, application/vnd.ms-powerpoint">
                        <span class="btn btn-info text-uppercase">Choose files</span>
                    </label>
                    <div class="form-group mt-2 row" *ngIf="selectedAttachment">
                        <label class="col-sm-10 pr-0"
                                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ">{{selectedAttachment.name}}</label>
                        <fa-icon [icon]="faIcon.faTimes" class="close col-sm-2 mb-auto pl-2" style="color: red;" (click)="onRemoveAttachment()">
                        </fa-icon>
                    </div>
                    <div class="form-group mt-2 row" *ngIf="!selectedAttachment && whatNewForm.get('attachment').value">
                        <label class="col-sm-10 pr-0"
                                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; ">{{whatNewForm.get('attachment').value}}</label>
                        <fa-icon [icon]="faIcon.faTimes" class="close col-sm-2 mb-auto pl-2" style="color: red;" (click)="onRemovePreviousAttachment()">
                        </fa-icon>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <h6 class="col-2 text-center"><b>Target Groups <label class="text-danger"> *</label></b></h6>
                <div class="col-8 pl-0">
                    <div class="form-group row mb-0 ml-2" formArrayName="targetGroup">
                        <div class="mb-3" *ngFor="let targetGroup of targetGroupListData.controls; let i = index;"
                            [formGroupName]="i">
                            <span class="p-1 mr-2 mb-2 tag-style">{{targetGroup.get('name').value}} <fa-icon
                                    [icon]="faIcon.faTimes" class="text-secondary ml-1 cursor-pointer" *ngIf="isOwner"
                                    (click)="onRemoveTargetGroup(i)"></fa-icon></span>
                        </div>
                    </div>
                    <input type="text" class="form-control" formControlName="targetGroupSearch" [typeahead]="allTargetGroups"
                        typeaheadOptionField="name" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="6"
                        [typeaheadMinLength]="0" (typeaheadOnSelect)="onSelectTargetGroupTag($event)"
                        [ngClass]="{'is-invalid': whatNewForm.controls['targetGroup'].hasError('null')}" />
                    <label class="mt-2 text-muted">You can specify All Users, Divisions, Groups, Department, Unit, Staff Name, VP Up, EVP Up, IT Outsources</label>
                </div>
            </div>
            <div class="form-group row align-items-center">
                <div class="col-sm-2"></div>
                <input type="checkbox" class="form-control col-sm-auto" style="width: 1.5rem; height: 1.5rem;"  formControlName="isEmployeeOnly">
                <label class="col-form-label col-sm-auto">Employees Only</label>
            </div>
            <div class="form-group row align-items-center">
                <div class="col-sm-2"></div>
                <input type="checkbox" class="form-control col-sm-auto" style="width: 1.5rem; height: 1.5rem;"  formControlName="isBanner">
                <label class="col-form-label col-sm-auto">Banner</label>
            </div>
            <div class="form-group row">
                <h6 class="col-2 text-center"><b>Hashtag</b></h6>
                <!-- <textarea  class="col-8 form-control" rows="2" ></textarea> -->
                <div class="col-8 pl-0">
                    <div class="form-group row mb-0 ml-2" formArrayName="hashtag">
                        <div class="mb-3" *ngFor="let hashtag of hashtagListData.controls; let i = index;"
                            [formGroupName]="i">
                            <span class="p-1 mr-2 mb-2 tag-style">{{hashtag.get('name').value}} <fa-icon
                                    [icon]="faIcon.faTimes" class="text-secondary ml-1 cursor-pointer" *ngIf="isOwner"
                                    (click)="onRemoveHashtag(i)"></fa-icon></span>
                        </div>
                    </div>
                    <input type="text" class="form-control" formControlName="hashtagSearch" [typeahead]="allHashtag"
                        typeaheadOptionField="name" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="6"
                        [typeaheadMinLength]="0" (typeaheadOnSelect)="onSelectHashtag($event)"
                        [ngClass]="{'is-invalid': whatNewForm.controls['hashtag'].hasError('null')}" (keyup.enter)="onEnterHashtag()" />
                </div>
            </div>
            <div class="form-group row">
                <h6 class="col-2 text-center"><b>Effective Date <label class="text-danger"> *</label></b></h6>
                <input type="date" class="form-control col-4" formControlName="effectiveDate"
                [ngClass]="{'is-invalid': whatNewForm.controls['effectiveDate'].hasError('null')}"
                >
            </div>
            <div class="form-group row">
                <h6 class="col-2 text-center"><b>Expiry Date <label class="text-danger"> *</label></b></h6>
                <input type="date" class="form-control col-4" formControlName="expiryDate"
                [ngClass]="{'is-invalid': whatNewForm.controls['expiryDate'].hasError('null')}"
                >
            </div>
        </div>



        <div class="row justify-content-end mt-4 mb-4">
            <div class="col-sm-auto">
                <button type="button" class="btn btn-secondary app-btn-style"
                    (click)="onShowConfirmationModal(template,'back')">BACK
                </button>
            </div>
            <div class="col-sm-auto" *ngIf="status === 'edit' && this.whatNewDetail.id">
                <button type="button" class="btn btn-danger app-btn-style"
                    (click)="onShowConfirmationModal(template,'delete')" [disabled]="isLoading">DELETE</button>
            </div>
            <div class="col-sm-auto">
                <button type="button" class="btn btn-success app-btn-style"
                    (click)="onShowConfirmationModal(template,'submit')" [disabled]="isLoading">SUBMIT</button>
            </div>
        </div>
    </form>
</div>

<ng-template #template>
    <div class="modal-header thead-primary" style="background-color: #fab232;">
        <h4 class="modal-title pull-left ml-3">Confirmation</h4>
        <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group ml-3">
            <div class="d-flex">
                <h5>{{ confirmationWording }}</h5>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex mt-2 justify-content-end">
            <button class="btn btn-success mr-2" (click)="confirm()" [disabled]="isLoading">Confirm</button>
            <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-template>