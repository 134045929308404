import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators,} from '@angular/forms';
import {MedicalService} from '../../../core/services/medical.service';
import {MedicalModel} from '../../../core/models/medical';
// @ts-ignore
import hospital from '../data/hospital.json';
import {HospitalModel} from '../../../core/models/hospital';
import {PdpService} from '../../../core/services/pdp.service';
import {Attachment, PDPARequestModel,} from 'src/app/core/models/pdpaAtttachsModel';
import {EmployeeService} from 'src/app/core/services/employee.service';
import {switchMap} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {BsDatepickerConfig} from 'ngx-bootstrap';
import * as moment from 'moment';
import {RequestTrainingService} from 'src/app/core/services/request-training.service';
import {faIcon} from 'src/app/core/icon/fa.icon';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-new-request-medical',
  templateUrl: './new-request-medical.component.html',
  styleUrls: ['./new-request-medical.component.scss'],
})
export class NewRequestMedicalComponent implements OnInit {
  @Input() page
  @Output()
  Done = new EventEmitter<boolean>();
  faIcon = faIcon;

  @Input('mediCalBufferOnEdit')
  set setMedicalForm(m: MedicalModel) {
    this.whoami();
    if (m) {
      this.loader.overlay = true;
      this.loader.fileAttachment = false;
      const {
        id,
        empID,
        email,
        claimGroup,
        nameEn,
        nameTh,
        receiptNo,
        treatmentDate,
        receiptDate,
        amount,
        other,
        hospital,
        documentId,
        status,
      } = m;
      this.currentDataDetail.medicalId = id;
      this.currentDataDetail.formStatus = status;
      this.formRequest.patchValue({
        id,
        empID,
        email,
        claimGroup,
        nameEn,
        nameTh,
        receiptNo,
        treatmentDate,
        receiptDate,
        amount,
        other,
        hospital,
        documentId
      });

      if (hospital === 'อื่นๆ (Others)') {
        this.formRequest.controls['other'].setValidators(Validators.required)
        this.formRequest.controls['other'].updateValueAndValidity()
      }

      if (documentId) {
        this.pdpaService
          .getDocumentId(documentId)
          .toPromise()
          .then((x) => {
            const {data} = x;
            const {attachments, aspects, documentId} = data;
            this.documentId = documentId;
            this.addAttachments(attachments);
            // this.loader.fileAttachment = false;
            this.loader.overlay = false;
          });
      }
      // this.loader.overlay = false;
    } else {
      this.loader.fileAttachment = false;
      this.loader.overlay = false;
      // this.whoami();
    }
  }

  currentDataDetail = {
    medicalId: '',
    formStatus: '-',
  };
  displayReviewButton = false;
  employeeList: any;
  formRequest: FormGroup;
  pdpaForm: FormGroup;
  documentId;
  env = environment;
  isHrAdmin = false;
  ownerID


  hospitalList: HospitalModel[] = hospital;
  file = {
    fileToUpload: null,
    uploadProgress: 0,
  };
  loader = {
    fileAttachment: true,
    overlay: true,
  };
  datePickerConfig: Partial<BsDatepickerConfig> = {
    dateInputFormat: 'YYYY-MM-DD',
    containerClass: 'theme-orange',
  };
  @ViewChild('attachment')
  fileInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    private medicalService: MedicalService,
    private pdpaService: PdpService,
    private employeeService: EmployeeService,
    private requestTraining: RequestTrainingService
  ) {
    this.createForm();
    this.createAttachmentForm();
  }

  private createForm() {
    this.formRequest = this.fb.group({
      id: [''],
      email: [''],
      nameTh: [{value: '', disabled: true}],
      nameEn: [''],
      empID: ['', Validators.required],
      receiptNo: ['', Validators.required],
      receiptDate: ['', this.customValidatorDate],
      treatmentDate: [new Date(), this.customValidatorDate],
      hospital: ['', Validators.required],
      other: [''],
      amount: [0, [Validators.min(1), this.customValidatorNumberOnly]],
      documentId: [''],
    });
  }

  whoami() {
    const token = sessionStorage.getItem('token')
    // console.log('token new ---> ',token)
    if (token !== 'undefined') {
      this.employeeService
        .meDNA(token)
        .toPromise()
        .then((p) => {
          const {nameEn, nameTh, empID, email} = p;
          this.ownerID = empID;
          this.formRequest.patchValue({
            nameTh,
            nameEn,
            empID,
            email
          });
          this.pdpaForm.get('aspect').patchValue({
            requestId: new Date().getTime(),
            employeeId: empID,
            employeeName: nameEn
          })
          // aspect: this.fb.group({
          //   requestId: '',
          //   employeeId: '',
          //   employeeName: '',
          // }),
        });
    } else {
      this.employeeService
        .me()
        .toPromise()
        .then((p) => {
          const {nameEn, nameTh, empID, email} = p;
          this.ownerID = empID;
          this.formRequest.patchValue({
            nameTh,
            nameEn,
            empID,
            email
          });
          this.pdpaForm.get('aspect').patchValue({
            requestId: new Date().getTime(),
            employeeId: empID,
            employeeName: nameEn
          })
        });
        this.checkRole();
    }
  }

  checkRole(): void {
    this.requestTraining
      .getUserRole()
      .toPromise()
      .then((x) => {
        if (x.some(r => r.role === 'HRMedical')) {
          this.isHrAdmin = true;
          // todo : iTom test
          // if (this.currentDataDetail.formStatus == 'REVIEWING') {
          // this.displayReviewButton = true;
          // }

          this.formRequest.get('nameTh').enable({ onlySelf: true });
          this.employeeService
            .employeeList()
            .toPromise()
            .then((x) => {
              this.employeeList = x;
            });
        } else {
          this.isHrAdmin = false;
        }
      })
      .catch((y) => {
        this.isHrAdmin = false;
      });
  }
  async deleteItem() {
    const id = this.formRequest.get('id').value;
    const documentId = this.formRequest.get('documentId').value;

    console.log('documentId', documentId)
    const c = await this.confirmDelete('Are you sure you want to remove this request ?');
    if (c.value) {
      this.medicalService
        .deleteById(id)
        .pipe(switchMap((d) => this.pdpaService.deleteDocumentByid(documentId)))
        .toPromise()
        .then((x) => {
          this.back();
        });
    }
  }

  clearEmployee() {
    this.formRequest.patchValue({
      nameTh: '',
      nameEn: '',
      empID: '',
    });
  }

  handleSubmit() {
    console.log('who am i -->', this.ownerID)
    if (this.currentDataDetail.medicalId === '') {
      // console.log('no iddddddd ==> ', this.currentDataDetail.medicalId )
      this.handleSave('submit')
    } else {
      this.medicalService
        .review(this.currentDataDetail.medicalId, this.attachments.getRawValue())
        .toPromise()
        .then((y) => {
          let msg = ''
          if (this.ownerID === '02250' || this.ownerID === '01767') {
            msg = 'กรุณาส่งเอกสารตัวจริงที่ HR ถึงจะได้รับเงิน !!'
          } else {
            msg = 'The data has been submited.'
          }
          this.alert(true, msg).then((x) => {
            if (x.value) {
              this.Done.emit(true);
            }
          });
        })
        .catch((x) => {
          this.alert(false, 'Something wrong.').then((x) => {
          });
        });
    }
  }

  onSelectEmployee({item}) {
    const {nameTh, nameEn, empID} = item;
    this.formRequest.patchValue({
      nameTh,
      nameEn,
      empID,
    });
  }

  private createAttachmentForm() {
    this.pdpaForm = this.fb.group({
      encryptAttachments: [true],
      documentTypeId: [this.env.pdpa.documentTypeId],
      childPath: ['พ.ศ.2563'],
      documentName: ['2 ธ.ค 63'],
      aspect: this.fb.group({
        requestId: '',
        employeeId: '',
        employeeName: '',
      }),
      attachments: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    // console.log('this.currentDataDetail.formStatus => ' ,this.currentDataDetail.formStatus)
    if (this.currentDataDetail.formStatus == 'SUBMIT') {
      this.formRequest.disable()
    }
    // setTimeout(() => {
    //   this.loader.fileAttachment = false;
    //   this.loader.overlay = false;
    // }, 5000);
    // this.checkRole();
  }

  customValidatorDate(f: AbstractControl): ValidationErrors | null {
    const d = f.value;
    const date = moment(d).toDate();
    if (date.toString() === 'Invalid Date') {
      return {
        dateInvalid: true,
      };
    }

    return null;
  }

  customValidatorNumberOnly(f: AbstractControl): ValidationErrors | null {
    const v = Number(f.value);
    if (isNaN(v) || f.value == null) {
      return {
        notNumber: true,
      };
    }
    return null;
  }

  customValidate(f: AbstractControl): ValidationErrors | null {
    return {
      isNull: true,
    };
  }

  onClickAttachFile(fileName: string): void {
  }

  onChangeHospital() {
    const value = this.formRequest.get('hospital').value;
    if (value === 'อื่นๆ (Others)') {
      this.formRequest.controls['other'].setValidators(Validators.required)
      this.formRequest.controls['other'].updateValueAndValidity()
    } else {
      this.formRequest.controls['other'].clearValidators()
      this.formRequest.controls['other'].updateValueAndValidity()
    }
  }

  confirmDelete(msg: string) {
    return Swal.fire({
      scrollbarPadding: false,
      title: 'Are you sure?',
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      focusCancel: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });
  }

  back() {
    this.Done.emit(true);
  }

  buildaspectFormControl({empID, nameTh}) {
    return this.fb.group({
      requestId: [''],
      employeeId: [empID],
      employeeName: [nameTh],
    });
  }

  otherIsReadOnly(): boolean {
    const value = this.formRequest.get('hospital').value;
    return value !== 'อื่นๆ (Others)';
  }

  get attachments(): FormArray {
    return this.pdpaForm.get('attachments') as FormArray;
  }

  addAttachments(attactments: Attachment[]) {
    attactments?.forEach((d) => {
      this.attachments.push(this.fb.group(d));
    });
  }

  getAspact(): FormGroup {
    return this.pdpaForm.controls.aspect as FormGroup;
  }

  getControl(controleName: string): FormControl {
    return this.formRequest.get(controleName) as FormControl;
  }

  onUpload() {
    this.formRequest.controls['documentId'].setErrors({isNull: false,});
    if (this.file.fileToUpload) {
      this.pdpaService
        .toBase64(this.file.fileToUpload)
        .then((x) => {
          this.attachments.push(this.fb.group(x));
          this.file.fileToUpload = null;

          //clear file input
          this.fileInput.nativeElement.value = '';
        })
        .catch((e) => console.error);
    }
  }

  findValue(f: AbstractControl, key: string): string {
    return f.get(key).value;
  }

  handleSave(action) {

    if (isNaN(this.formRequest.controls.amount.value)) {
      this.formRequest.controls.amount.setErrors({isNull: true,});
    } else {
      this.formRequest.controls.amount.setErrors({isNull: false,});
      this.formRequest.controls.amount.updateValueAndValidity();
    }

    if (this.attachments.length == 0) {
      this.formRequest.controls.documentId.setErrors({isNull: true,});
    } else {
      this.formRequest.controls.documentId.setErrors({isNull: false,});
      this.formRequest.controls.documentId.updateValueAndValidity();
    }
    if (this.preValidateControl()) {
      return;
    }

    this.loader.overlay = true;
    const body = this.pdpaForm.getRawValue() as PDPARequestModel;
    console.log('save data', body)
    this.pdpaService
      .saveBody(body, this.documentId)
      .pipe(
        switchMap((x) => {
          const {documentId} = x.data;

          this.formRequest.patchValue({
            documentId,
          });
          if (this.formRequest.controls['other'].value === 'อื่นๆ (Others)') {
            this.formRequest.controls['other'].setValidators(Validators.required)
            this.formRequest.controls['other'].updateValueAndValidity()
          }
          const medicalModel: MedicalModel = this.formRequest.getRawValue();
          medicalModel.attachments = this.attachments.getRawValue();
          const {id} = medicalModel;
          return this.medicalService.saveBody(
            medicalModel as MedicalModel,
            +id
          );
        })
      )
      .toPromise()
      .then((r) => {
        const {id} = r;

        this.formRequest.patchValue({
          id,
        });
        if (this.formRequest.controls['other'].value === 'อื่นๆ (Others)') {
          this.formRequest.controls['other'].setValidators(Validators.required)
          this.formRequest.controls['other'].updateValueAndValidity()
        }
        this.loader.overlay = false;
        if (action == 'submit') {
          this.currentDataDetail.medicalId = id
          this.handleSubmit()
        } else {
          this.alert(true, 'Save the data successfully.').then((x) => {
            if (x.value) {
              this.Done.emit(true);
            }
          });
        }
      })
      .catch((e) => {
        this.loader.overlay = false;
        this.alert(false, 'Something wrong!');

        console.log(e);
      });
  }

  // handleSubmit() {
  // }


  post(body: PDPARequestModel) {
    this.pdpaService
      .postBody(body)
      .toPromise()
      .then((x) => {
        console.log('xxx is ', x);
      });
  }

  alert(isSuccess, msg) {
    return Swal.fire({
      scrollbarPadding: false,
      title: isSuccess ? 'Successfully' : 'Error',
      icon: isSuccess ? 'success' : 'error',
      text: msg,
      buttonsStyling: false,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-warning text-light',
      },
    });
  }

  preValidateControl() {
    let result = false;
    const controls = this.formRequest.controls;
    for (const cn in controls) {
      if (controls[cn].invalid) {
        controls[cn].setErrors({
          isNull: true,
        });
        result = true;
      }
    }
    return result;
  }

  onDownload(base64: string, fileType: string, fileName: string) {
    this.pdpaService.download(base64, fileType, fileName);
  }

  removeAttachment(index: number) {
    this.confirmDelete('Are you sure you want to remove this attachment ?').then((result) => {
      if (result.value) {
        this.attachments.removeAt(index);
        Swal.fire('Deleted!', 'Your attachment has been deleted.', 'success');
      }
    });
  }

  handleFileUpdate(files: FileList, value) {
    this.file.fileToUpload = files[0];
  }

  today() {
    return new Date();
  }
}
