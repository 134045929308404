import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DisbursementInterface } from '../models/disbursement.model';

@Injectable({
  providedIn: 'root',
})
export class DisburesementService {
  constructor(private http: HttpClient) {}

  getRequestDisbursementList(
    start,
    end,
    lastExport = null
  ): Observable<DisbursementInterface[]> {
    return this.http.get<DisbursementInterface[]>(
      `${environment.api_endpoint}/disbursement/list`,
      {
        params: {
          start,
          end,
          lastExport,
        },
      }
    );
  }
  setLastExportDate(idList: number[]) {
    return this.http.post<any[]>(
      `${environment.api_endpoint}/disbursement/last-export`,
      {
        idList,
      }
    );
  }
  public updatePayAmount(obj: any) {
    return this.http.put<any[]>(
      `${environment.api_endpoint}/disbursement/update-pay-amount`,
      obj
    );
  }
}
