<app-layout>
  <div class="container-fluid mt-4 pl-5 pr-5">
    <h5>Attachments</h5>
    <ul>
      <li *ngFor="let attachment of attachments$ | async">
        <a (click)="downloadAttachment(attachment)" class="clickable">{{attachment}}</a>
      </li>
    </ul>
  </div>
</app-layout>
