import {Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TokenService} from '../services/token.service';
import {Router} from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private exceptURL = '/fast-feedback'
  private exceptURL2 = '/open-talk'
  private exceptURL5 = '/medical'
  private exceptURLs = ['/project', '/outsource-timesheet']

  constructor(
    private tokenService: TokenService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq = request;
    const token = this.tokenService.getToken
    if (token != null) {
      if (
        request.url.includes(this.exceptURL) ||
        request.url.includes(this.exceptURL2) ||
        this.outsourceTimesheet(request) ||
        request.url.includes(this.exceptURL5)
      ) {
        console.log('intercept work!', request.url);
        authReq = request.clone({
          headers: request.headers.set('Authorization', 'dna ' + token),
        });
      }
    }
    return next.handle(authReq);
  }

  private outsourceTimesheet(request: HttpRequest<unknown>) {
    const found = this.exceptURLs.find(p => request.url.includes(p));
    return found && this.router.url.startsWith('/mobile')
  }
}

export const TokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true,
};
