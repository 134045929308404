
  <div class="container-fluid" style="margin-top: 10px;">
    <div class="form-group">
         <div class="row">
        <div style="font-size: 20px; margin-left:15px ; margin-top: 5px;"> Start Date: </div>
        <div class="col-sm-2">
            <label>
                <input type="text" placeholder="Please select date" [(ngModel)]="dateRange.startDate"
                    class="form-control" bsDatepicker [bsConfig]="bsConfig"
                    (bsValueChange)="onDateChange($event,null)" >
            </label>
        </div>
    <div style="font-size: 20px; margin-left:15px ; margin-top: 5px;"> End Date: </div>
    <div class="col-sm-2">
        <label>
            <input type="text" placeholder="Please select date" [(ngModel)]="dateRange.endDate"
                class="form-control" bsDatepicker [bsConfig]="bsConfig"
                (bsValueChange)="onDateChange(null,$event)" [minDate]="dateLimit().minDate">
        </label>
    </div>
    </div>
    </div>
    <table  class="table table-hover" style="width: 100%; margin-top: 10px;">
        <tr style="color: aliceblue; text-align: center" class="black">
            <th style="width: 13;">ACTION DATE</th>
            <th style="width: 10;">COURSE</th>
            <th style="width: 10;">SESSION</th>
            <th style="width: 10;">COMPETENCY</th>
            <th style="width: 10;">ATTENDEE</th>
            <th style="width: 10;">ONLINE COURSE</th>
            <th style="width: 10;">ONLINE SESSION</th>
            <th style="width: 10;">ONLINE COMPETENCY</th>
            <th style="width: 10;">ONLINE ATTENDEE</th>
            <th style="width: 7;">STATUS</th>
          </tr>
          <tbody>
                <tr *ngFor="let track of trackFileList | paginate: config">
                <th style="text-align: left;">{{track.actionDate | date:'dd/MM/yyyy HH:mm'}}</th>
                <th style="text-align: center;">{{track.course}}</th>
                <th style="text-align: center;">{{track.session}}</th>
                <th style="text-align: center;">{{track.competency}}</th>
                <th style="text-align: center;">{{track.attendee}}</th>
                <th style="text-align: center;">{{track.onlCourse}}</th>
                <th style="text-align: center;">{{track.onlSession}}</th>
                <th style="text-align: center;">{{track.onlCompetency}}</th>
                <th style="text-align: center;">{{track.onlAttendee}}</th>
                <th style="text-align: center;">{{track.status}}</th>
            </tr>
            <tr>
                <td *ngIf="trackFileList.length == 0 " colspan="10" class="border" style="text-align: center;">
                  <h5>
                    <b> No Data To Display </b>
                  </h5>
                </td>
              </tr>
          </tbody>
    </table>
    <div >
      <div class="float-right">
        <pagination-controls class="pagination"
        [maxSize]="maxSize"
        [previousLabel]="labels.previousLabel"
        [nextLabel]="labels.nextLabel"
        (pageChange)="handlePaging($event)"></pagination-controls>
    </div>
    </div>
  </div>
