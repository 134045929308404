import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

import {faIcon} from 'src/app/core/icon/fa.icon';
import {EmployeeService} from 'src/app/core/services/employee.service';
import {Employee} from 'src/app/core/models/employee';
import {FastFeedbackService} from 'src/app/core/services/fast-feedback.service';
import {FastFeedback} from 'src/app/core/models/fast-feedback';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {PagerModel} from 'ngx-bootstrap/pagination/models';
import {ActivatedRoute, Router} from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { FastFeedbackResult } from 'src/app/core/models/fast-feedback-result';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-vendor-evaluation-detail',
  templateUrl: './vendor-evaluation-detail.component.html',
  styleUrls: ['./vendor-evaluation-detail.component.scss'],
})

export class VendorEvaluationDetailComponent implements OnInit, OnChanges {
    @Output() onEmitData = new EventEmitter();
    @Input() fastFeedbackData: FastFeedback;
    @Input() pageData: String;
    selectedPeriod = 'GET_ALL';
    allPeriod = [
        {
            id: 1,
            period: 'Today',
            periodType: 'TODAY',
        },
        {
            id: 2,
            period: 'This week',
            periodType: 'THIS_WEEK',
        },
        {
            id: 3,
            period: 'This month',
            periodType: 'THIS_MONTH',
        },
        {
            id: 4,
            period: 'All time',
            periodType: 'GET_ALL',
        },
    ];
    fastFeedbackResult: FastFeedbackResult;
    optionsJsonToEx = {
        filename: 'Response-fast-feedback',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
    };
    barChart: string;
    dataOfBarChart: any;
    optionsBarChart: any;
    lineChart: string;
    dataOfLineChart: any;
    optionsLineChart: any;

    constructor(
        private fastFeedbackService: FastFeedbackService,
        private datepipe: DatePipe
    ) {

    }

    ngOnInit() {
        // if(this.fastFeedbackData) {
        //     console.log('fastFeedbackData', this.fastFeedbackData);
        // }
    }

    ngOnChanges() {
        if (this.fastFeedbackData) {
            if(this.fastFeedbackData.numberOfResponses > 0) {
                this.getFastFeedbackResult();
            }
        }
    }

    getFastFeedbackResult() {
        this.fastFeedbackService.getFastFeedbackResult(this.fastFeedbackData.id, this.selectedPeriod).toPromise()
            .then(res => {
                this.fastFeedbackResult = res;
                this.assignDataToBarChart();
                this.assignDataToLineChart();
            })
            .catch(err => {
                console.error('err', err)
            })
    }

    onChangePeriod(data) {
        this.getFastFeedbackResult();
    }

    exportExcel() {
        if (this.fastFeedbackResult && Array.isArray(this.fastFeedbackResult.comments)) {
            if (this.fastFeedbackResult.comments.length > 0) {
                const json = this.fastFeedbackResult.comments.map(v => {
                    let dateTime = this.datepipe.transform(v.date,'dd-MM-yyyy HH:mm'); 

                    let data  = {
                        ['DATE-TIME']: dateTime ? dateTime : '-',
                        ['SCORE']: v.rate ? v.rate : 0,
                        ['COMMENT']: v.message ? v.message : '-',
                    }

                    if(Array.isArray(v.fastFeedbackResponseAnswers)) {
                         v.fastFeedbackResponseAnswers.forEach((answer,index) => {
                            let answerData = {
                                [`ANSWER${index + 1}`]: answer.answer ? answer.answer : '-'
                            };

                            Object.assign(data, answerData); 
                        })
                    }

                    return data;
                })
                const csvExporter = new ExportToCsv(this.optionsJsonToEx);
                csvExporter.generateCsv(json);
            }
        }
    }

    assignDataToBarChart() {
        this.barChart = 'bar';
        this.dataOfBarChart = {
            labels: [ 1, 2, 3, 4, 5],
            datasets: [
                {
                    data: [this.fastFeedbackResult.summary.rate1,
                    this.fastFeedbackResult.summary.rate2,
                    this.fastFeedbackResult.summary.rate3,
                    this.fastFeedbackResult.summary.rate4,
                    this.fastFeedbackResult.summary.rate5
                    ],
                    backgroundColor: [
                        'rgba(255, 96, 92, 0.5)',
                        'rgba(255, 96, 92, 0.5)',
                        'rgba(255, 189, 68, 0.5)',
                        'rgba(0, 202, 78, 0.5)',
                        'rgba(0, 202, 78, 0.5)'
                    ],
                }
            ]
        };
        let max = (Math.max(this.fastFeedbackResult.summary.rate1,
                this.fastFeedbackResult.summary.rate2,
                this.fastFeedbackResult.summary.rate3,
                this.fastFeedbackResult.summary.rate4,
                this.fastFeedbackResult.summary.rate5
        ));
 
        let step = max > 10 ? 2 : 1;

        this.optionsBarChart = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        display: true,
                        min:0,
                        max:max,
                        stepSize: step
                    },
                    gridLines: {
                        display: true
                    }
                }],
                xAxes: [{
                    gridLines: {
                        display: false
                    }
                }]
            },
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        // var legend = new Array();
                        // for(var i in data.datasets){
                        //     legend.push(
                        //         data.datasets[i].label + ": " + parseFloat(data.datasets[i].data[tooltipItem.index])
                        //     );
                        // }

                        // return legend;
                        return `No. Of Response: ${tooltipItem.yLabel}`;
                    }
                }
            }
        };
    }

    assignDataToLineChart() {
        let period = [];
        if (Array.isArray(this.fastFeedbackResult.period)) {
            this.fastFeedbackResult.period.forEach(item => {
                let date = new Date(item);
                let month = date.toLocaleString('en-GB', { month: 'short' })
                let dateDetail = `${date.getDate()} ${month} ${date.getFullYear()}`;
                period.push(dateDetail);
            })
        }

        let dataOfChart = [];
        if (Array.isArray(this.fastFeedbackResult.rate)) {
            if(this.fastFeedbackResult.rate.reduce((a, b) => a + b, 0) > 0) {
                dataOfChart = this.fastFeedbackResult.rate;
            }
        }


        if(dataOfChart.length === 1 && period.length === 1) {
            dataOfChart.push(null) 
            dataOfChart.unshift(null) 
            period.push('') 
            period.unshift('') 
        }

        let min = 0;
        let max = 5;
        let step = 1;
        let isCSI = false;
        if(this.fastFeedbackData.feedbackType?.toLowerCase() === 'csi') {
            min = 0;
            max = 100;
            step = 20;
            isCSI = true;
        } 


        this.lineChart = 'line';
        this.dataOfLineChart = {
            labels: period,
            datasets: [
                {
                    data: dataOfChart,
                    type: 'line',
                    borderColor:'rgb(255, 227, 128)',
                    backgroundColor:'rgb(255, 227, 128)',
                    fill: false
                }
            ]
        };
        this.optionsLineChart = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        display: true,
                        min: min,
                        max: max,
                        stepSize: step,
                        callback: function(value, index, values) {
                            if (value <= 100) {
                                return isCSI ? `${values[index]}%` : values[index]
                            }
                        }
                    },
                    gridLines: {
                        display: true
                    }
                }],
                xAxes: [{
                    gridLines: {
                        display: false
                    }
                }]
            },
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        return `Average Score: ${tooltipItem.yLabel}`;
                    }
                }
            }
        };
    }

    checkHaveComment(): boolean {
        if(Array.isArray(this.fastFeedbackResult.comments)) {
            if(this.fastFeedbackResult.comments.filter(item => { return item.message}).length === 0) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }

    onBack() {
        this.onEmitData.emit({state:'list', page: this.pageData});
    }
}