export const pagePermission = [
  {
    page: 'external',
    permission: {
      HRAdmin: 'Edit',
      UserAdmin: 'Edit',
    }
  }, {
    page: 'uploadfile',
    permission: {
      HRAdmin: 'Edit',
      UserAdmin: 'Edit',
    }
  }, {
    page: 'userrole',
    permission: {
      HRAdmin: 'Edit',
      UserAdmin: 'Edit',
    }
  }, {
    page: 'allowance',
    permission: {
      HRAdmin: 'Edit',
      UserAdmin: 'Edit',
    }
  }, {
    page: 'medical',
    permission: {
      HRAdmin: 'Edit',
      HRMedical: 'Edit',
    }

  }, {
    page: 'intern',
    permission: {
      HRAdmin: 'Edit',
      OutsourceAdmin: 'Edit',
    }
  }, {
    page: 'outsource',
    permission: {
      HRAdmin: 'Edit',
      OutsourceAdmin: 'Edit',
    }
  },
  {
    page: 'outsourceProject',
    permission: {
      HRAdmin: 'Edit',
      OutsourceAdmin: 'Edit',
    }
  },
  {
    page: 'additional-information',
    permission: {
      HRAdmin: 'Edit',
      UserAdmin: 'View',
    }
  },
  {
    page: 'deptAuthorization',
    permission: {
      HRAdmin: 'Edit',
      UserAdmin: 'View',
    }
  },
  {
    page: 'special-holiday',
    permission: {
      HRAdmin: 'Edit',
      UserAdmin: 'Edit',
    }
  }
]
