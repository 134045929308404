import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { RequestTrainingService } from '../../core/services/request-training.service';
import { RequestTraining } from '../../core/models/request-training';
import { UserRole } from '../../core/models/userRole';
import * as moment from 'moment';

import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RequestDetailComponent } from './request-detail/request-detail.component';
import { SystemControl } from 'src/app/core/models/system-control';

@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.scss'],
})
export class RequestListComponent implements OnInit {
  @ViewChild('requestDetailComponent')
  requestDetailComponent: RequestDetailComponent;

  modalRef: BsModalRef;
  requestDetail;
  userRoles: UserRole[] = [];
  Manager;
  tooltipHtml = '';
  isDataFilter = false;
  systemControl: SystemControl = {
    systemId: null,
    systemName: '',
    status: false,
    reason: '',
    updateBy: '',
    nameUpdateBy: '',
    updateDate: null,
  };
  isActive: boolean = false;
  reason = '';

  @Output() loadData = new EventEmitter();

  requestList: RequestTraining[] = [];

  constructor(
    private modalService: BsModalService,
    private requestTrainingService: RequestTrainingService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  searchForm = this.fb.group({
    keyword: '',
  });

  ngOnInit(): void {
    this.getRequestList();
    this.getHrRoleName();
    this.checkService();
  }

  getRequestList() {
    this.isDataFilter = false;
    this.searchForm.controls.keyword.patchValue('');

    this.loadData.emit();
    this.requestTrainingService.findAll().subscribe(
      (response) => {
        this.decorateResult(response);
      },
      (e) => console.error(e)
    );
  }

  checkService() {
    this.requestTrainingService.checkExternalTraningIsActive().subscribe(
      (response) => {
        console.log('checkExternalTraningIsActive response', response);
        this.isActive = response.status;
        this.reason = response.reason;
      },
      (e) => console.error(e)
    );
  }
  decorateResult(response) {
    let state;
    let displayDate;
    const ext = [];
    response.forEach((s) => {
      if (s.approvalState) {
        switch (s.approvalState) {
          case 'CANCELED':
            state = 'CANCELED';
            break;
          case 'DRAFT':
            state = 'DRAFT';
            break;
          case 'PENDING HR':
          case 'PENDING UNIT':
          case 'PENDING DEPT':
          case 'PENDING GROUP':
          case 'PENDING DIV':
          case 'PENDING AAA':
          case 'PENDING HRVP MANAGER':
            state = 'PENDING';
            break;
          case 'HR REJECT':
          case 'UNIT REJECT ':
          case 'DIV REJECT':
          case 'DEPT REJECT':
          case 'GROUP REJECT':
          case 'AAA REJECT':
            state = 'REJECTED';
            break;
          case 'COMPLETED':
            state = 'APPROVED';
            break;
        }
        if (s.status === 'REJECTED') {
          state = 'REJECTED';
        }
        if (s.startDate === s.endDate) {
          displayDate = moment(s.startDate).format('DD MMM YYYY');
        } else {
          displayDate =
            moment(s.startDate).format('DD MMM YYYY') +
            ' - ' +
            moment(s.endDate).format('DD MMM YYYY');
        }
      }
      ext.push({
        ...s,
        DisplayState: state,
        DisplayDate: displayDate,
      });
      state = '';
    });
    this.requestList = ext;
    this.requestList.sort(
      this.requestTrainingService.sortByProperty('createDate', 'desc')
    );

    const { detail } = this.activatedRoute.snapshot.queryParams;
    const d = this.requestList.find((it) => +it.id === +detail);
    if (d) {
      this.newRequest(this.requestDetailComponent.template, d);
    }
  }

  searchRequest() {
    this.isDataFilter = true;

    const searchKeyword = this.searchForm.controls.keyword.value;

    this.requestTrainingService.searchRequestByKeyword(searchKeyword).subscribe(
      (s) => {
        console.log(s);
        this.decorateResult(s);
      },
      (e) => {
        console.error(e);
        this.requestList = [];
        this.isDataFilter = true;
      }
    );
  }

  getHrRoleName() {
    this.requestTrainingService.findHrApprover().subscribe(
      (s) => {
        if (s) {
          this.userRoles = s.map((o) => o);
          s.forEach((o) => {
            this.tooltipHtml += `<p>${o?.employee?.nameTh}</p>`;
          });
        }
      },
      (e) => console.error(e)
    );
  }

  newRequest(template, detail) {
    console.log('Detail: ', detail);
    // this.requestDetail = null
    this.requestDetail = detail && { ...detail };
    console.log('newRequest Clicked', detail);
    this.modalRef = this.modalService.show(template, {
      class: 'modal-xl',
      keyboard: false,
      ignoreBackdropClick: true,
    });
  }
}
