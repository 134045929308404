import {Component, OnInit, Output, EventEmitter, Input, OnChanges} from '@angular/core';
import {RequestManpower} from '../../../core/models/requestManpower';
import {ManpowerService} from '../../../core/services/manpower.service';
import {Router} from '@angular/router';
import { PagerModel } from 'ngx-bootstrap/pagination/models';
import { PageChangedEvent } from 'ngx-bootstrap';

@Component({
  selector: 'app-man-power-pending-approve-list',
  templateUrl: './man-power-pending-approve-list.component.html',
  styleUrls: ['./man-power-pending-approve-list.component.scss'],
})
export class ManPowerPendingApproveListComponent implements OnInit {
  manPowerList: RequestManpower[] = []
  currentPage = 1;
  paginationConfig: PagerModel = {
    itemsPerPage: 10,
    previousText: 'ย้อนกลับ',
    nextText: 'ถัดไป',
    pageBtnClass: 'btn btn-warning',
    align: true,
  };
  manPowerListPerPage: RequestManpower[] = []

  constructor(
    private manpowerService: ManpowerService,
    private router: Router
  ) {

  }

  async ngOnInit() {
    this.manPowerList = await this.manpowerService.getMyRequest('Y').toPromise()
    const startItem = (this.currentPage - 1) * 10;
    const endItem = this.currentPage * 10;
    this.manPowerListPerPage = this.manPowerList.slice(startItem, endItem);
  }

  handleOnViewRequisition(manPower: RequestManpower) {
    this.router.navigateByUrl(`man-power/approve/${manPower.id}`)
  }

  handlePaging({ page, itemsPerPage }: PageChangedEvent) {
    const startItem = (page - 1) * itemsPerPage;
    const endItem = page * itemsPerPage;
    this.currentPage = page;
    this.manPowerListPerPage = this.manPowerList.slice(startItem, endItem);
  }
}
