import {Component, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {FileServiceService} from 'src/app/core/services/file-service.service';
import Swal from 'sweetalert2';
import {saveAs} from 'file-saver';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-upload-external-file',
  templateUrl: './upload-external-file.component.html',
  styleUrls: ['./upload-external-file.component.scss']
})
export class UploadExternalFileComponent implements OnInit {
  file = {
    courseFile: null,
    learningHistoryFile: null,
    uploadProgress: 0,
  };
  onlineCourseList = [];
  onlineAttendeeList = [];
  onlineCourseLogList = [];
  modalRef: BsModalRef;
  confirmationWording = '';
  public pageMaxSize = 7;
  coursePagination = {
    id: 'course_pagination',
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0
  };
  attendeePagination = {
    id: 'attendee_pagination',
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0
  }
  logsPagination = {
    id: 'logs_pagination',
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0
  }
  public labels: { previousLabel: string, nextLabel: string } = {
    previousLabel: '<',
    nextLabel: '>',
  };

  fg = this.fb.group({
    courseFile: new FormControl('', Validators.required),
    learningHistoryFile: new FormControl('', Validators.required),
  })
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private fileService: FileServiceService,
    private modalService: BsModalService
  ) {
  }

  ngOnInit(): void {
    this.getOnlineCourse();
    this.getOnlineAttendee();
    this.getOnlineCourseLog();
  }

  handleCourseUpload(fileUpload: FileList) {
    console.log('File', fileUpload);
    this.file.courseFile = fileUpload.item(0);
  }

  // handleAttendeeUpload(fileUpload: FileList) {
  //   console.log("File", fileUpload);
  //   this.file.learningHistoryFile = fileUpload.item(0);
  // }

  getOnlineCourse() {
    this.fileService.getOnlineCourse().subscribe(res => {
        this.onlineCourseList = res
        this.coursePagination = {
          ...this.coursePagination,
          totalItems: this.onlineCourseList.length
        };
        console.log('onlineCourseList :', res);
      }
    )
  }

  downloadTemplate() {
    this.fileService.downloadOnlineAttendeeTemplate().subscribe(response => {
      const blob = new Blob([response.body]);
      saveAs(blob, 'online_course_template.xlsx');
    });
  }

  getOnlineAttendee() {
    this.fileService.getOnlineAttendee().subscribe(res => {
      this.onlineAttendeeList = res
      this.attendeePagination = {
        ...this.attendeePagination,
        totalItems: this.onlineAttendeeList.length
      };
      console.log('onlineAttendeeList :', res);
    })
  }

  getOnlineCourseLog() {
    this.fileService.getOnlineCourseLog().subscribe(res => {
      this.onlineCourseLogList = res
      this.logsPagination = {
        ...this.logsPagination,
        totalItems: this.onlineCourseLogList.length
      };
      console.log('onlineCourseLogList', res);
    })
  }

  export() {
    this.fileService.export().subscribe(
      res => {
        saveAs(res, 'OnlineCourse.xlsx')
      }, e => {
        console.log(e);
      }
    )
  }

  delete() {
    this.fileService.deleteOnlineCouse().toPromise()
      .then(res => {
        console.log('delete susses');
        this.alertModal(true, 'The file has been delete successfully')
        this.modalRef.hide();
      }).catch(err => {
      console.log('error', err.error.customMessage);

      this.alertModal(false, err.error.customMessage)
      this.modalRef.hide();
    })
    // .subscribe((res) => {
    //   console.log("delete susses");
    //   this.alertModal(true, "The file has been delete successfully")
    // })
  }

  onUploadClick() {
    this.isLoading = true;
    const data = this.fg.getRawValue();
    if (this.fg.controls.courseFile.invalid) {
      this.fg.controls.courseFile.setErrors({null: true});
    }
    if (this.file.courseFile) {
      this.fileService.postCourseFile(this.file.courseFile).subscribe(
        (r) => {
          console.log('rest --> save -->', r)
          const {status, progress} = r;
          switch (status) {
            case 'progress':
              this.setProgress(progress);
              break;
            case 'done':
              this.isLoading = false;
              if (r.progress.body) {
                const blob = new Blob([r.progress.body], {type: 'text/csv;charset=utf-8;'});
                let headers = r.progress.headers.get('content-disposition');
                if (headers) {
                  let fileName = headers.split('filename=')[1]
                  saveAs(blob, fileName);
                }
                r.progress.status === 200 ? this.alertModal(true, 'The file has been uploaded successfully') : this.alertModal(false, 'Upload failed, Please try again');
                this.setProgress();
              }
              break;
            default:
              break;
          }
        },
        (e) => {
          this.setProgress(0);
          console.error(e);
          this.isLoading = false;
          const msg = 'Upload failed, Please try again'

          this.alertModal(false, msg);
        }
      );
    } else {
      console.log('else file --> ', this.file)
      this.isLoading = false;
    }
  }

  alertModal(isSuccess: boolean, msg: string) {
    return Swal.fire({
      scrollbarPadding: false,
      text: msg,
      title: isSuccess ? 'Successfully' : 'Failed',
      icon: isSuccess ? 'success' : 'error',
      confirmButtonText: 'OK',
      focusCancel: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-info btn-lg mr-2 text-light',
      },
    }).then(result => {
      if (isSuccess) {
        this.ngOnInit();
      }
    });
  }

  setProgress(progress?: 'reset' | number) {
    this.file.uploadProgress = progress === 'reset' ? 0 : progress;
  }

  handlePagingOnline(event) {
    this.coursePagination.currentPage = event;
  }

  handlePagingAttendee(event) {
    this.attendeePagination.currentPage = event;
  }

  handlePagingLog(event) {
    this.logsPagination.currentPage = event;
  }

  onConfirmationModal(template: TemplateRef<any>, status) {
    if (status === 'delete') {
      this.confirmationWording =
        'Do you want to remove this online course and online attendee ?';
      this.modalRef = this.modalService.show(template, {class: 'modal-lg1'});
    }
  }
}
