import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import {Sort} from '../utils/sort.util';

@Directive({
  selector: '[appSort]',
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    '[style.cursor]': '"pointer"',
  }
})
export class SortDirective {

  @Input() appSort: Array<any>;


  constructor(private renderer: Renderer2, private targetElement: ElementRef) {
    setTimeout(() => {
      const elem = this.targetElement.nativeElement;
      const order = elem.getAttribute('data-order');
      this.setDirection(order === 'asc');
    }, 0)


  }

  @HostListener('click')
  sortData() {

    const sort = new Sort();

    const elem = this.targetElement.nativeElement;

    const order = elem.getAttribute('data-order');

    const type = elem.getAttribute('sort-type');

    const property = elem.getAttribute('data-name');


    if (order === 'desc') {
      this.setDirection(true);
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute('data-order', 'asc');
    } else {
      this.setDirection(false);
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute('data-order', 'desc');
    }

  }

  private setDirection(setUp: boolean) {
    const clear = this.targetElement.nativeElement.innerHTML.replace(' ▴', '').replace(' ▾', '');
    this.targetElement.nativeElement.innerHTML = clear + (setUp ? ' ▴' : ' ▾');
  }


}
