import { Component, OnInit } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment';
import { BsDatepickerConfig, mnLocale } from 'ngx-bootstrap';
import { filter, map } from 'rxjs/operators';
import { MedicalModel } from 'src/app/core/models/medical';
import { MedicalService } from 'src/app/core/services/medical.service';

@Component({
  selector: 'app-admin-medical',
  templateUrl: './admin-medical.component.html',
  styleUrls: ['./admin-medical.component.scss'],
})
export class AdminMedicalComponent implements OnInit {
  medicalList: MedicalModel[];
  page = "Admin"
  dateRang: DateRang;
  noData = true;
  listFilter = 'ALL';
  mediCalBufferOnEdit: MedicalModel;
  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-orange',
    dateInputFormat: 'DD MMM YYYY',
  };
  constructor(private medicalService: MedicalService) {}

  isMedicalDetail = false;
  ngOnInit(): void {
    this.initDate();
  }
  loading = true;
  initDate() {
    this.dateRang = {
      startDate: new Date(),
      endDate: new Date(),
    };
  }
  onClickDetail(data: MedicalModel) {
    this.mediCalBufferOnEdit = data;
    this.isMedicalDetail = true;
  }
  exportCSV() {
    const data = this.medicalList.map((d) => {
      return {
        EmployeeId: d.empID,
        Group: d.claimGroup.toUpperCase(),
        Name: d.nameEn,
        'Name(Thai)': d.nameTh,
        ReceiptNo: d.receiptNo,
        ReceiptDate: moment(d.receiptDate).format('DD-MM-YYYY'),
        RequestDate: moment(d.createDate).format('DD-MM-YYYY'),
        Amount: d.amount.toFixed(2),
        Hospital: d.hospital == 'Other' ? d.other : d.hospital,
      };
    });
    const { start, end } = this.convertDateToString(this.dateRang);

    const optionsJsonToEx = {
      filename: `medical-benefit-claim ${start}-${end}`,
      fieldSeparator: ',',
      quoteStrings: '',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(optionsJsonToEx);
    csvExporter.generateCsv(data);
  }
  dateLimit(): { minDate: Date; maxDate: Date } {
    return {
      minDate: this.dateRang.startDate,
      maxDate: this.dateRang.endDate,
    };
  }
  onDateChange(startDate?: Date, endDate?: Date) {
    if (startDate) {
      this.dateRang.startDate = startDate;
    }
    if (endDate) {
      this.dateRang.endDate = endDate;
    }

    // this.onDateChange(this.dateRang);
    this.onSearch(this.listFilter);
  }
  convertDate(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }
  onDone(event) {
    this.isMedicalDetail = false;
  }
  onChangeFilter() {
    this.onSearch(this.listFilter);
    // this.medicalList = this.medicalList.filter((m) => {
    //   if (this.listFilter == 'ALL') return true;
    //   else return m.status === this.listFilter;
    // });
  }
  convertDateToString(input: DateRang): { start: string; end: string } {
    const { startDate, endDate } = input;
    return {
      start: this.convertDate(startDate),
      end: this.convertDate(endDate),
    };
  }
  onSearch(filterOption?) {
    const { start, end } = this.convertDateToString(this.dateRang);

    this.medicalService
      .getList(start, end)
      .pipe(
        map((d) => {
          d = d.filter((d) => {
            if (!filterOption || filterOption == 'ALL') {
              return true;
            } else {
              return d.status == filterOption;
            }
          });
          return d;
        })
      )
      .toPromise()
      .then((resp) => {
        this.medicalList = resp;
        this.noData = false;
        this.loading = false;
      })
      .catch((e) => {
        this.medicalList = [];
        this.noData = true;
        this.loading = false;
      });
  }
}
interface DateRang {
  startDate: Date;
  endDate: Date;
}
