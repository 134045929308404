import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'textTruncate'
})
export class TextTruncatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const size = args[0] as number;
    if (!value) {
      return '';
    }
    if (value.length < size) {
      return value;
    }
    return value.slice(0, size).concat('...');
  }

}
