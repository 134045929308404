<table class="table">
  <thead class="custom-dark-bg text-white-smoke">
    <tr>
      <th scope="col">REQ ID</th>
      <th scope="col">REQUEST DATE</th>
      <th scope="col">COURSE</th>
      <th scope="col">TRAINING DATE</th>
      <th scope="col">AMOUNT</th>
      <th scope="col">STATUS</th>
      <th scope="col">PENDING AT</th>
      <th scope="col">REQUESTER</th>
      <th scope="col">PR ID</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of requestTrainingList">
      <td>{{ data.id }}</td>
      <td>{{ data.createDate | date }}</td>
      <td>
        <button
          (click)="newRequest(requestDetailComponent.template, data)"
          class="btn btn-link"
        >
          {{ data.course.courseDescr }}
        </button>
      </td>
      <td>{{ compareDateResult(data.startDate, data.endDate) }}</td>
      <td>{{ data.totalAmount }}</td>
      <td>{{ data.DisplayState }}</td>
      <td>
        {{
          data.approvalState === 'PENDING HR'
            ? userRoles[0]?.employee?.nameTh
            : data.pendingName
        }}
        <span
          style="cursor: pointer"
          *ngIf="data.approvalState === 'PENDING HR' && userRoles.length > 1"
          [tooltip]="popTemplate"
          placement="auto"
          >...</span
        >
      </td>
      <td>{{ data.employee?.nameTh }}</td>
      <td>{{ data.prId }}</td>
    </tr>
  </tbody>
</table>
<ng-template #popTemplate>
  <div [innerHtml]="tooltipHtml"></div>
</ng-template>
<app-request-detail
  #requestDetailComponent
  [modalRef]="modalRef"
  [openByHRAdmin]="true"
  [requestDetail]="requestDetail"
  (loadData)="emitEvent()"
>
</app-request-detail>
