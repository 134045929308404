import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { EmployeeService } from './employee.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Employee } from '../models/employee';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GuardService implements CanActivate {
  constructor(private employeeService: EmployeeService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if(state.url.includes('/administrative-strategic-plan')) {  
      let isAdminOfStrategicProject = false;
      return this.employeeService.me().pipe(
        switchMap((r: Employee) =>{
         if(r.deptRelation.deptID === '100046' || r.deptRelation.deptID === '100060' || r.deptRelation.deptID === '600029') {
        //  if(r.deptRelation.deptID === '100046') {
          isAdminOfStrategicProject = true
         } 
        //  else if(r.deptRelation.deptID === '100060' && !environment.production) {
        //   isAdminOfStrategicProject = true
        //  }
          return of(isAdminOfStrategicProject)
        }),
        catchError((e) => of(false))
      );
    } else {
      return this.employeeService.whoami().pipe(
        map((r) => true),
        catchError((e) => of(false))
      );
    }
  }
}
