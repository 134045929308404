import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learning-center-layout',
  template: `
    <app-layout>
      <router-outlet></router-outlet>
    </app-layout>
  `,
  styles: []
})
export class LearningCenterLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
