export const chartConfig = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: 'right',
    align: 'center',
    textDirection: 'ltr',
    labels: {
      usePointStyle: true,
      fontColor: '#3c3c3c',
    }
  },
  scales: {
    xAxes: [{
      gridLines: {
        drawOnChartArea: false
      }
    }],
    yAxes: [{
      gridLines: {
        drawOnChartArea: true
      }
    }]
  }
};
