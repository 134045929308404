import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { faIcon } from 'src/app/core/icon/fa.icon';
import { Employee } from 'src/app/core/models/employee';
import { TimesheetKeyInfo } from 'src/app/core/models/timesheetInfo';
import { EmployeeService } from 'src/app/core/services/employee.service';

@Component({
  selector: 'app-wrapper-outsource-timesheet',
  templateUrl: './wrapper-outsource-timesheet.component.html',
  styleUrls: ['./wrapper-outsource-timesheet.component.scss']
})
export class WrapperOutsourceTimesheetComponent implements OnInit {
  readonly ALLOW_ROLE = ['HRAdmin', 'UserAdmin', 'OutsourceAdmin'];
  display: 'list' | 'detail' = 'list'
  displayName: string;
  faIcon = faIcon
  isOutsourceAdmin = true
  isHr$: Observable<boolean> = of(false);
  emp$: Observable<Employee> = new Observable<Employee>(undefined);
  timesheetKeyInfo: TimesheetKeyInfo;
  constructor(
    private employeeService: EmployeeService
   ) {

  }



  ngOnInit(): void {
    this.emp$ = this.employeeService.me();
    this.isHr$ = this.emp$.pipe(
      map(e => e.userRoles_?.some(r => this.ALLOW_ROLE.includes(r.role))),
    )
  }

  setName(name) {
    this.displayName = name;
  }

  info(event: TimesheetKeyInfo) {
    this.display = 'detail'
    this.timesheetKeyInfo = event;
  }

  back() {
    this.display = 'list';
    this.displayName = undefined;
    this.timesheetKeyInfo = undefined;
  }


}
