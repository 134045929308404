<app-layout>
  <div class="container-fluid mt-4 pl-5 pr-5">
    <div class="row">
      <div class="col">
        <h2>
          <span> Parallel Position </span>
        </h2>
      </div>
    </div>
    <hr class="set" />
    <div class="row justify-content-between">
      <div class="col-sm-8">
        <div class="row">
          <div class="col-sm-8 input-group">
            <div class="col-sm-4">
              <select class="form-control" [(ngModel)]="selectedStatus" (change)="onChangeStatus(selectedStatus)">
                <option *ngFor="let status of searhStatus" [ngValue]="status">
                  {{ status }}
                </option>
              </select>
            </div>
            <div class="input-group-append">
              <input type="text" class="form-control" placeholder="Search" [(ngModel)]="keyword" />
            <div class="input-group-append">
              <button class="search" type="button" id="button-addon2" (click)="searchRequest()">
                <fa-icon [icon]="faIcon.faSearch"></fa-icon>
                Search
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-auto">
        <button type="button" class="btn btn-primary" style="width: 88px" (click)="addParallelPosition()">
          ADD
        </button>
      </div>
    </div>
    <table class="table table-hover" style="margin-top: 15px">
      <thead class="thead-pink">
        <tr style="text-align: center">
          <th scope="col" style="width: 2%">No.</th>
          <th scope="col" style="width: 28%">หน่วยงาน</th>
          <th scope="col" style="width: 28%">ตำแหน่ง</th>
          <th scope="col" style="width: 28%">ทดแทนพนักงาน</th>
          <th scope="col" style="width: 15%">สถานะ</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows | paginate: config; let i = index ">
          <th>{{ (config.currentPage -1)* config.itemsPerPage + i + 1 }}</th>
          <td *ngIf="row.id && enableEditIndex !== i">
            {{ row.departmentName }}
          </td>
          <td *ngIf="!row.id || (enableEditIndex === i && enableEdit)">
            <ng-select [items]="allDepartment"
                    bindValue="department"
                    bindLabel="deptName"
                    [ngModel]="row.departmentId"
                    (change)="onChangeDepartment($event,row)">
              </ng-select>
          </td>
          <th *ngIf="row.id && enableEditIndex !== i">
            {{ row.positionName }} ({{row.positionId}})
          </th>
          <th *ngIf="!row.id || (enableEditIndex === i && enableEdit)">
            <select class="form-control" [(ngModel)]="row.positionId" (change)="onChangePosition(row,i)">
              <option *ngFor="let position of allPosition" [ngValue]="position.positionCode">
                {{ position.positionName }} ({{position.positionCode}})
              </option>
            </select>
          </th>
          <th *ngIf="row.id && enableEditIndex !== i ">
            {{ row.employeeName }}
          </th>
          <th *ngIf="!row.id || (enableEditIndex === i && enableEdit)">
            <select class="form-control" [(ngModel)]="row.employeeId"  (change)="onEmployee(row, i)">
              <option *ngFor="let emp of employees[i]" [ngValue]="emp.empID">
                {{ emp.nameTh }}
              </option>
            </select>
          </th>
          <th *ngIf="row.id && enableEditIndex !== i || disableStatus">
            {{ row.status }}
          </th>
          <th *ngIf="(enableEditIndex === i && enableEdit)">
            <select class="form-control" [(ngModel)]="row.status" (change)="onStatus($event.target.value)">
              <option *ngFor="let status of allStatus" [ngValue]="status">
                {{ status }}
              </option>
            </select>
          </th>

          <td *ngIf="row.id && enableEditIndex !== i && !enableEdit">
            <fa-icon [icon]="faIcon.faEdit" class="text-c-set icon-size" (click)="editRow('edit', i, row)"></fa-icon>
        </td>
        <td *ngIf="row.id && enableEditIndex !== i && enableEdit === true">
            <fa-icon [icon]="faIcon.faEdit" class="text-c-gray icon-size"></fa-icon>
        </td>
        <td *ngIf="(enableEditIndex === i && enableEdit)">
            <fa-icon
                *ngIf="row.departmentId && row.positionId && row.employeeId && row.status"
                [icon]="faIcon.faSave" class="text-c-set icon-size" (click)="onConfirmationModal(template, 'update', row, i)">
            </fa-icon>
            <fa-icon
                *ngIf="!row.departmentId || !row.positionId || !row.employeeId"
                [icon]="faIcon.faSave" class="text-c-gray icon-size" disabled>
            </fa-icon>
        </td>
        <!-- SAVE -->
        <td *ngIf="!row.id">
            <fa-icon
                *ngIf="row.departmentId && row.positionId && row.employeeId && row.status"
                [icon]="faIcon.faSave" class="text-c-set icon-size" (click)="onConfirmationModal(template, 'save', row, i)">
            </fa-icon>
            <fa-icon
                *ngIf="!row.departmentId || !row.positionId || !row.employeeId"
                [icon]="faIcon.faSave" class="text-c-gray icon-size" disabled>
            </fa-icon>
        </td>
        <td>
          <fa-icon [icon]="faIcon.faTrashAlt" class="icon-size trash-btn-style"
            (click)="onConfirmationModal(template, 'delete', row, i)"></fa-icon>
        </td>
        </tr>
      </tbody>
      <tbody *ngIf="rows">
        <tr *ngIf="rows.length == 0 ">
          <td colspan="7" style="text-align: center">
            <h5>
              <b> No Data To Display </b>
            </h5>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="rows.length > 10" class="float-right">
      <pagination-controls class="pagination"
      [maxSize]="maxSize"
      [previousLabel]="labels.previousLabel"
      [nextLabel]="labels.nextLabel"
      (pageChange)="handlePaging($event)"></pagination-controls>
  </div>
  </div>
</app-layout>
<ng-template #template>
  <div class="modal-header thead-primary" style="background-color: #fab232">
    <h4 class="modal-title pull-left ml-3">Confirmation</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group ml-3">
      <div class="d-flex">
        <h5>{{ confirmationWording }}</h5>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex mt-2 justify-content-end">
      <button class="btn btn-success mr-2" (click)="confirm()">Confirm</button>
      <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>
