import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {RequestTraining} from '../models/request-training';
import {UserRole} from '../models/userRole';
import {requestTrainingSearch} from '../models/requestTrainingSearch';
import {shareReplay} from 'rxjs/operators';
import {RequestAttachFile} from '../models/request-attach-file';
import {SystemControl} from '../models/system-control';
import {Employee} from '../models/employee';
import {saveAs} from 'file-saver'

export interface IEmployeePipLine {
  0?: Employee,
  1?: Employee,
  2?: Employee,
  3?: Employee,
  4?: Employee,
}

export interface ApproveLog {
  id: number
  createBy: string
  updateBy: string
  createDate: Date
  updateDate: Date
  requestTraining?: number
  approverId: string
  pendingAt?: string//empid
  reason: any
  action: string
}

@Injectable({
  providedIn: 'root',
})
export class RequestTrainingService {
  constructor(private http: HttpClient) {
  }

  userRole: Observable<UserRole[]>;
  LearningItem = [
    {
      value: 'CLASS',
      descr: 'Class Room',
    },
    {
      value: 'BLEND',
      descr: 'Class Room & Online',
    },
    {
      value: 'ELEARN',
      descr: 'Online',
    },
  ];

  LearningCatalog = ['Fundamental', 'Leadership', 'Functional', 'Executive Program'];

  findAll(): Observable<RequestTraining[]> {
    return this.http.get<RequestTraining[]>(
      `${environment.api_endpoint}/requestTrainings`
    );
  }

  findAttachByRequestId(requestId): Observable<RequestAttachFile[]> {
    return this.http.get<RequestAttachFile[]>(
      `${environment.api_endpoint}/requestTraining/${requestId}/attach`
    );
  }

  findById(id): Observable<any> {
    return this.http.get(`${environment.api_endpoint}/requestTraining/${id}`);
  }

  save(
    body,
    actionType:
      | 'SAVE'
      | 'SUBMIT'
      | 'WITHDRAW'
      | 'APPROVED'
      | 'REJECTED'
      | 'CANCEL'
      | 'EDIT'
  ): Observable<any> {
    return this.http.post(`${environment.api_endpoint}/requestTraining`, body, {
      params: {actionType},
    });
  }

  update(
    id,
    body,
    actionType:
      | 'SAVE'
      | 'SUBMIT'
      | 'WITHDRAW'
      | 'APPROVED'
      | 'REJECTED'
      | 'CANCEL'
      | 'EDIT'
  ): Observable<any> {
    return this.http.put(
      `${environment.api_endpoint}/requestTraining/${id}`,
      body,
      {params: {actionType}}
    );
  }


  /**
   * @Deprecated
   */
  findHrApprover(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(
      `${environment.api_endpoint}/role/byrole/HRVP`
    );
  }

  findHRVPApprover(): Observable<Employee[]> {
    return this.http.get<Employee[]>(
      `${environment.api_endpoint}/pipe-line/hrvp`
    );
  }


  getHRAdmin(): Observable<any> {
    return this.http.get<any>(`${environment.api_endpoint}/role/byrole/HRAdmin`)
  }

  findPendingApprove(): Observable<RequestTraining[]> {
    return this.http.get<RequestTraining[]>(
      `${environment.api_endpoint}/requestTraining/pendingApproval`
    );
  }

  getApprovePipeLine(id): Observable<Employee[]> {
    return this.http.get<Employee[]>(`${environment.api_endpoint}/requestTraining/getApprovePipeline/${id}`);
  }

  getEmployee(id) {
    return this.http.get(`${environment.api_endpoint}/employee/empId/${id}`);
  }

  getUserRole(): Observable<UserRole[]> {
    if (!this.userRole) {
      this.userRole = this.getDoUserRole().pipe(shareReplay(1)) // caching
    }
    return this.userRole;
  }

  private getDoUserRole(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(`${environment.api_endpoint}/check-role`);
  }



  getApproveLog(id): Observable<ApproveLog[]> {
    return this.http.get<ApproveLog[]>(`${environment.api_endpoint}/approve/approveLog/request/${id}`);
  }

  searchRequestTraining(obj: requestTrainingSearch): Observable<any> {
    return this.http.post(
      `${environment.api_endpoint}/requestTraining/search`,
      obj
    );
  }

  searchRequestByKeyword(msg) {
    return this.http.get(
      `${environment.api_endpoint}/requestTraining/search-request?keyword=${msg}`
    );
  }

  sortByProperty(property, sortType) {
    return function(a, b) {
      switch (sortType) {
        case 'asc':
          if (a[property] > b[property]) {
            return 1;
          } else if (a[property] < b[property]) {
            return -1;
          }
          // return 0;
          break;
        case 'desc':
          if (a[property] > b[property]) {
            return -1;
          } else if (a[property] < b[property]) {
            return 1;
          }

          // return 0;
          break;
      }
      return 0;
    };
  }

  getCourse() {
    return this.http.get(`${environment.api_endpoint}/courses`);
  }

  checkExternalTraningIsActive(): Observable<SystemControl> {
    return this.http.get<SystemControl>(
      `${environment.api_endpoint}/public/check-service-is-active`
    );
  }

  downloadAttendeeTemplate(): Observable<any> {
    return this.http.get(`${environment.api_endpoint}/attendee-template`,
      {
        observe: 'response', responseType: 'blob' as 'json'
      }
    );
  }

  // checkExternalTraningIsActive(){
  //   return this.http.get(`${environment.api_endpoint}/public/check-service-is-active`);
  // }
}
