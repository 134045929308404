import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Vendor} from './vendor.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private readonly EP = environment.api_endpoint;

  constructor(private http: HttpClient) {
  }

  getToken(body: {
    displayName,
    email
  }): Observable<any> {
    return this.http.post(`${this.EP}/webex-token`, body, {responseType: 'text'});
  }

  listAttachment(uuid: string) {
    return this.http.get<string[]>(`${this.EP}/list-attachments/${uuid}`);
  }


  downloadAttachment(uuid: string, file: string): Observable<Blob> {
    return this.http.get(`${this.EP}/download/attach-pr/${uuid}/${file}`,
      {
        responseType: 'blob'
      })
  }

}
