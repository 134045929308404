<app-layout>
    <div class="container-fluid mt-4">
        <h4>
        <b>
            <a *ngIf="view === 'LIST'" >What's New?</a>
            <a *ngIf="view !== 'LIST'" (click)="onBack()" class="c-link" class="text-click-able">What's New?</a>     
            <span *ngIf="view == 'NEW' || view == 'EDIT'">
                <fa-icon [icon]="faIcon.faChevronRight" class="text-c-set ml-2"></fa-icon>
                <b> Post</b>
            </span>
            <span *ngIf="view == 'DETAIL'">
                <fa-icon [icon]="faIcon.faChevronRight" class="text-c-set ml-2"></fa-icon>
                <b> View</b>
            </span>
        </b>
        </h4>
        <hr class="set">
        <ng-container [ngSwitch]="view">
            <ng-container *ngSwitchCase="'LIST'">
            <div class="form-group row justify-content-between">  
                <div class="col-sm-9">
                    <div class="row">
                        <div class="col-sm-4 input-group">
                            <!-- <input type="text" class="form-control" placeholder="Search" (keyup.enter)="getWhatNewSearch()" [(ngModel)]="keyword"/> -->
                            <input type="text" class="form-control" [(ngModel)]="keyword" [typeahead]="allHashtag"
                                    typeaheadOptionField="name" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="6"
                                    [typeaheadMinLength]="0" (typeaheadOnSelect)="onSelectHashtag($event)" (keyup.enter)="getWhatNewSearch()" />

                            <div class="input-group-append">
                            <button class="btn  btn-info" type="button" id="button-addon2" (click)="getWhatNewSearch()">
                                <fa-icon [icon]="faIcon.faSearch"></fa-icon>
                            </button>
                            </div>
                        </div>
                        <div class="col-sm-auto">
                            <!-- <div class="custom-control custom-checkbox text-center ml-2 mt-2">
                                <input type="checkbox" class="custom-control-input"  [checked]="isOnlyPostedByMe"  (change)="onClickOnlyPostByMe()">
                                <label class="custom-control-label">Only Posted By Me</label>
                            </div> -->
                            <div class="ml-2 row align-items-center">
                                <input type="checkbox" class="form-control  col-sm-auto" style="width: 1.5rem; height: 1.5rem;" [(ngModel)]="isOnlyPostedByMe" (change)="getWhatNewSearch()" />
                                <label class="col-form-label col-sm-auto">Only Posted By Me</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-auto">
                    <button type="button" class="btn  btn-info" (click)="onNewPost()">POST</button>
                </div>
            </div>
            <table class="table" id="dataTable">
                <thead class="dt-head">
                    <tr>
                    <th width="15%" class="col-label">DATE</th>
                    <th width="55%" class="col-label">NEWS</th>
                    <th width="20%" class="col-label">POSTED BY</th>
                    <th width="10%" class="col-label"></th>
                    </tr>
                </thead>
                <tbody *ngIf="whatNewList">
                    <tr *ngFor="let p of whatNewListPerPage;  index as i">
                        <td>{{p.createDate ? (p.createDate | date:'dd MMM yyyy HH:mm') : '-'}}</td>
                        <td><a (click)="changeToDetailPage(p)" class="custom-link">{{p.title ? p.title : "-"}}</a></td>
                        <td>{{p.deptName ? p.deptName : '-'}}</td>
                        <td class="text-center">
                            <fa-icon *ngIf="checkEditPermission(p)" (click)="changeToEditPage(p)" [icon]="faIcon.faEdit" class="text-c-set"></fa-icon>
                        </td>
                    </tr>
                    <tr>
                    <td class="text-center" *ngIf="whatNewList.length == 0" colspan="9">
                        No data.
                    </td>
                    </tr>
                </tbody>
                </table>

            
            <pagination [totalItems]="whatNewList.length" [itemsPerPage]="paginationConfig.itemsPerPage" [(ngModel)]="currentPage"
                (pageChanged)="handlePaging($event)" >
            </pagination>
        </ng-container>
        <ng-container *ngSwitchCase="'NEW'">
            <app-what-new-form (onEmitData)="switchView($event)" [pageData]="currentPage" [whatNewDetail]="null"></app-what-new-form>
        </ng-container>
        <ng-container *ngSwitchCase="'EDIT'">
            <app-what-new-form (onEmitData)="switchView($event)" [pageData]="currentPage" [whatNewDetail]="whatNewDetail"></app-what-new-form>
        </ng-container>
        <ng-container *ngSwitchCase="'DETAIL'">
            <app-what-new-detail (onEmitData)="switchView($event)" [pageData]="currentPage" [whatNewDetail]="whatNewDetail"></app-what-new-detail>
        </ng-container>
    </ng-container>
    </div>
</app-layout>