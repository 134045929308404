import { Component, Input, OnInit } from '@angular/core';
import { StrategicProjectHistory } from 'src/app/core/models/strategic-project-history.model';
import { StrategicProjectTrackingService } from 'src/app/core/services/strategic-project-tracking.service';

@Component({
  selector: 'app-strategic-project-log',
  templateUrl: './strategic-project-log.component.html',
  styleUrls: ['./strategic-project-log.component.scss']
})
export class StrategicProjectLogComponent implements OnInit {
  @Input() strategicProjectId: number;

  histories: StrategicProjectHistory[] = [];

  constructor(private strategicProjectTrackingService: StrategicProjectTrackingService) { }

  ngOnInit(): void {
    console.log('fetchStrategicProjectHistory > ');
    this.fetchStrategicProjectHistory();
  }

  fetchStrategicProjectHistory() {
    this.strategicProjectTrackingService.getStrategicProjectHistoryById(this.strategicProjectId).subscribe(data => {
      console.log('fetchStrategicProjectHistory > ', data);
      this.histories = data;
    });
  }

}
