import {Component, OnInit} from '@angular/core';
import {BsModalService, BsModalRef} from 'ngx-bootstrap';
import {UserManagementService} from './../../core/services/user-management.service';
import {EmployeeService} from './../../core/services/employee.service';
import {faIcon} from '../../core/icon/fa.icon';
import {Employee} from 'src/app/core/models/employee';
import {UserRole} from 'src/app/core/models/userRole';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  constructor(
    private modalService: BsModalService,
    private userManage: UserManagementService,
    private employeeService: EmployeeService
  ) {
  }

  employeeList: Employee[];
  modalRef: BsModalRef;
  rowData: UserRole;
  faIcon = faIcon;
  roleList: UserRole[] = []
  employeeFiltered;

  ngOnInit(): void {
    this.loadList();
    this.loadRole();
  }

  loadList() {
    this.employeeService.employeeList().subscribe((resp) => {
      this.employeeList = resp;
    });
    this.employeeService.employeeFilter();
    this.employeeService.employeeFilterUqi.subscribe((x) => {
      this.employeeFiltered = x;
      // console.log('ggg');
    });
  }

  loadRole() {
    this.userManage.getList().toPromise().then((resp) => {
      this.roleList = resp;
    });
  }

  relaodEvent() {
    this.modalRef.hide();
    this.loadRole();
    this.employeeService.employeeFilter();
    this.employeeService.employeeFilterUqi.toPromise().then((x) => {
      this.employeeFiltered = x;
    });
  }

  openModal(template, currentData) {
    this.rowData = currentData;
    this.modalRef = this.modalService.show(template);
  }
}
