<form [formGroup]="fastFeeadbackForm">
    <div class="form-group row">
        <div class="col-sm-3">
            <div id="upload_button">
<!--                <img [src]="imageSrc" *ngIf="imageSrc" class="image-responsive" width="320" height="200">-->
<!--                <img src="assets/images/fast-feedback/no-image.png" *ngIf="!imageSrc" class="image-responsive"-->
<!--                    width="320" height="200">-->

              <img [src]="fastFeedbackService.getImgForExternalUser(fastFeedbackData?.image)"
                   class="image-responsive"
                   onerror="this.src ='assets/images/fast-feedback/no-image.png'"
                   width="320" height="200">
            </div>
        </div>
        <div class="col-sm-9">
            <div class="form-group row">
                <label class="col-sm-2 col-form-label">Description</label>
                <label class="col-sm-10 col-form-label" *ngIf="isActive" style="white-space: break-spaces;" [innerHTML]="fastFeedbackData?.description ? renderhtmlText(fastFeedbackData.description) : '-'"></label>
                <label class="col-sm-10 col-form-label" *ngIf="!isActive">-</label>
            </div>
            <div class="form-group row" *ngIf="fastFeedbackData?.feedbackType !== 'booking' && fastFeedbackData?.feedbackType !== 'survey'">
                <label class="col-sm-2 col-form-label">Click to Rate</label>
                <div class="col-sm-10">
                    <rating formControlName="rate" [max]="5" style="color:#fea501;font-size: 3.2rem; "
                            [readonly]="isReadOnly"
                    ></rating>
                </div>
            </div>

            <label class="" *ngIf="fastFeedbackData?.feedbackType === 'booking'">ทำรายการโดยคุณ {{empDetail.nameTh}}</label>

            <ng-container class="form-group row" *ngIf="itemsData.controls.length > 0" formArrayName="items">
              <div class="form-group row mt-2" *ngFor="let item of itemsData.controls; let i = index;" [formGroupName]="i">
                <label class="col-sm-2 col-form-label">
                  {{i === 0 ? 'Booking' : ''}}
                  <label class="text-danger" *ngIf="i === 0">*</label>
                </label>
                
                <input type="radio" class="form-control col-sm-auto ml-2 mt-1" name="selectedId" formControlName="selectedId" [value]="item.get('id').value" 
                (click)="onSelectItem(item)" 
                  style="width: 1.4rem; height: 1.4rem;"
                [class.text-danger]="fastFeeadbackForm.controls['bookingId'].hasError('null')"
                >
                <p class="col-sm-auto">{{item.get('itemName').value}}</p>
              </div>
            </ng-container>

          <ng-container *ngIf="answerListData.controls.length > 0" formArrayName="answers">
            <div class="form-group" *ngFor="let answer of answerListData.controls; let i = index;" [formGroupName]="i">
              <app-response-fast-feedback-questions [answer]="answer" [i]="i" ></app-response-fast-feedback-questions>
            </div>
          </ng-container>

          <div class="form-group" *ngIf="fastFeedbackData?.feedbackType !== 'booking' && fastFeedbackData?.feedbackType !== 'survey'">
            <label class="col-form-label">Comment</label>
            <div>
              <textarea class="form-control" formControlName="comment" [ngClass]="{'is-invalid': fastFeeadbackForm.controls['comment'].hasError('null')}" rows="4"></textarea>
            </div>
          </div>

          <label class="text-danger" *ngIf="this.isReadOnly">{{readOnlyWording}}</label>
          <label class="text-danger" *ngIf="!this.isActive">Sorry, this survey is not active now.</label>


          <div class="row justify-content-end mt-4 mb-4">
            <div class="col-6 col-md-auto">
              <button type="button" class="btn btn-secondary app-btn-style"
                      (click)="onShowConfirmationModal(template,'back')">BACK
              </button>
            </div>
            <div class="col-6 col-md-auto d-flex justify-content-end">
              <button type="button" class="app-btn-style" [ngClass]="isReadOnly || !isActive ?  'btn btn-secondary' : 'btn btn-primary'"
                      (click)="onShowConfirmationModal(template,'submit')"
                      [attr.disabled]="(fastFeeadbackForm.invalid  || isReadOnly || !isActive)?true:null">SUBMIT</button>
            </div>
          </div>
        </div>
    </div>
</form>

<ng-template #template>
  <div class="modal-header thead-primary" style="background-color: #fab232;">
    <h4 class="modal-title pull-left ml-3">Confirmation</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group ml-3">
      <div class="d-flex">
        <h5>{{ confirmationWording }}</h5>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex mt-2 justify-content-end">
      <button class="btn btn-success mr-2" (click)="confirm()">Confirm</button>
      <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>
