import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AdditionalInformation} from '../models/additionalInformation';
import {EmployeeAdditionalResponse} from '../models/employeeAdditionalResponse';
import {TokenService} from '../../modules/core/services/token.service';
import {Address} from '../../modules/additional-information/address';

@Injectable({
  providedIn: 'root'
})
export class AdditionalInformationService {

  selectedData

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
  ) { }

  save(data: AdditionalInformation): Observable<AdditionalInformation> {
    return this.http.post<AdditionalInformation>(`${environment.api_endpoint}/additional-information`, data);
  }

  delete(id) {
    return this.http.delete(`${environment.api_endpoint}/additional-information/${id}`);
  }

  update(id: string, data: AdditionalInformation): Observable<AdditionalInformation> {
    return this.http.put<AdditionalInformation>(`${environment.api_endpoint}/additional-information/${id}`, data);
  }

  findAll(activeOnly = false): Observable<AdditionalInformation[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `dna ${this.tokenService.getToken}`
    });
    if (this.tokenService.getToken) {
      return this.http.get<AdditionalInformation[]>(`${environment.api_endpoint}/additional-information`, {headers, params: {
          activeOnly: `${activeOnly}`
        }});
    } else {
      return this.http.get<AdditionalInformation[]>(`${environment.api_endpoint}/additional-information`, {
        params: {
          activeOnly: `${activeOnly}`
        }
      });
    }
  }

  findById(id: string): Observable<AdditionalInformation> {
    return this.http.get<AdditionalInformation>(`${environment.api_endpoint}/additional-information/${id}`);
  }

  findEmployeeDataByAdditionalId(id: string): Observable<EmployeeAdditionalResponse[]> {
    return this.http.get<EmployeeAdditionalResponse[]>(`${environment.api_endpoint}/additional-information/${id}/employees`);
  }

  uploadFile(id: string, fd: FormData) {
    return this.http.post<AdditionalInformation>(`${environment.api_endpoint}/additional-information/import/${id}`, fd);
  }

  findMyAdditionalData(id): Observable<EmployeeAdditionalResponse[]> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `dna ${this.tokenService.getToken}`
    });
    if (this.tokenService.getToken) {
      return this.http.get<EmployeeAdditionalResponse[]>(`${environment.api_endpoint}/additional-information/employees-data`, {headers: httpHeaders});
    } else {
      if (id) {
        return this.http.get<EmployeeAdditionalResponse[]>(`${environment.api_endpoint}/additional-information/employees-data`, {params: {id}});
      } else {
        return this.http.get<EmployeeAdditionalResponse[]>(`${environment.api_endpoint}/additional-information/employees-data`);
      }
    }
  }

  saveMyAdditionalData(body, id): Observable<EmployeeAdditionalResponse[]> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `dna ${this.tokenService.getToken}`
    });
    if (this.tokenService.getToken) {
      return this.http.post<EmployeeAdditionalResponse[]>(`${environment.api_endpoint}/additional-information/employees-data`, body, {headers: httpHeaders});
    } else {
      if (id) {
        return this.http.post<EmployeeAdditionalResponse[]>(`${environment.api_endpoint}/additional-information/employees-data`, body, {params: {id}});
      } else {
        return this.http.post<EmployeeAdditionalResponse[]>(`${environment.api_endpoint}/additional-information/employees-data`, body);
      }
    }
  }

  exportExcel(id: string) {
    return this.http.get(`${environment.api_endpoint}/additional-information/export/${id}`,
      {responseType: 'blob' });
  }

  getAddress(): Observable<Address[]> {
    return this.http.get<Address[]>('assets/address.json')
  }
}
