
import { DatePipe } from '@angular/common';
import { TrackFile } from './../../core/models/trackFile';
import { saveAs } from 'file-saver';
import { FileServiceService } from 'src/app/core/services/file-service.service';
import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { PagerModel } from 'ngx-bootstrap/pagination/models';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-track-file',
  templateUrl: './track-file.component.html',
  styleUrls: ['./track-file.component.scss']
})
export class TrackFileComponent implements OnInit {

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-orange',
    dateInputFormat: 'DD MMM YYYY',
  };
  onActionDate 
  dateStart: any = null
  dateEnd:any = null
  disableDate
  dateRange: DateRange
  trackFileList: TrackFile[] = []
  trackFilePerpage: TrackFile[] = []
  onDate:boolean = false

  public maxSize: number = 7;
  config: any;
  public labels: any = {
    previousLabel: '<',
    nextLabel: '>',
};
  // currentPage = 1;
  // paginationConfig: PagerModel = {
  //   itemsPerPage: 20,
  //   previousText: '<',
  //   nextText: '>',
  //   pageBtnClass: 'btn btn-warning',
  //   align: true,
  // };
  constructor(private fileService: FileServiceService, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.initDate();
    this.config = {
      itemsPerPage: 20,
      currentPage: 0,
      totalItems: this.trackFileList.length
    };
  }


  initDate() {
    this.dateRange = {
      startDate: new Date(),
      endDate: new Date(),
    };
    
  }
  dateLimit(): { minDate: Date; maxDate: Date } {
    return {
      minDate: this.dateRange.startDate,
      maxDate: this.dateRange.endDate,
    };
  }
  onListTrackFile(start, end){
    if(start&&end) {
      this.fileService.training(start,end).subscribe(res => {
      this.trackFileList = res
      this.trackFilePerpage = res
      console.log(this.trackFileList);
      // this.trackFileList = this.trackFilePerpage.slice(0, this.paginationConfig.itemsPerPage);
    })
    }
  }

  onDateChange(startDate?: Date, endDate?: Date) {
    
    if (startDate) {
      this.dateRange.startDate = startDate;
      if(endDate == null) {
        this.dateRange.endDate = startDate
      }
    }
    if (endDate) {
      this.dateRange.endDate = endDate;
      console.log(this.dateRange.startDate);
      this.dateStart = this.datepipe.transform(this.dateRange.startDate,"yyyy-MM-dd")
      this.dateEnd = this.datepipe.transform(this.dateRange.endDate,"yyyy-MM-dd 23:59:59")
      console.log("init Start Date",this.dateStart);
      console.log("init End Date",this.dateEnd);
      console.log("actionDate: ",this.onActionDate);
    }
    this.onListTrackFile(this.dateStart,this.dateEnd);
  }


  onExportFile() {
    if(this.dateRange.startDate) {
      let date = this.datepipe.transform(this.dateRange.startDate,"yyyyMMdd")
      console.log(date);
      this.fileService.exportFile(date).subscribe(
        res=>{
          saveAs(res,"compressed.zip")
        }, e =>{
          console.log(e);
        }
      )
    }
  }

  handlePaging(event) {
    this.config.currentPage = event;
  }

}

  interface DateRange {
    startDate: Date;
    endDate: Date;
  }