import {Component, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Employee} from '../../../core/models/employee';
import {TimesheetKeyInfo} from '../../../core/models/timesheetInfo';
import {EmployeeService} from '../../../core/services/employee.service';
import {map} from 'rxjs/operators';
import {faIcon} from '../../../core/icon/fa.icon';
import {ProjectDetail} from '../../../modules/outsource-time-sheet/core/services/project.service';

@Component({
  selector: 'app-admin-project-wrapper',
  templateUrl: './admin-project-wrapper.component.html',
  styleUrls: ['./admin-project-wrapper.component.scss']
})
export class AdminProjectWrapperComponent implements OnInit {

  readonly ALLOW_ROLE = ['HRAdmin', 'UserAdmin', 'OutsourceAdmin'];
  display: 'list' | 'new' = 'list'
  displayName: string;
  faIcon = faIcon
  isOutsourceAdmin = true
  isHr$: Observable<boolean> = of(false);
  emp$: Observable<Employee> = new Observable<Employee>(undefined);
  timesheetKeyInfo: TimesheetKeyInfo;
  projectSelected: ProjectDetail;

  constructor(
    private employeeService: EmployeeService
  ) {

  }


  ngOnInit(): void {
    this.emp$ = this.employeeService.me();
    this.isHr$ = this.emp$.pipe(
      map(e => e.userRoles_?.some(r => this.ALLOW_ROLE.includes(r.role))),
    )
  }

  setName(name) {
    this.displayName = name;
  }

  onNew(event) {
    this.display = 'new';
  }

  // info(event: TimesheetKeyInfo) {
  //   this.display = 'new'
  //   this.timesheetKeyInfo = event;
  // }

  back() {
    this.display = 'list';
    this.displayName = undefined;
    this.timesheetKeyInfo = undefined;
    this.projectSelected = undefined;
  }

  onReceivePj(pj: ProjectDetail) {
    this.projectSelected = pj;
    this.display = 'new';
  }


}
