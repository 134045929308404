import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { MedicalService } from 'src/app/core/services/medical.service';
import { ExportToCsv } from 'export-to-csv';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { MedicalModel } from '../../core/models/medical';
import { faIcon } from 'src/app/core/icon/fa.icon';
import { PdpService } from 'src/app/core/services/pdp.service';
import { switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { RequestTrainingService } from 'src/app/core/services/request-training.service';
import {EmployeeService} from '../../core/services/employee.service';
import {Employee} from '../../core/models/employee';
@Component({
  selector: 'app-medical-claim',
  templateUrl: './medical-claim.component.html',
  styleUrls: ['./medical-claim.component.scss'],
})
export class MedicalClaimComponent implements OnInit {
  constructor(
    private medical: MedicalService,
    private pdpaService: PdpService,
    private requestTraining: RequestTrainingService,
    private employeeService: EmployeeService
  ) {}

  currentUser: Employee
  isNewRequest = false;
  page = "User"
  medicalList: MedicalModel[] = [];
  noData = false;
  loading = true;
  mediCalBufferOnEdit: MedicalModel;

  dateRang: DateRang;
  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-orange',
    dateInputFormat: 'DD MMM YYYY',
  };
  faIcon = faIcon;
  currentYear = (new Date().getFullYear()).toString()
  startDate = new Date(this.currentYear + '-01-01')



  ngOnInit(): void {
    this.initDate();
    const token = sessionStorage.getItem('token')
    // console.log('token list --> ', token)
    if(token !== 'undefined') {
      this.page = 'mobile'
      this.loading = false
      this.onNewRequest()
    }
  }
  onDone(event) {
    if (event) {
      this.isNewRequest = false;
      this.initDate();
    }
  }

  // checkRole() {
  //   this.requestTraining
  //     .getUserRole()
  //     .toPromise()
  //     .then((u) => {
  //       const { role } = u;
  //       if (role == 'HRMedical') {
  //         this.deleteAble = true;
  //       }
  //     });
  // }
  async deleteItem(d: MedicalModel) {
    const { id, documentId } = d;
    const c = await this.confirmDelete();
    if (c.value) {
      this.medical
        .deleteById(id)
        .pipe(switchMap((d) => this.pdpaService.deleteDocumentByid(documentId)))
        .toPromise()
        .then((x) => {
          this.onSearch();
        });
    }
  }
  onEditRow(d: MedicalModel) {
    this.isNewRequest = true;
    this.mediCalBufferOnEdit = d;
  }

  onNewRequest() {
    this.isNewRequest = true;
    this.mediCalBufferOnEdit = undefined;
  }

  initDate() {
    this.dateRang = {
      startDate: this.startDate,
      endDate: new Date(),
    };
  }

  convertDate(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }
  exportCSV() {
    const data = this.medicalList.map((d) => {
      return {
        EmployeeId: d.empID,
        Group: d.claimGroup.toUpperCase(),
        Name: d.nameEn,
        'Name(Thai)': d.nameTh,
        ReceiptNo: d.receiptNo,
        ReceiptDate: moment(d.receiptDate).format('DD-MM-YYYY'),
        TreatmentDate: moment(d.treatmentDate).format('DD-MM-YYYY'),
        Amount: d.amount.toFixed(2),
        Hospital: d.hospital,
      };
    });
    const { start, end } = this.convertDateToString(this.dateRang);

    const optionsJsonToEx = {
      filename: `medical-benefit-claim ${start}-${end}`,
      fieldSeparator: ',',
      quoteStrings: '',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(optionsJsonToEx);
    csvExporter.generateCsv(data);
  }
  onSearch() {
    this.medicalList = [];
    const { start, end } = this.convertDateToString(this.dateRang);

    this.medical
      .getList(start, end)
      .toPromise()
      .then((resp) => {
        this.medicalList = resp;
        this.noData = false;
        this.loading = false;

        this.employeeService.me().toPromise()
          .then(s => this.currentUser = s)

      })
      .catch((e) => {
        this.medicalList = [];
        this.noData = true;
        this.loading = false;
      });
  }
  dateLimit(): { minDate: Date; maxDate: Date } {
    return {
      minDate: this.dateRang.startDate,
      maxDate: this.dateRang.endDate,
    };
  }
  onDateChange(startDate?: Date, endDate?: Date) {
    if (startDate) {
      this.dateRang.startDate = startDate;
    }
    if (endDate) {
      this.dateRang.endDate = endDate;
    }

    this.onSearch();
  }
  // disableDeleteButton({ status }) {
  //   //
  //   return status == 'REVIEWING' ? null : this.deleteAble ? null : true;
  // }
  confirmDelete() {
    return Swal.fire({
      scrollbarPadding: false,
      title: 'Are you sure?',
      text: 'Are you sure you want to remove this request?',
      icon: 'warning',
      showCancelButton: true,
      focusCancel: true,
      confirmButtonColor: '#b32942',
      cancelButtonColor: '#75b329',
      confirmButtonText: 'Yes, delete it!',
    });
  }
  convertDateToString(input: DateRang): { start: string; end: string } {
    const { startDate, endDate } = input;
    return {
      start: this.convertDate(startDate),
      end: this.convertDate(endDate),
    };
  }
}
interface DateRang {
  startDate: Date;
  endDate: Date;
}
