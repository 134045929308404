import {Component, OnInit} from '@angular/core';

import {faIcon} from 'src/app/core/icon/fa.icon';
import {EmployeeService} from 'src/app/core/services/employee.service';
import {Employee} from 'src/app/core/models/employee';
import {FastFeedbackService} from 'src/app/core/services/fast-feedback.service';
import {FastFeedback} from 'src/app/core/models/fast-feedback';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {PagerModel} from 'ngx-bootstrap/pagination/models';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';

@Component({
  selector: 'app-send-your-fast-feedback',
  templateUrl: './send-your-fast-feedback.component.html',
  styleUrls: ['./send-your-fast-feedback.component.scss'],
})

export class SendYourFastFeedbackComponent implements OnInit {
    faIcon = faIcon;
    selectedType: string = 'all';
    displayItem: string = 'list';
    fastFeedbackList: FastFeedback[];
    fastFeedbackDetailData: FastFeedback;
    fastFeedbackTitle: string = '';
    paginationConfig: PagerModel = {
      itemsPerPage: 10,
      previousText: 'ย้อนกลับ',
      nextText: 'ถัดไป',
      pageBtnClass: 'btn btn-warning',
      align: true,
    };
    currentPage = 1;
    fastFeedbackListPerPage: FastFeedback[] = [];
    isOwner: boolean = false;
    filterFastFeedbackList: FastFeedback[];
    selectedEmployee: Employee;
    id: number;
    allType = [
      {
        type: 'all',
        typeName: 'All'
      },
      {
        type: 'fast feedback',
        typeName: 'Fast Feedback'
      },
      {
        type: 'csi',
        typeName: 'CSI'
      },
      {
        type: 'vendor evaluation',
        typeName: 'Vender Evalutation'
      },
      {
        type: 'booking',
        typeName: 'Booking'
      }
    ]
    fastFeedbackId: number = 0;
    keyword: string
    constructor(
        private employeeService: EmployeeService,
        public fastFeedbackService: FastFeedbackService,
        private activateRoute: ActivatedRoute,
        private route: Router,
        private location: Location,
        private previousRouteService: PreviousRouteService,
      ) {
        const data = this.activateRoute.snapshot.params.detail;

        let {queryParams: {type}} = this.activateRoute.snapshot;
        if(type) {
          let typeData = type.replace("-", " ");
          this.selectedType = typeData;
        }

        if (data && data === 'vendor-evaluation') {
          this.selectedType = 'vendor evaluation';
        }

        if(data && !(data === 'vendor-evaluation' || data === 'all')) {
          let id = Number(data);
          if(id > 0) {
            this.id = id;
            this.displayItem = 'response';
          }
        }
      }

    ngOnInit(): void {
      // this.route.navigate(['/fast-feedback-send-feedback'])
      this.getUserDetail();
      this.getFastFeedbackList();
    }

    getFastFeedbackList() {
      this.fastFeedbackService.fastFeedBackList().toPromise()
        .then((res: FastFeedback[]) => {
          this.fastFeedbackList = res;
          this.filterFastFeedbackList = res;

          if (this.selectedType === 'vendor evaluation' || this.selectedType === 'fast feedback' || this.selectedType === 'csi' || this.selectedType === 'booking') {
            this.filterFastFeedbackList = this.fastFeedbackList.filter(item => { return item.feedbackType === this.selectedType });
          } else {
            this.filterFastFeedbackList = this.fastFeedbackList;
          }

          if(this.keyword !== '' && this.keyword !== undefined) {
            this.filterFastFeedbackList = this.filterFastFeedbackList.filter(item => item.title.toUpperCase().includes(this.keyword.toUpperCase()));
          }  

          this.fastFeedbackListPerPage = this.filterFastFeedbackList.slice(0, this.paginationConfig.itemsPerPage);
        })
        .catch(err => {
          console.log('err', err);
        })
    }

    search(){
      this.getFastFeedbackList()
    }
  
    getUserDetail() {
      this.employeeService.me().toPromise()
        .then(res => {
          this.selectedEmployee = res;
        })
        .catch(err => {
          console.log('err', err);
  
        })
    }

    onBack() {
      // if(this.previousRouteService.getPreviousUrl() === '/fast-feedback-send-feedback') {
        this.route.navigate(['/fast-feedback-send-feedback'])
    
        this.displayItem = 'list';
        this.currentPage = 1;
        this.selectedType = 'all'
        this.getFastFeedbackList();
      // } else {
      //   this.route.navigate(['/fast-feedback'])
      // }
    }
  
    switchDisplayItem(event: ({state: string, page: number})) {
      this.displayItem = event.state;
      this.currentPage = event.page ? event.page : 1;
      if (this.displayItem === 'list') {
        this.getFastFeedbackList();
      }
    }
  
    onReceiveTitle(event) {
      this.fastFeedbackTitle = event;
    }
  
    handleOnEditFastFeedback(data) {
      let checkPermission = false;
      this.isOwner = false;
  
      if (Array.isArray(data.whoCanSeeResult)) {
        if (data.whoCanSeeResult.some(item => item.targetId === this.selectedEmployee.empID)) {
          checkPermission = true;
          this.isOwner = true;
        }
      }
  
      if (data.createBy) {
        if (this.selectedEmployee.empID === data.createBy) {
          checkPermission = true;
          this.isOwner = true;
        }
      }
  
      if (checkPermission) {
        this.displayItem = 'edit';
        this.fastFeedbackTitle = data.title;
        this.fastFeedbackDetailData = data;
      }
    }
  
    checkPermission(data) {
      let checkPermission = false;
      if (Array.isArray(data.whoCanSeeResult)) {
        if (data.whoCanSeeResult.some(item => item.targetId === this.selectedEmployee.empID)) {
          checkPermission = true;
        }
      }
  
      if (data.createBy) {
        if (this.selectedEmployee.empID === data.createBy) {
          checkPermission = true;
        }
      }
  
      return checkPermission;
    }
  
    handleOnResponseFeedback(data) {
      if(this.checkResponsePermission(data)) {
        this.displayItem = 'response';
        this.fastFeedbackTitle = data.title;
        this.fastFeedbackDetailData = data;
      }
    }
    
    handleRouteToFastFeedback(data) {
      if(this.checkResponsePermission(data)) {
        this.route.navigate([`/fast-feedback-send-feedback/${data.id}`])
        this.id = data.id;
        this.displayItem = 'response';
      }
    }
  
    checkResponsePermission(data) {
      let checkPermission = false;
      if (Array.isArray(data.tags)) {
        if (data.tags.some(item => (item.targetId === this.selectedEmployee.empID && item.targetType === 'EMP') 
        || (
              (item.targetId === this.selectedEmployee?.deptRelation.deptID 
              || item.targetId === this.selectedEmployee?.deptRelation.department
              || item.targetId === this.selectedEmployee?.deptRelation.subDiv
              || item.targetId === this.selectedEmployee?.deptRelation.division
              || item.targetId === this.selectedEmployee?.deptRelation.company
              ) && item.targetType === 'DEPT'
            )
        ) || data.tags.some(item => (item.targetType.toUpperCase() === 'ALL'))) {
          checkPermission = true;
        }
      }
  
      if (Array.isArray(data.whoCanSeeResult)) {
        if (data.whoCanSeeResult.some(item => item.targetId === this.selectedEmployee.empID)) {
          checkPermission = true;
        }
      }
  
      if (data.createBy) {
        if (this.selectedEmployee.empID === data.createBy) {
          checkPermission = true;
        }
      }
  
      return checkPermission;
    }
  
    onChangeType() {
      this.currentPage = 1;
      if (this.selectedType === 'vendor evaluation' || this.selectedType === 'fast feedback' || this.selectedType === 'csi' || this.selectedType === 'booking') {
        this.filterFastFeedbackList = this.fastFeedbackList.filter(item => { return item.feedbackType === this.selectedType });
        let type = this.selectedType.replace(" ", "-");
        this.route.navigate([`/fast-feedback-send-feedback`], {queryParams: {type: type}});
      } else {
        this.filterFastFeedbackList = this.fastFeedbackList;
        this.route.navigate([`/fast-feedback-send-feedback`], {queryParams: {type: 'all'}});
      }
      this.fastFeedbackListPerPage = this.filterFastFeedbackList.slice(0, this.paginationConfig.itemsPerPage);
    }

    renderhtmlText(description: String) {
      let result = description ? description.replace(/\\n/g, "<br>").replace(/&/g, "&amp;") : "-";
      if(result.length > 100) {
          result = result.substring(0,100);
          result = result + "...";
      }

      return result;
    }
  
    handlePaging({ page, itemsPerPage }: PageChangedEvent) {
      const startItem = (page - 1) * itemsPerPage;
      const endItem = page * itemsPerPage;
      this.currentPage = page;
      this.fastFeedbackListPerPage = this.filterFastFeedbackList.slice(startItem, endItem);
    }
}