import {Component, OnInit} from '@angular/core';
import {ManpowerService} from '../../../core/services/manpower.service';
import * as dayjs from 'dayjs'
import * as buddhistEra from 'dayjs/plugin/buddhistEra'
import {DeptRelationLabel, ManpowerReport} from '../../../core/models/manpower-report';
import {BookType} from 'xlsx';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {EmployeeService} from '../../../core/services/employee.service';

dayjs.extend(buddhistEra)

@Component({
  selector: 'app-manpower-report',
  template: `
    <div class="container-fluid">
      <div class="row">
        <div class="col-1 ml-auto">
          <button
            class="btn btn-info btn-block"
            (click)="exportTableToExcel('data_table')"
          >Export</button>
        </div>
      </div>
      <div class="row mt-3" *ngIf="data && (data.length !== 0)">
        <div class="col table-responsive">
          <table class="table table-bordered" id="data_table">
            <thead>
            <tr class="bg-secondary text-white">
              <th>กำลังพลปัจจุบัน</th>
              <th></th>
              <th colspan="2"> Status </th>
              <th>จำนวน</th>
              <th>รวมกำลังพลทั้งหมด*</th>
            </tr>

            <tr>
              <td>{{getHeaderData('กำลังพลปัจจุบัน').amount}}</td>
              <td></td>
              <td>Process</td>
              <td>อัตราค้างรับ</td>
              <td>{{getHeaderData('อัตราค้างรับ').amount}}</td>
              <td>{{getHeaderData('อัตราค้างรับ').actual}}</td>
<!--              <td>Other</td>-->
<!--              <td>Scholarship</td>-->
<!--              <td>{{getHeaderData('Scholarship').amount}}</td>-->
<!--              <td>{{getHeaderData('Scholarship').actual}}</td>-->
            </tr>
<!--            <tr>-->
<!--              <td class="border-0"></td>-->
<!--              <td class="border-0"></td>-->
<!--              <td rowspan="2">Process</td>-->
<!--              <td>อัตราค้างรับ</td>-->
<!--              <td>{{getHeaderData('อัตราค้างรับ').amount}}</td>-->
<!--              <td>{{getHeaderData('อัตราค้างรับ').actual}}</td>-->
<!--            </tr>-->
            <tr>
              <td class="border-0"></td>
              <td class="border-0"></td>
              <td>Process</td>
              <td>Pool</td>
              <td>{{getHeaderData('Pool').amount}}</td>
              <td>{{getHeaderData('Pool').actual}}</td>
            </tr>

            <tr class="border-0">
              <td class="border-0" colspan="13"></td>
            </tr>

            <tr>
              <td class="border-0"></td>
              <td class="border-0"></td>
              <td colspan="2">อัตรากำลังที่ Board อนุมัติ</td>
              <td>{{getHeaderData('อัตรากำลังที่ Board อนุมัติ').amount}}</td>
              <td class="border-0"></td>
            </tr>
            <tr>
              <td class="border-0"></td>
              <td class="border-0"></td>
              <td colspan="2">Leave without pay</td>
              <td>{{getHeaderData('Leave without pay').amount}}</td>
              <td class="border-0"></td>
            </tr>
            <tr>
              <td class="border-0"></td>
              <td class="border-0"></td>
              <td colspan="2">Scholarship</td>
              <td>{{getHeaderData('Scholarship').amount}}</td>
              <td class="border-0"></td>
            </tr>

            <tr class="border-0">
              <td class="border-0" colspan="13"></td>
            </tr>

            <tr>
              <th colspan="13" style="background-color: #fbb034">สรุปอัตรากำลังพนักงาน ปี {{toDay.format('BBBB')}}</th>
            </tr>
            <tr>
              <th rowspan="2">ข้อมูล ณ {{toDay.date()}} {{months[toDay.month()]}} {{toDay.format('BBBB')}}</th>
<!--              <th rowspan="2">code</th>-->
              <th rowspan="2"><span>อัตรากำลัง</span><br><span>(อัตรา)</span></th>
              <th rowspan="2"><span>กำลังพล</span><br><span>(คน)</span></th>
              <th colspan="9">ค้างรับ</th>
              <th rowspan="2">หมายเหตุ</th>
            </tr>
            <tr>
              <th>จำนวน</th>
              <th>ตำแหน่ง</th>
              <th>New</th>
              <th>Recruiter Screening</th>
              <th>Business screening</th>
              <th>Interview</th>
              <th>Final</th>
              <th>Offer</th>
              <th>Hiring</th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let d of data"
                [class.bg-secondary]="d.divUp"
                [class.bg-c-gray]="d.groupUp"
                [class.text-white]="d.divUp"
                [class.bg-warning]="'TOTAL' === d.deptName"
            >
              <td [class.font-weight-bold]="d.groupUp || d.divUp"
                  [class.pl-4]="d.groupUp"
                  [class.pl-5]="!d.groupUp && !d.divUp"
              >{{d.deptName}}</td>
<!--              <td [class.font-weight-bold]="d.groupUp || d.divUp">{{d.deptId}}</td>-->
              <td [class.font-weight-bold]="d.groupUp || d.divUp" class="text-center">{{d.amount || ''}}</td>
              <td [class.font-weight-bold]="d.groupUp || d.divUp" class="text-center">{{d.actual || ''}}</td>
              <td [class.font-weight-bold]="d.groupUp || d.divUp" class="text-center">{{d.vacant || ''}}</td>
              <td [class.font-weight-bold]="d.groupUp || d.divUp">
                <ul>
                  <li *ngFor="let p of d.positions">
                    {{p}}
                  </li>
                </ul>
              </td>

              <td [class.font-weight-bold]="d.groupUp || d.divUp" class="text-center">{{d.fnew || ''}}</td>
              <td [class.font-weight-bold]="d.groupUp || d.divUp" class="text-center">{{d.recruiterScreening || ''}}</td>
              <td [class.font-weight-bold]="d.groupUp || d.divUp" class="text-center">{{d.businessScreening || ''}}</td>
              <td [class.font-weight-bold]="d.groupUp || d.divUp" class="text-center">{{d.interview || ''}}</td>
              <td [class.font-weight-bold]="d.groupUp || d.divUp" class="text-center">{{d.ffinal || ''}}</td>
              <td [class.font-weight-bold]="d.groupUp || d.divUp" class="text-center">{{d.offer || ''}}</td>
              <td [class.font-weight-bold]="d.groupUp || d.divUp" class="text-center">{{d.hiring || ''}}</td>

              <td [class.font-weight-bold]="d.groupUp || d.divUp" class="text-center"></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  `,
  styles: [`
    th {
      text-align: center;
      white-space: nowrap;
    }

    td {
      white-space: nowrap;
    }
  `]
})
export class ManpowerReportComponent implements OnInit {
  toDay = dayjs()
  months = new Array('มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม')
  data: ManpowerReport[] = []
  head: ManpowerReport[] = []

  constructor(
    private manpowerService: ManpowerService,
    private employeeService: EmployeeService
  ) {
  }

  async ngOnInit() {
    this.loadData()
  }

  loadData = async () => {
    const d = await this.manpowerService.getReport().toPromise()
    this.data = d.filter(it => it.detail)
    this.head = d.filter(it => !it.detail)
    this.data.forEach(it => {
      it.deptName = it.departmentName || it.groupName || it.divisionName
      it.deptId = it.department || it.group || it.division
    })
  }

  isEmpty = (s: string): boolean => {
      return s === null || s === undefined || s === ''
  }

  async exportTableToExcel(tableID = 'table_data', filename = 'export', type: BookType = 'xlsx') {
    const blob = await this.manpowerService.exportReport().toPromise()
    saveAs(blob, 'manpower-report.xlsx')
    // const data = document.getElementById(tableID);
    //
    // const file = XLSX.utils.table_to_book(data);
    //
    // XLSX.write(file, { bookType: type, bookSST: true, type: 'base64' });
    //
    // XLSX.writeFile(file, `${filename}.` + type);
  }

  getHeaderData = (name): ManpowerReport => {
      return this.head.find(it => it.groupName === name)
  }
}
