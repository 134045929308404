import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, pipe, ReplaySubject, Subject} from 'rxjs';

import {Employee} from '../models/employee';
import {switchMap, filter, shareReplay, map} from 'rxjs/operators';
import {UserManagementService} from './user-management.service';
import * as http from 'http';
import {TokenService} from '../../modules/core/services/token.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private employeeCache: Observable<Employee[]>;
  pure = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private userRoleService: UserManagementService,
    private tokenService: TokenService
  ) {
  }

  employeeFilterUqi = new Subject<Employee>();

  search(eid: string, ename: string) {
    return this.http.get(
      `${environment.api_endpoint}/employee/empId/${eid}?search=${ename}`
    );
  }

  searchx(eid: string): Observable<Employee[]> {
    return this.http.get<Employee[]>(
      `${environment.api_endpoint}/employee/empId/${eid}`
    );
  }

  employeeList(): Observable<Employee[]> {
    if (!this.employeeCache) {
      this.employeeCache = this.http.get<Employee[]>(`${environment.api_endpoint}/employees`).pipe(shareReplay(1))
    }
    return this.employeeCache;
  }

  whoami(): Observable<any> {
    return this.http.get<any>(`${environment.api_endpoint}/whoami`);
  }

  whoamiDNA(token: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `dna ${token}`
      })
    };
    return this.http.get<any>(`${environment.api_endpoint}/whoami`, httpOptions);
  }

  me(): Observable<Employee> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `dna ${this.tokenService.getToken}`
    });
    if (this.tokenService.getToken) {
      return this.http.get<Employee>(`${environment.api_endpoint}/me`, {headers: httpHeaders});
    } else {
      return this.http.get<Employee>(`${environment.api_endpoint}/me`);
    }
  }

  deepMyDept(deptId: string, empOnly = 'false'): Observable<Employee[]> {
    return this.http.get<Employee[]>(`${environment.api_endpoint}/deep-my-department/${deptId}`, {
      params: {
        empOnly
      }
    });
  }

  meDNA(token?: string): Observable<any> {

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `dna ${token}`
    });
    if (token) {
      return this.http.get<any>(`${environment.api_endpoint}/me`, {headers: httpHeaders});
    } else {
      return this.http.get<any>(`${environment.api_endpoint}/me`);
    }
  }

  employeeFilter() {
    this.http
      .get<Employee>(`${environment.api_endpoint}/employees`)
      .toPromise().then((respAllEmployee) => {
      this.employeeFilterUqi.next(respAllEmployee);
    });
  }

  employeeFilterType(type: 'Employee' | 'Outsource') {
    return this.http.get<Employee[]>(`${environment.api_endpoint}/employees/${type}`)
      .pipe(
        map(a => a.sort((ea, eb) => (ea.nameTh || '').localeCompare(eb.nameTh || '')))
      );
  }

  hrvp(): Observable<Employee> {
    return this.http.get<Employee>(`${environment.api_endpoint}/hrvp`);
  }

  findAllCommandChain(empId): Observable<Employee[]> {
    return this.http.get<Employee[]>(`${environment.api_endpoint}/chain-of-command/${empId}`);
  }
}
