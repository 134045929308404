<app-layout>

  <div class="container-fluid mt-4 pl-5 pr-5">
    <div>
      <h3 [routerLink]="['/outsource-timesheet']" class="clickable" style="display: inline">
        <b>Outsource Timesheet  {{ date ? ('- ' + (date | date: 'MMM yyyy') + ' ') : null}}</b>
      </h3>

      <h3 style="display: inline" *ngIf="displayName">
        <b>
          <fa-icon [icon]="faIcon.faChevronRight"></fa-icon>
        </b>
        <b> {{displayName}}</b>
      </h3>
    </div>
    <hr style="border-top: 1px solid #ffa553">

    <ng-container [ngSwitch]="display">
      <app-timesheet-list *ngSwitchCase="'list'" (clickList)="info($event)"></app-timesheet-list>
      <app-monthly-detail *ngSwitchCase="'detail'" (name)="setName($event)" [IsInternPage]="false"></app-monthly-detail>
    </ng-container>
  </div>


</app-layout>
