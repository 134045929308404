import {Component, OnInit, TemplateRef} from '@angular/core';

import {faIcon} from '../../core/icon/fa.icon';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {DeptAuthorization} from 'src/app/core/models/deptAuthorization';
import {DeptAuthorizationService} from 'src/app/core/services/dept-authorizations.service';
import {DepartmentServiceService} from 'src/app/core/services/department-service.service';
import {DeptRelation} from 'src/app/core/models/deptRelation';
import {EmployeeService} from 'src/app/core/services/employee.service';
import {Employee} from 'src/app/core/models/employee';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-dept-authorization',
  templateUrl: './admin-dept-authorization.component.html',
  styleUrls: ['./admin-dept-authorization.component.scss'],
})
export class AdminDeptAuthorizationComponent implements OnInit {

  actingLevel = [ 'M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M7' ]
  actingService = [ 'HR', 'FINANCE' ]

  faIcon = faIcon;
  modalRef: BsModalRef;
  deptAuthorizations: DeptAuthorization[] = [];
  departments: DeptRelation[] = [];
  activeDepartments: DeptRelation[] = [];
  costCenter: string[] = []
  employees: Employee[] = [];
  isValidData: boolean = false;
  confirmationWording: string = '';
  confirmationStatus: string = '';
  deleteId = 0;
  deleteIndex = null;
  isLoaded = false;

  constructor(
    private deptAuthorizationService: DeptAuthorizationService,
    private departmentService: DepartmentServiceService,
    private employeeService: EmployeeService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.getAllDepartment();
    this.getEmployees();
  }

  getAllDepartment() {
    this.departmentService
      .getAllDepartment()
      .toPromise()
      .then((res) => {
        this.departments = res;
        this.activeDepartments = this.departments.filter(
          (item) => item.isActive
        );
        this.costCenter = this.activeDepartments.map(it => it.costCenter)
          .filter(it => it)
          .sort()
        this.getAllDeptAuthorizations();
        console.log('departments', this.departments);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  getAllDeptAuthorizations() {
    this.deptAuthorizationService
      .getAllDeptAuthorizations()
      .toPromise()
      .then((res) => {
        this.deptAuthorizations = res;
        this.deptAuthorizations.forEach((item) => {
          item.deptName = this.departments.find(
            (dept) => dept.deptID === item.deptID
          ).deptName;
        });
        console.log('deptAuthorizations', this.deptAuthorizations);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  getEmployees() {
    this.employeeService
      .employeeList()
      .toPromise()
      .then((res) => {
        this.employees = res.filter(v => v.empType === 'Employee');
        this.isLoaded = true;
        console.log('employees', this.employees);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }

  addRow() {
    let deptRelation: DeptRelation = {
      deptID: '',
      deptName: '',
      division: '',
    };

    let emp: Employee = {
      id: 0,
      createBy: '',
      updateBy: '',
      createDate: '',
      updateDate: '',
      empID: '',
      nameEn: '',
      nameTh: '',
      email: '',
      mobile: '',
      deptId: '',
      position: '',
      emplType: '',
      empType: '',
      company: '',
      managerId: '',
      deptRelation: deptRelation,
      cluster: '',
      userRoles_: [],
      grade: '',
    };

    let newData: DeptAuthorization = {
      id: 0,
      department: '',
      deptID: '',
      employee: emp,
      level: 0,
    };
    this.deptAuthorizations.unshift(newData);
  }

  onChangeDept(item, deptAuthorization: DeptAuthorization) {
    let dept: DeptRelation = item.item;
    deptAuthorization.department = dept.department;
    deptAuthorization.deptID = dept.deptID;

    this.isValidData = (deptAuthorization.employee != null && deptAuthorization.employee.empID != "") && (deptAuthorization.deptID != null && deptAuthorization.deptID != "") ?  true : false;
  }

  onChangeName(item, deptAuthorization: DeptAuthorization) {
    let emp: Employee = item.item;
    deptAuthorization.employee = emp;

    this.isValidData = (deptAuthorization.employee != null && deptAuthorization.employee.empID != "") && (deptAuthorization.deptID != null && deptAuthorization.deptID != "") ?  true : false;
  }

  getDeptName(department) {
    if (department && this.departments) {
      const dept = this.departments.find((item) => item.deptID === department);
      return dept ? dept.deptName : '-';
    } else {
      return '';
    }
  }

  getName(empID) {
    if (empID && this.employees) {
      const emp = this.employees.find((item) => +item.empID === +empID);
      return emp ? emp.nameEn : '-';
    } else {
      return '';
    }
  }

  checkValidData(data: DeptAuthorization): boolean {
    if (!data.deptID || !data.employee) {
      return false;
    }

    return true;
  }

  confirm() {
    if (this.confirmationStatus === 'delete') {
      this.deleteRow(this.deleteId, this.deleteIndex);
    } else if (this.confirmationStatus === 'save') {
      this.saveRow();
    }
  }

  saveRow() {
    let body: DeptAuthorization = this.deptAuthorizations[0];
    console.log(JSON.stringify(body));
    this.deptAuthorizationService.insert(body).toPromise()
      .then(res => {
        this.modalRef.hide();
        this.alertModal(true, 'This dept authorization has been saved.');
        this.getAllDeptAuthorizations();
      })
      .catch(err => {
        this.alertModal(false, 'an error occurred while adding. please try again');
        this.modalRef.hide();
        console.log('err', err);
      })
  }

  deleteRow(deleteId: number, deleteIndex: number) {
    if (deleteId === 0) {
      this.deptAuthorizations.splice(deleteIndex, 1);
      this.alertModal(true, 'This dept authorization has been deleted.');
      this.modalRef.hide();
    } else {
      this.deptAuthorizationService.delete(deleteId).toPromise()
      .then( res => {
        this.modalRef.hide();
        this.alertModal(true, 'This dept authorization has been deleted.');
        this.getAllDeptAuthorizations();
      })
      .catch( err => {
        this.alertModal(false, 'an error occurred while deleting. please try again');
        this.modalRef.hide();
        console.log('err', err);
      })
    }
  }

  onShowConfirmationModal(template: TemplateRef<any>, status, id, index) {
    if (status === 'delete') {
      this.confirmationWording =
        'Do you want to remove this dept authorization ?';
      this.confirmationStatus = status;
      this.deleteId = id;
      this.deleteIndex = index;
      this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
    } else if (status === 'save') {
      this.confirmationWording = 'Do you want to add this dept authorization ?';
      this.confirmationStatus = status;
      this.modalRef = this.modalService.show(template, { class: 'modal-lg1' });
    }
  }

  alertModal(isSuccess: boolean, msg: string) {
    return Swal.fire({
      scrollbarPadding: false,
      text: msg,
      title: isSuccess ? "Successfully" : "Failed",
      icon: isSuccess ? "success" : "error",
      confirmButtonText: "OK",
      focusCancel: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-info btn-lg mr-2 text-light",
      },
    });
  }
}
