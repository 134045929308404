import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-response-fast-feedback-questions',
  template: `
    <form [formGroup]="answer">
      <label class="col-form-label"
        >{{ i + 1 }}. {{ answer.get('question').value }}
        <label *ngIf="answer.get('isRequire').value" class="text-danger"
          >*</label
        >
      </label>
      <div *ngIf="answer.get('type').value === 'TEXT'">
        <textarea
          class="form-control"
          formControlName="answer"
          rows="4"
          [class.is-invalid]="answer.get('answer').invalid"
        ></textarea>
      </div>
      <div class="col-sm-10" *ngIf="answer.get('type').value === 'CHOICE'">
        <div class="form-group row" *ngIf="possibleListData()?.length > 0">
          <div
            class="mb-2 row"
            [ngClass]="
              possible.data.toLowerCase() !== 'other'
                ? 'col-12 pl-4 pr-4 mr-4'
                : 'pl-4 pr-3'
            "
            *ngFor="let possible of possibleListData(); let j = index"
          >
            <input
              type="radio"
              class="form-control mt-2"
              id="{{ possible.data + j }}"
              formControlName="answer"
              [value]="possible.data"
              (click)="onAnswer(possible.data)"
              style="width: 1.4rem; height: 1.4rem;"
            />
            <label
              for="{{ possible.data + j }}"
              *ngIf="possible.data.toLowerCase() !== 'other'"
              class="pl-2 pr-0 pt-2 col-form-label"
              [class.text-danger]="answer.get('answer').invalid"
              >{{ possible.data }}</label
            >
          </div>
          <input
            type="text"
            class="form-control col-6 col-md-5 ml-2"
            *ngIf="findOtherChoice()"
            formControlName="otherAnswer"
            placeholder="Other"
            [class.is-invalid]="answer.get('otherAnswer').invalid"
          />
        </div>
      </div>
      <div *ngIf="answer.get('type').value === 'DROPDOWN'">
        <div class="form-group row" *ngIf="possibleListData()?.length > 0">
          <select class="form-control ml-4 col-sm-6" formControlName="answer"
            [class.text-danger]="answer.get('answer').invalid"
          >
            <option value=""></option>
            <option
              *ngFor="let possible of possibleListData()"
              [ngValue]="possible.data"
            >
              {{ possible.data }}
            </option>
          </select>
        </div>
      </div>
    </form>
  `,
  styles: [],
})
export class ResponseFastFeedbackQuestionsComponent implements OnInit {
  @Input() answer: FormGroup;
  @Input() i: number;

  constructor() {}

  ngOnInit(): void {}

  possibleListData() {
    return this.answer.get('possibleValue').value;
  }

  onAnswer(data: any) {
    if (data.toLowerCase() === 'other') {
      this.answer.get('otherAnswer').enable();
    } else {
      this.answer.patchValue({
        otherAnswer: '',
      });
      this.answer.get('otherAnswer').disable();
    }

    this.answer.patchValue({
      answer: data,
    });
  }

  findOtherChoice() {
    return this.answer
      .get('possibleValue')
      .value.some((item) => item.data.toLowerCase() === 'other');
  }
}
