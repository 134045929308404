<form action="" [formGroup]="optionForm">
  <div class="form-row align-items-center">
    <div class="col-md-auto my-1">
      <div class="form-check">
        <label class="form-check-label" for="date">
          Month
        </label>
      </div>
    </div>
    <div class="col-md-2 my-1">
      <select name="" formControlName="date" class="form-control" id="date">
        <option [ngValue]="d" *ngFor="let d of options.date">{{d.display}}</option>
      </select>
    </div>
    <div class="col-md-2 my-1 text-right">
      <div class="form-check">
        <label class="form-check-label" for="project">
          Project
        </label>
      </div>
    </div>
    <div class="col-md-6 my-2">
      <select name="" id="project" formControlName="project" class="form-control">
        <option value="">All project</option>
        <option [value]="p" *ngFor="let p of options.project$ | async">{{p}}</option>
      </select>
    </div>
  </div>
  <div class="form-row align-items-center">
    <div class="col-md-auto my-1">
      <div class="form-check">
        <label class="form-check-label" for="vendor">
          Vendor
        </label>
      </div>
    </div>
    <div class="col-md-2 my-2">
      <select name="" id="vendor" formControlName="vendor" class="form-control">
        <option value="">All Vendor</option>
        <option [value]="v" *ngFor="let v of options.vendor$ | async">{{v}}</option>
      </select>
    </div>
    <div class="col-md-2 my-1 text-right">
      <div class="form-check">
        <label class="form-check-label text-right" for="department">
          Department
        </label>
      </div>
    </div>
    <div class="col-md-6 my-2">
      <select name="" id="department" formControlName="department" class="form-control">
        <option value="">All Department</option>
        <option [value]="d.department" *ngFor="let d of options.department$ | async">{{d.deptName}}</option>
      </select>
    </div>
  </div>
  <div class="row">
    <table class="table">
      <colgroup>
        <col span="1" style="width: 5%;">
        <col span="1" style="width: 14%;">
        <col span="1" style="width: 18%;">
        <col span="1" style="width: 15%;">
        <col span="1" style="width: 10%;">
        <col span="1" style="width: 10%;">
        <col span="1" style="width: 10%;">
        <col span="1" style="width: 5%;">
      </colgroup>
      <thead class="thead-dark">
      <tr>
        <th scope="col">ID</th>
        <th scope="col">NAME</th>
        <th scope="col" [appSort]="timesheet" data-order="desc" data-name="projectName">PROJECT</th>
        <th scope="col" [appSort]="timesheet" data-order="desc" data-name="vendor">VENDOR</th>
        <th scope="col" [appSort]="timesheet" data-order="desc" data-name="manDay">MAN-DAYS</th>
        <th scope="col" [appSort]="timesheet" data-order="desc" data-name="status">STATUS</th>
        <th scope="col">LAST UPDATED</th>
        <th scope="col"></th>

      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let tc of timesheet">
        <th scope="row"><small>{{tc.employee.empID}}</small></th>
        <td>
          <a (click)="onClickList(tc)" class="clickable"><small>{{tc.employee.nameEn}}</small></a>
          <small style="margin-left: 3px">
            <span *ngIf="tc.foc" class="badge badge-info"
                  title="Free Of Charge">FOC</span></small>
        </td>
        <td><small>            {{tc.projectName}}</small></td>
        <td><small> {{tc.vendor}}</small></td>
        <td>{{tc.manDay}}</td>
        <td><small>{{tc.status}}</small></td>
        <td><small>{{ tc.updateDate ? (tc.updateDate | date: 'd MMM yyyy') : '-' }}</small></td>
        <td>
        </td>
      </tr>
      <tr>
        <td *ngIf="!timesheet || timesheet.length == 0" colspan="8" class="text-center" style="opacity: 0.6">
          <fa-icon [icon]="faIcon.faFolderOpen"></fa-icon>
          Empty list
        </td>
      </tr>
      <!--      <tr>-->
      <!--        <td colspan="8" class="text-center">-->
      <!--          <div class="spinner-border text-warning" role="status">-->
      <!--            <span class="sr-only">Loading...</span>-->
      <!--          </div>-->
      <!--        </td>-->
      <!--      </tr>-->
      <!--      <tr>-->
      <!--        <td colspan="5"></td>-->
      <!--        <td colspan="3" class="item-right text-right">-->
      <!--          <div>-->
      <!--            <button-->
      <!--              class="btn btn-danger btn-lg mr-3">-->
      <!--              REJECT ALL-->
      <!--            </button>-->
      <!--            <button-->
      <!--              class="btn btn-success btn-lg">-->
      <!--              APPROVE ALL-->
      <!--            </button>-->
      <!--          </div>-->
      <!--          <div *>-->
      <!--            <button-->
      <!--              class="btn btn-success btn-lg">-->
      <!--              REVIEW ALL-->
      <!--            </button>-->
      <!--          </div>-->
      <!--        </td>-->
      <!--      </tr>-->
      </tbody>
    </table>
  </div>

</form>
