import {Component, OnInit} from '@angular/core';
import {EmployeeService} from '../../../../core/services/employee.service';
import {UserManagementService} from '../../../../core/services/user-management.service';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-wrapper-outsource-report',
  templateUrl: './wrapper-outsource-report.component.html',
  styleUrls: ['./wrapper-outsource-report.component.scss']
})
export class WrapperOutsourceReportComponent implements OnInit {
  switch = 'notAllow'
  onLoad = true;
  private readonly allowGrade = ['M4', 'M5', 'M6', 'M7', 'AAA'];

  constructor(private empService: EmployeeService) {
  }

  async ngOnInit() {
    const allowAccess = await this.empService.me().pipe(map(e => {
      // return true;
      if (e.deptRelation.division === '100224' && e.grade && (e.grade === 'M4' || e.grade === 'M5' )) { // vp it
        return true;
      } else if ((e.grade && this.allowGrade.includes(e.grade))) { // grade
        return true;
      } else if (e.deptRelation.deptID === '100321') { // it procurement
        return true;
      }
      return false;
    })).toPromise();

    if (allowAccess) {
      this.switch = 'report'
      this.onLoad = false;
    } else {
      console.log('change')
      this.onLoad = false;
    }
  }
}
