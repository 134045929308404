<div *ngIf="havePermission === false" class="container-fluid mt-4 px-5">
  <div class="row">
    <div class="col text-center">
      <h1>NO PERMISSION</h1>
    </div>
  </div>
</div>
<div *ngIf="havePermission === true" class="container-fluid mt-4 px-5">
  <div class="row" [ngSwitch]="displayEl">
    <div class="col" *ngSwitchCase="'edit'">
      <h4><span
        (click)="onChangeDisplay(null)"
        style="cursor: pointer; color: #fbb034"
      ><u>Additional Information</u></span> > {{name}}</h4>
    </div>

    <div class="col" *ngSwitchDefault>
      <h4>Additional Information</h4>
    </div>
  </div>
  <hr style="border-top: 1px solid #fbb034">

  <ng-container [ngSwitch]="displayEl">
    <app-admin-additional-information-form *ngSwitchCase="'edit'" [id]="id" (showForm)="onChangeDisplay($event)" (name)="onSetName($event)" ></app-admin-additional-information-form>
    <app-admin-additional-information-list *ngSwitchDefault (showForm)="onChangeDisplay($event)"></app-admin-additional-information-list>
  </ng-container>

</div>


