<!--<div class="row">-->
<!--  <div class="col-sm-4">-->
<!--    -->
<!--  </div>-->
<!--  <div class="col-sm-8">-->
<!--   -->
<!--  </div>-->
<!--</div>-->
<div class="d-flex justify-content-start">
  <h5 class="mr-3 mt-2">Pending For Approval</h5>
  <div class="col-sm-4">
    <div class="form-inline">
      <input class="input-style form-control mr-1"
             [(ngModel)]="filterPendingAt"
             [typeahead]="managers"
             (typeaheadOnSelect)="onSelectFilter($event)"
             typeaheadOptionField="nameTh"
             [typeaheadScrollable]="true"
             [typeaheadOptionsInScrollableView]="5" [typeaheadMinLength]="0" required />
      <button *ngIf="filterPendingAt" (click)="clearFilter()" class="btn btn-outline-secondary my-2 my-sm-0" type="submit">clear</button>
    </div>
  </div>

</div>
<div class="body-element">

  <table class="table table-hover">
    <thead class="thead-pink">
    <tr>
      <th scope="col" style="width: 3%;">ID</th>
      <th scope="col" style="width: 25%;">Position</th>
      <th scope="col" style="width: 7%;">Quantity</th>
      <th scope="col" style="width: 10%;">Level</th>
      <th scope="col" style="width: 20%;  align-items: center; text-align:  center;">Status</th>
      <th scope="col" style="width: 18%;">Request</th>
      <th scope="col" style="width: 10%;">Request date</th>
      <th scope="col" style="width: 10%; white-space: nowrap">Pending at</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let manPower of manPowerListPerPage;index as i" (click)="handleOnViewRequisition(manPower)" style="cursor: pointer;">
      <td>{{ manPower?.id}}</td>
      <td>{{manPower?.positionName || manPower?.position?.positionName}}</td>
      <td>{{manPower?.amount | number : '1.0-0'}}</td>
      <td>{{manPower?.level}}</td>
      <td>
        <button *ngIf="manPower?.status === 'DRAFT'" class="bg-secondary status-container btn py-1 btn-man-power-status">
          {{manPower?.status?.toUpperCase()}}
        </button>
        <button *ngIf="manPower?.status === 'PENDING' || manPower?.status === 'PENDING_HR' || manPower?.status === 'PENDING_PRESIDENT'" class="bg-c-set status-container btn py-1 btn-man-power-status">
          {{manPower?.status?.toUpperCase()}}
        </button>
        <button *ngIf="manPower?.status === 'APPROVED'" class="bg-success status-container btn py-1 btn-man-power-status">
          {{manPower?.status?.toUpperCase()}}
        </button>
        <button *ngIf="manPower?.status === 'REJECTED'" class="bg-danger status-container btn py-1 btn-man-power-status">
          {{manPower?.status?.toUpperCase()}}
        </button>
      </td>
      <td>{{manPower?.requester?.nameTh}} </td>
      <td> {{ manPower?.requesterDate | date:'dd MMM yyyy'}}</td>
      <td style="white-space: nowrap"> {{ manPower?.pendingAt?.nameTh}}</td>
    </tr>
    <tr>
      <td *ngIf="manPowerList.length == 0" colspan="8" class="text-center">
        <h5>
          <b>
            No Data To Display
          </b>
        </h5>
      </td>

    </tr>
    </tbody>
  </table>
  <div *ngIf="manPowerList">
    <div *ngIf="manPowerList.length > paginationConfig.itemsPerPage">
      <pagination [totalItems]="manPowerList.length" [(ngModel)]="currentPage" [itemsPerPage]="paginationConfig.itemsPerPage"
                  (pageChanged)="handlePaging($event)">
      </pagination>
    </div>
  </div>
</div>
