<app-layout>
    <div class="container-fluid mt-4 pl-5 pr-5">
        <h4 class="mt-4">
            <b>
                <span class="mr-2">Import Strategic Project</span>
            </b>
        </h4>
        <hr class="set">

        <div style="width: 40vw;">
            <div class="form-group row">
                <div class="col-3 pr-0 d-flex align-items-center">
                    <h5>
                        Year
                    </h5>
                </div>
                <div class="col-sm-7">
                    <select class="form-control" [(ngModel)]="selectedYear"
                            (change)="onChangeYear($event.target.value)">
                            <option *ngFor="let year of allYear" [ngValue]="year">
                                {{year}}
                            </option>
                    </select>
                </div>

                <div class="col-3 pr-0 d-flex align-items-center mt-3">
                    <h5>
                        File
                    </h5>
                </div>
                <div class="col-sm-7 mt-3">
                    <input type="file" (change)="handleFileUpload($event.target.files)" accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" class="form-control" id="inputFile">
                </div>
                <div class="col-sm-2 mt-3">
                    <button class="btn btn-info" [disabled]="!isUploadable" (click)="onUploadClick()">UPLOAD</button>
                </div>
            </div>
        </div>
    </div>
</app-layout>