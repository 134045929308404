<div class="container-fluid mt-4 pl-5 pr-5">
  <div class="row mb-2">
    <h3>Online Course</h3>
  </div>
  <mat-tab-group class="custom-tab-group" animationDuration="500ms">
    <mat-tab label="Import">
      <form [formGroup]="fg">
        <div style="width: 40vw; margin-top: 20px"></div>
        <div class="form-group row ml-3">
          <div class="d-flex flex-column">
            <label style="font-size: 20px">Online Course File: </label>
            <a (click)="downloadTemplate()" class="clickable text-info"> Download Template</a>
          </div>
          <div class="col-sm-4">
            <input
              type="file"
              (change)="handleCourseUpload($event.target.files)"
              class="form-control required"
              formControlName="courseFile"
              [ngClass]="{
                  'is-invalid': fg.controls['courseFile'].hasError('null')
                }"
            />
          </div>
          <div class="col-sm-auto">
            <button
              class="btn btn-info"
              (click)="onUploadClick()"
              [disabled]="isLoading">
              UPLOAD
            </button>
          </div>
        </div>
        <ng-container *ngIf="file.uploadProgress > 0">
          <div class="form-group row">
            <div class="col-sm-10 ml-5">
              <progressbar
                [value]="file.uploadProgress"
                type="warning"
                class="mb-2"
                [striped]="true"
              >
                <!-- {{file.uploadProgress}}% -->
                Uploading
              </progressbar>
            </div>
            <div class="col-sm-2"></div>
          </div>
        </ng-container>
      </form>
      <div style="margin-top: 30px; ">
        <mat-tab-group class="custom">
          <mat-tab label="Online Course">
            <div class="row justify-content-end" style="margin-top: 10px;">
              <div class="col-sm-1">
                <button
                  class="btn btn-warning"
                  (click)="export()"
                >
                  Export
                </button>
              </div>
              <div class="col-sm-1">
                <button
                  class="btn btn-danger"
                  (click)="onConfirmationModal(template,'delete')"
                >
                  Delete
                </button>
              </div>
            </div>

            <table class="table table-hover" style="margin-top: 15px">
              <thead class="thead-pink">
              <tr style="text-align: center">
                <th scope="col">Column No.</th>
                <th scope="col">Course Code</th>
                <th scope="col">Course Title</th>
                <th scope="col">Credit Hours</th>
                <th scope="col">Last session</th>
                <th scope="col">Subject Area Id</th>
                <th scope="col">Survey</th>
                <th scope="col">Transfer Date</th>
                <th scope="col">Transfer Flag</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let course of onlineCourseList | paginate: coursePagination; index as i">
                <th>{{(i + 1) + ((coursePagination.currentPage) * coursePagination.itemsPerPage) }}</th>
                <th>{{course.courseCode}}</th>
                <th>{{course.courseTitle}}</th>
                <th style=" text-align: center;">{{course.creditHour}}</th>
                <th style=" text-align: center;">{{course.lastSession}}</th>
                <th style=" text-align: center;">{{course.subjectAreaId}}</th>
                <th style=" text-align: center;">{{course.survey}}</th>
                <th style=" text-align: center;">{{course.transferDate}}</th>
                <th style=" text-align: center;">{{course.transferFlag}}</th>
              </tr>
              </tbody>
            </table>
            <div class="float-right">
              <pagination-controls class="pagination"
                                   id="course_pagination"
                                   [maxSize]="pageMaxSize"
                                   [previousLabel]="labels.previousLabel"
                                   [nextLabel]="labels.nextLabel"
                                   (pageChange)="handlePagingOnline($event)"></pagination-controls>
            </div>
          </mat-tab>
          <mat-tab label="Online Attendee">
            <div class="row justify-content-end" style="margin-top: 10px;">
              <div class="col-sm-1">
                <button
                  class="btn btn-warning"
                  (click)="export()"
                >
                  Export
                </button>
              </div>
              <div class="col-sm-1">
                <button
                  class="btn btn-danger"
                  (click)="onConfirmationModal(template,'delete')"
                >
                  Delete
                </button>
              </div>
            </div>
            <table class="table table-hover" style="margin-top: 15px">
              <thead class="thead-pink">
              <tr style="text-align: center">
                <th scope="col">Column No.</th>
                <th scope="col">Course Code</th>
                <th scope="col">Completion Date</th>
                <th scope="col">Employee Id</th>
                <th scope="col">Transfer Date</th>
                <th scope="col">Transfer Flag</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let attendee of onlineAttendeeList | paginate: attendeePagination; index as i">
                <th>{{(i + 1) + ((attendeePagination.currentPage) * attendeePagination.itemsPerPage) }}</th>
                <th>{{attendee.courseCode}}</th>
                <th style=" text-align: center;">{{attendee.completionDate}}</th>
                <th style=" text-align: center;">{{attendee.emplId}}</th>
                <th style=" text-align: center;">{{attendee.transferDate}}</th>
                <th style=" text-align: center;">{{attendee.transferFlag}}</th>
              </tr>
              </tbody>
            </table>
            <div class="float-right">
              <pagination-controls class="pagination"
                                   id="attendee_pagination"
                                   [maxSize]="pageMaxSize"
                                   [previousLabel]="labels.previousLabel"
                                   [nextLabel]="labels.nextLabel"
                                   (pageChange)="handlePagingAttendee($event)"></pagination-controls>
            </div>
          </mat-tab>
          <mat-tab label="Logs">
            <table class="table table-hover" style="margin-top: 15px">
              <thead class="thead-pink">
              <tr style="text-align: center">
                <th scope="col">Action Date</th>
                <th scope="col">Online Course</th>
                <th scope="col">Online Attendee</th>
                <th scope="col">Action</th>
                <th scope="col">Action By</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let log of onlineCourseLogList  | paginate: logsPagination">
                <th scope="col" style="width: 15px;">{{log.actionDate | date:'dd/MM/yyyy hh:mm:ss'}}</th>
                <th scope="col" style="width: 15px; text-align: center;">{{log.onlineCourse}}</th>
                <th scope="col" style="width: 15px; text-align: center;">{{log.onlineAttendee}}</th>
                <th scope="col" style="width: 15px; text-align: center;">{{log.action}}</th>
                <th scope="col" style="width: 40px; text-align: center;">{{log.actionBy}} </th>
              </tr>
              </tbody>
            </table>
            <div class="float-right">
              <pagination-controls class="pagination"
                                   id="logs_pagination"
                                   [maxSize]="pageMaxSize"
                                   [previousLabel]="labels.previousLabel"
                                   [nextLabel]="labels.nextLabel"
                                   (pageChange)="handlePagingLog($event)"></pagination-controls>
            </div>

          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-tab>
    <mat-tab label="Export">
      <app-track-file></app-track-file>
    </mat-tab>
  </mat-tab-group>
</div>
<ng-template #template>
  <div class="modal-header thead-primary" style="background-color: #fab232">
    <h4 class="modal-title pull-left ml-3">Confirmation</h4>
    <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group ml-3">
      <div class="d-flex">
        <h5>{{ confirmationWording }}</h5>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-flex mt-2 justify-content-end">
      <button class="btn btn-success mr-2" (click)="delete()">Confirm</button>
      <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>
