<div class="row">
  <div class="col-md-2">
    <input type="text" [(ngModel)]="filter" placeholder="Filter" (ngModelChange)="doFilter()" class="form-control">
  </div>
  <div class="col-md-4"></div>
  <div class="col-md-6 text-right">
    <button class="btn btn-success mb-1" (click)="handleNew()">
      New Outsource
    </button>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <table class="table">
      <colgroup>
        <col span="1" style="width: 5%;">
        <col span="1" style="width: 10%;">
        <col span="1" style="width: 10%;">
        <col span="1" style="width: 15%;">
        <col span="1" style="width: 10%;">
        <col span="1" >
        <col span="1" style="width: 10%;">
        <col span="1" style="width: 5%;">
        <!--        <col span="1" style="width: 10%;">-->
        <!--        <col span="1" style="width: 5%;">-->
      </colgroup>
      <thead class="thead-dark">
      <tr>
        <th scope="col" [appSort]="projectListBackup" data-order="desc" data-name="empId">ID</th>
        <th scope="col" [appSort]="projectListBackup" data-order="desc" data-name="empName">NAME</th>
        <th scope="col">PO</th>
        <th scope="col" [appSort]="projectListBackup" data-order="desc" data-name="projectName">PROJECT</th>
        <th scope="col" [appSort]="projectListBackup" data-order="desc" data-name="vendor">VENDOR</th>
        <th scope="col" data-name="vendor">DATE</th>
        <th scope="col" [appSort]="projectListBackup" data-order="desc" data-name="type">TYPE</th>
        <th scope="col" [appSort]="projectListBackup" data-order="desc" data-name="active">STATUS</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let pr of projectListRender">
        <td><small>{{pr.empId}}</small></td>
        <td><small class="clickable" (click)="onClickProject(pr)">{{pr.empName}}</small></td>
        <td> <small [innerHTML]="pr.poList ? (pr.poList | map : 'po' | joinWith :'<br>') : '-'"></small></td>
        <td><small>{{pr.projectName}}</small></td>
        <td><small>{{pr.vendor}}</small></td>
        <td><small>{{pr.startDate | date: 'dd MMM yyyy'}}-{{pr.endDate | date: 'dd MMM yyyy'}}</small></td>
        <td><small>{{pr.type}}</small></td>
        <td><small class="badge"
                  [ngClass]="{
                  'badge-success': pr.active,
                  'badge-danger': !pr.active
                  }">{{pr.active ? 'Active' : 'Inactive'}}</small>
        </td>

      </tr>
      </tbody>
    </table>
  </div>
</div>
<app-loading-overlay [display]="load"></app-loading-overlay>
