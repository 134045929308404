import {Component, Input, OnInit} from '@angular/core';
import { log } from 'console';


export interface IApprovalState {
  approverName: string;
  approveDate: Date
  approved: boolean;
  rejectReason?: string;
}

@Component({
  selector: 'app-approval-state',
  templateUrl: './approval-state.component.html',
  styleUrls: ['./approval-state.component.scss']
})
export class ApprovalStateComponent implements OnInit {

  @Input()
  approvalState: IApprovalState[] = [];

  constructor() { }

  ngOnInit(): void { }

  get getLastApprovedIndex(): number {
    let idx = 0;
    const noApprove = !this.approvalState.some(a => a.approved)
    if (noApprove) {
      return -1;
    }
    for (let i = this.approvalState.length - 1; i >= 0; i--) {
      if (this.approvalState[i].approved) {
        idx = i;
        break;
      }
    }

    // if (idx < this.approvalState.length - 1) {
    //   idx++;
    // }
    return idx;
  }

  get getIsDisplayApprovalComment(): Boolean {
    let isDisplayApprovalComment = false;
    console.log("this.approvalState.length ===> ", this.approvalState.length)
    const noApprove = !this.approvalState.some(a => a.approved)
    if (noApprove) {
      return false;
    }
    
    for (let i = this.approvalState.length - 1; i >= 0; i--) {
      if (this.approvalState[i].approved && this.approvalState[i].rejectReason) {
        isDisplayApprovalComment = true;
        break;
      }
    }

    return isDisplayApprovalComment;
  }
}
