import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appNumberFormat]'
})
export class NumberFormatDirective {

  constructor(private e: ElementRef) { }

  @HostListener('focus')
  setInputFocus(): void {
    const v = this.e.nativeElement.value.replace(/,/g, '')
    if (v !== '') {
      this.e.nativeElement.value = +v
    }
  }

  @HostListener('blur')
  setInputFocusOut(): void {
    this.e.nativeElement.value = this.e.nativeElement.value.replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }

  triggerP = (e: ElementRef) => {
    if (e.nativeElement.value.indexOf('%') < 0) {
      e.nativeElement.value = e.nativeElement.value + ' %'
    }
  }

}
