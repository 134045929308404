import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'map'
})
export class MapPipe implements PipeTransform {

  transform(value: any[], ...args: string[]): any[] {
    if (!value) {
      return [];
    }
    const [key, ...rest] = args
    return value.map(va => va[key]);
  }

}
