import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import Swal from 'sweetalert2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { FastFeedbackService } from 'src/app/core/services/fast-feedback.service';
import { CreateResponseModel, FastFeedback } from 'src/app/core/models/fast-feedback';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { Employee } from 'src/app/core/models/employee';
import { TokenService } from 'src/app/modules/core/services/token.service';

@Component({
    selector: 'app-response-fast-feedback',
    templateUrl: './response-fast-feedback.component.html',
    styleUrls: ['./response-fast-feedback.component.scss'],
})
export class ResponseFastFeedbackComponent implements OnInit, OnChanges {
    modalRef: BsModalRef;
    @Output() onEmitData = new EventEmitter();
    fastFeedbackData: FastFeedback;

    @Output() emitTitle: EventEmitter<string> = new EventEmitter();
    imageSrc: string = '';
    confirmationWording: string = '';
    confirmationStatus: string = '';
    isReadOnly: boolean = false;
    isActive: boolean = true;
    isExternalUser: boolean = false;
    answers: FormArray;
    items: FormArray;
    readOnlyWording = "Thank you, you already gave your feedback today.";
    fastFeeadbackForm = this.fb.group({
        fastFeedbackId: [{ value: '', disabled: false }],
        rate: [{ value: 0, disabled: false }, [Validators.min(1), Validators.max(5)]],
        comment: [{ value: '', disabled: false }],
        bookingId: [{ value: 0, disabled: false }],
        volume: [{ value: 1, disabled: false }],
        answers: this.fb.array([]),
        items: this.fb.array([])
    });
    ratingData;
    createResponseBody: CreateResponseModel;
    empDetail: Employee;
    @Input('fastFeedbackData')
    set data(d: FastFeedback) {
        if (d) {
            this.fastFeedbackData = d;
            if (this.fastFeedbackData.effectiveDate && this.fastFeedbackData.expiryDate) {
                let startDate = new Date(this.fastFeedbackData.effectiveDate);
                let endDate = new Date(new Date(this.fastFeedbackData.expiryDate).setHours(23, 59, 59));
                if (startDate > new Date() || endDate < new Date()) {
                    this.isActive = false;
                }
            }

            const { id } = d;
            this.fastFeeadbackForm.patchValue({
                fastFeedbackId: id
            })

            this.answers = this.fastFeeadbackForm.get('answers') as FormArray;
            this.items = this.fastFeeadbackForm.get('items') as FormArray;

            if(Array.isArray(this.fastFeedbackData.questions)) {
                this.fastFeedbackData.questions.forEach(item => {
                    let possibleValue = this.fb.array([]);
                    if((item.type === 'CHOICE' || item.type === 'DROPDOWN') && Array.isArray(item.possibleValue)) {
                        item.possibleValue.forEach(data => {
                            possibleValue.push(this.fb.group({data}));
                        })
                    }

                    this.answers.push(this.fb.group({
                        answer: [{ value: '', disabled: this.isReadOnly }],
                        fastFeedbackResponseId: [{ value: 0, disabled: false }],
                        fastFeedbackQuestionId: [{ value: item.id, disabled: false }],
                        question: [{ value: item.question, disabled: this.isReadOnly }],
                        isRequire: [{ value: item.isRequire, disabled: this.isReadOnly }],
                        isActive: [{ value: item.isActive, disabled: this.isReadOnly }],
                        type: [{ value: item.type, disabled: this.isReadOnly }],
                        possibleValue: possibleValue,
                        otherAnswer: [{ value: '', disabled: true }]
                    }))
                })
            }

            if(this.fastFeedbackData.feedbackType === 'booking' || this.fastFeedbackData.feedbackType === 'survey') {
                this.fastFeeadbackForm.controls['rate'].setValidators(null);
                this.fastFeeadbackForm.controls['rate'].updateValueAndValidity();

                if (this.fastFeedbackData.feedbackType === 'booking') {
                    this.fastFeeadbackForm.controls['bookingId'].setValidators([Validators.required])
                    this.fastFeeadbackForm.controls['bookingId'].updateValueAndValidity();
                }

                this.employeeService.me().toPromise().then(res => {this.empDetail = res});
            }

            if(Array.isArray(this.fastFeedbackData.bookingItems)) {
                this.fastFeedbackData.bookingItems.forEach(item => {
                    this.items.push(this.fb.group({
                        id: [{ value: item.id, disabled: this.isReadOnly }],
                        itemName: [{ value: item.itemName, disabled: false }],
                        volume: [{ value: item.volume, disabled: false }],
                        selectedId: [{ value: 0, disabled: false }],
                    }))
                })
            }

            if(this.fastFeedbackData.allowOneResponse) {
                this.checkResponded();
            }
        }
    }

    @Input('id')
    set prepareDate(id: number) {
        if (id) {
            this.fastFeedbackService.getFastFeedBackById(id)
                .toPromise()
                .then((r: FastFeedback) => {
                    this.isExternalUser = true;
                    this.fastFeedbackData = r;
                    if (this.fastFeedbackData.effectiveDate && this.fastFeedbackData.expiryDate) {
                        let startDate = new Date(this.fastFeedbackData.effectiveDate);
                        let endDate = new Date(new Date(this.fastFeedbackData.expiryDate).setHours(23, 59, 59));
                        if (startDate > new Date() || endDate < new Date()) {
                            this.isActive = false;
                        }
                    }
                    const { id: fastFeedbackId, title } = r;
                    this.emitTitle.emit(title);
                    this.fastFeeadbackForm.patchValue({
                        fastFeedbackId
                    });

                    this.getFastFeedbackImage();
                    if(this.fastFeedbackData.feedbackType !== 'booking') {
                        this.isReadOnly = !!this.getLastResponse(id) && this.getLastResponse(id).toDateString() === new Date().toDateString();
                    }
                    // tslint:disable-next-line:no-unused-expression
                    this.isReadOnly && this.fastFeeadbackForm.disable();

                    // this.getFastFeedbackImage();

                    this.answers = this.fastFeeadbackForm.get('answers') as FormArray;
                    this.items = this.fastFeeadbackForm.get('items') as FormArray;

                    if(Array.isArray(this.fastFeedbackData.questions)) {
                        this.fastFeedbackData.questions.forEach(item => {
                            let possibleValue = this.fb.array([]);
                            if((item.type === 'CHOICE' || item.type === 'DROPDOWN') && Array.isArray(item.possibleValue)) {
                                item.possibleValue.forEach(data => {
                                    possibleValue.push(this.fb.group({data}));
                                })
                            }

                            this.answers.push(this.fb.group({
                                answer: [{ value: '', disabled: this.isReadOnly }],
                                fastFeedbackResponseId: [{ value: 0, disabled: false }],
                                fastFeedbackQuestionId: [{ value: item.id, disabled: false }],
                                question: [{ value: item.question, disabled: this.isReadOnly }],
                                isRequire: [{ value: item.isRequire, disabled: this.isReadOnly }],
                                isActive: [{ value: item.isActive, disabled: this.isReadOnly }],
                                type: [{ value: item.type, disabled: this.isReadOnly }],
                                possibleValue: possibleValue,
                                otherAnswer: [{ value: '', disabled: true }]
                            }))
                        })
                    }

                    if(this.fastFeedbackData.feedbackType === 'booking' || this.fastFeedbackData.feedbackType === 'survey') {
                        this.fastFeeadbackForm.controls["rate"].setValidators(null);
                        this.fastFeeadbackForm.controls["rate"].updateValueAndValidity();

                        if(this.fastFeedbackData.feedbackType === 'booking') {
                            this.fastFeeadbackForm.controls['bookingId'].setValidators([Validators.required])
                            this.fastFeeadbackForm.controls['bookingId'].updateValueAndValidity();
                        }

                        this.employeeService.me().toPromise().then(res => {this.empDetail = res});
                    }

                    if(Array.isArray(this.fastFeedbackData.bookingItems)) {
                        this.fastFeedbackData.bookingItems.forEach(item => {
                            this.items.push(this.fb.group({
                                id: [{ value: item.id, disabled: this.isReadOnly }],
                                itemName: [{ value: item.itemName, disabled: false }],
                                volume: [{ value: item.volume, disabled: false }],
                                selectedId: [{ value: 0, disabled: false }],
                            }))
                        })
                    }

                    if(this.fastFeedbackData.allowOneResponse) {
                        this.checkResponded();
                    }
                })
                .catch(err => {
                    this.getFastFeedBackDetail(id);
                })
        }
    }

    constructor(
        private fb: FormBuilder,
        public fastFeedbackService: FastFeedbackService,
        private modalService: BsModalService,
        private location: Location,
        private previousRouteService: PreviousRouteService,
        private router: Router,
        private employeeService: EmployeeService,
        private activatedRoute: ActivatedRoute,
        private tokenService: TokenService
    ) {
        let {queryParams: {code}} = this.activatedRoute.snapshot;
        console.log('code', code);
        if(code) {
            this.getToken();
        }
    }

    ngOnChanges() {
    }

    ngOnInit() {

    }

    getToken(): void {
        this.activatedRoute.queryParams.subscribe((d) => {
          let token = decodeURIComponent(d?.code);
          console.log('token', token);
          if (token && token !== 'undefined') {
            this.tokenService.setToken(token);
          } else {
            token = this.tokenService.getToken
          }
        });
      }

    onBack() {
        // if(this.previousRouteService.getPreviousUrl() === '/fast-feedback' || this.previousRouteService.getPreviousUrl() === '/fast-feedback-send-feedback') {
        //     this.location.back();
        // } else {
        //     this.router.navigate(['/fast-feedback'])
        // }

        this.router.navigate(['/fast-feedback-send-feedback'])
    }

    get fastFeeadbackFormGroup() {
        return this.fastFeeadbackForm.controls;
    }

    getFastFeedBackDetail(id) {
        this.fastFeedbackService.fastFeedBackList(id)
                .toPromise()
                .then((r: FastFeedback) => {
                    this.fastFeedbackData = r;
                    if (this.fastFeedbackData.effectiveDate && this.fastFeedbackData.expiryDate) {
                        let startDate = new Date(this.fastFeedbackData.effectiveDate);
                        let endDate = new Date(new Date(this.fastFeedbackData.expiryDate).setHours(23, 59, 59));
                        if (startDate > new Date() || endDate < new Date()) {
                            this.isActive = false;
                        }
                    }
                    const { id: fastFeedbackId, title } = r;
                    this.emitTitle.emit(title);
                    this.fastFeeadbackForm.patchValue({
                        fastFeedbackId
                    });

                    this.getFastFeedbackImage();
                    if(this.fastFeedbackData.feedbackType !== 'booking') {
                        this.isReadOnly = !!this.getLastResponse(id) && this.getLastResponse(id).toDateString() === new Date().toDateString();
                    }
                    // tslint:disable-next-line:no-unused-expression
                    this.isReadOnly && this.fastFeeadbackForm.disable();

                    // this.getFastFeedbackImage();


                    this.answers = this.fastFeeadbackForm.get('answers') as FormArray;
                    this.items = this.fastFeeadbackForm.get('items') as FormArray;

                    if(Array.isArray(this.fastFeedbackData.questions)) {
                        this.fastFeedbackData.questions.forEach(item => {
                            let possibleValue = this.fb.array([]);
                            if((item.type === 'CHOICE' || item.type === 'DROPDOWN') && Array.isArray(item.possibleValue)) {
                                item.possibleValue.forEach(data => {
                                    possibleValue.push(this.fb.group({data}));
                                })
                            }

                            this.answers.push(this.fb.group({
                                answer: [{ value: '', disabled: this.isReadOnly }],
                                fastFeedbackResponseId: [{ value: 0, disabled: false }],
                                fastFeedbackQuestionId: [{ value: item.id, disabled: false }],
                                question: [{ value: item.question, disabled: this.isReadOnly }],
                                isRequire: [{ value: item.isRequire, disabled: this.isReadOnly }],
                                isActive: [{ value: item.isActive, disabled: this.isReadOnly }],
                                type: [{ value: item.type, disabled: this.isReadOnly }],
                                possibleValue: possibleValue,
                                otherAnswer: [{ value: '', disabled: true }]
                            }))
                        })
                    }

                    if(this.fastFeedbackData.feedbackType === 'booking' || this.fastFeedbackData.feedbackType === 'survey') {
                        this.fastFeeadbackForm.controls["rate"].setValidators(null);
                        this.fastFeeadbackForm.controls["rate"].updateValueAndValidity();

                        if(this.fastFeedbackData.feedbackType === 'booking') {
                            this.fastFeeadbackForm.controls['bookingId'].setValidators([Validators.required])
                            this.fastFeeadbackForm.controls['bookingId'].updateValueAndValidity();
                        }

                        this.employeeService.me().toPromise().then(res => {this.empDetail = res});
                    }

                    if(Array.isArray(this.fastFeedbackData.bookingItems)) {
                        this.fastFeedbackData.bookingItems.forEach(item => {
                            this.items.push(this.fb.group({
                                id: [{ value: item.id, disabled: this.isReadOnly }],
                                itemName: [{ value: item.itemName, disabled: false }],
                                volume: [{ value: item.volume, disabled: false }],
                                selectedId: [{ value: 0, disabled: false }],
                            }))
                        })
                    }

                    if(this.fastFeedbackData.allowOneResponse) {
                        this.checkResponded();
                    }
                })
                .catch(err => {
                    this.isActive = false;
                })
    }

    get answerListData() {
        return <FormArray>this.fastFeeadbackForm.get('answers');
    }

    get itemsData() {
        return <FormArray>this.fastFeeadbackForm.get('items');
    }

    getFastFeedbackImage() {
        this.fastFeedbackService.getFastFeedbackImage(this.fastFeedbackData?.image).toPromise()
            .then(res => {
                let blob = new Blob([res.body], { type: 'image/jpeg' });
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = () => {
                    this.imageSrc = reader.result as string;
                };

            }).catch(err => {
                console.error(err);
            })
    }

    checkResponded() {
        this.fastFeedbackService.getResponded(this.fastFeedbackData?.id).toPromise()
            .then(res => {

            }).catch(err => {
                this.isReadOnly = true;
                this.fastFeeadbackForm.disable();
                this.readOnlyWording = "Sorry, you already responded to this survey.";
            })
    }

    onShowConfirmationModal(template: TemplateRef<any>, status) {
        this.confirmationStatus = status
        if (status.toUpperCase() === 'SUBMIT') {
            if (this.validateFastFeedbackFrom()) {
                this.confirmationWording = this.fastFeedbackData.allowOneResponse ? 'ขอขอบคุณสำหรับการสละเวลาในการทำแบบสอบถามนี้ ความคิดเห็นของท่านสำคัญต่อเรา เนื่องจากทางระบบไม่ได้เก็บชื่อผู้ตอบแบบสอบถาม กรุณาตรวจสอบความถูกต้องของข้อมูล หากกดปุ่ม “Confirm” แล้วจะไม่สามารถแก้ไขข้อมูลหรือทำแบบสอบถามใหม่ได้' : 'Do you want to save this response ?';
                this.modalRef = this.modalService.show(template, { class: 'modal-lg1' })
            }

        } else if (status.toUpperCase() === 'BACK') {
            this.confirmationWording = 'Do you want to go back ?';
            this.modalRef = this.modalService.show(template, { class: 'modal-lg1' })
        }
    }

    confirm() {
        this.modalRef.hide()
        if (this.confirmationStatus.toLocaleUpperCase() === 'SUBMIT') {
            this.onSaveResult();
        } else if (this.confirmationStatus.toLocaleUpperCase() === 'BACK') {
            // this.onEmitData.emit('list');
            // this.onEmitData.emit({state:'list', page: 1});
            // if(this.previousRouteService.getPreviousUrl() === '/fast-feedback' || this.previousRouteService.getPreviousUrl() === '/fast-feedback-send-feedback') {
            //     this.location.back();
            // } else {
            //     this.router.navigate(['/fast-feedback'])
            // }
            this.router.navigate(['/fast-feedback-send-feedback'])
        }
    }

    validateFastFeedbackFrom() {
        let valid: boolean = true;
        if(this.fastFeedbackData.feedbackType === 'booking') {
            if (!this.fastFeeadbackForm.controls.bookingId.value) this.fastFeeadbackForm.controls.bookingId.setErrors({ null: true });
        } else {
            if (this.fastFeeadbackForm.controls.rate.value < 1 && this.fastFeedbackData.feedbackType !== "survey") this.fastFeeadbackForm.controls.rate.setErrors({ null: true });
        }

        if(Array.isArray(this.fastFeedbackData.questions)) {
            this.answers.controls.forEach((item, index) => {
                if(item.get('isRequire').value) {
                    if(!item.get('answer').value) {
                        item.get('answer').setValidators(Validators.required)
                        item.get('answer').updateValueAndValidity();
                    } else {
                        item.get('answer').setValidators(null)
                        item.get('answer').updateValueAndValidity();

                        if(item.get('answer').value.toLowerCase() === 'other') {
                            if(!item.get('otherAnswer').value) {
                                item.get('otherAnswer').setValidators(Validators.required)
                                item.get('otherAnswer').updateValueAndValidity();
                            } else {
                                item.get('otherAnswer').setValidators(null)
                                item.get('otherAnswer').updateValueAndValidity();
                            }
                        } else {
                            item.get('otherAnswer').setValidators(null)
                            item.get('otherAnswer').updateValueAndValidity();
                        }
                    }
                }
            });
        }

        if (this.fastFeeadbackForm.controls.rate.status.toUpperCase() !== 'VALID' || this.fastFeeadbackForm.controls.answers.status.toUpperCase() !== 'VALID' ||
            this.fastFeeadbackForm.controls.bookingId.status.toUpperCase() !== 'VALID'
        ) {
            valid = false;
            this.alert(false, 'Please fill out the form completely')
        }

        if (this.fastFeedbackData.requiredComment) {
            let fastFeedbackData = this.fastFeeadbackForm.getRawValue();
            if (!fastFeedbackData.comment) {
                valid = false;
                this.alert(false, 'Please fill out the form completely')
                this.fastFeeadbackForm.controls.comment.setErrors({ null: true });
            } else {
                this.fastFeeadbackForm.controls.comment.setErrors(null);
            }
        }

        return valid;
    }

    async onSaveResult() {
        let answers = [];
        this.fastFeeadbackForm.disable();
        this.isReadOnly = true;

        if(Array.isArray(this.fastFeeadbackFormGroup.answers.value)) {
            this.fastFeeadbackFormGroup.answers.value.forEach(item => {
                    let answerDetail = item.answer ? item.answer : '';
                    let answerData = {
                        answer: answerDetail.toLowerCase() === 'other' &&  item.type.toLowerCase() === 'choice' ? item.otherAnswer : answerDetail,
                        fastFeedbackQuestionId: item.fastFeedbackQuestionId,
                        fastFeedbackResponseId: 0,
                    }
                    answers.push(answerData);
            });
        }

        this.createResponseBody = {
            fastFeedbackId: this.fastFeeadbackForm.get('fastFeedbackId').value,
            rate: this.fastFeedbackData.feedbackType === 'booking' || this.fastFeedbackData.feedbackType === 'survey'  ? null  : this.fastFeeadbackForm.get('rate').value,
            comment: this.fastFeeadbackForm.get('comment').value,
            bookingId: this.fastFeeadbackForm.get('bookingId').value,
            volume: this.fastFeedbackData.feedbackType === 'booking' ? this.fastFeeadbackForm.get('volume').value : null,
            answers: answers,
        }

        // console.log('createResponseBody 👑', this.createResponseBody);
        if(!this.isExternalUser || this.fastFeedbackData.feedbackType === 'booking') {
            try {
                const createResponseModelPromise = await this.fastFeedbackService
                    .submitResponse(this.createResponseBody)
                    .toPromise();
                if (createResponseModelPromise) {
                    localStorage.setItem(`last-response:${this.fastFeedbackData.id}`, (this.addDays(new Date(), 0)).toString())
                    this.alert(true, 'Your data has been submitted.')
                        .then(react => {
                            // tslint:disable-next-line:no-unused-expression
                            react.value
                            // react.value && this.onBack();
                        })
                        // if(this.fastFeedbackData.isAllowAnyone) {
                        //     this.router.navigate(['/fast-feedback-send-feedback'])
                        // }
                }

            } catch (e) {
                this.fastFeeadbackForm.enable();
                this.isReadOnly = false;
                if(e.error.message) {
                    this.alert(false, e.error.message)
                } else {
                    this.alert(false, 'Something went wrong please try again later.')
                    console.error(e);
                }
            }
        } else {
            try {
                const createResponseModelPromise = await this.fastFeedbackService
                    .submitResponseByExternalUser(this.createResponseBody)
                    .toPromise();
                if (createResponseModelPromise) {
                    localStorage.setItem(`last-response:${this.fastFeedbackData.id}`, (this.addDays(new Date(), 0)).toString())
                    this.alert(true, 'Your data has been submitted.')
                        .then(react => {
                            // tslint:disable-next-line:no-unused-expression
                            react.value;
                            // if(this.fastFeedbackData.isAllowAnyone) {
                            //     this.router.navigate(['/fast-feedback-send-feedback'])
                            // }
                        })
                }

            } catch (e) {
                this.fastFeeadbackForm.enable();
                this.isReadOnly = false;
                this.alert(false, 'Something went wrong please try again later.')
                console.error(e);
            }
        }
    }

    addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    getLastResponse(id: number): Date | null {
        const lastResponse = localStorage.getItem(`last-response:${id}`);
        return lastResponse && new Date(lastResponse);
    }

    possilbleListData (index) {
        let formDataAt = (<FormArray>this.answerListData).at(index);
        let possibleValueAt = <FormArray>formDataAt.get('possibleValue')
        // return <FormArray>formDataAt.get('possibleValue');
        return possibleValueAt.getRawValue();
    }

    findOtherChoice (index) {
        let formDataAt = (<FormArray>this.answerListData).at(index);
        let possibleValueAt = <FormArray>formDataAt.get('possibleValue')

        return possibleValueAt.getRawValue().some(item => item.data.toLowerCase() === 'other');
    }

    onAnswer(index, data) {
        let formDataAt = (<FormArray>this.answerListData).at(index);

        if(data.toLowerCase() === 'other') {
            formDataAt.get('otherAnswer').enable();
        } else {
            formDataAt.patchValue({
                otherAnswer: '',
            })
            formDataAt.get('otherAnswer').disable();
        }

        formDataAt.patchValue({
            answer: data,
        })
    }

    onSelectItem(data) {
        this.fastFeeadbackForm.patchValue({
            bookingId: data.value.id,
        })
    }

    renderhtmlText(description: String) {
        return description.replace(/\\n/g, "<br>").replace(/&/g, "&amp;");
    }

    alert(isSuccess: boolean, msg: string) {
        return Swal.fire({
            scrollbarPadding: false,
            text: msg,
            title: isSuccess ? "Successfully" : "Failed",
            icon: isSuccess ? "success" : "error",
            confirmButtonText: "OK",
            focusCancel: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-info btn-lg mr-2 text-light",
            },
        });
    }
}
