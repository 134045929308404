<ng-container>
  <form action="" [formGroup]="optionForm">
    <div class="form-row align-items-center">
      <div class="col-md-auto my-1">
        <div class="form-check">
          <label class="form-check-label" for="date">
            Month
          </label>
        </div>
      </div>
      <div class="col-md-2 my-1">
        <select name="" class="form-control" formControlName="date" id="date">
          <option [ngValue]="d" *ngFor="let d of options.date">{{d.display}}</option>
        </select>
      </div>
      <div class="col-md-auto my-1">
        <div class="form-check">
          <label class="form-check-label" for="project">
            Department
          </label>
        </div>
      </div>
      <div class="col-md-2 my-2">
        <select name="" id="project" formControlName="department" class="form-control">
          <option value="all">All Departments</option>
          <option [ngValue]="p?.department" *ngFor="let p of options.department$ | async">{{p?.deptName}}</option>
        </select>
      </div>
      <div class="col-md-auto my-1">
        <div class="form-check">
          <label class="form-check-label" for="vendor">
            Status
          </label>
        </div>
      </div>
      <div class="col-md-2 my-2">
        <select name="" id="vendor" formControlName="status" class="form-control text-capitalize">
          <option value="all">All Status</option>
          <option [value]="st" *ngFor="let st of options.status">{{st.toLowerCase()}}</option>
        </select>
      </div>
      <div class="col-md-2">
        <button class="btn btn-sm btn-outline-success btn-excel" *ngIf="(timesheetList$ | async)?.length"
                (click)="exportExcel()">
          Export
          <img src="assets/icon/excel.svg" class="exelIcon " alt="">
        </button>
      </div>
    </div>
  </form>


</ng-container>
<div style="width: 100%; overflow-x: scroll">
  <table class="table" >
    <colgroup>
      <col span="1" style="width: 4.5%;">
      <col span="1" style="width: 15%;">
      <col span="1" style="width: auto;">
      <col span="1" style="width: 15%;">
      <col span="1" style="width: 5%;">
      <col span="1" style="width: 7%;">
      <col span="1" style="width: 7%;">
      <col span="1" style="width: 10%;">
      <col span="1" style="width: 10%;">
      <col span="1" style="width: 7%;">
    </colgroup>
    <thead class="thead-dark">
    <tr>
      <th scope="col">ID</th>
      <th scope="col">NAME</th>
      <th scope="col">DEPARTMENT</th>
      <th scope="col">SUPERVISOR</th>
      <th scope="col">MAN-DAYS</th>
      <th scope="col">AMOUNT</th>
      <th scope="col">STATUS</th>
      <th scope="col">LAST UPDATED</th>
      <th scope="col">PR NO.</th>
      <th scope="col">VENDOR ID</th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let t of timesheetList$ | async">
      <th scope="row">{{t.employee.empID}}</th>

      <!--      [routerLink]="['/intern-timesheet/',t.employee.empID,this.dateSelected.value.month,this.dateSelected.value.year]"-->
      <!--      [queryParams]="{ projectName:t.projectName}"-->
      <td>
        <small><a (click)="onClickList(t)" class="clickable"
        >{{t.employee.nameEn}}</a></small>
      </td>
      <td><small class="text-truncate">{{t.employee.deptRelation.deptName}}</small></td>
      <td><small>{{t.reviewer.nameEn}}</small></td>
      <td>{{t.manDay}}</td>
      <td>{{t.amount | number : '1.2-2'}}</td>
      <td><small>{{t.status}}</small></td>
      <td>{{t.updateDate | date: 'd MMM yyyy'}}</td>
      <td>{{t?.prNumber || '-'}}</td>
      <td *ngIf="t.vendorId">{{t.vendorId}}</td>
      <td *ngIf="!t.vendorId"><b class="redbold">-</b></td>
    </tr>
    <tr *ngIf="(timesheetList$ | async)?.length">
      <td colspan="10" class="text-right">
        <b>Total Amount {{sumAmount(timesheetList$ | async) | number : '1.2-2'}}</b>
      </td>
    </tr>
    <tr *ngIf="showSubmitButton$ | async">
      <td colspan="6"></td>
      <td colspan="4" class="item-right text-right">
        <div>
          <button (click)="submitPayment()" [attr.disabled]="onLoad? '': null"
                  class="btn btn-success btn-lg text-uppercase" *ngIf="showSubmitButton$ | async">
            Submit for payment
          </button>
        </div>
      </td>
    </tr>
    <tr *ngIf="!(timesheetList$ | async)?.length">
      <td colspan="10" class="text-center text-secondary" style="opacity: 0.6">
        <fa-icon [icon]="faIcon.faFolderOpen"></fa-icon>
        Empty list
      </td>
    </tr>
    </tbody>
    <app-loading-overlay [display]="onLoad"></app-loading-overlay>
  </table>
</div>

