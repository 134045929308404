/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeptRelation } from './deptRelation';
import { Employee } from './employee';
import { Position } from './position';
import { RequestManpowerLog } from './requestManpowerLog';

export interface RequestManpower {
    amount?: number;
    createBy?: string;
    createDate?: Date;
    department?: DeptRelation;
    detail?: string;
    englishSkill?: string;
    id?: number;
    level?: string;
    pendingAt?: Employee;
    position?: Position;
    positionName?: string
    qualification?: string;
    reason?: string;
    replaceName?: string;
    requester?: Employee;
    requesterDate?: Date;
    responsibility?: string;
    status?: RequestManpower.StatusEnum;
    updateBy?: string;
    updateDate?: Date;
    reject?: any;
    extraName?: any;
    requestManpowerLogs: Array<RequestManpowerLog>;
}
export namespace RequestManpower {
    export type StatusEnum = 'DRAFT' | 'PENDING' | 'PENDING_HR' | 'APPROVED' | 'REJECTED' | 'PENDING_PRESIDENT';
  export const StatusEnum = {
    DRAFT: 'DRAFT' as StatusEnum,
    PENDING: 'PENDING' as StatusEnum,
    PENDING_HR: 'PENDING_HR' as StatusEnum,
    APPROVED: 'APPROVED' as StatusEnum,
    REJECTED: 'REJECTED' as StatusEnum,
    PENDING_PRESIDENT: 'PENDING_PRESIDENT' as StatusEnum
  };
}
