<app-layout>
  <div class="container-fluid mt-4 pl-5 pr-5">
    <div>
      <h3 class="clickable" style="display: inline; ">
        <b>Outsource Summary Report </b>
      </h3>

      <h3 style="display: inline">
        <!--        <b>-->
        <!--          <fa-icon [icon]="faIcon.faChevronRight"></fa-icon>-->
        <!--        </b>-->
        <!--        <b> {{displayName}}</b>-->
      </h3>
    </div>
    <hr style="border-top: 1px solid #ffa553">
<!--    <form class="form-inline" [formGroup]="optionForm">-->
<!--      <label for="reportType" class="m-2">Report</label>-->
<!--      <select id="reportType" name="" class="form-control  form-control-sm text-capitalize m-5" formControlName="type">-->
<!--        <option *ngFor="let v of possibleValueOption.types" [value]="v">Summary by {{v.toLowerCase()}}</option>-->
<!--      </select>-->
<!--      <select name="" class="form-control m-5 form-control-sm" formControlName="filterOption" (change)="filter()">-->
<!--        <option *ngFor="let v of possibleValueOption.filterOptions" [value]="v">{{v}}</option>-->
<!--      </select>-->
<!--      <label for="inlineFormPassword" class="m-2">Password</label>-->
<!--      <input type="password" class="form-control m-2" id="inlineFormPassword">-->
<!--      <div class="form-check m-2">-->
<!--        <input class="form-check-input" type="checkbox" id="inlineFormCheck">-->
<!--        <label class="form-check-label" for="inlineFormCheck">Remember me</label>-->
<!--      </div>-->
<!--      <button type="submit" class="btn btn-primary m-2">Submit</button>-->
<!--    </form>-->

    <div>
      <form [formGroup]="optionForm">
        <div class="form-group row">
          <label for="" class="col-sm-1 col-form-label col-form-label-sm">Report</label>
          <div class="col-sm-2">
            <select name="" class="form-control form-control-sm text-capitalize" formControlName="type">
              <option *ngFor="let v of possibleValueOption.types" [value]="v">Summary by {{v.toLowerCase()}}</option>
            </select>
          </div>
          <div class="col-sm-3">
            <select name="" class="form-control form-control-sm" formControlName="filterOption" (change)="filter()">
              <option *ngFor="let v of possibleValueOption.filterOptions" [value]="v">{{v}}</option>
            </select>
          </div>
          <label for="" class="col-sm-1 col-form-label col-form-label-sm text-right">From</label>
          <div class="col-sm-1">
            <input formControlName="startDate" readonly autocomplete="off" class="form-control form-control-sm"
                   name="date" bsDatepicker [bsConfig]="datePickerConfig">
          </div>
          <label for="" class="col-sm-1 col-form-label col-form-label-sm text-center">To</label>
          <div class="col-sm-1">
            <input formControlName="endDate" readonly autocomplete="off" class="form-control form-control-sm"
                   name="date" bsDatepicker [bsConfig]="datePickerConfig">
          </div>
        </div>
      </form>
      <chart [type]="chartType" [data]="chartData" [options]="chartOptions" style="min-height: 65vh"></chart>
    </div>

    <div class=" mt-5 ">
        <div class="text-right">
          <button class="btn btn-outline-light btn-excel" (click)="exportExcel()">

            <img src="assets/icon/excel.svg" class="exelIcon " alt="">
          </button>
        </div>
    </div>
    <div class="mb-5" *ngIf="chartData" [@inOutAnimation] style=" overflow-x: scroll">
      <table class="table" id="dataTable">
        <col style="width: 15%;"/>
        <col *ngFor="let col of chartData?.labels"
             [ngStyle]="{'width.%': (85/chartData.labels.length).toString()}"/>
        <thead class="dt-head">
        <tr>
          <th>
            {{this.optionForm.get('type').value.toUpperCase()}}
          </th>
          <th *ngFor="let label of chartData?.labels" class="text-center">
            {{(label | outsourceShortDate)}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let col of renderSummary;let i = index">
          <td><small class="text-nowrap ">{{col.label}}</small></td>
          <td *ngFor="let dataState of col.data;let j = index" class="text-center">{{ dataState }}</td>
        </tr>
        <tr *ngIf="chartData?.datasets.length">
        <tr>
          <td>
            <small><b>Total</b></small>
          </td>
          <td *ngFor="let data of chartData.labels;let indexI = index"
              class="text-center">
            <b>{{ renderSummary | sumOutsourceReport: indexI }}</b>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!chartData" [@inOutAnimation] class="mt-5 mb-5 text-center">
      <div class="spinner-border text-c-set " role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</app-layout>
