import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { logging } from 'protractor';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  readonly UNAUTHORISED = '401 unauthorised';
  readonly INVALID_SESSION = '401 invalid session';
  readonly LAST_UNIT = 'Last unit does not have enough valid bits';

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const auth = req.headers.get('Authorization');

    const cloneHeader = {
      'client-origin': window.location.href,
    };

    if (auth) {
      Object.assign(cloneHeader, { Authorization: auth });
    }

    const headers = new HttpHeaders(cloneHeader);

    const requestClone = req.clone({
      withCredentials: true,
      headers,
    });

    console.warn('AUTH INTERCEPTOR')
    let endpoint = '/oauth2';
    if (environment.local) {
      endpoint = endpoint + 'local'
    }
    console.warn('redirect ', environment.api_endpoint + endpoint)

    return next.handle(requestClone).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (error: HttpErrorResponse) => {
          if (error.error && this.UNAUTHORISED === error.error.message) {
            localStorage.setItem('redirect', location.href);
            location.href = environment.api_endpoint + endpoint;
          }

          if (error.error && this.INVALID_SESSION === error.error.message) {
            localStorage.setItem('redirect', location.href);
            location.href = environment.api_endpoint + endpoint;
          }

          if (error.error && this.LAST_UNIT === error.error.message) {
            localStorage.setItem('redirect', location.href);
            location.href = environment.api_endpoint + endpoint;
          }
        }
      )
    );
  }
  constructor() {}
}
export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
