<div class="row">
  <div class="col">
    <form>
      <div class="form-group row">
        <label for="field" class="col-sm-auto col-form-label">Field</label>
        <div class="col-sm-5">
          <select class="form-control" name="field" id="field"
                  [(ngModel)]="selectedField"
                  (change)="onChange()"
          >
            <option [value]="d.id" *ngFor="let d of data">{{d.sequenceNo ? d.sequenceNo + '.' : ''}} {{d.name}} {{!d.active ? '(INACTIVE)' : ''}} </option>
          </select>
        </div>
        <div class="col-sm-auto">
          <button type="button" class="btn btn-success" (click)="onShowForm(selectedField)"
                  [disabled]="!selectedField"
          >UPDATE DATA</button>
        </div>
        <div class="col-sm-auto ml-auto">
          <button type="button" class="btn btn-info" (click)="onShowForm('new')">NEW FIELD</button>
        </div>
        <div class="col-sm-auto ml-1">
          <button type="button" class="btn btn-outline-info" (click)="exportExcel()" >
            <img src="assets/icon/excel.svg" class="exelIcon mt-1" alt="">
            EXPORT
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row">
  <div class="col">
    <table class="table">
      <thead>
      <tr>
        <th style="cursor: pointer; white-space: nowrap" (click)="sort('employee.empID')" >EMP ID.
          <fa-icon class="text-success" *ngIf="order === 'employee.empID' && !isReverse" [icon]="faIcon.faCaretDown"></fa-icon>
          <fa-icon class="text-danger" *ngIf="order === 'employee.empID' && isReverse" [icon]="faIcon.faCaretUp"></fa-icon>
        </th>
        <th style="cursor: pointer; white-space: nowrap" (click)="sort('employee.nameTh')" >NAME
          <fa-icon class="text-success" *ngIf="order === 'employee.nameTh' && !isReverse" [icon]="faIcon.faCaretDown"></fa-icon>
          <fa-icon class="text-danger" *ngIf="order === 'employee.nameTh' && isReverse" [icon]="faIcon.faCaretUp"></fa-icon>
        </th>
        <th style="cursor: pointer; white-space: nowrap" (click)="sort('employee.departmentName')" >DEPARTMENT
          <fa-icon class="text-success" *ngIf="order === 'employee.departmentName' && !isReverse" [icon]="faIcon.faCaretDown"></fa-icon>
          <fa-icon class="text-danger" *ngIf="order === 'employee.departmentName' && isReverse" [icon]="faIcon.faCaretUp"></fa-icon>
        </th>
        <th style="cursor: pointer; white-space: nowrap" (click)="sort('value')" >DATA
          <fa-icon class="text-success" *ngIf="order === 'value' && !isReverse" [icon]="faIcon.faCaretDown"></fa-icon>
          <fa-icon class="text-danger" *ngIf="order === 'value' && isReverse" [icon]="faIcon.faCaretUp"></fa-icon>
        </th>
        <th style="cursor: pointer; white-space: nowrap" (click)="sort('updateDate')" >LAST UPDATE
          <fa-icon class="text-success" *ngIf="order === 'updateDate' && !isReverse" [icon]="faIcon.faCaretDown"></fa-icon>
          <fa-icon class="text-danger" *ngIf="order === 'updateDate' && isReverse" [icon]="faIcon.faCaretUp"></fa-icon>
        </th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let l of list; index as i">
        <td>{{l.employee.empID}}</td>
        <td>
          <a routerLink="/additional-information" [queryParams]="{id: l.employee.empID}" target="_blank">
            {{l.employee.nameTh}}
          </a>
        </td>
        <td>{{l.employee.departmentName}}</td>
        <td>
          <!-- {{l.value}} -->
          <label [innerText]='l.value'></label>
        </td>
        <td>{{l.updateDate|date: 'dd MMM yyyy HH:mm:ss'}}</td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="isLoading" class="text-center ">
      <div class="spinner-border text-warning" role="status">
        <span class=" sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
