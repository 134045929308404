<app-layout>

  <div>
    <app-approve-list #approveList></app-approve-list>
  </div>

  <div>
    <app-request-list (loadData)="approveList.getApproveList()"></app-request-list>
  </div>
</app-layout>
