<form [formGroup]="strategicForm">
    <div class="form-group row">
        <div class="col-sm-3 col-lg-2 pl-4 pr-4">
            <div class="p-2 stakeholder-pointer">
                <h5>
                    <b>
                        Lead
                    </b>
                </h5>
            </div>
        </div>

        <div class="col-sm-9 col-lg-10 row ml-1 pl-4" *ngIf="!isAdminOfStrategicProject">
            <div class="form-group col-sm-6 row">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white">
                    <h6 class="mb-0">
                        <b>
                            Owner
                        </b>
                    </h6>
                </div>
                <div *ngIf="strategicProjectData.approver || strategicProjectData.coApprover"
                    class="col-7 p-2 col-form-label" style="background-color: #d4d4d5;">
                    <div *ngIf="strategicProjectData.approver.length > 0 || strategicProjectData.coApprover.length > 0">
                        <div *ngIf="strategicProjectData.approver">
                            <label *ngFor="let approver of strategicProjectData.approver;let i = index;">
                                {{getName(approver)}}
                            </label>
                        </div>
                        <div *ngIf="strategicProjectData.coApprover">
                            <label *ngFor="let coApprover of strategicProjectData.coApprover;let i = index;">
                                {{getName(coApprover)}}
                            </label>
                        </div>
                    </div>
                    <div
                        *ngIf="strategicProjectData.approver.length === 0 && strategicProjectData.coApprover.length === 0">
                        -
                    </div>
                </div>
                <div *ngIf="!strategicProjectData.approver && strategicProjectData.coApprover"
                    class="col-7 p-2 col-form-label" style="background-color: #d4d4d5;">
                    -
                </div>
            </div>
            <div class="form-group col-sm-6 ml-4 row">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white">
                    <h6 class="mb-0">
                        <b>
                            Reporter
                        </b>
                    </h6>
                </div>
                <div *ngIf="strategicProjectData.reviewer || strategicProjectData.coReviewer || strategicProjectData.creator"
                    class="col-7 p-2 col-form-label" style="background-color: #d4d4d5;">
                    <div
                        *ngIf="strategicProjectData.reviewer.length > 0 || strategicProjectData.coReviewer.length > 0 || strategicProjectData.creator.length > 0">
                        <div *ngIf="strategicProjectData.reviewer">
                            <label *ngFor="let reviewer of strategicProjectData.reviewer;let i = index;">
                                {{getName(reviewer)}}
                            </label>
                        </div>
                        <div *ngIf="strategicProjectData.coReviewer">
                            <label *ngFor="let coReviewer of strategicProjectData.coReviewer;let i = index;">
                                {{getName(coReviewer)}}
                            </label>
                        </div>
                        <div *ngIf="strategicProjectData.creator">
                            <label *ngFor="let creator of strategicProjectData.creator;let i = index;">
                                {{getName(creator)}}
                            </label>
                        </div>
                    </div>
                    <div
                        *ngIf="strategicProjectData.reviewer.length === 0 && strategicProjectData.coReviewer.length === 0 && strategicProjectData.creator.length === 0">
                        -
                    </div>
                </div>
                <div *ngIf="!strategicProjectData.reviewer && !strategicProjectData.coReviewer && !strategicProjectData.creator"
                    class="col-7 p-2 col-form-label" style="background-color: #d4d4d5;">
                    -
                </div>
            </div>
            <!-- <div class="form-group col-sm-6 row">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white">
                    <h6 class="mb-0">
                        <b>
                            Project
                        </b>
                    </h6>
                </div>
                <div *ngIf="strategicProjectData.budgetProject" class="col-7 p-2 col-form-label"
                    style="background-color: #d4d4d5;">
                    <div *ngIf="strategicProjectData.budgetProject.length > 0">
                        <div *ngIf="strategicProjectData.budgetProject">
                            <label *ngFor="let budgetProject of strategicProjectData.budgetProject;let i = index;">
                                {{budgetProject}}&nbsp;
                            </label>
                        </div>
                    </div>
                    <div *ngIf="strategicProjectData.budgetProject.length === 0">
                        -
                    </div>
                </div>
                <div *ngIf="!strategicProjectData.budgetProject" class="col-7 p-2 col-form-label"
                    style="background-color: #d4d4d5;">
                    -
                </div>
            </div> -->
        </div>

        <div class="col-sm-9 col-lg-10 row ml-1 pl-4" *ngIf="isAdminOfStrategicProject">
            <div class="col-sm-12 col-lg-6 form-group row mb-2">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white h-auto">
                    <h6 class="mb-0">
                        <b>
                            Approver
                        </b>
                    </h6>
                </div>
                <div class="col-7">
                    <tag-input [ngModel]="approverTag" [ngModelOptions]="{standalone: true}" [placeholder]="'+ name'"
                        [secondaryPlaceholder]="'+ name'" (onAdd)="addApproverTag($event)"
                        (onRemove)="removeApproverTag($event)" theme='bootstrap' [identifyBy]="'empID'"
                        [displayBy]="'nameEn'">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="employeeList"
                            [identifyBy]="'empID'" [displayBy]="'nameEn'">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 form-group row">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white h-auto">
                    <h6 class="mb-0">
                        <b>
                            Co-approver
                        </b>
                    </h6>
                </div>
                <div class="col-7">
                    <tag-input [ngModel]="coApproverTag" [ngModelOptions]="{standalone: true}" [placeholder]="'+ name'"
                        [secondaryPlaceholder]="'+ name'" (onAdd)="addCoApproverTag($event)"
                        (onRemove)="removeCoApproverTag($event)" theme='bootstrap' [identifyBy]="'empID'"
                        [displayBy]="'nameEn'">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="employeeList"
                            [identifyBy]="'empID'" [displayBy]="'nameEn'">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 form-group row mb-2">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white h-auto">
                    <h6 class="mb-0">
                        <b>
                            Reviewer
                        </b>
                    </h6>
                </div>
                <div class="col-7">
                    <tag-input [ngModel]="reviewerTag" [ngModelOptions]="{standalone: true}" [placeholder]="'+ name'"
                        [secondaryPlaceholder]="'+ name'" (onAdd)="addReviewerTag($event)"
                        (onRemove)="removeReviewerTag($event)" theme='bootstrap' [identifyBy]="'empID'"
                        [displayBy]="'nameEn'">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="employeeList"
                            [identifyBy]="'empID'" [displayBy]="'nameEn'">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 form-group row mb-2">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white h-auto">
                    <h6 class="mb-0">
                        <b>
                            Co-reviewer
                        </b>
                    </h6>
                </div>
                <div class="col-7">
                    <tag-input [ngModel]="coReviewerTag" [ngModelOptions]="{standalone: true}" [placeholder]="'+ name'"
                        [secondaryPlaceholder]="'+ name'" (onAdd)="addCoReviewerTag($event)"
                        (onRemove)="removeCoReviewerTag($event)" theme='bootstrap' [identifyBy]="'empID'"
                        [displayBy]="'nameEn'">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="employeeList"
                            [identifyBy]="'empID'" [displayBy]="'nameEn'">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 form-group row mb-2">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white h-auto">
                    <h6 class="mb-0">
                        <b>
                            Creator
                        </b>
                    </h6>
                </div>
                <div class="col-7">
                    <tag-input [ngModel]="creatorTag" [ngModelOptions]="{standalone: true}" [placeholder]="'+ name'"
                        [secondaryPlaceholder]="'+ name'" (onAdd)="addCreatorTag($event)"
                        (onRemove)="removeCreatorTag($event)" theme='bootstrap' [identifyBy]="'empID'"
                        [displayBy]="'nameEn'">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="employeeList"
                            [identifyBy]="'empID'" [displayBy]="'nameEn'">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
            <!-- <div class="col-sm-12 col-lg-6 form-group row mb-2">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white h-auto">
                    <h6 class="mb-0">
                        <b>
                            Project
                        </b>
                    </h6>
                </div>
                <div class="col-7">
                    <tag-input [ngModel]="budgetProjectTag" [ngModelOptions]="{standalone: true}"
                        [placeholder]="'+ project'" [secondaryPlaceholder]="'+ project'"
                        (onAdd)="addBudgetProjectTag($event)" (onRemove)="removeBudgetProjectTag($event)"
                        theme='bootstrap' [identifyBy]="'project'" [displayBy]="'projectName'">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="budgetProjectList"
                            [identifyBy]="'project'" [displayBy]="'projectName'">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div> -->
        </div>
    </div>
    <hr>

    <div class="form-group row">
        <div class="col-sm-3 col-lg-2 pl-4 pr-4">
            <h5 style="color: transparent;"><b>.</b></h5>
            <hr style="border-color: transparent;">
            <div class="p-2 corporate-pointer">
                <h5>
                    <b>
                        <!-- Corporate KPI -->Target
                    </b>
                </h5>
            </div>
        </div>
        <div class="col-sm-9 col-lg-10 pl-4">
            <h5><b>เป้าหมายปี {{strategicProjectData.year ?  strategicProjectData.year : '-'}}</b></h5>
            <hr>
            <div *ngIf="isAdminOfStrategicProject"
                class="form-group row d-flex justify-content-between container-fluid pr-0 mr-0">
                <input class="form-control col-sm-10" formControlName="target"
                    [ngClass]="{'is-invalid': strategicForm.controls['target'].hasError('null')}" />
                <button class="btn btn-success col-sm-auto" (click)="addTarget()">ADD</button>
            </div>
            <div *ngIf="strategicProjectData.targets && !isAdminOfStrategicProject">
                <div *ngIf="strategicProjectData.targets.length > 0">
                    <div *ngFor="let target of strategicProjectData.targets; let i = index;">
                        <h6>
                            {{i +1}}. {{target.detail}}
                        </h6>
                    </div>
                </div>
                <div *ngIf="strategicProjectData.targets.length === 0">
                    -
                </div>
            </div>
            <div *ngIf="!strategicProjectData.targets && !isAdminOfStrategicProject">
                -
            </div>
            <div *ngIf="isAdminOfStrategicProject" formArrayName="milestoneList">
                <div *ngFor="let target of formDataList.controls; let i = index;">
                    <h6>{{i + 1}}. {{target.get('detail').value}}
                        <fa-icon [icon]="faIcon.faTimes" class="text-danger ml-3 cursor-pointer"
                            (click)="onShowConfirmationModal(template,'deleteTarget',i)"></fa-icon>
                    </h6>
                </div>
            </div>
        </div>
    </div>
    <hr>

    <div class="form-group row">
        <div class="col-sm-3 col-lg-2 pl-4 pr-4">
            <div class="p-2 milestone-pointer">
                <h5>
                    <b>
                        Milestone
                    </b>
                </h5>
            </div>
        </div>
        <div class="col-sm-9 col-lg-10 pl-4 scroll-table">
            <table class="table milestone-table" id="dataTable">
                <col style="width: 25%;" />
                <col style="width: 25%;" />
                <col style="width: 25%;" />
                <col style="width: 25%;" />
                <thead class="dt-head">
                    <tr class="text-center" style="background-color: #EF9A9A;">
                        <th style="border-right: 2px solid white;">
                            <h5 class="mb-0"> Q1 </h5>
                        </th>
                        <th style="border-right: 2px solid white;">
                            <h5 class="mb-0"> Q2 </h5>
                        </th>
                        <th style="border-right: 2px solid white;">
                            <h5 class="mb-0"> Q3 </h5>
                        </th>
                        <th style="border-right: 2px solid white;">
                            <h5 class="mb-0"> Q4 </h5>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="strategicProjectData && !isAdminOfStrategicProject">
                    <ng-container class="bg-light" *ngFor="let target of strategicProjectData.targets; let i = index;">
                        <tr class="bg-white">
                            <td colspan="4">
                                <div class="form-group row">
                                    <h6 class="mt-2 col-sm-auto">{{i + 1}}. {{target.detail}}</h6>
                                </div>
                            </td>
                        </tr>
                        <tr class="bg-light">
                            <td style="border-right: 2px solid white;" *ngIf="target">
                                <div *ngIf="target.milestoneQ1" style="min-height: 5rem;">
                                    <label
                                        class="mb-1 mile-stone-font-size">{{target.milestoneQ1.milestoneDetail}}</label>
                                </div>
                                <div *ngIf="!target.milestoneQ1" style="min-height: 5rem;">
                                    <label class="mb-1 mile-stone-font-size">-</label>
                                </div>
                            </td>
                            <td style="border-right: 2px solid white;">
                                <div *ngIf="target.milestoneQ2" style="min-height: 5rem;">
                                    <label
                                        class="mb-1 mile-stone-font-size">{{target.milestoneQ2.milestoneDetail}}</label>
                                </div>
                                <div *ngIf="!target.milestoneQ2" style="min-height: 5rem;">
                                    <label class="mb-1 mile-stone-font-size">-</label>
                                </div>
                            </td>
                            <td style="border-right: 2px solid white;">
                                <div *ngIf="target.milestoneQ3" style="min-height: 5rem;">
                                    <label
                                        class="mb-1 mile-stone-font-size">{{target.milestoneQ3.milestoneDetail}}</label>
                                </div>
                                <div *ngIf="!target.milestoneQ3" style="min-height: 5rem;">
                                    <label class="mb-1 mile-stone-font-size">-</label>
                                </div>
                            </td>
                            <td style="border-right: 2px solid white;">
                                <div *ngIf="target.milestoneQ4" style="min-height: 5rem;">
                                    <label
                                        class="mb-1 mile-stone-font-size">{{target.milestoneQ4.milestoneDetail}}</label>
                                </div>
                                <div *ngIf="!target.milestoneQ4" style="min-height: 5rem;">
                                    <label class="mb-1 mile-stone-font-size">-</label>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tbody *ngIf="strategicProjectData && isAdminOfStrategicProject" formArrayName="milestoneList">
                    <ng-container *ngFor="let target of formDataList.controls; let i = index;" [formGroupName]="i">
                        <tr class="bg-white">
                            <td colspan="4">
                                <div class="form-group row">
                                    <h6 class="mt-3 col-sm-auto col-lg-auto">{{i + 1}}.</h6>
                                    <input class="mt-2 col-sm-8 col-lg-9 form-control" type="text"
                                        [formControl]="target['controls'].detail"
                                        [class.is-invalid]="target.get('detail').invalid" />
                                    <h6 class="mt-3 col-sm-auto col-lg-auto">Is Corporate KPIs : </h6>
                                    <input class="mt-3 col-sm-auto col-lg-auto form-control"
                                        style="width: 20px; height: 20px;" type="checkbox"
                                        [formControl]="target['controls'].corporateKPIs">
                                </div>
                            </td>

                        </tr>
                        <tr class="bg-light">
                            <td style="border-right: 2px solid white;">
                                <textarea class="form-control"
                                    [formControl]="target['controls'].milestoneQ1['controls'].milestoneDetail" rows="5"
                                    [class.is-invalid]="target.get('milestoneQ1').get('milestoneDetail').invalid"></textarea>
                            </td>
                            <td style="border-right: 2px solid white;">
                                <textarea class="form-control"
                                    [formControl]="target['controls'].milestoneQ2['controls'].milestoneDetail" rows="5"
                                    [class.is-invalid]="target.get('milestoneQ2').get('milestoneDetail').invalid"></textarea>
                            </td>
                            <td style="border-right: 2px solid white;">
                                <textarea class="form-control"
                                    [formControl]="target['controls'].milestoneQ3['controls'].milestoneDetail" rows="5"
                                    [class.is-invalid]="target.get('milestoneQ3').get('milestoneDetail').invalid"></textarea>
                            </td>
                            <td style="border-right: 2px solid white;">
                                <textarea class="form-control"
                                    [formControl]="target['controls'].milestoneQ4['controls'].milestoneDetail" rows="5"
                                    [class.is-invalid]="target.get('milestoneQ4').get('milestoneDetail').invalid"></textarea>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tbody *ngIf="!strategicProjectData">
                    <td class="text-center" colspan="2">
                        No data.
                    </td>
                </tbody>
            </table>
        </div>
    </div>
    <hr>
    <div class="form-group row">
        <div class="col-sm-3 col-lg-2 pl-4 pr-4">
            <div class="p-2 progress-pointer">
                <h5>
                    <b>
                        Progress
                    </b>
                </h5>
            </div>
        </div>
        <div class="col-sm-9 col-lg-10 pl-4" style="overflow-x:auto;">
            <table class="table respnsive-table" id="dataTable">
                <col style="width: 40%;" />
                <col style="width: 13%;" />
                <col style="width: 47%;" />
                <thead class="dt-head">
                    <tr class="text-center" style="background-color: #fab232; color: white;">
                        <th class="pl-0 pr-0" style="border-right: 2px solid white;">
                            <div class="form-group row mb-0">
                                <h5 class="align-top pr-0 col-sm-7 mb-0">Milestone ณ สิ้นไตรมาส</h5>
                                <div class="col-sm-4 pl-0">
                                    <select class="form-control" formControlName="selectedMilestonePeriod"
                                        (change)="onChangeMilestonePeriod()">
                                        <option *ngFor="let quarter of allQuarter" [ngValue]="quarter">
                                            {{quarter}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </th>
                        <th class="align-top" style="border-right: 2px solid white;">
                            <h5 class="mb-0 align-top"> Status </h5>
                        </th>
                        <th class="align-top" style="border-right: 2px solid white;">
                            <h5 class="mb-0"> ความคืบหน้า และความสำเร็จที่เกิดขึ้นจริง </h5>
                        </th>
                    </tr>
                </thead>
                <tbody formArrayName="milestoneList">
                    <ng-container class="bg-light" *ngFor="let target of formDataList.controls; let i = index;"
                        [formGroupName]="i">
                        <tr *ngIf="checkMileStoneData(target)" class="bg-white">
                            <td colspan="4">
                                <h6 class="mt-2 mb-2">{{i + 1}}. {{target.get('detail').value}}</h6>
                            </td>
                        </tr>
                        <tr *ngIf="checkMileStoneData(target)" class="bg-light">
                            <td style="border-right: 2px solid white;">
                                <div *ngIf="strategicFormGroup.selectedMilestonePeriod.value.includes('Q1')"
                                    style="min-height: 5rem;">
                                    <div *ngIf="target.get('milestoneQ1').value">
                                        <label
                                            class="mb-1 white-space-style">{{target.value.milestoneQ1.milestoneDetail ? formDataList.at(i).get("milestoneQ1").get("milestoneDetail").value : ""}}</label>
                                    </div>
                                </div>

                                <div *ngIf="strategicFormGroup.selectedMilestonePeriod.value.includes('Q2')"
                                    style="min-height: 5rem;">
                                    <div *ngIf="target.get('milestoneQ2').value">
                                        <label class="mb-1"
                                            white-space-style>{{target.value.milestoneQ2.milestoneDetail ? formDataList.at(i).get("milestoneQ2").get("milestoneDetail").value : ""}}</label>
                                    </div>
                                </div>

                                <div *ngIf="strategicFormGroup.selectedMilestonePeriod.value.includes('Q3')"
                                    style="min-height: 5rem;">
                                    <div *ngIf="target.get('milestoneQ3').value">
                                        <label
                                            class="mb-1 white-space-style">{{target.value.milestoneQ3.milestoneDetail ? formDataList.at(i).get("milestoneQ3").get("milestoneDetail").value : ""}}</label>
                                    </div>
                                </div>

                                <div *ngIf="strategicFormGroup.selectedMilestonePeriod.value.includes('Q4')"
                                    style="min-height: 5rem;">
                                    <div *ngIf="target.get('milestoneQ4').value">
                                        <label
                                            class="mb-1 white-space-style">{{target.value.milestoneQ4.milestoneDetail ? formDataList.at(i).get("milestoneQ4").get("milestoneDetail").value : ""}}</label>
                                    </div>
                                </div>
                            </td>
                            <td style="border-right: 2px solid white;">
                                <div *ngIf="strategicFormGroup.selectedMilestonePeriod.value.includes('Q1')">
                                    <div
                                        *ngIf="target.get('milestoneQ1').value && target.value.milestoneQ1.milestoneDetail">
                                        <div class="circle-status  ml-auto mr-auto row cursor-pointer"
                                            [ngClass]="target.value.milestoneQ1.status === 'G' ?  'bg-success' : 'bg-secondary'"
                                            (click)="onChangeStatus('G','Q1',i)">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">G</span>
                                        </div>
                                        <div class="circle-status ml-auto mr-auto row cursor-pointer mt-3"
                                            [ngClass]="target.value.milestoneQ1.status === 'Y' ?  'bg-warning' : 'bg-secondary'"
                                            (click)="onChangeStatus('Y','Q1',i)">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">Y</span>
                                        </div>
                                        <div class="circle-status ml-auto mr-auto row cursor-pointer mt-3"
                                            [ngClass]="target.value.milestoneQ1.status === 'R' ?  'bg-danger' : 'bg-secondary'"
                                            (click)="onChangeStatus('R','Q1',i)">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">R</span>
                                        </div>
                                    </div>
                                </div>



                                <div *ngIf="strategicFormGroup.selectedMilestonePeriod.value.includes('Q2')">
                                    <div
                                        *ngIf="target.get('milestoneQ2').value && target.value.milestoneQ2.milestoneDetail">
                                        <div class="circle-status  ml-auto mr-auto row cursor-pointer"
                                            [ngClass]="target.value.milestoneQ2.status === 'G' ?  'bg-success' : 'bg-secondary'"
                                            (click)="onChangeStatus('G','Q2',i)">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">G</span>
                                        </div>
                                        <div class="circle-status ml-auto mr-auto row cursor-pointer mt-3"
                                            [ngClass]="target.value.milestoneQ2.status === 'Y' ?  'bg-warning' : 'bg-secondary'"
                                            (click)="onChangeStatus('Y','Q2',i)">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">Y</span>
                                        </div>
                                        <div class="circle-status ml-auto mr-auto row cursor-pointer mt-3"
                                            [ngClass]="target.value.milestoneQ2.status === 'R' ?  'bg-danger' : 'bg-secondary'"
                                            (click)="onChangeStatus('R','Q2',i)">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">R</span>
                                        </div>
                                    </div>
                                </div>



                                <div *ngIf="strategicFormGroup.selectedMilestonePeriod.value.includes('Q3')">
                                    <div
                                        *ngIf="target.get('milestoneQ3').value && target.value.milestoneQ3.milestoneDetail">
                                        <div class="circle-status  ml-auto mr-auto row cursor-pointer"
                                            [ngClass]="target.value.milestoneQ3.status === 'G' ?  'bg-success' : 'bg-secondary'"
                                            (click)="onChangeStatus('G','Q3',i)">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">G</span>
                                        </div>
                                        <div class="circle-status ml-auto mr-auto row cursor-pointer mt-3"
                                            [ngClass]="target.value.milestoneQ3.status === 'Y' ?  'bg-warning' : 'bg-secondary'"
                                            (click)="onChangeStatus('Y','Q3',i)">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">Y</span>
                                        </div>
                                        <div class="circle-status ml-auto mr-auto row cursor-pointer mt-3"
                                            [ngClass]="target.value.milestoneQ3.status === 'R' ?  'bg-danger' : 'bg-secondary'"
                                            (click)="onChangeStatus('R','Q3',i)">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">R</span>
                                        </div>
                                    </div>
                                </div>



                                <div *ngIf="strategicFormGroup.selectedMilestonePeriod.value.includes('Q4')">
                                    <div
                                        *ngIf="target.get('milestoneQ4').value && target.value.milestoneQ4.milestoneDetail">
                                        <div class="circle-status  ml-auto mr-auto row cursor-pointer"
                                            [ngClass]="target.value.milestoneQ4.status === 'G' ?  'bg-success' : 'bg-secondary'"
                                            (click)="onChangeStatus('G','Q4',i)">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">G</span>
                                        </div>
                                        <div class="circle-status ml-auto mr-auto row cursor-pointer mt-3"
                                            [ngClass]="target.value.milestoneQ4.status === 'Y' ?  'bg-warning' : 'bg-secondary'"
                                            (click)="onChangeStatus('Y','Q4',i)">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">Y</span>
                                        </div>
                                        <div class="circle-status ml-auto mr-auto row cursor-pointer mt-3"
                                            [ngClass]="target.value.milestoneQ4.status === 'R' ?  'bg-danger' : 'bg-secondary'"
                                            (click)="onChangeStatus('R','Q4',i)">
                                            <span class="d-flex align-items-center ml-auto mr-auto"
                                                style="font-size: 1.5rem;">R</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td style="border-right: 2px solid white;">
                                <div *ngIf="strategicFormGroup.selectedMilestonePeriod.value.includes('Q1')">
                                    <div
                                        *ngIf="target.get('milestoneQ1').value && target.value.milestoneQ1.milestoneDetail">
                                        <textarea class="form-control"
                                            [formControl]="target['controls'].milestoneQ1['controls'].progressDetail"
                                            [class.is-invalid]="target.get('milestoneQ1').get('progressDetail').invalid"
                                            rows="6"></textarea>
                                    </div>
                                </div>
                                <div *ngIf="strategicFormGroup.selectedMilestonePeriod.value.includes('Q2')">
                                    <div
                                        *ngIf="target.get('milestoneQ2').value && target.value.milestoneQ2.milestoneDetail">
                                        <textarea class="form-control"
                                            [formControl]="target['controls'].milestoneQ2['controls'].progressDetail"
                                            [class.is-invalid]="target.get('milestoneQ2').get('progressDetail').invalid"
                                            rows="6"></textarea>
                                    </div>
                                </div>
                                <div *ngIf="strategicFormGroup.selectedMilestonePeriod.value.includes('Q3')">
                                    <div
                                        *ngIf="target.get('milestoneQ3').value && target.value.milestoneQ3.milestoneDetail">
                                        <textarea class="form-control"
                                            [formControl]="target['controls'].milestoneQ3['controls'].progressDetail"
                                            [class.is-invalid]="target.get('milestoneQ3').get('progressDetail').invalid"
                                            rows="6"></textarea>
                                    </div>
                                </div>
                                <div *ngIf="strategicFormGroup.selectedMilestonePeriod.value.includes('Q4')">
                                    <div
                                        *ngIf="target.get('milestoneQ4').value && target.value.milestoneQ4.milestoneDetail">
                                        <textarea class="form-control"
                                            [formControl]="target['controls'].milestoneQ4['controls'].progressDetail"
                                            [class.is-invalid]="target.get('milestoneQ4').get('progressDetail').invalid"
                                            rows="6"></textarea>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tbody *ngIf="!strategicProjectData">
                    <td class="text-center" colspan="3">
                        No data.
                    </td>
                </tbody>
            </table>
        </div>
    </div>
    <div class="form-group row" *ngIf="isShowEstimateCorporateKPI">
        <div class="col-sm-3 col-lg-2 pl-4 pr-5">
            <div class="p-2 estimated-pointer">
                <h5 class="mb-0">
                    <b>
                        Estimated Corp KPI
                    </b>
                </h5>
                <h5 class="mb-0">
                    <b>
                        Score
                    </b>
                </h5>
            </div>
        </div>
        <div class="col-sm-9 col-lg-5 pl-4">
            <table class="table" id="dataTable">
                <col style="width: 50%" />
                <col style="width: 50%" />
                <thead class="dt-head">
                    <tr class="text-center" style="background-color:#00BFA5;">
                        <th style="border-right: 2px solid white;">
                            <div class="form-group row mb-0">
                                <h5 class="align-top pr-0 col-sm-7 mb-0">ณ สิ้นไตรมาส</h5>
                                <div class="col-sm-5 pl-0">
                                    <select class="form-control" formControlName="selectedEstimatePeriod"
                                        (change)="onChangeEstimatePeriod()">
                                        <option *ngFor="let quarter of allQuarter" [ngValue]="quarter">
                                            {{quarter}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </th>
                        <th class="align-top" style="border-right: 2px solid white;">
                            <h5 class="mb-0"> ณ สิ้นปี {{strategicProjectData.year}} (Optional) </h5>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="bg-light text-center">
                        <td style="border-right: 2px solid white;">
                            <input type="number" class="form-control" formControlName="estimateQuarterScore" min="0"
                                max="5" onkeyup="if(this.value > 5 || this.value < 0) this.value = null;"
                                (change)="estiamateQuarter($event.target.value)">
                        </td>
                        <td style="border-right: 2px solid white;">
                            <input type="number" class="form-control" formControlName="estimateYearScore" min="0"
                                max="5" onkeyup="if(this.value > 5 || this.value < 0) this.value = null;"
                                (change)="estiamateYear($event.target.value)">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="form-group row" *ngIf="checkDisplayBudgetProject(strategicProjectData)">
        <div class="col-sm-3 col-lg-2 pl-4 pr-5" style="margin-top: 1.7rem;">
            <div class="p-2 budget-pointer">
                <h5>
                    <b>
                        Budget
                    </b>
                </h5>
                <b style="font-size: 14px;">
                    <!-- ณ วันที่ {{yesterday | date: 'dd MMM yy'}} -->
                    ณ วันที่ {{asOfDate}}
                </b>
            </div>
        </div>
        <div class="col-sm-9 col-lg-10" style="padding-left: 2.2rem;">
            <div class="row">
                <div class="col-sm-2 ml-2" style="margin-top: 1.7rem;">
                    <div class="row" style="height: 4rem;">
                        <div class="col-sm-6 pt-2 pb-2 text-center" style="background-color: #90c978; color: white; ">
                            <b>CAPEX ACTUAL</b>
                        </div>
                        <div class="col-sm-6 pt-3 pb-2 pl-0 pr-0 text-center" style="background-color: #C6DDAF; ">
                            <b>{{(strategicProjectData.capex / 1000000) | number : '1.2-2'}} MB</b>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 ml-2" style="margin-top: 1.7rem;">
                    <div class="row" style="height: 4rem;">
                        <div class="col-sm-6 pt-2 pb-2 text-center" style="background-color: #90c978; color: white; ">
                            <b>OPEX ACTUAL</b>
                        </div>
                        <div class="col-sm-6 pt-3 pb-2 pl-0 pr-0 text-center" style="background-color: #C6DDAF; ">
                            <b>{{(strategicProjectData.opex / 1000000) | number : '1.2-2'}} MB </b>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 ml-2" style="margin-top: 1.7rem;">
                    <div class="row" style="height: 4rem;"
                        [ngClass]="strategicProjectData.budgetUtilization < 30 ?  'budget-warning-style' : 'budget-utilization-style'">
                        <div class="col-sm-6 pt-2 pb-2 pl-0 pr-0 text-center">
                            <b
                                [ngClass]="strategicProjectData.budgetUtilization < 30 ?  'budget-warning-text-style' : 'budget-utilization-text-style'">Budget
                                Utilization</b>
                        </div>
                        <div class="col-sm-6 pt-3 pb-2 pl-0 pr-0 text-center"
                            [ngClass]="strategicProjectData.budgetUtilization < 30 ?  'budget-warning-text-style' : 'budget-utilization-text-style'">
                            <b>{{strategicProjectData.budgetUtilization | number : '1.2-2'}} %</b>
                        </div>
                    </div>
                </div>
                <div class="col-sm-5 col-lg-5 ml-4 px-0" *ngIf="strategicProjectData.budgetUtilization < 30 ">
                    <h6>เหตุผลที่ Budget Utilization < 30%</h6>
                            <!-- <div class="form-group row mb-1">
                        <h6 class="pt-2 pr-0 col-sm-7 mb-0">เหตุผลที่ Budget Utilizayion < 30%</h6>
                        <div class="col-sm-4 pl-0">
                            <select class="form-control" formControlName="selectedBudgetReasonPeriod"
                                (change)="onChangeBudgetProjectPeriod()">
                                <option *ngFor="let quarter of allQuarter" [ngValue]="quarter">
                                    {{quarter}}
                                </option>
                            </select>
                        </div>
                    </div> -->
                            <textarea formControlName="budgetReason" class="form-control" rows="2"
                                [ngClass]="{'is-invalid': strategicForm.controls['budgetReason'].hasError('null')}">
                    </textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-end mt-4 mb-4 text-right">
        <div class="col-sm-auto">
            <button type="button" class="btn btn-secondary app-btn-style"
                (click)="onShowConfirmationModal(template,'back',0)">BACK
            </button>
        </div>
        <div *ngIf="(isAdminOfStrategicProject || isApprover) && checkStatusNeedApprove(strategicProjectData)"
            class="col-sm-auto">
            <button type="button" class="btn btn-danger app-btn-style"
                (click)="onShowConfirmationModal(reasonTemplate,'reject',0)" [disabled]="!isEditable">REJECT</button>
        </div>
        <div *ngIf="!checkStatusApprove(strategicProjectData) && (isStakeHolder || isAdminOfStrategicProject)"
            class="col-sm-auto">
            <button type="button" class="btn btn-primary app-btn-style"
                (click)="onShowConfirmationModal(template,'save',0)" [disabled]="!isEditable">SAVE</button>
        </div>
        <div *ngIf="(isAdminOfStrategicProject || isCreator) && checkStatusNeedUpdate(strategicProjectData)"
            class="col-sm-auto">
            <button type="button" class="btn btn-success app-btn-style"
                (click)="onShowConfirmationModal(template,'submit',0)" [disabled]="!isEditable">SUBMIT</button>
        </div>
        <div *ngIf="(isAdminOfStrategicProject || isReviewer) && checkStatusNeedReview(strategicProjectData)"
            class="col-sm-auto">
            <button type="button" class="btn btn-success app-btn-style"
                (click)="onShowConfirmationModal(template,'submitForApproval',0)" [disabled]="!isEditable">SUBMIT FOR
                APPROVAL</button>
        </div>
        <div *ngIf="(isAdminOfStrategicProject || isApprover) && checkStatusNeedApprove(strategicProjectData)"
            class="col-sm-auto">
            <button type="button" class="btn btn-success app-btn-style"
                (click)="onShowConfirmationModal(reasonTemplate,'approve',0)" [disabled]="!isEditable">APPROVE</button>
        </div>
    </div>
</form>

<hr>
<h5>History Log</h5>
<app-strategic-project-log [strategicProjectId]="strategicProjectData?.id"></app-strategic-project-log>

<ng-template #template>
    <div class="modal-header thead-primary" style="background-color: #fab232;">
        <h4 class="modal-title pull-left ml-3">Confirmation</h4>
        <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group ml-3">
            <div class="d-flex">
                <h5>{{ confirmationWording }}</h5>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex mt-2 justify-content-end">
            <button class="btn btn-success mr-2" (click)="confirm()">Confirm</button>
            <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #reasonTemplate>
    <form [formGroup]="reasonForm">
        <div class="modal-header thead-primary" style="background-color: #fab232;">
            <h4 class="modal-title pull-left ml-3">Confirmation</h4>
            <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group ml-3">
                <div class="d-flex">
                    <h5>{{ confirmationWording }}</h5>
                </div>
            </div>
            <div class="form-group ml-3">
                <div class="d-flex">
                    <textarea class="form-control" formControlName="reasonForApproveOrReject" rows="4"
                        [ngClass]="{'is-invalid': reasonForm.controls['reasonForApproveOrReject'].hasError('null')}"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="d-flex mt-2 justify-content-end">
                <button class="btn btn-success mr-2" (click)="confirm()">Confirm</button>
                <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
            </div>
        </div>
    </form>
</ng-template>