import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Manpower} from '../models/manpower';
import {DeptRelation} from '../models/deptRelation';
import {Position} from '../models/position';
import {Observable, of} from 'rxjs';
import {RequestManpower} from '../models/requestManpower';
import {RequestManpowerAction} from '../enums/RequestManpowerAction';
import {ManpowerReport} from '../models/manpower-report';
import {Employee, SpecialRole} from '../models/employee';
import {catchError} from 'rxjs/operators';
import {UserRole} from '../models/userRole';

@Injectable({
  providedIn: 'root'
})
export class ManpowerService {

  deptRelation: DeptRelation[] = []

  constructor(private http: HttpClient) {
  }

  getManpowerByDept(deptid: string) {
    return this.http.get<Manpower[]>(`${environment.api_endpoint}/manpower/dept/${deptid}`).pipe(
      catchError(error => {
        console.error('Error fetching manpower data:', error);
        return of([]);
      })
    );
  }

  getDivisionByCode(code: string) {
    return this.http.get<DeptRelation>(`${environment.api_endpoint}/department/deptId/${code}`);
  }

  getPosition() {
    return this.http.get<Position[]>(`${environment.api_endpoint}/manpower/positions`);
  }

   isHRVPOrHRVPManager(): Observable<IsHRVP> {
    return this.http.get<IsHRVP>(`${environment.api_endpoint}/manpower/is-hrvp`);
  }

  getPositionVacantFlag() {
    return this.http.get<Position[]>(`${environment.api_endpoint}/manpower/positions?vacantFlag=true`);
  }

  getPositionByDept(deptid: string, vacant?: boolean) {
    let params = new HttpParams()
    if (vacant) {
      params = params.append('vacant', 'true')
    }
    if (vacant === false){
      params = params.append('vacant', 'false')
    }
    return this.http.get<Position[]>(`${environment.api_endpoint}/manpower/dept/${deptid}/positions`, {
      params
    })
      .pipe(
        catchError(error => {
          console.error('Error fetching position data:', error);
          return of([]);
        })
      );
  }

  getAllDepartment() {
    return this.http.get<DeptRelation[]>(`${environment.api_endpoint}/departments`);
  }

  getDeepDepartment(deptid: string) {
    return this.http.get<DeptRelation[]>(`${environment.api_endpoint}/departments/deep/${deptid}`);
  }

  saveRequest(body: RequestManpower, action: RequestManpowerAction) {
    return this.http.post<RequestManpower>(`${environment.api_endpoint}/manpower/request`, body, {
      params: {
        action: action.toString()
      }
    });
  }

  updateRequest(body: RequestManpower, id: string, action: RequestManpowerAction) {
    return this.http.put<RequestManpower>(`${environment.api_endpoint}/manpower/request/${id}`, body, {
      params: {
        action: action.toString()
      }
    });
  }

  getMyRequest(pending = 'N') {
    return this.http.get<RequestManpower[]>(`${environment.api_endpoint}/manpower/requests`, {
      params: {
        pending,
        admin: 'Y'
      }
    });
  }

  getPendingForHRAdmin(pendingAt = '') {
    return this.http.get<RequestManpower[]>(`${environment.api_endpoint}/manpower/get-pending`, {
      params: {
        pendingAt,
      }
    });
  }

  finManagers(): Observable<Employee[]> {
    return this.http.get<Employee[]>(`${environment.api_endpoint}/employee/managers`);
  }

  getSpecialRole(): Observable<SpecialRole[]> {
    return this.http.get<SpecialRole[]>(`${environment.api_endpoint}/manpower/special-role`);
  }

  getRequestById(id) {
    return this.http.get<RequestManpower>(`${environment.api_endpoint}/manpower/request/${id}`);
  }

  getReport(): Observable<ManpowerReport[]> {
    return this.http.get<ManpowerReport[]>(`${environment.api_endpoint}/manpower/report`);
  }

  exportReport() {
    return this.http.get(`${environment.api_endpoint}/manpower/report/export`, {
      responseType: 'blob'
    });
  }

  findCommandChain(requestId): Observable<Employee[]> {
    return this.http.get<Employee[]>(`${environment.api_endpoint}/manpower/command-chain/${requestId}`);
  }

  checkIsAdmin(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.api_endpoint}/manpower/is-admin`);
  }
}
export interface IsHRVP{
  isHRVP: boolean
  isHRVPManager: boolean;
}
