import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {faIcon} from '../../../../core/icon/fa.icon';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import * as dayjs from 'dayjs';
import {ProjectService} from '../../../../modules/outsource-time-sheet/core/services/project.service';
import {DepartmentServiceService} from '../../../../core/services/department-service.service';
import {OutsourceTimesheetService} from '../../../../core/services/outsource-timesheet.service';
import {merge, Observable, Subscription} from 'rxjs';
import {TimesheetInfo, TimesheetKeyInfo} from '../../../../core/models/timesheetInfo';
import {map, shareReplay} from 'rxjs/operators';

@Component({
  selector: 'app-admin-outsource-timesheet-list',
  templateUrl: './admin-outsource-timesheet-list.component.html',
  styleUrls: ['./admin-outsource-timesheet-list.component.scss']
})
export class AdminOutsourceTimesheetListComponent implements OnInit, OnDestroy {
  @Output() clickList: EventEmitter<TimesheetKeyInfo> = new EventEmitter<TimesheetKeyInfo>();
  optionForm: FormGroup;
  sub: Subscription[] = [];
  options = {
    date: [...this.initialDate],
    project$: this.projectService.allProject(),
    vendor$: this.projectService.getAllvendor(),
    department$: this.departmentService.getAllDepartment().pipe(map(l => l.filter(d => d.deptID === d.department))),
  }
  faIcon = faIcon;
  timesheet: TimesheetInfo[] = []
  timesheetBackup: TimesheetInfo[] = []
  timesheetCache$: TCCache = {};

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private departmentService: DepartmentServiceService,
    private outsourceTimesheetService: OutsourceTimesheetService
  ) {
  }

  ngOnDestroy(): void {
    this.sub.forEach(r => r.unsubscribe());
  }

  ngOnInit(): void {

    this.optionForm = this.fb.group(this.initialOptionState(undefined, undefined))
    this.sub.push(this.optionForm.get('date').valueChanges.subscribe(({month, year}) => {
      this.initTimesheet();
    }));
    this.initTimesheet();

    this.sub.push(merge(
      this.optionForm.get('vendor').valueChanges,
      this.optionForm.get('project').valueChanges,
      this.optionForm.get('department').valueChanges
    ).subscribe(() => {
      this.doFilter();
    }));
  }

  cacheTimesheet(month: number, year: number) {
    if (!this.timesheetCache$[month + '-' + year]) {
      this.timesheetCache$[month + '-' + year] = this.outsourceTimesheetService.getTimesheetWithHr(month, year).pipe(shareReplay(1))
    }
    return this.timesheetCache$[month + '-' + year];
  }


  private doFilter() {
    this.timesheet = this.timesheetBackup
      .filter(tc => this.filterVendor(tc))
      .filter(tc => this.filterProjectName(tc))
      .filter(tc => this.filterDept(tc));

  }

  private filterVendor(tc: TimesheetInfo) {
    if (!this.vendor.value || this.vendor.value === '') {
      return true;
    }
    return tc.vendor === this.vendor.value
  }


  private filterProjectName(tc: TimesheetInfo) {
    if (!this.project.value) {
      return true;
    }
    return tc.projectName === this.project.value
  }

  private filterDept(tc: TimesheetInfo) {
    if (!this.department.value) {
      return true;
    }
    return tc.employee.deptRelation.department === this.department.value
  }

  get department(): FormControl {
    return this.optionForm.get('department') as FormControl;
  }

  get vendor(): FormControl {
    return this.optionForm.get('vendor') as FormControl;
  }

  get project(): FormControl {
    return this.optionForm.get('project') as FormControl;
  }

  private initTimesheet() {
    const {month, year} = this.optionForm.get('date').value;
    this.outsourceTimesheetService.getTimesheetWithHr(month, year)
      .toPromise()
      .then(tc => {
        this.timesheetBackup = tc;
      })
      .catch(() => {
        this.timesheetBackup = []
      })
      .finally(() => {
        this.doFilter()
      })
  }

  get initialDate() {
    const result = [];
    const initDate = dayjs().subtract(6, 'month')
    for (let i = 0; i < 8; i++) {
      result.push({
        display: initDate.add(i, 'month').format('MMM YYYY'),
        month: initDate.add(i, 'month').month(),
        year: initDate.add(i, 'month').year()
      })
    }
    return result;
  }

  initialOptionState(month?: number, year?: number) {
    let haveDate = false;
    if (month && year) {
      haveDate = true;
    }
    const fm = Number(month ? month - 1 : dayjs().month());
    const fy = Number(year ? year : dayjs().year());
    let found = this.options.date.find(d => d.month === fm && d.year === fy);
    if (!found && haveDate) {
      this.options.date.push({
        display: dayjs().month(Number(fm)).year(Number(fy)).format('MMM YYYY'),
        month: Number(fm),
        year: Number(fy)
      })
      found = this.options.date[this.options.date.length - 1];
    }
    return {
      date: found,
      project: '',
      vendor: '',
      department: ''
    }
  }

  onClickList(data: TimesheetInfo) {
    const {projectName, employee: {empID}} = data;
    const {month, year} = this.optionForm.get('date').value;
    this.clickList.emit({
      projectName,
      empID,
      month,
      year
    });
  }

}

interface TCCache {
  [key: string]: Observable<TimesheetInfo[]>
}
