<div class="col text-center">
  <div class="row">
    <div class="line col-12 row">
      <small *ngIf="getIsDisplayApprovalComment" class="col-auto align-self-end" style="margin-bottom: 0.8rem;">
        Approve Reason :
      </small>
      <ul id="line" [ngClass]="getIsDisplayApprovalComment ? 'col-10 pl-0' : 'col-12'" style="margin-top: 0px; margin-bottom: 0px;">
        <li *ngFor="let approval of approvalState; index as i"
            [class.passed]="approval.approved"
            [class.active]="i == getLastApprovedIndex"
        >
          <p style="font-size: 16px; margin-bottom: 0.5rem;">
            <small [title]="approval.approverName">{{approval.approverName | textTruncate: 15}}</small>
            <br>
            <small *ngIf="approval.approveDate && approval.approved">{{ approval.approveDate |  date:'dd MMM yyyy HH:mm' }}</small>
          </p>
          <div *ngIf="approval.rejectReason" data-tooltip="{{approval.rejectReason}}">
            <p [ngClass]="approval.approved ? 'text-muted' : 'text-danger'" title="{{approval.rejectReason}}" style="font-size: 12px;">
              {{approval.rejectReason | textTruncate: 15}}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
