<div class="row">
  <div class="col">
    <form [formGroup]="fg">
      <div class="form-group row">
        <label for="field" class="col-sm-2 col-form-label">Field</label>
        <div class="col-sm-10">
          <input type="text" class="form-control" id="field"
                 [class.is-invalid]="fg.controls['name'].hasError('required') && fg.controls['name'].touched"
          formControlName="name">
          <div>
            <small class="text-danger"
                   *ngIf="fg.controls['name'].hasError('required') && fg.controls['name'].touched"
            >Please provide field name.</small>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="sequenceNo" class="col-sm-2 col-form-label">Sequence No</label>
        <div class="col-sm-10">
          <input type="number" class="form-control" id="sequenceNo"
                 formControlName="sequenceNo">
        </div>
      </div>

      <div class="form-group row">
        <label for="description" class="col-sm-2 col-form-label">Description</label>
        <div class="col-sm-10">
          <textarea class="form-control" id="description" rows="4" formControlName="description"></textarea>
        </div>
      </div>

      <div class="form-group row">
        <label for="active" class="col-sm-2 col-form-label">Active</label>
        <div class="col-sm-1">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="active"
            formControlName="active">
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="disable" class="col-sm-2 col-form-label">Disable</label>
        <div class="col-sm-1">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="disable"
                   formControlName="disable">
          </div>
        </div>
      </div>

      <fieldset class="form-group row">
        <legend class="col-form-label col-sm-2 float-sm-left pt-0">Field type</legend>
        <div class="col-sm-10">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="choice" value="CHOICE"
            formControlName="type">
            <label class="form-check-label" for="choice"
                   [class.text-danger]="fg.controls['type'].hasError('required') && fg.controls['type'].touched"
            >Choice</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="text" value="TEXT"
            formControlName="type">
            <label class="form-check-label" for="text"
                   [class.text-danger]="fg.controls['type'].hasError('required') && fg.controls['type'].touched"
            >Text</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="location" value="LOCATION"
                   formControlName="type">
            <label class="form-check-label" for="location"
                   [class.text-danger]="fg.controls['type'].hasError('required') && fg.controls['type'].touched"
            >Location</label>
          </div>
          <div>
            <small class="text-danger"
                   *ngIf="fg.controls['type'].hasError('required') && fg.controls['type'].touched"
            >Please select one.</small>
          </div>
        </div>
      </fieldset>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="targetGroup">Target Group</label>
        <div class="col-sm-9">
          <select class="form-control" formControlName="targetGroup" id="targetGroup">
            <option value="{{o}}" *ngFor="let o of targetGroup">{{o}}</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="possibleValue" class="col-sm-2 col-form-label">Possible value</label>
        <div class="col-sm-10">
          <div class="mb-2" *ngIf="fg.controls['type'].value === 'CHOICE'">
            <label class="btn btn-outline-warning rounded-pill py-1 px-2 m-1 text-dark" *ngFor="let v of fg.get('possibleValue').value; index as i">
              {{v}} <span class="ml-5" (click)="onRemovePossibleValue(i)" ><b>X</b></span>
            </label>
          </div>
          <div class="input-group flex-nowrap">
            <input type="text" class="form-control" placeholder="Possible Value" aria-label="Possible Value" aria-describedby="addon-wrapping"
                   id="possibleValue" [disabled]="fg.controls['type'].value !== 'CHOICE'"
                   (keyup.enter)="onAddPossibleValue()"
                   [(ngModel)]="possibleValueTxt"
                   [ngModelOptions]="{standalone: true}"
                   autocomplete="off"
            >
            <div class="input-group-prepend" (click)="onAddPossibleValue()">
              <span class="input-group-text" id="addon-wrapping">ADD</span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row" *ngIf="fg.controls['type'].value === 'CHOICE'">
        <label class="col-sm-2 col-form-label" for="defaultValue">Default Value</label>
        <div class="col-sm-9">
          <select class="form-control" formControlName="defaultValue" id="defaultValue">
            <option value="{{o}}" *ngFor="let o of fg.get('possibleValue').value">{{o}}</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="csvFile" class="col-sm-2 col-form-label">CSV File</label>
        <div class="col-sm-9">
          <div class="custom-file">
            <input type="file" class="custom-file-input" accept="text/csv" (change)="onFileChange($event.target.files, dFileName)" id="csvFile">
            <label class="custom-file-label" for="csvFile" #dFileName >Choose file</label>
          </div>
        </div>
        <div class="col-sm-1">
          <button class="btn btn-secondary btn-block" (click)="onClearFile(dFileName)">CLEAR</button>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-1 text-right ml-auto pr-1">
          <button type="button" class="btn btn-secondary btn-block" (click)="onBack()">Back</button>
        </div>
        <div class="col-1 text-right px-1">
          <button type="button" class="btn btn-primary btn-block" (click)="onSubmit()">Submit</button>
        </div>
        <div class="col-1 text-right px-1" *ngIf="fg.get('id').value">
          <button type="button" class="btn btn-danger btn-block" (click)="onDelete()">Delete</button>
        </div>
      </div>
    </form>
  </div>
</div>

<app-loading-overlay [display]="loading"></app-loading-overlay>
