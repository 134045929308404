
<app-layout>
  <div class="container-fluid mt-4">
    <h4>
      <b><a (click)="isNewRequest = false" class="text-click-able">MEDICAL REQUEST</a>
        <span *ngIf="isNewRequest && !mediCalBufferOnEdit" class="text-head"> > NEW</span>
        <span *ngIf="isNewRequest && mediCalBufferOnEdit" class="text-head"> > EDIT</span>
      </b>
    </h4>
    <hr class="set">
    <ng-container *ngIf="!isNewRequest else newRequest">

      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="request_date">Request Date</label>

        <div class="col-sm-2 calendar">
          <label>
            <input   type="text" placeholder="Please select date" id="request_date" [(ngModel)]="dateRang.startDate"
              class="form-control text-show" bsDatepicker [bsConfig]="bsConfig" (bsValueChange)="onDateChange($event,null)"
              [maxDate]="dateLimit().maxDate">
          </label>
        </div>
        <label class="col-sm-1 col-form-label " for="request_date">to</label>
        <div class="col-sm-2 calendar">
          <label>
            <input  type="text" placeholder="Please select date" [(ngModel)]="dateRang.endDate"
              class="form-control text-show ml-lg-n5" bsDatepicker [bsConfig]="bsConfig"
              (bsValueChange)="onDateChange(null,$event)" [minDate]="dateLimit().minDate">
          </label>
        </div>
        <div class="col-sm-5">
          <!-- <button class="btn btn-info mb-2 float-right" *ngIf="noData == false && medicalList" (click)="exportCSV()">Export
            CSV
          </button> -->
          <button  class="btn btn-info" (click)="onNewRequest()">NEW REQUEST</button>
        </div>
      </div>

      <div class="row">
        <!-- <div class="col-md-12 text-right">

          <button class="btn btn-info mb-2" *ngIf="noData == false && medicalList" (click)="exportCSV()">Export
            CSV
          </button>
        </div> -->
      </div>
      <!-- <div class="container-fluid"> -->

        <table class="table" id="dataTable">
          <thead class="dt-head">
            <tr>
              <th class="col-label">RECEIPT NO.</th>
              <th class="text-show">RECEIPT DATE</th>
              <th class="text-show">HOSPITAL</th>
              <th class="col-label">GROUP</th>
              <th class="text-show">AMOUNT</th>
              <th class="col-label">STATUS</th>
              <th class="col-trash"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of medicalList">
              <td class="col-label">
                <a  (click)="onEditRow(d)" class="text-click-able text-info"> {{d.receiptNo}} </a>
              </td>
              <td class="text-show">
                {{d.receiptDate | date: 'dd MMM yyyy'}}
              </td>
              <td class="text-show">
                {{d.hospital === "อื่นๆ (Others)" ? d.other : d.hospital}}
              </td>
              <td class="col-label">
                {{d.claimGroup.toUpperCase()}}
              </td>
              <td class="text-show text-number">
                {{d.amount | number : '1.2-2'}}
              </td>
              <td class="col-label text-capitalize">
                {{d.status?d.status.toLowerCase():'-'}}
              </td>
              <td  class="col-trash">
                <button *ngIf="d.empID == currentUser?.empID && d.status.toUpperCase() !== 'SUBMIT'" class="btn btn-outline-danger"  (click)="deleteItem(d)">
                  <fa-icon [icon]="faIcon.faTrash"></fa-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td class="text-center" *ngIf="noData" colspan="8">
                No data.
              </td>
            </tr>
          </tbody>
        </table>
  <!-- </div> -->
    </ng-container>

    <ng-template #newRequest>
      <app-new-request-medical (Done)="onDone($event)" [mediCalBufferOnEdit]="mediCalBufferOnEdit">
      </app-new-request-medical>
    </ng-template>
  </div>
</app-layout>
<app-loading-overlay [display]="loading"></app-loading-overlay>
