<div class="modal-header">
    <h4 class="modal-title">Confirm</h4>
    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"
        (click)="activeModal.close({ confirm: false })">x</button>
</div>
<form [formGroup]="form" *ngIf="showTextInput">
    <div class="modal-body">
        <div class="container">
            <div class="py-2">
                <h5 [innerHTML]=body></h5>
            </div>
            <div class="form-group">
                <textarea class="form-control" placeholder="{{placeholder}}" formControlName="reason" rows="3"
                    id="reason" name="reason"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn {{btnConfirmColor ? btnConfirmColor : 'btn-primary'}}" [disabled]="!form.valid"
            (click)="submitForm()">
            {{ btnTextConfirm }}
        </button>
        <button class="btn btn-secondary" (click)="activeModal.close({ confirm: false })">
            {{ btnTextDecline }}
        </button>
    </div>
</form>

<div *ngIf="!showTextInput">
    <div class="modal-body" [innerHTML]=body></div>
    <div class="modal-footer">
        <button class="btn {{btnConfirmColor ? btnConfirmColor : 'btn-primary'}}" (click)="submitForm()">
            {{ btnTextConfirm }}
        </button>
        <button class="btn btn-secondary" (click)="activeModal.close({ confirm: false })">
            {{ btnTextDecline }}
        </button>
    </div>
</div>