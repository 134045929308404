import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  Inject,
} from '@angular/core';
import { DisburesementService } from 'src/app/core/services/disburesement.service';
import { DisbursementInterface } from 'src/app/core/models/disbursement.model';
import * as moment from 'moment';

import { Subject } from 'rxjs';
import { ExportToCsv } from 'export-to-csv';
import { faIcon } from 'src/app/core/icon/fa.icon';
import { map } from 'rxjs/operators';
import { FormArray, FormControl } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-allowance',
  templateUrl: './allowance.component.html',
  styleUrls: ['./allowance.component.scss'],
})
export class AllowanceComponent implements OnInit {
  constructor(private disburesementService: DisburesementService) {}
  // ngAfterViewInit(): void {
  //   // this.dtTrigger.next();
  // }
  List: DisbursementInterface[] = [];
  startDate;
  endDate;
  optionSearchModel;
  optionSearch = [
    { text: 'ALL', value: 'all' },
    { text: 'BY LAST EXPORT DATE', value: 'last' },
    { text: 'NEVER  EXPORT&CAN CLAIM', value: '1' },
    { text: 'NEVER EXPORT', value: '-' },
  ];
  faIcon = faIcon;
  showLastUpdate = false;
  lastExportDate;
  lastExportDateModel;
  formRows = new FormArray([]);

  ngOnInit(): void {
    this.optionSearchModel = this.optionSearch.find((d) => d).value;
    // disable alert error
  }
  onOptionSearchChange() {
    this.showLastUpdate = !!(this.optionSearchModel == 'last');
    if (!this.showLastUpdate) this.lastExportDate = '';

    if (this.optionSearchModel == '-') this.lastExportDate = '-';
    if (this.optionSearchModel == '1') this.lastExportDate = '1';
  }
  onLastExportChange(event) {
    console.log(event);
    this.lastExportDate = this.convertDate(event);
    console.log(this.lastExportDate);
  }
  loadList(start, end, lastExportDate) {
    this.disburesementService
      .getRequestDisbursementList(start, end, lastExportDate)
      .pipe(
        map((d) => {
          d = d.map((c) => {
            c.edit = false;
            c.saving = false;
            // c.invoiceNo = c.invoiceNo.split(',').join('<br>');
            return c;
          });
          return d;
        })
      )
      .toPromise()
      .then((d) => {
        this.List = d;
      })
      .catch((e) => {});
  }
  onDateChange([startDate, endDate]) {
    this.startDate = this.convertDate(startDate);
    this.endDate = this.convertDate(endDate);
    //
  }
  edit(id) {
    const elClick = this.List.find((d) => d.id == id);

    this.updateList(id);

    if (!elClick.edit) {
      this.List.map((d) => {
        if (d.id == id) d.saving = true;
        return d;
      });

      let el = <HTMLInputElement>document.getElementById('edit-' + id);
      let body = {
        id,
        amount: el.value,
      };
      this.disburesementService
        .updatePayAmount(body)
        .toPromise()
        .then((r) => {
          this.onSearch();
          this.List.map((d) => {
            if (d.id == id) d.saving = false;
            return d;
          });
        })
        .catch((er) => console.error);
    }
  }
  updateList(id: any) {
    this.List.map((d) => {
      if (d.id == id) d.edit = !d.edit;
      return d;
    });
  }
  convertDate(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }
  getLastMount(date) {
    return moment(date).endOf('month').format('DD/MM/YYYY');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
  }
  convertAmount(number: number): string {
    let amountStr = number.toFixed(2).toString();
    let [firtDigit, LastDigit] = amountStr.split('.');

    firtDigit = this.fillZero(firtDigit);
    console.log('last', LastDigit);
    let sumAmountText = firtDigit + LastDigit;
    return sumAmountText;
  }
  fillZero(str: string) {
    while (str.length < 12) {
      str = '0' + str;
    }
    return str;
  }
  onSearch() {
    this.loadList(this.startDate, this.endDate, this.lastExportDate);
  }
  logDetail(log: string) {
    let result;
    switch (log) {
      case 'Not pass pro date.':
        result = 'ยังไม่ผ่านโปร.';
        break;
      case 'Invoice number is duplicate.':
        result = 'เลขที่ใบเสร็จเคยมีการเบิก.';
        break;
      case 'The invoice is created before the job starts.':
        result = 'วันที่ใบเสร็จก่อนเริ่มงาน.';
        break;
      case 'Invoices are unavailable this year.':
        result = 'วันที่ใบเสร็จก่อนไม่สามารถใช้งานได้ในปีนี้';
        break;
      case 'Not yet completed a year of work':
        result = 'ไม่สามารถเบิกเงินได้เต็มจำนวน (ทำงานไม่ครบ 1 ปี)';
        break;
      case 'Limit.':
        result = 'เบิกเงินครบจำนวนที่สามารถเบิกได้';
        break;
      // default:
      //   break;
    }
    return result;
  }
  exportThisPage() {
    let data = this.List.map((d) => {
      let result1 = {
        id: d.id,
        key: d.jiraKey ? d.jiraKey : '-',
        'reviwed date': d.reviewedDate
          ? moment(d.reviewedDate).format('DD-MM-YYYY')
          : '-',
        'request date on jira': d.requestDate
          ? moment(d.requestDate).format('DD-MM-YYYY')
          : '-',
        company: d.company,
        amount: d.amount,
        payable: d.payableAmount,
        empId: d.empId,
        'e-mail': d.email,
        'end pro date': moment(d.endProDate).format('DD-MM-YYYY'),
        'create date on hr': this.getLastMount(d.createDate),
        'invoice date': moment(d.invoiceDate).format('DD-MM-YYYY'),
        'last export date': d.lastExport
          ? moment(d.lastExport).format('DD-MM-YYYY')
          : '-',

        element: d.elementName,
        status: d.status ? d.status : '-',
        reason: d.reason ? d.reason : '-',
        logs: d.errorLog ? this.logDetail(d.errorLog) : '-',
      };

      return result1;
    });
    const optionsJsonToEx = {
      filename: 'WFH-List-Datail',
      fieldSeparator: ',',
      quoteStrings: '',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(optionsJsonToEx);
    csvExporter.generateCsv(data);
  }
  async exportCSV() {
    if (this.List.length < 0) return;
    try {
      const idList = this.List.filter((d) => d.payableAmount > 0).map(
        (d) => d.id
      );
      let result = await this.disburesementService
        .setLastExportDate(idList)
        .toPromise();

      let data = this.List.filter((d) => d.payableAmount > 0).map((d) => {
        let result1 = {
          company: d.company,
          empId: d.empId,
          fix: '0',
          date: this.getLastMount(d.createDate),
          element: d.elementName,
          payable: this.convertAmount(d.payableAmount),
        };

        return result1;
      });
      const optionsJsonToEx = {
        filename: 'request_allowance',
        fieldSeparator: ',',
        quoteStrings: '',
        decimalSeparator: '.',
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
      };

      const csvExporter = new ExportToCsv(optionsJsonToEx);
      csvExporter.generateCsv(data);

      this.loadList(this.startDate, this.endDate, this.lastExportDate); //reload
    } catch (e) {}
  }
}
