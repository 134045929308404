
  <div class="container-fluid mt-4 pl-5 pr-5">
    <!--      [routerLink]="['/intern-timesheet']"-->
    <div>
      <h3 class="clickable" (click)="back()" style="display: inline">
        <b>Intern Timesheet </b>
      </h3>

      <h3 style="display: inline" *ngIf="displayName">
        <b>
          <fa-icon [icon]="faIcon.faChevronRight"></fa-icon>
        </b>
        <b> {{displayName}}</b>
      </h3>
    </div>
    <hr style="border-top: 1px solid #ffa553">
    <ng-container *ngIf="(isHr$ | async)">
      <div [ngSwitch]="display">
        <app-intern-timesheet-list  *ngSwitchCase="'list'" (clickList)="info($event)"></app-intern-timesheet-list>
        <app-monthly-detail *ngSwitchCase="'detail'" [timesheetKeyInfo]="timesheetKeyInfo" [IsInternPage]="true" (name)="setName($event)" [openByAdminPage]="true"></app-monthly-detail>
      </div>
    </ng-container>

    <div *ngIf="!(isHr$ | async) && (this.emp$ | async)" class="text-center align-items-center">
      NO PERMISSION
    </div>
    <app-loading-overlay [display]="!(this.emp$ | async)"></app-loading-overlay>
  </div>

