import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import * as dayjs from 'dayjs';
import {Observable, of, Subscription} from 'rxjs';
import {OutsourceTimesheetService} from '../../../../core/services/outsource-timesheet.service';
import {InternStatusEnum, TimesheetInfo, TimesheetKeyInfo} from '../../../../core/models/timesheetInfo';
import {map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {ProjectService} from '../../../../modules/outsource-time-sheet/core/services/project.service';
import {EmployeeService} from '../../../../core/services/employee.service';
import {OutsourceWeeklyModel} from '../../../../modules/outsource-time-sheet/core/models/outsource.weekly.model';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {faIcon} from '../../../../core/icon/fa.icon';
import {Employee} from '../../../../core/models/employee';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-timesheet-list',
  templateUrl: './timesheet-list.component.html',
  styleUrls: ['./timesheet-list.component.scss']
})
export class TimesheetListComponent implements OnInit, OnDestroy {

  outsourceType = [
    {value: 'IT_OUTSOURCE', display: 'IT Outsource'},
    {value: 'INTERN', display: 'Intern'},
    {value: 'INTERN_COOP', display: 'Intern Coop'}
  ]
  @Output() clickList: EventEmitter<TimesheetKeyInfo> = new EventEmitter<TimesheetKeyInfo>();
  loading = false;
  loadList = true;
  optionForm: FormGroup;
  sub: Subscription[] = [];
  showReviewBTN$: Observable<boolean> = new Observable<boolean>();
  showApproveBTN$: Observable<boolean> = new Observable<boolean>();
  options = {
    date: [...this.initialDate],
    project$: this.projectService.allProject(),
    vendor$: this.projectService.getAllvendor()
  }
  timesheetList$: Observable<TimesheetInfo[]>
  timesheetList: TimesheetInfo[] = []
  canApprove: {
    [key: string]: Observable<boolean>
  } = {};
  timesheetListBackup$: {
    [key: string]: Observable<TimesheetInfo[]>
  } = {};
  canReview$: {
    [key: string]: Observable<boolean>
  } = {};
  faIcon = faIcon;
  profile: Employee;

  constructor(
    private outsourceTimesheetService: OutsourceTimesheetService,
    private projectService: ProjectService,
    private fb: FormBuilder,
    private employee: EmployeeService,
    private route: ActivatedRoute,
    private outsourceTimesheet: OutsourceTimesheetService
  ) {
    this.employee.me().toPromise().then(e => this.profile = e)
  }

  get projectSelected(): FormControl {
    return this.optionForm.get('project') as FormControl
  }

  get vendorSelected(): FormControl {
    return this.optionForm.get('vendor') as FormControl
  }

  public get dateSelected(): FormGroup {
    return this.optionForm.get('date') as FormGroup;
  }

  public get typeSelected(): FormGroup {
    return this.optionForm.get('type') as FormGroup;
  }

  get initialDate() {
    const result = [];
    const initDate = dayjs(new Date()).subtract(6, 'month')
    for (let i = 0; i < 8; i++) {
      result.push({
        display: initDate.add(i, 'month').format('MMM YYYY'),
        month: initDate.add(i, 'month').month(),
        year: initDate.add(i, 'month').year()
      })
    }
    return result;
  }

  private static mapForSortable(t: TimesheetInfo) {
    t.sortEmpId = t.employee.empID;
    t.sortEmpName = t.employee.nameEn
    t.sortSupervisor = t.reviewer.nameEn
    return t;
  }

  public separateName(name: string) {
    if (!name) {
      return ''
    }
    return name.split(' ').slice(0, -1).join(' ');
  }

  log() {
    // console.log(this.optionForm.get('date').value);
  }

  ngOnInit(): void {
    const {month, year} = this.route.snapshot.params;
    this.optionForm = this.fb.group(this.initialOptionState(month, year))
    this.initialList();
    this.subscriptOptionForm();


    const defaultSelect = this.getTimeSelected();
    if (defaultSelect) {
      this.dateSelected.patchValue({
        display: defaultSelect.display,
        month: defaultSelect.month,
        year: defaultSelect.year
      })
    }
  }

  public async onReview(detail: TimesheetInfo) {
    const {value} = await this.confirmAction('review');
    if (!value) {
      return;
    }
    this.loading = true;
    const {month, year} = this.dateSelected.value;
    this.outsourceTimesheet.getMonthlyTimesheetDetail(detail.employee.empID, month, year, detail.projectName)
      .pipe(switchMap(d => this.outsourceTimesheet.save(d as OutsourceWeeklyModel, true)))
      .toPromise()
      .then(() => {
        this.alert(true, 'The Timesheet has been reviewed.')
        this.loading = false;
        this.timesheetListBackup$[month + ',' + year] = undefined;
        this.initialList();

      })
      .catch(() => {
        this.alert(false, 'something went wrong.');
        this.loading = false;
      })
  }

  cacheIsApproval(month: number, year: number) {
    if (!this.canApprove[month + ',' + year]) {
      this.canApprove[month + ',' + year] = this.outsourceTimesheetService.isApproval(month, year).pipe(shareReplay(1))
    }
    return this.canApprove[month + ',' + year];
  }

  initialOptionState(month?: number, year?: number) {
    let haveDate = false;
    if (month && year) {
      haveDate = true;
    }
    const fm = Number(month ? month - 1 : dayjs().month());
    const fy = Number(year ? year : dayjs().year());
    let found = this.options.date.find(d => d.month === fm && d.year === fy);
    if (!found && haveDate) {
      this.options.date.push({
        display: dayjs().month(Number(fm)).year(Number(fy)).format('MMM YYYY'),
        month: Number(fm),
        year: Number(fy)
      })
      found = this.options.date[this.options.date.length - 1];
    }
    return {
      date: found,
      project: 'all',
      vendor: 'all',
      type: 'all'
    }
  }

  public rewriteStatus(t: TimesheetInfo): string {
    switch (t.status) {
      case 'PENDING_REVIEW':
        return `Pending For Review`
      case 'REVIEWED':
        return `Reviewed`
      case 'REJECTED':
        return `Rejected`
      case 'APPROVED':
        return `Approved By ${t.approval.nameEn}`
      case 'NOT_SUBMIT':
        return '-'
      case 'PAYMENT':
        return 'Payment'
      case 'NEED_REVIEWING':
        return 'Need Reviewing'
      default:
        return t.status ? t.status.toUpperCase() : '-'  // *t.status ? t.status.toUpperCase() :
    }
  }

  async actionAll(status: 'APPROVED' | 'REJECTED' | 'REVIEWED') {
    this.loading = true;
    const {date: {month, year}} = this.optionForm.getRawValue();
    const body = {
      status,
      month,
      year
    }
    const nStatus = status.toLowerCase()
      .replace('ved', 'veed')
      .replace('ed', '');
    const sweetAlertResultPromise = await this.confirmAction(nStatus);

    if (!sweetAlertResultPromise.value) {
      this.loading = false;
      return 0;
    }
    switch (status) {
      case 'REJECTED':
      case 'APPROVED':
        this.outsourceTimesheetService.saveActionAll(body as OutsourceWeeklyModel)
          .toPromise()
          .then(() => {
            this.loading = false;
            this.alert(true, 'Successfully ')
            // clear cache
            this.timesheetListBackup$[month + ',' + year] = undefined;
            this.initialList();
          })
          .catch(() => {
            this.loading = false;
            this.alert(false, 'Something went wrong please try again later')
          })
        break;
      case 'REVIEWED':
        const filterProject = this.optionForm.get('project').value;
        const filterVendor = this.optionForm.get('vendor').value;
        this.outsourceTimesheetService.saveReviewAll(body as OutsourceWeeklyModel, filterProject, filterVendor)
          .toPromise()
          .then(() => {
            this.loading = false;
            this.alert(true, 'Successfully ')
            // clear cache
            this.timesheetListBackup$[month + ',' + year] = undefined;
            this.initialList();
          })
          .catch(() => {
            this.loading = false;
            this.alert(false, 'Something went wrong please try again later')
          })
        break;
    }
  }

  alert(isSuccess, msg) {
    Swal.fire({
      scrollbarPadding: false,
      title: isSuccess ? 'Successfully' : 'Error',
      icon: isSuccess ? 'success' : 'error',
      text: msg,
      buttonsStyling: false,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    });
  }

  onClickList(data: TimesheetInfo) {
    const {projectName, employee: {empID}} = data;
    const {month, year} = this.dateSelected.value;
    this.clickList.emit({
      projectName,
      empID,
      month,
      year
    });
  }

  confirmAction(action: string) {
    return Swal.fire({
      scrollbarPadding: false,
      icon: 'question',
      text: `Are you sure you want to ${action} this timesheet`,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: `${action.toUpperCase()}`,
      focusCancel: true,
      customClass: {
        confirmButton: 'btn btn-success text-light mr-2',
        cancelButton: 'btn btn-secondary text-light',
      },
    });
  }

  ngOnDestroy(): void {
    this.sub.forEach(a => a.unsubscribe())
  }

  private initialList() {
    this.loadList = true;
    const {month, year} = this.optionForm.get('date').value;
    this.showReviewBTN$ = this.cacheCanReview(month, year);
    this.showApproveBTN$ = this.cacheIsApproval(month, year);
    this.getTimesheet(month, year);
  }

  setTimeSelected(value: any) {
    sessionStorage.setItem('date-selected', JSON.stringify(value));
  }

  getTimeSelected() {
    const v = sessionStorage.getItem('date-selected')
    if (v) {
      return JSON.parse(v)
    }
    return null;
  }

  private cacheCanReview(month, year) {
    if (!this.canReview$[month + ',' + year]) {
      this.canReview$[month + ',' + year] = this.outsourceTimesheetService.canReview(month, year).pipe(map(r => r.success), shareReplay(1))
    }
    return this.canReview$[month + ',' + year];
  }

  private getTimesheet(month, year) {

    this.timesheetList$ = this.cacheIsApproval(month, year).pipe(
      switchMap(e => {
        return this.cacheTimesheet(month, year, e);
      }),
      map((p: TimesheetInfo[]) => p.filter((a: TimesheetInfo) => this.filterProjectAndVendor(a))),
      tap(v => {
          this.showReviewBTN$ = of(v.some(y => y.status === 'NEED_REVIEWING')).pipe(switchMap(havePending => {
            return havePending ? this.cacheCanReview(month, year) : of(false);
          }))
          this.showApproveBTN$ = of(v.some(y => y.status === 'REVIEWED' && y.approval.empID === this.profile.empID))
            .pipe(switchMap(haveReviewed => {
              return haveReviewed ? this.cacheIsApproval(month, year) : of(false);
            }));
          this.loadList = false;
        }
      ));
    this.timesheetList$.toPromise().then(r => {
      if (this.typeSelected.value === 'all') {
        this.timesheetList = r
      } else {
        this.timesheetList = r.filter(o => o.type === this.typeSelected.value)
      }
    })
      .catch(() => this.timesheetList = [])
  }

  private cacheTimesheet(month, year, isApproval: boolean) {
    if (!this.timesheetListBackup$[month + ',' + year]) {
      this.timesheetListBackup$[month + ',' + year] = this.request(isApproval, month, year)
        .pipe(map(tc => {
          tc.map(t => {
            t.status = t.status === 'PENDING_REVIEW' && this.profile.empID === t.reviewer.empID
              ? InternStatusEnum.NEED_REVIEWING : t.status;
            return t;
          })
          return tc
        }))
        .pipe(shareReplay(1))
    }
    return this.timesheetListBackup$[month + ',' + year];
  }

  private request(isApproval: boolean, month, year) {
    return this.outsourceTimesheetService.getTimesheet(month, year)
      .pipe(map(tc => tc.map(t => TimesheetListComponent.mapForSortable(t))))

  }

  private filterProjectAndVendor(a: TimesheetInfo) {
    return (this.projectSelected.value === 'all' || a.projectName === this.projectSelected.value)
      && (this.vendorSelected.value === 'all' || a.vendor === this.vendorSelected.value)
  }

  public compareWith() {
    return (a: OptionDate, b: OptionDate) => a.year === b.year && a.month === b.month
  }

  private subscriptOptionForm() {
    this.loadList = true;
    this.sub.push(this.optionForm.get('date').valueChanges.subscribe(() => {
      this.loadList = true
      const {month, year} = this.optionForm.get('date').value;
      this.setTimeSelected(this.dateSelected.value)
      this.showReviewBTN$ = this.cacheCanReview(month, year);
      this.getTimesheet(month, year);
    }))
    this.sub.push(this.optionForm.get('vendor').valueChanges.subscribe(() => {
      this.loadList = true;
      this.initialList()
    }))
    this.sub.push(this.optionForm.get('project').valueChanges.subscribe(() => {
      this.loadList = true;
      this.initialList();
    }))
    this.sub.push(this.optionForm.get('type').valueChanges.subscribe(() => {
      this.loadList = true;
      this.initialList();
    }))
  }

}

interface OptionDate {
  display: string,
  month: number;
  year: number;
}
