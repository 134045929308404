<div class="container-fluid">
  <div class="col-12" style="min-height: 40vh; overflow: scroll">
    <!-- <div class="col-12" style="overflow: scroll;"> -->

    <div class="row">
      <div class="col mt-2 mb-2">
        <button
          *ngIf="isActive"
          type="submit"
          class="btn btn-primary"
          (click)="newRequest(requestDetailComponent.template, null)"
        >
          NEW REQUEST
        </button>
        <div *ngIf="!isActive">
          <h4 style="color: red;"><b>ไม่สามารถทำรายการได้เนื่องจาก {{reason}}</b></h4>
        </div>
      </div>
      <div>
        <td
          *ngIf="isDataFilter"
          (click)="getRequestList()"
          class="col-header"
          style="cursor: pointer; color: orange; padding-top: 15px"
        >
          View All
        </td>
      </div>

      <div class="col-3 mt-2 mb-2">
        <form [formGroup]="searchForm">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Course Name"
              formControlName="keyword"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                (click)="searchRequest()"
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <table class="table">
          <thead class="custom-dark-bg text-white-smoke">
            <tr>
              <th></th>

              <th class="col-header">REQ ID</th>
              <th class="col-header">COURSE</th>
              <th class="col-header">TRAINING DATE</th>
              <th class="col-header">AMOUNT (BAHT)</th>
              <th class="col-header">REQUEST DATE</th>
              <th class="col-header">REQUESTER</th>
              <th class="col-header">STATUS</th>
              <th class="col-header">PENDING AT</th>
              <th class="col-header">PR ID</th>
            </tr>
          </thead>
          <tbody *ngIf="requestList">
            <tr *ngFor="let r of requestList; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ r.id }}</td>
              <!-- <td (click)="newRequest(requestDetailComponent.template, r)"
                style="cursor: pointer; text-decoration: underline; color: deepskyblue">{{r.course.courseDescr}}</td> -->
              <td
                (click)="newRequest(requestDetailComponent.template, r)"
                style="cursor: pointer; color: deepskyblue"
              >
                {{ r.course.courseDescr }}
              </td>

              <!-- <td *ngIf="r.startDate !== r.endDate">{{r.startDate | date: 'dd MMM y'}} -
                {{r.endDate | date: 'dd MMM y'}}</td>
              <td *ngIf="r.startDate === r.endDate">{{r.startDate | date: 'dd MMM y'}}</td> -->
              <td>{{ r.DisplayDate }}</td>
              <td>{{ r.totalAmount | number }}</td>
              <td>{{ r.createDate | date: 'dd MMM y' }}</td>
              <td>{{ r.employee.nameTh }}</td>
              <td>{{ r.DisplayState }}</td>
              <!-- <td>{{r.approvalState}}</td> -->
              <!-- <td>{{r.pendingAt}}</td> -->
              <td>
                {{
                  r.approvalState === 'PENDING HR'
                    ? userRoles[0]?.employee?.nameTh
                    : r.pendingName
                }}
                <span
                  style="cursor: pointer"
                  *ngIf="
                    r.approvalState === 'PENDING HR' && userRoles.length > 1
                  "
                  [tooltip]="popTemplate"
                  placement="auto"
                  >...</span
                >
              </td>
              <td>{{ r.prId }}</td>
            </tr>
          </tbody>
          <tbody *ngIf="requestList.length === 0">
            <tr>
              <td colspan="8">
                <div class="d-flex justify-content-center">
                  <span style="color: darkgrey">No Request</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #popTemplate>
  <div [innerHtml]="tooltipHtml"></div>
</ng-template>

<app-request-detail
  #requestDetailComponent
  [modalRef]="modalRef"
  [requestDetail]="requestDetail"
  (loadData)="getRequestList()"
  [isActive]="isActive"
  [serviceReason]="reason"
></app-request-detail>
