import { Component, OnInit, Input, OnChanges, TemplateRef, Output, EventEmitter } from '@angular/core';

import { faIcon } from 'src/app/core/icon/fa.icon';
import Swal from 'sweetalert2';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { Employee } from 'src/app/core/models/employee';
import { TypeaheadMatch, BsModalService, BsModalRef } from 'ngx-bootstrap';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { StrategicProjectTracking } from 'src/app/core/models/strategicProjectTracking';
import { StrategicProjectTrackingService } from 'src/app/core/services/strategic-project-tracking.service';

@Component({
    selector: 'app-new-strategic-project',
    templateUrl: './new-strategic-project.component.html',
    styleUrls: ['./new-strategic-project.component.scss'],
})

export class NewStrategicProjectComponent implements OnInit, OnChanges {
    faIcon = faIcon;
    modalRef: BsModalRef;

    @Input() strategicProjectYear:number;
    @Input() strategicProjectName:string;
    @Output() onEmitData = new EventEmitter();
    @Output() onCheckStrategicProjectName = new EventEmitter();

    targets: FormArray;
    strategicForm = this.fb.group({
        target: [{ value: '', disabled: false }],
        targets: this.fb.array([])
    });
    confirmationWording:string = '';
    confirmationStatus:string = '';
    targetIndex:number = 0;
    approverTag = [];
    coApproverTag = [];
    reviewerTag = [];
    coReviewerTag = [];
    creatorTag = [];
    employeeList: Employee[] = [];
    budgetProjectTag = [];
    budgetProjectList = [];

    constructor(
        private fb: FormBuilder,
        private modalService: BsModalService,
        private strategicProjectTrackingService: StrategicProjectTrackingService,
        private employeeService: EmployeeService
    ) {}

    ngOnChanges() {
        this.targets = this.strategicForm.get('targets') as FormArray;
        console.log('strategicProjectYear', this.strategicProjectYear);
        if(this.strategicProjectYear) {
            this.getBudgetProjectByYear(this.strategicProjectYear - 543);
        }
    }

    ngOnInit() {
        this.targets = this.strategicForm.get('targets') as FormArray;
        this.getAllEmployee();
    }

    get strategicFormGroup() {
        return this.strategicForm.controls;
    }

    get targetList() {
        let data = <FormArray>this.strategicForm.get('targets');
        return data;
    }

    getAllEmployee() {
        this.employeeService.employeeList().toPromise()
        .then(res => {
            this.employeeList = res;
            this.employeeList = this.employeeList.filter(item => {return item.email});
        })
        .catch(err => {
            console.log('err', err);
        })
    }

    getBudgetProjectByYear(year) {
        this.strategicProjectTrackingService.getStrategicProjectByYear(year).toPromise()
        .then(res => {
            this.budgetProjectList = res;
            if(Array.isArray(this.budgetProjectList)) {
                this.budgetProjectList = this.budgetProjectList.map(d => {
                    d.projectName = `${d.project} : ${d.projectName}`;
                    return d;
                })

                let notAvailableProject = {
​                    fiscalYear: 0,
                    id: 0,
                    project: "N/A",
                    projectName: "N/A",
                }
                this.budgetProjectList.push(notAvailableProject);
            }
        })
        .catch(err => {
            console.log('err', err);
        })
    }

    addTarget() {
        let strategicFormDetail =  this.strategicForm.getRawValue();
        if(strategicFormDetail.target) {
            this.targets.push(
                this.fb.group({
                    detail: strategicFormDetail.target,
                    corporateKPIs: false,
                    milestoneQ1Detail: '',
                    milestoneQ2Detail: '',
                    milestoneQ3Detail: '',
                    milestoneQ4Detail: ''
                })
            );

            this.strategicForm.patchValue({
                target: '',
            });
        }
    }

    onShowConfirmationModal(template: TemplateRef<any>, index, status) {
        this.confirmationStatus = status;
        if(status ==='deleteTarget') {
            this.targetIndex = index;
            this.confirmationWording = 'Do you want to cancel this target ?';
            this.modalRef = this.modalService.show(template, { class: 'modal-lg1' })
        } else if(status === 'create') {
            if(this.validateForm()) {
            this.confirmationWording = 'Do you want to create this strategic project tracking ?';
            this.modalRef = this.modalService.show(template, { class: 'modal-lg1' })
            }
        } else if(status === 'back') {
            this.confirmationWording = 'Do you want to go back ?';
            this.modalRef = this.modalService.show(template, { class: 'modal-lg1' })
        }
    }

    confirm() {
        if(this.confirmationStatus === 'deleteTarget') {
            this.modalRef.hide();
            this.targets.removeAt(this.targetIndex);
        } else if (this.confirmationStatus === 'create') {
            this.createStrategicProject();
        } else if (this.confirmationStatus === 'back') {
            this.modalRef.hide()
            this.onEmitData.emit('list');
        }
    }

    createStrategicProject() {
        let strategicFormDetail =  this.strategicForm.getRawValue();
        let allApprover = [];
        if (Array.isArray(this.approverTag)) {
            if (this.approverTag.length > 0) {
                this.approverTag.forEach(data => {allApprover.push(data.email);})
            }
        }

        let allCoApprover = [];
        if (Array.isArray(this.coApproverTag)) {
            if (this.coApproverTag.length > 0) {
                this.coApproverTag.forEach(data => {allCoApprover.push(data.email);})
            }
        }

        let allReviewer = [];
        if (Array.isArray(this.reviewerTag)) {
            if (this.reviewerTag.length > 0) {
                this.reviewerTag.forEach(data => {allReviewer.push(data.email);})
            }
        }

        let allCoReviewer = [];
        if (Array.isArray(this.coReviewerTag)) {
            if (this.coReviewerTag.length > 0) {
                this.coReviewerTag.forEach(data => {allCoReviewer.push(data.email);})
            }
        }

        let allCreator = [];
        if (Array.isArray(this.creatorTag)) {
            if (this.creatorTag.length > 0) {
                this.creatorTag.forEach(data => {allCreator.push(data.email);})
            }
        }

        let allBudgetProject = [];
        if (Array.isArray(this.budgetProjectTag)) {
            if (this.budgetProjectTag.length > 0) {
                this.budgetProjectTag.forEach(data => {allBudgetProject.push(data.project);})
            }
        }

        let targetList = []

        if (Array.isArray(strategicFormDetail.targets)) {
            if(strategicFormDetail.targets.length > 0) {
                strategicFormDetail.targets.forEach(data => {
                    let milestoneQ1 = {
                        milestoneDetail: data.milestoneQ1Detail,
                    }

                    let milestoneQ2 = {
                        milestoneDetail: data.milestoneQ2Detail,
                    }

                    let milestoneQ3 = {
                        milestoneDetail: data.milestoneQ3Detail,
                    }

                    let milestoneQ4 = {
                        milestoneDetail: data.milestoneQ4Detail,
                    }

                    let targetDetail = {
                        detail: data.detail,
                        corporateKPIs: data.corporateKPIs,
                        milestoneQ1: milestoneQ1,
                        milestoneQ2: milestoneQ2,
                        milestoneQ3: milestoneQ3,
                        milestoneQ4: milestoneQ4,
                    }

                    targetList.push(targetDetail);
                })
            }
        }

        let body = {
            approver: allApprover,
            coApprover: allCoApprover,
            reviewer: allReviewer,
            coReviewer: allCoReviewer,
            creator: allCreator,
            projectName: this.strategicProjectName,
            targets: targetList,
            year: this.strategicProjectYear,
            budgetProject: allBudgetProject,
        }


        this.strategicProjectTrackingService.createStrategicProject(body).toPromise()
        .then(res => {
            this.alert(true,'This strategic project has been created.');
            this.modalRef.hide();
            this.onEmitData.emit('list');
        })
        .catch( err => {
            console.log('err', err);
            this.modalRef.hide();
            this.alert(false,'Can not create this strategic project, Please try again.');
        })
    }

    addApproverTag(item) {
        if (!item.status) item.labelId = null;
        let labeltemp = {
            empID: item.empID,
            email: item.email,
            nameEn: item.nameEn
        }
        this.approverTag.push(labeltemp);
    }

    removeApproverTag(item) {
        this.approverTag = this.approverTag.filter((obj) => obj.empID !== item.empID);
    }

    addCoApproverTag(item) {
        if (!item.status) item.labelId = null;
        let labeltemp = {
            empID: item.empID,
            email: item.email,
            nameEn: item.nameEn
        }
        this.coApproverTag.push(labeltemp);
    }

    removeCoApproverTag(item) {
        this.coApproverTag = this.coApproverTag.filter((obj) => obj.empID !== item.empID);
    }

    addReviewerTag(item) {
        if (!item.status) item.labelId = null;
        let labeltemp = {
            empID: item.empID,
            email: item.email,
            nameEn: item.nameEn
        }
        this.reviewerTag.push(labeltemp);
    }

    removeReviewerTag(item) {
        this.reviewerTag = this.reviewerTag.filter((obj) => obj.empID !== item.empID);
    }

    addCoReviewerTag(item) {
        if (!item.status) item.labelId = null;
        let labeltemp = {
            empID: item.empID,
            email: item.email,
            nameEn: item.nameEn
        }
        this.coReviewerTag.push(labeltemp);
    }

    removeCoReviewerTag(item) {
        this.coReviewerTag = this.coReviewerTag.filter((obj) => obj.empID !== item.empID);
    }

    addCreatorTag(item) {
        if (!item.status) item.labelId = null;
        let labeltemp = {
            empID: item.empID,
            email: item.email,
            nameEn: item.nameEn
        }
        this.creatorTag.push(labeltemp);
    }

    removeCreatorTag(item) {
        this.creatorTag = this.creatorTag.filter((obj) => obj.empID !== item.empID);
    }

    addBudgetProjectTag(item) {
        let labeltemp = {
            project: item.project,
            projectName: item.projectName
        }
        this.budgetProjectTag.push(labeltemp);
    }

    removeBudgetProjectTag(item) {
        this.budgetProjectTag = this.budgetProjectTag.filter((obj) => obj.project !== item.project);
    }

    validateForm() {
        let valid: boolean = true;
        let strategicFormDetail =  this.strategicForm.getRawValue();

        if (strategicFormDetail.targets.length < 1) this.strategicForm.controls.target.setErrors({ null: true });
        this.onCheckStrategicProjectName.emit();

        this.targets.controls.forEach((item, index) => {
            item.get('detail').setValidators(Validators.required)
            item.get('milestoneQ1Detail').setValidators(Validators.required)
            item.get('milestoneQ2Detail').setValidators(Validators.required)
            item.get('milestoneQ3Detail').setValidators(Validators.required)
            item.get('milestoneQ4Detail').setValidators(Validators.required)

            if (!item.get('detail').value) {
                valid = false
                item.get('detail').updateValueAndValidity();
            } else {
                item.get('detail').setValidators(null)
                item.get('detail').updateValueAndValidity();
            }

            if(item.get('corporateKPIs').value) {
                if(item.get('milestoneQ1Detail').value && item.get('milestoneQ2Detail').value && item.get('milestoneQ3Detail').value && item.get('milestoneQ4Detail').value ) {
                    item.get('milestoneQ1Detail').setValidators(null)
                    item.get('milestoneQ2Detail').setValidators(null)
                    item.get('milestoneQ3Detail').setValidators(null)
                    item.get('milestoneQ4Detail').setValidators(null)

                    item.get('milestoneQ1Detail').updateValueAndValidity();
                    item.get('milestoneQ2Detail').updateValueAndValidity();
                    item.get('milestoneQ3Detail').updateValueAndValidity();
                    item.get('milestoneQ4Detail').updateValueAndValidity();
                } else {
                    valid = false

                    item.get('milestoneQ1Detail').updateValueAndValidity();
                    item.get('milestoneQ2Detail').updateValueAndValidity();
                    item.get('milestoneQ3Detail').updateValueAndValidity();
                    item.get('milestoneQ4Detail').updateValueAndValidity();
                }
            } else if(!item.get('milestoneQ1Detail').value && !item.get('milestoneQ2Detail').value && !item.get('milestoneQ3Detail').value && !item.get('milestoneQ4Detail').value ) {
                valid = false

                item.get('milestoneQ1Detail').updateValueAndValidity();
                item.get('milestoneQ2Detail').updateValueAndValidity();
                item.get('milestoneQ3Detail').updateValueAndValidity();
                item.get('milestoneQ4Detail').updateValueAndValidity();
            } else {
                item.get('milestoneQ1Detail').setValidators(null)
                item.get('milestoneQ2Detail').setValidators(null)
                item.get('milestoneQ3Detail').setValidators(null)
                item.get('milestoneQ4Detail').setValidators(null)

                item.get('milestoneQ1Detail').updateValueAndValidity();
                item.get('milestoneQ2Detail').updateValueAndValidity();
                item.get('milestoneQ3Detail').updateValueAndValidity();
                item.get('milestoneQ4Detail').updateValueAndValidity();
            }
          });

        if (this.approverTag.length < 1 || this.reviewerTag.length < 1 || this.creatorTag.length < 1 || strategicFormDetail.targets.length < 1 || !valid) {
            valid = false;
            this.alert(false, 'Please fill out the form completely')
            return valid;
        }

        if(!this.strategicProjectName) {
            valid = false;
            this.alert(false, 'Please fill in your project name')
            return valid
        }

        return valid
    }

    alert(isSuccess: boolean, msg: string) {
        return Swal.fire({
          scrollbarPadding: false,
            text: msg,
            title: isSuccess ? "Successfully" : "Failed",
            icon: isSuccess ? "success" : "error",
            confirmButtonText: "OK",
            focusCancel: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-info btn-lg mr-2 text-light",
            },
        });
    }

}
