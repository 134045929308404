import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AdditionalInformation} from '../../../core/models/additionalInformation';
import {AdditionalInformationService} from '../../../core/services/additional-information.service';
import {EmployeeAdditionalResponse} from '../../../core/models/employeeAdditionalResponse';
import { saveAs } from 'file-saver';
import {HttpResponse} from '@angular/common/http';
import {faIcon} from '../../../core/icon/fa.icon';

@Component({
  selector: 'app-admin-additional-information-list',
  templateUrl: './admin-additional-information-list.component.html',
  styleUrls: ['./admin-additional-information-list.component.scss']
})
export class AdminAdditionalInformationListComponent implements OnInit {

  @Output() showForm = new EventEmitter()

  data: AdditionalInformation[] = []
  selectedField
  list: EmployeeAdditionalResponse[] = []
  isLoading = false
  order
  faIcon = faIcon
  isReverse = false

  constructor(
    private additionalInformationService: AdditionalInformationService
  ) { }

  ngOnInit(): void {
    this.additionalInformationService.findAll().subscribe(
      s => {
        this.data = s
        if (this.additionalInformationService.selectedData) {
          this.selectedField = this.additionalInformationService.selectedData
          this.onChange()
        }
      }
    )
  }

  onChange() {
    this.isLoading = true
    this.order = null
    this.list = []
    this.additionalInformationService.selectedData = this.selectedField
    this.additionalInformationService.findEmployeeDataByAdditionalId(this.selectedField).subscribe(
      s => this.list = s,
      e => console.error(e),
      () => this.isLoading = false
    )
  }

  onShowForm(type: string) {
    this.showForm.emit(type)
  }

  exportExcel() {
    this.additionalInformationService.exportExcel(this.selectedField).subscribe(
      s => {
        const o = this.data.find(it => it.id === this.selectedField)
        saveAs(s, `additional-information(${o.name}).csv`)
      }
    )
  }

  sort(field) {
    if (this.order) {
      if (this.order === field) {
        this.list = this.list.reverse()
        this.isReverse = !this.isReverse
      } else {
        this.isReverse = false
        this.compare(field);
      }
    } else {
      this.isReverse = false
      this.compare(field)
    }

    this.order = field
  }

  private compare(field: string) {
    const sp = field.split('.')
    console.log('tom ==> ', sp)
    if (sp.length > 1) {
      this.list = this.list.sort((a, b) => {
        const aa = a[sp[0]][sp[1]];
        const bb = b[sp[0]][sp[1]];
        return aa === bb ? 0 :
          aa > bb ? 1 : -1
      })
    } else {
      this.list = this.list.sort((a, b) => {
        return a[field] === b[field] ? 0 :
          a[field] > b[field] ? 1 : -1
      })
    }
  }
}
