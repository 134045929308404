<header class="site-header">

  <div class="container-fluid">
    <div class="row bg-ca">
      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
        <!-- <div class="col-auto pt-0 px-2 pb-2 ml-4"> -->
        <a routerLink="/">
          <img style="padding-top: 15px;max-width: 80%;" class="header-img" src="assets/images/SET50th logo.png" alt="SET LOGO"/>
        </a>
      </div>
      <div class="col align-self-center">
        <h1 style="font-weight: bolder;">{{header}}</h1>
      </div>
    </div>
  </div>
</header>
<ng-container *ngIf="!isStrategicMode">
  <nav class="navbar navbar-expand-lg navbar-light">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <div class="navbar-nav">
        <!-- <li class="col-auto">
          <a href="additional-information" target="_blank">EMPLOYEE INFO</a>
        </li> -->
        <!-- <li class="col-auto">
          <a routerLink="/external" routerLinkActive="active">EXTERNAL TRAINING</a>
        </li> -->
        <li class="col-auto">
          <a routerLink="/learning-center" routerLinkActive="active">LEARNING CENTER</a>
        </li>
        <!-- <li class="col-auto">
          <a routerLink="/medical" routerLinkActive="active">MEDICAL</a>
        </li> -->
        <li class="col-auto dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            RECRUIT
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li>
              <a class="dropdown-item" routerLink="/man-power" routerLinkActive="active">RECRUIT</a>
              <a class="dropdown-item" routerLink="/parallel-position" routerLinkActive="active">PARALLEL POSITION</a>
            </li>
          </ul>
          
        </li>
        <li class="col-auto dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            FAST FEEDBACK
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li>
              <a class="dropdown-item" routerLink="/fast-feedback" routerLinkActive="active">Create Fast Feedback</a>
              <a class="dropdown-item" routerLink="/fast-feedback-send-feedback" routerLinkActive="active">Send Your
                Feedback</a>
              <a class="dropdown-item" routerLink="/vendor-evaluation" routerLinkActive="active">Vendor Evaluation</a>
            </li>
          </ul>
        </li>
        <li class="col-auto">
          <a routerLink="/what-new" routerLinkActive="active">WHAT'S NEW?</a>
        </li>


        <li class="col-auto dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink2" role="button" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            OUTSOURCE
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2">
            <li>
              <a class="dropdown-item" routerLink="/outsource-timesheet"
                 routerLinkActive="active">Outsource-Timesheet</a>
              <a class="dropdown-item" routerLink="/outsource-report" routerLinkActive="active">Outsource-Report</a>
            </li>
          </ul>
        </li>
        <!--         <li class="col-auto">-->
        <!--           <a routerLink="/outsource-timesheet" routerLinkActive="active">OUTSOURCE</a>-->
        <!--         </li>-->
        <!--      <li class="col-auto">-->
        <!--        <a routerLink="/intern-timesheet" routerLinkActive="active">INTERN TIMESHEET</a>-->
        <!--      </li>-->
        <li class="col-auto">
          <a routerLink="/administrative" routerLinkActive="active">Administrative</a>
        </li>
      </div>
    </div>
  </nav>
</ng-container>
<!-- <div class="row bg-dark-gray pt-2 py-1"> -->
<!-- <div class="col-auto text-center ml-4">
  <a routerLink="/external" routerLinkActive="active ">EXTERNAL TRAINING</a>
</div>
<div class="col-auto text-center">
  <a routerLink="/medical" routerLinkActive="active">MEDICAL</a>
</div>
<div class="col-auto text-center" *ngIf="!isProd" >
  <a routerLink="/man-power" routerLinkActive="active">MAN POWER</a>
</div>
<div class="col-auto dropdown show text-center" routerLinkActive="active">
  <a class="dropdown-toggle text-uppercase p-0" style="background-color: #4a4848; border-color: #4a4848;"
  href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    FAST FEEDBACK
  </a>

  <div class="dropdown-menu text-uppercase" aria-labelledby="dropdownMenuLink">
    <a class="dropdown-item" routerLink="/fast-feedback"
    routerLinkActive="active border-bottom border-primary border-4">Create  Fast Feedback</a>
    <a class="dropdown-item" routerLink="/fast-feedback-send-feedback"
    routerLinkActive="active border-bottom border-primary border-4">Send Your Feedback</a>
  </div>
</div> -->
<!--      <div class="col-auto text-center">-->
<!--        <a routerLink="/outsource-timesheet" routerLinkActive="active">OUTSOURCE</a>-->
<!--      </div>-->
<!--      <div class="col-auto text-center">-->
<!--        <a routerLink="/intern-timesheet" routerLinkActive="active">INTERN TIMESHEET</a>-->
<!--      </div>-->
<!-- <div class="col-auto text-center text-uppercase">
  <a routerLink="/administrative" routerLinkActive="active ">Administrative</a>
</div> -->

<ng-container *ngIf="isStrategicMode">
  <div class="row bg-dark-gray pt-2 py-1">
    <div class="col-auto text-center ml-4">
      <a routerLink="/strategic-plan" class="text-uppercase" routerLinkActive="active ">Strategic project tracking</a>
    </div>
    <div *ngIf="isAdminOfStrategicProject" class="col-auto dropdown show text-center" routerLinkActive="active">
      <a class="dropdown-toggle text-uppercase p-0" style="background-color: #4a4848; border-color: #4a4848;"
         href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        ADMINISTRATIVE
      </a>

      <div class="dropdown-menu text-uppercase" aria-labelledby="dropdownMenuLink">
        <a class="dropdown-item" routerLink="/administrative-strategic-plan"
           routerLinkActive="active border-bottom border-primary border-4">Strategic project tracking</a>
        <a class="dropdown-item" routerLink="/administrative-strategic-plan-import"
           routerLinkActive="active border-bottom border-primary border-4">Import strategic project</a>
      </div>
    </div>
    <div class="col-auto text-center">
      <a class="text-uppercase" style="cursor: pointer;" (click)="onRouteToStrategicPlan()">Strategic Plan & KPIs</a>
    </div>
  </div>
</ng-container>


<ng-content></ng-content>
