import { Component, OnInit } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { PagerModel } from 'ngx-bootstrap/pagination/models';
import { faIcon } from 'src/app/core/icon/fa.icon';
import { Router } from '@angular/router';
import { WhatNewService } from 'src/app/core/services/what-new.service';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { WhatNew } from 'src/app/core/models/what-new';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { Employee } from 'src/app/core/models/employee';
import { RequestTrainingService } from 'src/app/core/services/request-training.service';

@Component({
  selector: 'app-what-new-list',
  templateUrl: './what-new-list.component.html',
  styleUrls: ['./what-new-list.component.scss']
})
export class WhatNewListComponent implements OnInit {
    faIcon = faIcon;

    keyword: string = '';
    isOnlyPostedByMe: boolean = false;
    view: 'NEW' | 'DETAIL' | 'EDIT' | 'LIST' = 'LIST';
    currentPage = 1;
    whatNewList: WhatNew[] = [];
    whatNewListPerPage: WhatNew[] = [];
    sessionDetail: Employee;
    whatNewDetail: WhatNew;
    allHashtag: string[] = [];
    isHRAdmin: Boolean = false;

    paginationConfig: PagerModel = {
        itemsPerPage: 10,
        previousText: 'ย้อนกลับ',
        nextText: 'ถัดไป',
        pageBtnClass: 'btn btn-warning',
        align: true
      };

    constructor(
        private router: Router,
        private whatNewService: WhatNewService,
        private employeeService: EmployeeService,
        private requestTraining: RequestTrainingService
    ) { }

    ngOnInit() {
        this.getWhatNewSearch();
        this.getSessionDetail();
        this.getAllHashtag();
        this.checkRole();
    }

    getSessionDetail() {
        this.employeeService.me().toPromise()
        .then(res => {
            this.sessionDetail = res;
        })
        .catch(err => {
            console.log('err', err);
        })
    }

    checkRole() {
        this.requestTraining.getUserRole().toPromise()
        .then((x) => {
          if (x.map(r => r.role === 'HRAdmin')) {
            this.isHRAdmin = true;
          }
        })
        .catch((e) => {
        });
    }

    getWhatNewSearch() {
        this.whatNewService.getWhatNewSearch(this.keyword, this.isOnlyPostedByMe).toPromise()
        .then(res => {
            this.currentPage = 1;
            this.whatNewList= res;
            this.whatNewListPerPage = this.whatNewList.slice(0, this.paginationConfig.itemsPerPage);
        })
        .catch(err => {
            console.log('err',err);
            this.whatNewList= [];
            this.whatNewListPerPage = [];
        })
    }

    getAllHashtag() {
        this.whatNewService.getAllHashtag().toPromise()
            .then(res => {
                this.allHashtag = res;
            })
            .catch(err => {
                console.log('err', err);
            })
    }

    onBack() {
        this.view = 'LIST';
        this.currentPage = 1;
        if (this.view === 'LIST') {
            this.router.navigate(['/what-new'])
            this.getWhatNewList();
        }
    }

    onNewPost() {
        this.view = 'NEW';
    }

    handlePaging({page, itemsPerPage}: PageChangedEvent) {
        const startItem = (page - 1) * itemsPerPage;
        const endItem = page * itemsPerPage;
        this.currentPage = page;
        this.whatNewListPerPage = this.whatNewList.slice(startItem, endItem);
    }

    onClickOnlyPostByMe() {
        console.log('data');
    }

    switchView(event: ({state: 'NEW' | 'DETAIL' | 'EDIT' | 'LIST', page: number})) {
        this.view = event.state;
        this.currentPage = event.page ? event.page : 1;
        if (this.view === 'LIST') {
          this.router.navigate(['/what-new'])
          this.getWhatNewList();
        }
    }

    getWhatNewList() {
        this.whatNewService.getWhatNewSearch(this.keyword, this.isOnlyPostedByMe).toPromise()
        .then(res => {
            this.whatNewList= res;
            const startItem = (this.currentPage - 1) * this.paginationConfig.itemsPerPage;
            const endItem = this.currentPage * this.paginationConfig.itemsPerPage;
            this.whatNewListPerPage = this.whatNewList.slice(startItem, endItem);
        })
        .catch(err => {
            console.log('err',err);
            this.whatNewList= [];
            this.whatNewListPerPage = [];
        })
    }

    checkEditPermission(data: WhatNew): boolean {
        if(this.isHRAdmin) {
            return true;
        }

        if(data.department) {
            if(data.department === this.sessionDetail.deptRelation.department || data.department === this.sessionDetail.deptRelation.division ||
                data.department === this.sessionDetail.deptRelation.subDiv || data.department === this.sessionDetail.deptRelation.unit ||
                data.department === this.sessionDetail.deptRelation.deptID || data.department === this.sessionDetail.deptRelation.company
            ) {
                return true;
            }
        }

        if(data.createBy && this.sessionDetail) {
            return data.createBy === this.sessionDetail.empID;
        }

        return false;
    }

    changeToDetailPage(data: WhatNew) {
        this.whatNewDetail = data;
        this.view = 'DETAIL';
    }

    changeToEditPage(data: WhatNew) {
        this.whatNewDetail = data;
        this.view = 'EDIT';
    }

    onSelectHashtag(event: TypeaheadMatch) {
        this.keyword = event.item;
        this.getWhatNewSearch();
    }
}
