import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  private readonly PATH = 'vendor'
  private readonly EP = environment.api_endpoint;

  constructor(private http: HttpClient) {
  }

  getAllVendor(): Observable<Vendor[]> {
    return this.http.get<Vendor[]>(`${this.EP}/${this.PATH}/all`);
  }
}

export interface Vendor {
  vendorName: string,
  vendorId: string,
  taxId: string
}
