<div class="container-fluid mt-4 pl-5 pr-5">
    <h3 class="mt-3">Dept Authorization</h3>
    <div class="row justify-content-end px-3">
        <button class="btn btn-primary app-btn-style col-sm-auto" (click)="addRow()">Add</button>
    </div>

    <div class="body-element">
        <table class="table table-hover">
            <col style="width: 5%;" />
            <col style="width: 10%;" />
            <col style="width: 22%;" />
            <col style="width: 20%;" />
            <col style="width: 12%;" />
            <col style="width: 12%;" />
            <col style="width: 15%;" />
            <col style="width: 2%;" />
            <col style="width: 2%;" />
            <thead class="thead-pink">
                <tr>
                    <th scope="col">NO</th>
                    <th scope="col">Emp ID</th>
                    <th scope="col">Department</th>
                    <th scope="col">Authorization Person</th>
                    <th scope="col">Acting level</th>
                    <th scope="col">Cost center</th>
                    <th scope="col">Service</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody *ngIf="deptAuthorizations && isLoaded">
                <tr *ngFor="let item of deptAuthorizations; let i = index;">
                    <!-- NO -->
                    <td scope="row">{{ i + 1 }}</td>

                    <td>{{item.employee.empID}}</td>

                    <!-- Dept -->
                    <td *ngIf="item.id" scope="row">{{getDeptName(item.deptID)}}</td>
                    <td *ngIf="!item.id">
                        <input class="input-style form-control" [(ngModel)]="item.deptName"
                            [typeahead]="activeDepartments" (typeaheadOnSelect)="onChangeDept($event,item)"
                            typeaheadOptionField="deptName" [typeaheadScrollable]="true"
                            [typeaheadOptionsInScrollableView]="5" [typeaheadMinLength]="0" required />
                    </td>

                    <!-- Name -->
                    <td *ngIf="item.id" scope="row">{{item.employee.nameEn}}</td>
                    <td *ngIf="!item.id">
                        <input class="input-style form-control" [(ngModel)]="item.employee.nameEn"
                            [typeahead]="employees" (typeaheadOnSelect)="onChangeName($event,item)"
                            typeaheadOptionField="nameEn" [typeaheadScrollable]="true"
                            [typeaheadOptionsInScrollableView]="5" [typeaheadMinLength]="0" required />
                    </td>

                    <td scope="row" *ngIf="item.id" > {{item.actingLevel}}</td>
                    <td *ngIf="!item.id">
                      <select class="form-control"
                              [(ngModel)]="item.actingLevel"
                      >
                        <option *ngFor="let level of actingLevel">{{level}}</option>
                      </select>
                    </td>

                    <td scope="row" *ngIf="item.id"> {{item.costCenter}}</td>
                    <td *ngIf="!item.id">
                      <select class="form-control"
                              [(ngModel)]="item.costCenter"
                      >
                        <option *ngFor="let c of costCenter">{{c}}</option>
                      </select>
                    </td>

                    <td scope="row" *ngIf="item.id">{{item.service}}</td>
                    <td *ngIf="!item.id">
                      <select class="form-control"
                              [(ngModel)]="item.service"
                      >
                        <option *ngFor="let s of actingService">{{s}}</option>
                      </select>
                    </td>


                  <td>
                        <fa-icon *ngIf="isValidData && !item.id" [icon]="faIcon.faSave" class="text-c-set icon-size"
                            (click)="onShowConfirmationModal(template,'save', item.id, i)"></fa-icon>
                        <fa-icon *ngIf="!isValidData && !item.id" [icon]="faIcon.faSave" class="text-c-gray icon-size"
                            disabled></fa-icon>
                    </td>

                    <td>
                        <fa-icon [icon]="faIcon.faTrashAlt" class="icon-size trash-btn-style"
                            (click)="onShowConfirmationModal(template,'delete', item.id, i)"></fa-icon>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</div>

<app-loading-overlay [display]="!isLoaded"></app-loading-overlay>

<ng-template #template>
    <div class="modal-header thead-primary" style="background-color: #fab232;">
        <h4 class="modal-title pull-left ml-3">Confirmation</h4>
        <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group ml-3">
            <div class="d-flex">
                <h5>{{ confirmationWording }}</h5>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex mt-2 justify-content-end">
            <button class="btn btn-success mr-2" (click)="confirm()">Confirm</button>
            <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-template>
