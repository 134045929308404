import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Attendee } from '../models/attendee';
import { environment } from '../../../environments/environment';
import { Employee } from '../models/employee';

@Injectable({
  providedIn: 'root'
})
export class AttendeeService {
  constructor(private http: HttpClient) {}

  getEmployee(): Observable<Employee[]> {
    return this.http.get<Employee[]>(`${environment.api_endpoint}/employees`);
  }
  getEmployeeByStatus(isActive: Boolean): Observable<Employee[]> {
    return this.http.get<Employee[]>(
      `${environment.api_endpoint}/employees/status/${isActive}`
    );
  }

  getAttendee(): Observable<Employee[]> {
    return this.http.get<Employee[]>(
      `${environment.api_endpoint}/employees/attendee`
    );
  }
}
