<form [formGroup]="strategicForm">
    <div class="form-group row">
        <div class="col-sm-3 col-lg-2 pl-4 pr-4">
            <div class="p-2 stakeholder-pointer">
                <h5>
                    <b>
                        Lead
                    </b>
                </h5>
            </div>
        </div>

        <div class="col-sm-9 col-lg-10  row ml-1 pl-4">
            <div class="col-sm-12 col-lg-6  form-group row mb-2">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white h-auto">
                    <h6 class="mb-0">
                        <b>
                            Approver
                        </b>
                    </h6>
                </div>
                <div class="col-7">
                    <tag-input [ngModel]="approverTag" [ngModelOptions]="{standalone: true}" [placeholder]="'+ name'"
                        [secondaryPlaceholder]="'+ name'" (onAdd)="addApproverTag($event)"
                        (onRemove)="removeApproverTag($event)" theme='bootstrap' [identifyBy]="'empID'"
                        [displayBy]="'nameEn'">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="employeeList"
                            [identifyBy]="'empID'" [displayBy]="'nameEn'">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 form-group row">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white h-auto">
                    <h6 class="mb-0">
                        <b>
                            Co-approver
                        </b>
                    </h6>
                </div>
                <div class="col-7">
                    <tag-input [ngModel]="coApproverTag" [ngModelOptions]="{standalone: true}" [placeholder]="'+ name'"
                        [secondaryPlaceholder]="'+ name'" (onAdd)="addCoApproverTag($event)"
                        (onRemove)="removeCoApproverTag($event)" theme='bootstrap' [identifyBy]="'empID'"
                        [displayBy]="'nameEn'">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="employeeList"
                            [identifyBy]="'empID'" [displayBy]="'nameEn'">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 form-group row mb-2">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white h-auto">
                    <h6 class="mb-0">
                        <b>
                            Reviewer
                        </b>
                    </h6>
                </div>
                <div class="col-7">
                    <tag-input [ngModel]="reviewerTag" [ngModelOptions]="{standalone: true}" [placeholder]="'+ name'"
                        [secondaryPlaceholder]="'+ name'" (onAdd)="addReviewerTag($event)"
                        (onRemove)="removeReviewerTag($event)" theme='bootstrap' [identifyBy]="'empID'"
                        [displayBy]="'nameEn'">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="employeeList"
                            [identifyBy]="'empID'" [displayBy]="'nameEn'">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 form-group row mb-2">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white h-auto">
                    <h6 class="mb-0">
                        <b>
                            Co-reviewer
                        </b>
                    </h6>
                </div>
                <div class="col-7">
                    <tag-input [ngModel]="coReviewerTag" [ngModelOptions]="{standalone: true}" [placeholder]="'+ name'"
                        [secondaryPlaceholder]="'+ name'" (onAdd)="addCoReviewerTag($event)"
                        (onRemove)="removeCoReviewerTag($event)" theme='bootstrap' [identifyBy]="'empID'"
                        [displayBy]="'nameEn'">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="employeeList"
                            [identifyBy]="'empID'" [displayBy]="'nameEn'">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 form-group row mb-2">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white h-auto">
                    <h6 class="mb-0">
                        <b>
                            Creator
                        </b>
                    </h6>
                </div>
                <div class="col-7">
                    <tag-input [ngModel]="creatorTag" [ngModelOptions]="{standalone: true}" [placeholder]="'+ name'"
                        [secondaryPlaceholder]="'+ name'" (onAdd)="addCreatorTag($event)"
                        (onRemove)="removeCreatorTag($event)" theme='bootstrap' [identifyBy]="'empID'"
                        [displayBy]="'nameEn'">
                        <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="employeeList"
                            [identifyBy]="'empID'" [displayBy]="'nameEn'">
                        </tag-input-dropdown>
                    </tag-input>
                </div>
            </div>
            <!-- <div class="col-sm-12 col-lg-6 form-group row mb-2">
                <div class="col-5 p-2 col-form-label bg-lightgrey text-white h-auto">
                    <h6 class="mb-0">
                        <b>
                            Project
                        </b>
                    </h6>
                </div>
                <div class="col-7">
                    <tag-input [ngModel]="budgetProjectTag" [ngModelOptions]="{standalone: true}" [placeholder]="'+ project'" [secondaryPlaceholder]="'+ project'"
                    (onAdd)="addBudgetProjectTag($event)" (onRemove)="removeBudgetProjectTag($event)" theme='bootstrap'  [identifyBy]="'project'"
                    [displayBy]="'projectName'"
                    >
                    <tag-input-dropdown [showDropdownIfEmpty]="true" [autocompleteItems]="budgetProjectList" [identifyBy]="'project'" [displayBy]="'projectName'">
                    </tag-input-dropdown>
                    </tag-input>
                </div>
            </div> -->
        </div>
    </div>
    <hr>

    <div class="form-group row">
        <div class="col-sm-3 col-lg-2 pl-4 pr-4">
            <h5 style="color: transparent;"><b>.</b></h5>
            <hr style="border-color: transparent;">
            <div class="p-2 corporate-pointer">
                <h5>
                    <b>
                        Target
                    </b>
                </h5>
            </div>
        </div>
        <div class="col-sm-9 col-lg-10 pl-4">
            <h5><b>เป้าหมายปี {{strategicProjectYear ?  strategicProjectYear : '-'}}</b></h5>
            <hr>
            <div class="form-group row d-flex justify-content-between container-fluid pr-0 mr-0">
                <input class="form-control col-sm-10" formControlName="target"
                    [ngClass]="{'is-invalid': strategicForm.controls['target'].hasError('null')}" />
                <button class="btn btn-success col-sm-auto" (click)="addTarget()">ADD</button>
            </div>
            <div formArrayName="targets">
                <div *ngFor="let target of targetList.controls; let i = index;">
                    <div class="form-group row container-fluid">
                        <h6 class="mb-0">{{i + 1}}. {{target.get('detail').value}}
                            <fa-icon [icon]="faIcon.faTimes" class="text-danger ml-3 cursor-pointer"
                                (click)="onShowConfirmationModal(template,i,'deleteTarget')"></fa-icon>
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>



    <div class="form-group row">
        <div class="col-sm-3 col-lg-2 pl-4 pr-4">
            <div class="p-2 milestone-pointer ">
                <h5>
                    <b>
                        Milestone
                    </b>
                </h5>
            </div>
        </div>
        <div class="col-sm-9 col-lg-10  pl-4 scroll-table">
            <table class="table" id="dataTable">
                <col style="width: 25%;" />
                <col style="width: 25%;" />
                <col style="width: 25%;" />
                <col style="width: 25%;" />
                <thead class="dt-head">
                    <tr class="text-center" style="background-color: #EF9A9A;">
                        <th style="border-right: 2px solid white;">
                            <h5 class="mb-0"> Q1</h5>
                        </th>
                        <th style="border-right: 2px solid white;">
                            <h5 class="mb-0"> Q2</h5>
                        </th>
                        <th style="border-right: 2px solid white;">
                            <h5 class="mb-0"> Q3</h5>
                        </th>
                        <th style="border-right: 2px solid white;">
                            <h5 class="mb-0"> Q4</h5>
                        </th>
                    </tr>
                </thead>
                <tbody formArrayName="targets">
                    <ng-container *ngFor="let target of targetList.controls; let i = index;" [formGroupName]="i">
                        <tr class="bg-white">
                            <td colspan="4">
                                <div class="form-group row">
                                    <h6 class="mt-3 col-sm-auto col-lg-auto">{{i + 1}}</h6>
                                    <input class="mt-2 col-sm-8 col-lg-9 form-control" type="text"
                                        [formControl]="target['controls'].detail"
                                        [class.is-invalid]="target.get('detail').invalid" />
                                    <h6 class="mt-3 col-sm-auto col-lg-auto">Is Corporate KPIs : </h6>
                                    <input class="mt-3 col-sm-auto col-lg-auto form-control"
                                        style="width: 20px; height: 20px;" type="checkbox"
                                        [formControl]="target['controls'].corporateKPIs">
                                </div>
                            </td>
                        </tr>
                        <tr class="bg-light">
                            <td style="border-right: 2px solid white;">
                                <textarea class="form-control" [formControl]="target['controls'].milestoneQ1Detail"
                                    rows="5" [class.is-invalid]="target.get('milestoneQ1Detail').invalid"></textarea>
                            </td>
                            <td style="border-right: 2px solid white;">
                                <textarea class="form-control" [formControl]="target['controls'].milestoneQ2Detail"
                                    rows="5" [class.is-invalid]="target.get('milestoneQ2Detail').invalid"></textarea>
                            </td>
                            <td style="border-right: 2px solid white;">
                                <textarea class="form-control" [formControl]="target['controls'].milestoneQ3Detail"
                                    rows="5" [class.is-invalid]="target.get('milestoneQ3Detail').invalid"></textarea>
                            </td>
                            <td style="border-right: 2px solid white;">
                                <textarea class="form-control" [formControl]="target['controls'].milestoneQ4Detail"
                                    rows="5" [class.is-invalid]="target.get('milestoneQ4Detail').invalid"></textarea>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
                <tbody *ngIf="targetList.controls.length === 0">
                    <td class="text-center" colspan="4">
                        No data.
                    </td>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row justify-content-end mt-4 mb-4 text-right">
        <div class="col-sm-auto">
            <button type="button" class="btn btn-secondary app-btn-style"
                (click)="onShowConfirmationModal(template,0,'back')">BACK
            </button>
        </div>
        <div class="col-sm-auto">
            <button type="button" class="btn btn-primary app-btn-style"
                (click)="onShowConfirmationModal(template,0,'create')">CREATE</button>
        </div>
    </div>

</form>

<ng-template #template>
    <div class="modal-header thead-primary" style="background-color: #fab232;">
        <h4 class="modal-title pull-left ml-3">Confirmation</h4>
        <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group ml-3">
            <div class="d-flex">
                <h5>{{ confirmationWording }}</h5>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex mt-2 justify-content-end">
            <button class="btn btn-success mr-2" (click)="confirm()">Confirm</button>
            <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-template>