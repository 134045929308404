<ng-template #template>
    <div class="modal-header bg-dark-gray">
        <h4 class="modal-title pull-left">{{isUpdate?'Update':'Add'}} Admin</h4>
        <button type="button" class="close pull-right text-light" (click)="modalRef.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form action="" [formGroup]="fb">
            <div class="form-group">
                <label for="">User:</label>
                <input type="text" formControlName="displayName" [typeahead]="employeeListFiltered"
                    typeaheadOptionField="nameTh" (typeaheadOnSelect)=selectEmployee($event) class="form-control"
                    [ngClass]="{'is-invalid':fb.controls.employeeObj.getError('inCorrect')||fb.controls.displayName.getError('isNull')}">
                <small *ngIf="fb.controls.employeeObj.getError('inCorrect')||fb.controls.displayName.getError('isNull')"
                    class="text-danger pl-2">{{fb.controls.displayName.getError('msg') ||fb.controls.employeeObj.getError('msg')}}
                </small>
            </div>
            <div class="form-group">
                <label for="">Role:</label>
                <select name="" formControlName="role" id="" class="form-control"
                    [ngClass]="{'is-invalid':fb.controls.role.getError('isNull')}">
                    <option *ngFor="let role of roles" value="{{role}}">{{role}}</option>
                </select>
                <small *ngIf="fb.controls.role.getError('isNull')"
                    class="text-danger pl-2">{{this.fb.controls.role.getError('msg')}} </small>

            </div>
            <button class="btn btn-danger btn-lg float-left" *ngIf="isUpdate"
                (click)="delete(fb?.controls?.displayName?.value)">
                <fa-icon [icon]="faIcon.faTrash"></fa-icon>
                Delete
            </button>
            <button class="btn btn-success btn-lg float-right" (click)="submit()">
                <fa-icon [icon]="faIcon.faSave"></fa-icon>

                Save
            </button>
        </form>
    </div>
</ng-template>