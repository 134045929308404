<form [formGroup]="formRequest">
  <div class="form-group row">
    <label for="receipt_no" class="col-xs-2 col-sm-2 col-md-2 col-form-label "><b>Receipt No.</b></label>
    <div class="col-xs-2 col-sm-2  col-md-2">
      <div class="input-group">
      <input type="text" [class.is-invalid]="getControl('receiptNo').hasError('isNull')" class="form-control"
        id="receipt_no" formControlName="receiptNo" autocomplete="off">
    </div>
  </div>
    <label for="receipt_date" class="col-xs-2 col-sm-2 col-md-2 col-form-label"><b>Receipt Date</b></label>
    <div class="col-xs-2 col-sm-2  col-md-2">
      <div class="input-group">
      <input [disabled]="currentDataDetail.formStatus === 'SUBMIT'"  type="text" [maxDate]="today()" class="form-control"
        [class.is-invalid]="getControl('receiptDate').hasError('isNull')" id="receipt_date" bsDatepicker
        formControlName="receiptDate" [bsConfig]='datePickerConfig' autocomplete="off" readonly>
      <!-- <input type="text" class="form-control" id="receipt_date" formControlName="receiptDate"> -->
    </div>
  </div>
    <label for="employee" class="col-xs-2 col-sm-2 col-md-2 col-form-label"><b>Employee Name</b></label>
    <div class="col-xs-2 col-sm-2  col-md-2">

      <div class="input-group">
        <input type="text" [typeahead]="employeeList" typeaheadOptionField="nameTh"
          (typeaheadOnSelect)="onSelectEmployee($event)" class="form-control "
          [class.is-invalid]="getControl('empID').hasError('isNull')" autocomplete="off" id="employee"
          formControlName="nameTh"
        >

        <!-- <button type="button" *ngIf="this.isHrAdmin && formRequest.controls.nameTh.value !== ''" (click)="clearEmployee()"
          class="btn bg-transparent" style="margin-left: -40px; z-index: 100;">
          <fa-icon class="text-danger" [icon]="faIcon.faTimes"> </fa-icon>
        </button> -->
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label for="amount" class="col-xs-2 col-sm-2 col-md-2 col-form-label"><b>Amount</b></label>
    <div class="col-xs-2 col-sm-2  col-md-2">
      <div class="input-group">
      <input [class.is-invalid]="getControl('amount').hasError('isNull')" type="number" step="any" min="0"
             class="form-control" id="amount" formControlName="amount"
             autocomplete="off"
      >
    </div>
  </div>
    <label for="hospital" class="col-xs-2 col-sm-2 col-md-2 col-form-label"><b>Hospital</b></label>
    <div class="col-xs-2 col-sm-2  col-md-2">
      <div class="input-group">
      <input type="text" class="form-control" id="hospital"
             [class.is-invalid]="getControl('hospital').hasError('isNull')" [typeahead]="hospitalList"
             [typeaheadOptionsLimit]="7" [typeaheadMinLength]="0" (typeaheadOnSelect)="onChangeHospital()"
             typeaheadOptionField="label" formControlName="hospital"
             autocomplete="off"
      >
    </div>
  </div>

    <label for="other" class="col-xs-2 col-sm-2 col-md-2 col-form-label"><b>Other Hospitals</b></label>
    <div class="col-xs-2 col-sm-2  col-md-2">
      <div class="input-group">
      <input type="text" [readonly]="otherIsReadOnly()" class="form-control" id="other" formControlName="other"
             [class.is-invalid]="getControl('other').hasError('isNull')"    
             autocomplete="off"
      >
    </div>
  </div>
  </div>

  <!-- <div class="form-group row">
    <label for="amount" class="col-xs-2 col-sm-2 col-md-2 col-form-label"><b>Amount</b></label>
    <div class="col-sm-3 ml-n4">
      <input [class.is-invalid]="getControl('amount').hasError('isNull')" type="number" step="any" min="0"
             class="form-control  ml-n4" id="amount" formControlName="amount"
             autocomplete="off"
      >
    </div>
  </div> -->

  <div class="form-group row">
    <label for="attach" class="col-xs-2 col-sm-2 col-md-2 col-form-label"><b>Attachments</b></label>
    <div class="col-xs-4 col-sm-4  col-md-4">
  
      <input  [disabled]="currentDataDetail.formStatus === 'SUBMIT'" [class.is-invalid]="getControl('documentId').hasError('isNull')" type="file" id="attach" #attachment class="form-control"
             (change)="handleFileUpdate($event.target.files,$event.target.value)" accept=
               "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
        text/plain, application/pdf, image/*">
        <small class="text-muted">File Type: pdf, doc, images</small>
    </div>
    <div class="col-sm-1 upload">
      <button  *ngIf="currentDataDetail.formStatus !== 'SUBMIT'" class="btn btn-info" (click)="onUpload()">UPLOAD</button>
    </div>
  </div>
  <!-- <div class="form-group row  mt-n3">
    <label for="attach" class="col-sm-2 col-xs-2 col-sm-2 col-md-2 col-form-label"><b></b></label>
    <div class="col-sm-3">
      <small class="text-muted">File Type: pdf, doc, images</small>
    </div>
  </div> -->

  <div class="form-group row">
    <div class="col-md-2"></div>
    <div class="col-sm-3">

      <ul class="style-selector" *ngIf="!loader.fileAttachment">
        <li *ngFor="let item of attachments?.controls;index as i" [title]="findValue(item,'fileName')">
          <!--        -->

          <a class="clickable text-info" 
             (click)="onDownload(findValue(item,'data'),findValue(item,'fileType'),findValue(item,'fileName'))">{{ findValue(item,'fileName').length > 15 ? ( findValue(item,'fileName') | slice:0:15) + '..' : findValue(item,'fileName') }}</a>
          <button type="button" class="close" (click)="removeAttachment(i)" aria-label="Close">
            <span  *ngIf="currentDataDetail.formStatus !== 'SUBMIT'" aria-hidden="true" style="color:#fa8080">&times;</span>
          </button>
        </li>
      </ul>
      <div *ngIf="loader.fileAttachment " class="text-center ">
        <div class="spinner-border text-warning" role="status">
          <span class=" sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 text-right mt-2 mb-3 pr-5 col-button">
      <button *ngIf="currentDataDetail.formStatus !== 'SUBMIT' && currentDataDetail.formStatus == 'save'  && formRequest.get('id').value" class="btn btn-danger " (click)="deleteItem()" >DELETE</button>
      <button class="btn btn-secondary ml-2" (click)="back()">BACK</button>
      <button *ngIf="currentDataDetail.formStatus !== 'SUBMIT'" class="btn btn-success ml-2" (click)="handleSave('save')">SAVE</button>
      <button *ngIf="currentDataDetail.formStatus !== 'SUBMIT'" class="btn btn-primary ml-2" (click)="handleSubmit()">SUBMIT</button>
      <!-- <button class="btn btn-warning ml-2 text-light" *ngIf="displayReviewButton && page == 'Admin'" (click)="review()">REVIEW</button> -->

    </div>
  </div>
  <div class="row">
    <div class="col-md-12 border-text">
      <p class="text-muted">  หมายเหตุ : <br>
            <br>
             &#160; - ข้อมูลที่ท่านให้ไว้ข้างต้นและเอกสารที่ท่านแนบ จะถูกส่งไปยังบริษัทประกันภัยซึ่งเป็นคู่สัญญากับกลุ่มตลาดหลักทรัพย์ฯ ในการบริหารจัดการด้านการรักษาพยาบาล<br> 
             &#160;&#160;&#160; โดยระบบจะส่งไปยัง e-mail: wilai@bangkoklife.com || : prapaiporn@bangkoklife.com <br> 
             <br>
             &#160; - พนง.กลุ่ม ASO (เข้างานก่อนวันที่ 1 ก.ค. 2559) = ต้องเก็บใบเสร็จตัวจริง + ใบรับรองแพทย์ไว้กรณีมีการเรียกตรวจ แต่ไม่ต้องส่งไปที่ HR<br>
             <br>
             &#160; - พนง.กลุ่ม GL (เข้างานตั้งแต่วันที่ 1 ก.ค. 2559) = ต้องส่งใบเสร็จ + ใบรับรองแพทย์ตัวจริงไปที่ HR <br>
             <br>
             &#160; - กรณีรักษาต่อเนื่อง มีใบรับรองแพทย์ใบเดียว ให้สำเนาเก็บใบรับรองแพทย์ไว้ประกอบการคีย์เบิกในครั้งถัดไป ( 1 ใบเสร็จ 1 ใบรับรองแพทย์) <br>
             <br>
             &#160; - เพื่อความสะดวกรวดเร็วในการเคลมเบิก กรุณาตรวจสอบความถูกต้อง ครบถ้วน และชัดเจนของเอกสารแนบ ก่อนส่ง หากเอกสารมีปัญหา ท่านจะได้รับการติดต่อกลับจากทางบริษัทประกัน <br> 
             &#160;&#160;&#160; ตาม contact ข้างต้น <br> 
      </p>
      </div>
    <!-- <div class="col-md-4"></div> -->
  </div>

</form>
<app-loading-overlay [display]="loader.overlay"></app-loading-overlay>
