<div class="container-fluid mt-4 pl-5 pr-5">
    <div class="mb-4">
    <h3>Medical Claim</h3>
    </div>
    
    <hr *ngIf="isMedicalDetail" class="set">

    <ng-container *ngIf="!isMedicalDetail else medicalDetail">

        <div class="form-group row mt-4">
            <label class="col-sm-2 col-form-label">
                Request Date 
            </label>

            <div class="col-sm-2">
                <label>
                    <input type="text" placeholder="Please select date" [(ngModel)]="dateRang.startDate"
                        class="form-control" bsDatepicker [bsConfig]="bsConfig"
                        (bsValueChange)="onDateChange($event,null)" [maxDate]="dateLimit().maxDate">
                </label>
            </div>
            <div class="col-sm-2">
                <label>
                    <input type="text" placeholder="Please select date" [(ngModel)]="dateRang.endDate"
                        class="form-control" bsDatepicker [bsConfig]="bsConfig"
                        (bsValueChange)="onDateChange(null,$event)" [minDate]="dateLimit().minDate">
                </label>
            </div>
            <div class="col-sm-2">
                <label>
                    <select type="text" class="form-control" [(ngModel)]="listFilter" (change)="onChangeFilter()">
                        <option value="ALL">ALL</option>
                        <option value="REVIEWING">REVIEWING</option>
                        <option value="REVIEWED">REVIEWED</option>
                    </select>
                </label>
            </div>
            <div class="col-sm-3 text-right ml-auto">
                <button class="btn btn-info mb-2" (click)="exportCSV()">Export
                    CSV
                </button>
            </div>
        </div>
        <div class="row ">
            <div class="table-responsive">
                <table class="table" id="dataTable">
                    <thead class="dt-head">
                        <tr>
                            <th style="width: 320px">
                                REQUEST DATE
                            </th>
                            <th style="width: 150px">
                                GROUP
                            </th>
                            <th style="width: 190px">
                                RECEIPT NO.
                            </th>
                            <th style="width: 150px">
                                HOSPITAL
                            </th>

                            <th style="width: 320px">
                                RECEIPT DATE
                            </th>
                            <!-- <th style="width: 320px">
                                TREATMENT DATE
                            </th> -->
                            <th style="width: 320px">
                                EMP ID
                            </th>

                            <th style="width: 150px">
                                NAME TH
                            </th>
                            <th style="width: 150px">
                                AMOUNT
                            </th>
                            <th style="width: 150px">
                                STATUS
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let d of medicalList">
                            <td>
                                <small class="text-secondary">
                                    {{d.createDate | date: 'dd MMM yyyy'}}
                                </small>
                            </td>
                            <td>
                                <small class="text-secondary">

                                    {{d.claimGroup.toUpperCase()}}
                                </small>

                            </td>
                            <td>
                                <a class="text-click-able text-info" (click)="onClickDetail(d)">{{d.receiptNo}}</a>
                            </td>
                            <td>
                                <small class="text-secondary">
                                    {{d.hospital == 'Other'?'Other: '+ d.other :d.hospital}}</small>
                            </td>

                            <td>
                                <small class="text-secondary">
                                    {{d.receiptDate | date: 'dd MMM yyyy'}}
                                </small>
                            </td>
                            <!-- <td>
                                <small class="text-secondary">
                                    {{d.treatmentDate | date: 'dd MMM yyyy'}}
                                </small>
                            </td> -->
                            <td>
                                <small class="text-secondary">
                                    {{d.empID}}
                                </small>

                            </td>

                            <!-- <td>
                                <small class="text-secondary">
                                    {{d.nameEn}}
                                </small>

                            </td> -->
                            <td>
                                <small class="text-secondary">
                                    {{d.nameTh}}
                                </small>

                            </td>
                            <td>
                                <small class="text-secondary">

                                    {{d.amount.toFixed(2)}}
                                </small>
                            </td>
                            <td>
                                <small class="text-secondary text-capitalize text-info">

                                    {{d.status?d.status.toLowerCase():'-'}}
                                </small>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center" *ngIf="noData" colspan="9">
                                No data.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
    <ng-template #medicalDetail>
        <app-new-request-medical [page]="page" (Done)="onDone($event)" [mediCalBufferOnEdit]="mediCalBufferOnEdit">
        </app-new-request-medical>
    </ng-template>

</div>
<app-loading-overlay [display]="loading"></app-loading-overlay>