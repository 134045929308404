import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { faIcon } from 'src/app/core/icon/fa.icon';
import { WhatNewService } from 'src/app/core/services/what-new.service';
import { BsModalRef, BsModalService, TypeaheadMatch } from 'ngx-bootstrap';
import { WhatNew } from 'src/app/core/models/what-new';
import { TargetGroup } from 'src/app/core/models/target-group';

@Component({
  selector: 'app-what-new-form',
  templateUrl: './what-new-form.component.html',
  styleUrls: ['./what-new-form.component.scss']
})
export class WhatNewFormComponent implements OnInit, OnChanges {
    faIcon = faIcon;
    modalRef: BsModalRef;

    @Output() onEmitData = new EventEmitter();
    @Input() pageData: String;
    @Input() whatNewDetail: WhatNew;

    targetGroup: FormArray;
    hashtag: FormArray;
    whatNewForm = this.buildForm;
    isOwner: boolean = true;
    allTargetGroups: TargetGroup[] = [];
    selectedThumbnail;
    selectedAttachment;
    confirmationWording: string = '';
    isLoading: boolean = false;
    confirmationStatus: string = '';
    allHashtag: string[] = [];
    status: string = '';

    constructor(
        private fb: FormBuilder,
        private whatNewService: WhatNewService,
        private modalService: BsModalService
    ) {

    }

    ngOnChanges() {
        if (this.whatNewDetail) {
            this.status = 'edit';
            this.initWhatNew();
        } else {
            this.status = 'new';
        }
    }

    ngOnInit() {
        this.getAllTargetGroups();
        this.getAllHashtag();

        this.targetGroup = this.whatNewForm.get('targetGroup') as FormArray;
        this.hashtag = this.whatNewForm.get('hashtag') as FormArray;
    }

    get buildForm() {
        let today = new Date;
        return this.fb.group({
          title: [{ value: '', disabled: false }, [Validators.required]],
          detail: [{value: '', disabled: false}, [Validators.required]],
          url: [{value: '', disabled: false}],
          thumbnail: [{ value: '', disabled: false }],
          attachment: [{ value: '', disabled: false }],
          targetGroup:  this.fb.array([]),
          targetGroupSearch: [{ value: '', disabled: false }],
          hashtag:  this.fb.array([]),
          hashtagSearch: [{ value: '', disabled: false }],
          isEmployeeOnly: [{ value: true, disabled: false }],
          isBanner: [{ value: false, disabled: false }],
          effectiveDate: [{ value: this.assignDateToString(today), disabled: false }],
          expiryDate: [{ value: this.assignDateToString(new Date(today.setMonth(today.getMonth()+1))), disabled: false }],
        });
      }

    get targetGroupListData() {
        return <FormArray>this.whatNewForm.get('targetGroup');
    }

    get hashtagListData() {
        return <FormArray>this.whatNewForm.get('hashtag');
    }

    initWhatNew() {
        console.log('this.whatNewDetail', this.whatNewDetail);
        this.whatNewForm.patchValue({
            title: this.whatNewDetail.title,
            detail: this.whatNewDetail.detail,
            url: this.whatNewDetail.url,
            thumbnail: this.whatNewDetail.thumbnail,
            attachment: this.whatNewDetail.attachment,
            isEmployeeOnly: this.whatNewDetail.isEmployeeOnly,
            isBanner: this.whatNewDetail.isBanner,
            effectiveDate: this.whatNewDetail.effectiveDate ?  this.assignDateToString(this.whatNewDetail.effectiveDate) : '',
            expiryDate: this.whatNewDetail.expiryDate ?  this.assignDateToString(this.whatNewDetail.expiryDate) : ''
        })

        this.targetGroup = this.whatNewForm.get('targetGroup') as FormArray;
        this.hashtag = this.whatNewForm.get('hashtag') as FormArray;

        if (Array.isArray(this.whatNewDetail.targetGroup)) {
            this.whatNewDetail.targetGroup.forEach(item => {
                this.targetGroup.push(this.fb.group({
                    name: [{ value: item.name, disabled: false }],
                    targetId: [{ value: item.targetId, disabled: false }],
                }))
            })
        }

        if (Array.isArray(this.whatNewDetail.hashtag)) {
            this.whatNewDetail.hashtag.forEach(item => {
                this.hashtag.push(this.fb.group({
                    name: [{ value: item, disabled: false }]
                }))
            })
        }
    }

    getAllTargetGroups() {
        this.whatNewService.getWhatNewTarget().toPromise()
            .then(res => {
                this.allTargetGroups = res;
            })
            .catch(err => {
                console.log('err', err);
            })
    }

    getAllHashtag() {
        this.whatNewService.getAllHashtag().toPromise()
            .then(res => {
                this.allHashtag = res;
            })
            .catch(err => {
                console.log('err', err);
            })
    }

    onRemoveTargetGroup(index) {
        this.targetGroup.removeAt(index);
    }

    onSelectThumbnail(event) {
        if (event.target.files && event.target.files.length) {
            if (event.target.files.item(0).size/1024/1024 <= 5) {
                this.whatNewForm.patchValue({
                    thumbnail: ''
                })
                this.selectedThumbnail = event.target.files.item(0);

            } else {
                this.alert(false, 'Image size exceeds the allowable limit')
            }
        }
    }

    onSelectAttachment(event) {
        if (event.target.files && event.target.files.length) {
            if (event.target.files.item(0).size/1024/1024  <= 5) {
                this.whatNewForm.patchValue({
                    attachment: ''
                })
                this.selectedAttachment = event.target.files.item(0);
            } else {
                this.alert(false, 'file size exceeds the allowable limit')
            }
        }
    }

    alert(isSuccess: boolean, msg: string) {
        return Swal.fire({
            scrollbarPadding: false,
            text: msg,
            title: isSuccess ? "Successfully" : "Failed",
            icon: isSuccess ? "success" : "error",
            confirmButtonText: "OK",
            focusCancel: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-info btn-lg mr-2 text-light",
            },
        });
    }

    onSelectTargetGroupTag(event: TypeaheadMatch) {
        let data = this.fb.group({
            name: [{ value: event.item.name, disabled: false }],
            targetId: [{ value: event.item.targetId, disabled: false }]
        })
        this.targetGroup.push(data);
        console.log('this.targetGroup', this.targetGroup);

        this.whatNewForm.patchValue({
            targetGroupSearch: ''
        })
    }

    onSelectHashtag(event: TypeaheadMatch) {
        let data = this.fb.group({
            name: [{ value: event.item, disabled: false }],
        })
        this.hashtag.push(data);
        console.log('this.hashtag', this.hashtag);

        this.whatNewForm.patchValue({
            hashtagSearch: ''
        })
    }

    onRemoveHashtag(index) {
        this.hashtag.removeAt(index);
    }

    onEnterHashtag() {
        let data = this.fb.group({
            name: [{ value:  this.whatNewForm.get('hashtagSearch').value, disabled: false }],
        })
        this.hashtag.push(data);
        console.log('this.hashtag', this.hashtag);

        this.whatNewForm.patchValue({
            hashtagSearch: ''
        })
    }

  onRemoveThumbnail() {
      this.selectedThumbnail = null;
  }

  onRemovePreviousThumbnail() {
    this.whatNewForm.patchValue({
        thumbnail: ''
    })
  }

  onRemoveAttachment() {
      this.selectedAttachment = null;
  }

  onRemovePreviousAttachment() {
    this.whatNewForm.patchValue({
        attachment: ''
    })
  }


  onShowConfirmationModal(template: TemplateRef<any>, status) {
    this.confirmationStatus = status
    if (status.toUpperCase() === 'BACK') {
        this.confirmationWording = 'Do you want to go back ?';
        this.modalRef = this.modalService.show(template, { class: 'modal-lg1' })
    } else if (status.toUpperCase() === 'DELETE') {
        this.confirmationWording = 'Do you want to delete this post ?';
        this.modalRef = this.modalService.show(template, { class: 'modal-lg1' })
    } else if (status.toUpperCase() === 'SUBMIT') {
        this.confirmationWording = 'Do you want to submit this post ?';
        this.modalRef = this.modalService.show(template, { class: 'modal-lg1' })
    }
  }

  confirm() {
    this.modalRef.hide()
    if(this.confirmationStatus == 'back') {
        this.onEmitData.emit({state:'LIST', page: this.pageData});
    } else if (this.confirmationStatus == 'delete') {
        this.isLoading = true;
        this.onDeleteWhatNew();
    } else if(this.confirmationStatus == 'submit') {
        if (this.validateFrom()) {
            this.isLoading = true;
            this.onSubmit();
        }
    }
  }

  onSubmit() {
    let whatNewDetailForm = this.whatNewForm.getRawValue();
    console.log('this.selectedThumbnail', this.selectedThumbnail);
    console.log('this.selectedAttachment', this.selectedAttachment);
    let hashtagList = [];

    if(Array.isArray(whatNewDetailForm.hashtag)) {
        if(whatNewDetailForm.hashtag.length > 0) {
            whatNewDetailForm.hashtag.forEach(item => {
                hashtagList.push(item.name);
            });
        }
    }

    let body = {
        title: whatNewDetailForm.title,
        detail: whatNewDetailForm.detail,
        url: whatNewDetailForm.url,
        targetGroup: whatNewDetailForm.targetGroup,
        isEmployeeOnly: whatNewDetailForm.isEmployeeOnly,
        isBanner: whatNewDetailForm.isBanner,
        hashtag: hashtagList,
        effectiveDate: whatNewDetailForm.effectiveDate,
        expiryDate: whatNewDetailForm.expiryDate
    }

    if(this.status === 'edit' && this.whatNewDetail) {
        let editData = {
            id: this.whatNewDetail.id,
            thumbnail: whatNewDetailForm.thumbnail,
            attachment: whatNewDetailForm.attachment,
        }
        Object.assign(body, editData);

        this.whatNewService.updateWhatNew(body, this.selectedThumbnail, this.selectedAttachment).toPromise()
        .then(res => {
            this.alert(true, 'Save the data successfully.')
            this.isLoading = false;
            this.onEmitData.emit({state:'LIST', page: this.pageData});
        })
        .catch(err => {
            this.alert(false, 'Cannot save this post, please try again.')
            this.isLoading = false;
            console.log('err',err);
        })

    } else {
        this.whatNewService.createWhatNew(body, this.selectedThumbnail, this.selectedAttachment).toPromise()
        .then(res => {
            this.alert(true, 'Save the data successfully.')
            this.isLoading = false;
            this.onEmitData.emit({state:'LIST', page: this.pageData});
        })
        .catch(err => {
            this.alert(false, 'Cannot save this post, please try again.')
            this.isLoading = false;
            console.log('err',err);
        })
    }
  }

  onDeleteWhatNew() {
    this.whatNewService.deleteWhatNew(this.whatNewDetail.id).toPromise()
    .then(res => {
        this.alert(true, 'Delete this post successfully.')
        this.isLoading = false;
        this.onEmitData.emit({state:'LIST', page: this.pageData});
    })
    .catch(err => {
        this.alert(false, 'Cannot delete this post, please try again.')
        this.isLoading = false;
        console.log('err',err);
    })
  }

  validateFrom() {
    let valid: boolean = true;

    // let whatNewDetailForm = this.whatNewForm.getRawValue();

    if (this.whatNewForm.controls.title.invalid) this.whatNewForm.controls.title.setErrors({ null: true });
    if (this.whatNewForm.controls.detail.invalid) this.whatNewForm.controls.detail.setErrors({ null: true });
    if (this.whatNewForm.value.targetGroup.length < 1) this.whatNewForm.controls.targetGroup.setErrors({ null: true });
    // if(this.whatNewForm.get('expiryDate').value ||  this.whatNewForm.get('effectiveDate').value) {
    //     if(this.whatNewForm.get('expiryDate').value && !this.whatNewForm.get('effectiveDate').value) { this.whatNewForm.controls.effectiveDate.setErrors({ null: true }) } else { this.whatNewForm.controls.effectiveDate.setErrors(null) }
    //     if(!this.whatNewForm.get('expiryDate').value && this.whatNewForm.get('effectiveDate').value) { this.whatNewForm.controls.expiryDate.setErrors({ null: true }) } else { this.whatNewForm.controls.expiryDate.setErrors(null) }
    // } else {
    //     this.whatNewForm.controls.effectiveDate.setErrors(null)
    //     this.whatNewForm.controls.expiryDate.setErrors(null)
    // }

    if(this.whatNewForm.get('expiryDate').value) { this.whatNewForm.controls.expiryDate.setErrors(null) } else { this.whatNewForm.controls.expiryDate.setErrors({ null: true }) };
    if(this.whatNewForm.get('effectiveDate').value) { this.whatNewForm.controls.effectiveDate.setErrors(null) } else { this.whatNewForm.controls.effectiveDate.setErrors({ null: true }) };

    if (this.whatNewForm.controls.targetGroup.status.toUpperCase() !== 'VALID' ||
        this.whatNewForm.controls.title.status.toUpperCase() !== 'VALID' ||
        this.whatNewForm.controls.detail.status.toUpperCase() !== 'VALID' ||
        this.whatNewForm.controls.expiryDate.status.toUpperCase() !== 'VALID' ||
        this.whatNewForm.controls.effectiveDate.status.toUpperCase() !== 'VALID'
    ) {
        valid = false;
        this.alert(false, 'Please fill out the form completely.')
        return valid;
    }

    if(this.status === 'new' && !this.selectedThumbnail) {
        valid = false;
        this.alert(false, 'Please upload a thumbnail before submitting.')
        return valid;
    } else if (this.status === 'edit') {
        if(!this.selectedThumbnail && !this.whatNewForm.get('thumbnail').value) {
            valid = false
            this.alert(false, 'Please upload a thumbnail before submitting.')
            return valid;
        } else {
            valid = true;
        }
    }

    return valid;
    }

    assignDateToString(dateData) {
        var date = new Date(dateData),
            month = '' + (date.getMonth() + 1),
            day = '' + date.getDate(),
            year = date.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        let formatDate  = [year, month, day].join('-');
        return formatDate;
    }

}
