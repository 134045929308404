<ng-container [ngSwitch]="switch">
  <ng-container *ngSwitchCase="'notAllow'">
    <app-layout>
      <hr style="border-top: 1px solid #ffa553">

      <div *ngIf="!onLoad;" class="container text-center align-items-center">
        NO PERMISSION
      </div>

      <div *ngIf="onLoad;" class="row">
        <div class="col-md-12 text-center">
          <div class="spinner-border text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </app-layout>
  </ng-container>
  <app-outsource-summary-report *ngSwitchCase="'report'"></app-outsource-summary-report>

</ng-container>
