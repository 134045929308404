import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Interface } from 'readline';
import {
  SpecialHoliday,
  Pagination,
} from '../models/special-holiday';

@Injectable({
  providedIn: 'root',
})
export class SpecialHolidayService {
  private endPoint = environment.api_endpoint;
  constructor(private http: HttpClient) {}

  getEntity(
    year: string,
    page: number,
    size: number
  ): Observable<Pagination<SpecialHoliday[]>> {
    const params = {
      year: year,
      page: page.toString(),
      size: size.toString(),
    };
    return this.http.get<Pagination<SpecialHoliday[]>>(
      `${this.endPoint}/get-special-holiday`,
      { params }
    );
  }

  public saveEntity(entity: SpecialHoliday): Observable<any> {
    return this.http.post<SpecialHoliday>(
      `${this.endPoint}/save-special-holiday`,
      entity
    );
  }
  public deleteEntity(id: number): Observable<any> {
    return this.http.delete<void>(
      `${this.endPoint}/delete-special-holiday?id=${id}`
    );
  }

}
