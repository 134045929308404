import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {saveAs} from 'file-saver';
import Swal from 'sweetalert2';
import {faIcon} from 'src/app/core/icon/fa.icon';
import {WhatNewService} from 'src/app/core/services/what-new.service';
import {BsModalRef, BsModalService, TypeaheadMatch} from 'ngx-bootstrap';
import {ActivatedRoute} from '@angular/router';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { WhatNew } from 'src/app/core/models/what-new';

@Component({
  selector: 'app-what-new-detail',
  templateUrl: './what-new-detail.component.html',
  styleUrls: ['./what-new-detail.component.scss']
})
export class WhatNewDetailComponent implements OnInit {
  @Input() pageData: String;
  @Input() whatNewDetail: WhatNew;
  @Output() onEmitData = new EventEmitter();
  thumbnail = '';
  pure = false

  constructor(
    private whatNewService: WhatNewService,
    private activatedRoute: ActivatedRoute,
    private employeeService: EmployeeService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      next => {
        if (next.id) {
          this.employeeService.pure.next(true)
          this.pure = true
          this.findById(next.id)
        }
      }
    )

    if (this.whatNewDetail) {
      this.getWhatNewFile(this.whatNewDetail.id, this.whatNewDetail.thumbnail, 'thumbnail');
      this.createWhatNewLog(this.whatNewDetail.id);
    } else {
      this.onEmitData.emit({state: 'LIST', page: 1});
    }
  }

  async findById(id) {
    try {
      this.whatNewDetail = await this.whatNewService.findById(id).toPromise()
      this.getWhatNewFile(this.whatNewDetail.id, this.whatNewDetail.thumbnail, 'thumbnail');
      this.createWhatNewLog(this.whatNewDetail.id);
    } catch (e) {
      console.error(e)
    }
  }

  onBack() {
    this.onEmitData.emit({state: 'LIST', page: this.pageData});
  }

  getWhatNewFile(id, fileName, fileType) {
    this.whatNewService.getWhatNewFile(id, fileName, fileType).toPromise()
      .then(res => {
        if (fileType === 'thumbnail') {
          const blob = fileName.includes('.pdf') ? new Blob([res.body], {type: 'application/pdf'}) : new Blob([res.body], {type: 'image/jpeg'});
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            this.thumbnail = reader.result as string;
          };
        }

        if (fileType === 'attachment') {
          const blob = new Blob([res.body]);
          saveAs(blob, fileName);
        }
      }).catch(err => {
      console.error(err);
    })
  }

  createWhatNewLog(whatNewId) {
    const body = {
      channel: 'WEB',
      whatNewId
    }
    this.whatNewService.createWhatNewLog(body).toPromise()
      .then(res => {

      })
      .catch(err => {
        console.log('err', err);
      })
  }
}
