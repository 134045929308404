import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DeptAuthorization } from '../models/deptAuthorization';

@Injectable({
  providedIn: 'root',
})
export class DeptAuthorizationService {
  constructor(private http: HttpClient) {}
  getAllDeptAuthorizations(): Observable<DeptAuthorization[]> {
    return this.http.get<DeptAuthorization[]>(`${environment.api_endpoint}/deptAuthorizations`);
  }

  insert(data): Observable<DeptAuthorization> {
    return this.http.post<DeptAuthorization>(`${environment.api_endpoint}/deptAuthorization`, data);
  }

  delete(id): Observable<any> {
    return this.http.delete(`${environment.api_endpoint}/deptAuthorization/${id}`);
  }
}
