import { Component, OnInit } from '@angular/core';
import { faIcon } from '../../core/icon/fa.icon';
import { PagerModel } from 'ngx-bootstrap/pagination/models';
import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { SpecialHolidayService } from '../../core/services/special-holiday.service';
import Swal from 'sweetalert2';
import {
  SpecialHoliday,
  Pagination,
  PaginationClass,
} from '../../core/models/special-holiday';

@Component({
  selector: 'special-holiday',
  templateUrl: './special-holiday.component.html',
  styleUrls: ['./special-holiday.component.scss'],
})
export class SpecialHolidayComponent implements OnInit {
  public faIcon = faIcon;
  holidays: any[];
  holidaysPage: Pagination<SpecialHoliday[]> = new PaginationClass<
    SpecialHoliday[]
  >();
  page = 0;
  size = 50;
  yearOptions: number[] = [];
  paginationConfig: PagerModel = {
    itemsPerPage: 50,
    previousText: 'ย้อนกลับ',
    nextText: 'ถัดไป',
    pageBtnClass: 'btn btn-warning',
    align: true,
  };
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private specialHolidayService: SpecialHolidayService
  ) {}

  form: FormGroup;
  get entityFormArray(): FormArray {
    return this.form.get('holidays') as FormArray;
  }
  entity: string = '2023';

  ngOnInit() {
    this.createForm();
    this.searchHoliday(this.entity);
    this.generateYearOptions();
  }

  createForm() {
    this.form = this.fb.group({
      holidays: this.fb.array([]),
    });
  }

  generateYearOptions() {
    const currentYear = new Date().getFullYear();

    for (let i = currentYear; i <= currentYear + 5; i++) {
      this.yearOptions.push(i);
    }
  }

  onYearChange(selectedYear: number) {
    this.entity = selectedYear.toString();
    this.searchHoliday(this.entity);
  }

  initFormArray(data: SpecialHoliday) {
    return this.fb.group({
      id: [data ? data.id : null],
      holiday: [data ? data.holiday : null, Validators.required],
      descEng: [data ? data.descEng : null, Validators.required],
      descTh: [data ? data.descTh : null, Validators.required],
      editable: [false],
    });
  }

  searchHoliday(entity: string) {
    this.entityFormArray.clear();
    this.specialHolidayService
      .getEntity(entity, this.page, this.size)
      .subscribe((res: Pagination<SpecialHoliday[]>) => {
        this.holidaysPage = res;
        this.holidays = res.content;
        this.holidays.forEach((it) =>
          this.entityFormArray.push(this.initFormArray(it))
        );
        console.log('Holiday >> ', this.holidays);
      });
  }

  onPageChange(page: number) {
    console.log('page change: ', page);
    this.page = page - 1;
    this.searchHoliday(this.entity);
  }

  onEntityChange(entity: string) {
    this.entity = entity;
  }

  onSearch() {
    this.searchHoliday(this.entity);
  }

  onAdd() {
    this.entityFormArray.insert(0, this.initFormArray(null));
    this.entityFormArray.at(0).get('editable').setValue(true);
  }

  onEdit(item: FormControl) {
    item.get('editable').setValue(true);
  }

  onCancel(item: FormControl) {
    item.get('editable').setValue(false);
  }

  onDelete(item: FormControl) {
    this.openConfirmModal(
      'คุณต้องการลบ Holiday นี้ใช่หรือไม่',
      'ใช่',
      null,
      false,
      null,
      (confirm: boolean) => {
        if (confirm) {
          const id = item.get('id').value;
          if (id) {
            this.specialHolidayService.deleteEntity(id).subscribe(
              () => {
                this.searchHoliday(this.entity);
                this.alert(true, 'Delete Holiday success.');
              },
              () => {
                this.alert(false, 'Delete Holiday failed.');
              }
            );
          }
        }
      }
    );
  }

  save(item: FormControl, isSuccess: (success: boolean) => void) {
    let request: SpecialHoliday = {
      id: item.get('id').value,
      holiday: item.get('holiday').value,
      descEng: item.get('descEng').value,
      descTh: item.get('descTh').value,
    };

    this.specialHolidayService.saveEntity(request).subscribe(
      () => {
        this.searchHoliday(this.entity);
        item.get('editable').setValue(false);
        isSuccess(true);
      },
      () => {
        this.alert(false, 'Save Holiday failed.');
      }
    );
  }

  onSave(item: FormControl) {
    console.log('save >> ', item.value);
    this.save(item, (success: boolean) => {
      if (success) {
        this.alert(true, 'Save entity success.');
      } else {
        this.alert(false, 'Save entity failed.');
      }
    });
  }

  openConfirmModal(
    message: string,
    btnTextConfirm: string,
    btnConfirmColor: string,
    showTextInput: boolean,
    placeholder: string,
    isConfirm: (confirm: boolean, reason?: string) => void
  ) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    const modalSuccess = this.modalService.open(
      ConfirmModalComponent,
      ngbModalOptions
    );
    modalSuccess.componentInstance.body = message;
    modalSuccess.componentInstance.btnTextConfirm = btnTextConfirm;
    modalSuccess.componentInstance.btnConfirmColor = btnConfirmColor;
    modalSuccess.componentInstance.showTextInput = showTextInput;
    modalSuccess.componentInstance.placeholder = placeholder;
    modalSuccess.result.then((res) => {
      console.log('res', res);
      if (res.confirm) {
        isConfirm(true, res.reason);
      } else {
        isConfirm(false);
      }
    });
  }

  alert(isSuccess: boolean, msg: string) {
    return Swal.fire({
      scrollbarPadding: false,
      text: msg,
      title: isSuccess ? 'Succesfully' : 'Failed',
      icon: isSuccess ? 'success' : 'error',
      confirmButtonText: 'OK',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-info  mr-2 text-light',
      },
    });
  }
}
