<div class="container-fluid mt-4 pl-5 pr-5">
    <h3 class="mt-3">WFH Allowance</h3>

    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label for="dateRange">REVIEWED DATE</label>
                <input placeholder="Please select date." id="dateRange" type="text" class="form-control"
                    bsDaterangepicker #dpr="bsDaterangepicker" (bsValueChange)="onDateChange($event)"
                    [bsConfig]="{ rangeInputFormat : 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY',containerClass: 'theme-orange'}">
            </div>


            <!-- <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker
                [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD',containerClass: 'theme-orange'}"> -->

        </div>

    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label for="option_">SEARCH OPTION</label>
                <select name="" id="option_" class="form-control" [(ngModel)]="optionSearchModel"
                    (change)="onOptionSearchChange()">
                    <option value="{{o.value}}" *ngFor="let o of optionSearch">{{o.text}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-2" *ngIf="showLastUpdate">
            <label for="option_">LAST EXPORT DATE</label>
            <input type="text" (bsValueChange)="onLastExportChange($event)" placeholder="Datepicker"
                class="form-control" bsDatepicker
                [bsConfig]="{  dateInputFormat: 'DD/MM/YYYY',containerClass: 'theme-orange'}">
        </div>

    </div>
    <div class="row">
        <div class="col-md-12">
            <button class="btn btn-info" (click)="onSearch()">SEARCH</button>

        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-right">
            <button class="btn btn-info mr-3" *ngIf="List?.length>0" (click)="exportThisPage()">Export Data In This
                Page</button>

            <button class="btn btn-secondary" *ngIf="List?.length>0" (click)="exportCSV()">Export
                CSV For Payroll</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="mt-2">
                <table class="table" id="dataTable">
                    <thead class="dt-head">
                        <tr>
                            <th>ID</th>
                            <th>KEY</th>
                            <th>REVIEWED DATE</th>
                            <th>REQUEST DATE</th>
                            <th>E-Mail</th>
                            <th>EMP ID</th>
                            <th>AMOUNT</th>
                            <th colspan="2">PAYABLE AMOUNT</th>
                            <th>INVOICE NO</th>
                            <th>LAST EXPORT DATE</th>
                            <th>STATUS</th>
                            <th>REASON</th>
                            <th>LOGS</th>

                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="List?.length > 0">
                            <tr *ngFor="let d of List; index as i" [ngClass]="{ 'tr-bg1':i%2==0,'tr-bg2':i%2==1}">
                                <td><small class="text-muted">{{d.id}}</small></td>
                                <td><small class="text-muted">{{d.jiraKey?d.jiraKey:'-'}}</small></td>
                                <td><small
                                        class="text-muted">{{d.reviewedDate ? (d.reviewedDate | date: 'dd MMM yyyy'):'-'}}</small>
                                </td>
                                <td><small class="text-muted">{{d.requestDate | date: 'dd MMM yyyy'}}</small></td>

                                <td><small class="text-muted">{{d.email}}</small></td>
                                <td><small class="text-muted">{{d.empId}}</small></td>
                                <td class="text-secondary">{{d.amount}}</td>
                                <td>
                                    <div *ngIf="d.saving" class="spinner-border text-warning" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <span *ngIf="!d.edit && !d.saving"
                                        [ngClass]="{'text-success':d.payableAmount.toFixed(2)>0,'text-dark':d.payableAmount.toFixed(2)<=0}">
                                        {{d.payableAmount.toFixed(2)}}</span>

                                    <input type="text" type="number" [id]="'edit-' + d.id" [value]="d.payableAmount"
                                        style="margin-top:-7px" class="form-control custom-control"
                                        [ngClass]="{ 'sr-only': !d.edit }">
                                </td>
                                <td>

                                    <button class="btn text-warning" style="margin-top:-10px" (click)="edit(d.id)">
                                        <fa-icon [icon]="!d.edit?faIcon.faPen:faIcon.faSave"></fa-icon>
                                    </button>
                                </td>
                                <td>
                                    <ng-container *ngFor="let inv of  d.invoiceNo.split(',')">
                                        <span class="badge badge-info">
                                            {{inv}}
                                        </span>
                                        <br>
                                    </ng-container>
                                </td>


                                <td><small
                                        class="text-muted">{{d.lastExport ? (d.lastExport | date: 'dd MMM yyyy'):'-'}}</small>
                                </td>
                                <td [ngClass]="{ 'text-danger':!!d.status,'text-dark':!d.status}">
                                    {{d.status ? d.status:'-' }}</td>
                                <td><small class="text-info">{{d.reason ? (d.reason | uppercase) :'-'}}</small></td>
                                <td><small
                                        class="text-danger">{{logDetail(d.errorLog)?logDetail(d.errorLog):'-'}}</small>
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="List?.length == 0">
                            <td colspan="7" class="no-data-available">No data!</td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </div>


</div>