import {Component, OnInit} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { faIcon } from './core/icon/fa.icon';
import { EmployeeService } from './core/services/employee.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'external-training-web';

  constructor(private employeeService: EmployeeService) {
    // this.employeeService.whoami().toPromise().then(s => console.warn('WHO AM I'))
  }

  ngOnInit(): void {
    console.log('init')
    const r = localStorage.getItem('redirect');
    if (r) {
      localStorage.removeItem('redirect')
      location.href = r
    }
  }
}
