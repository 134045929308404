import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExternalTrainingComponent } from './external-training/external-training.component';
import { RequestListComponent } from './external-training/request-list/request-list.component';
import { ApproveListComponent } from './external-training/approve-list/approve-list.component';

import { LayoutComponent } from './layout/layout.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {
  ModalModule,
  PopoverModule,
  TooltipModule,
  TypeaheadModule,
  RatingModule, CollapseModule,
} from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RequestDetailComponent } from './external-training/request-list/request-detail/request-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthInterceptorProvider } from './core/interceptors/auth.interceptor';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { AddUserComponent } from './components/user-management/add-user/add-user.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { AdministrativeComponent } from './components/administrative/administrative.component';
import { ExternalTrainingListComponent } from './components/external-training-list/external-training-list.component';
import { ExternalRequestListComponent } from './components/external-training-list/external-request-list/external-request-list.component';
import { ShareModule } from './share/share.module';
import { AllowanceComponent } from './components/allowance/allowance.component';
import { MedicalClaimComponent } from './components/medical-claim/medical-claim.component';
import { NewRequestMedicalComponent } from './components/medical-claim/new-request-medical/new-request-medical.component';
import { AdminMedicalComponent } from './components/admin-medical/admin-medical.component';
import { FastFeedbackComponent } from './components/fast-feedback/fast-feedback.component';
import { NewFastFeedbackComponent } from './components/fast-feedback/new-fast-feedback/new-fast-feedback.component';
import { ChartModule } from 'angular2-chartjs';
import { PaginationModule } from "ngx-bootstrap/pagination";
import {TokenInterceptorProvider} from './modules/core/interceptor/token.interceptor';
import { ResponseFastFeedbackComponent } from './components/fast-feedback/response-fast-feedback/response-fast-feedback.component';
import { StrategicProjectComponent } from './components/strategic-project/strategic-project.component';
import { StrategicProjectDetailComponent } from './components/strategic-project/strategic-project-detail/strategic-project-detail.component';
import { ImportStrategicProjectComponent } from './components/strategic-project/import-strategic-project/import-strategic-project.component';
import { OutsourceTimesheetComponent } from './components/outsource-timesheet/outsource-timesheet.component';
import { TimesheetListComponent } from './components/outsource-timesheet/components/timesheet-list/timesheet-list.component';
import { MonthlyDetailComponent } from './components/outsource-timesheet/components/monthly-detail/monthly-detail.component';
import { NewStrategicProjectComponent } from './components/strategic-project/new-strategic-project/new-strategic-project.component';
import { TagInputModule } from 'ngx-chips';
import { UploadExternalFileComponent } from './components/upload-external-file/upload-external-file.component';
import { InternTimesheetComponent } from './components/intern-timesheet/intern-timesheet.component';
import { InternTimesheetListComponent } from './components/intern-timesheet/components/intern-timesheet-list/intern-timesheet-list.component';
import { ManPowerComponent } from './components/man-power/man-power.component';
import { ManPowerListComponent } from './components/man-power/man-power-list/man-power-list.component';
import { ManPowerPendingApproveListComponent } from './components/man-power/man-power-pending-aprpove-list/man-power-pending-approve-list.component';
import { NewManPowerComponent } from './components/man-power/new-man-power/new-man-power.component';
import { WrapInternComponent } from './components/intern-timesheet/components/wrap-intern/wrap-intern.component';
import { SendYourFastFeedbackComponent } from './components/fast-feedback/send-your-fast-feedback/send-your-fast-feedback.component';
import { OutsourceSummaryReportComponent } from './components/outsource-summary-report/outsource-summary-report.component';
import { WrapperOutsourceReportComponent } from './components/outsource-summary-report/wrapper/wrapper-outsource-report/wrapper-outsource-report.component';
import { SumOutsourceReportPipe } from './core/pipes/sum-outsource-report.pipe';
import { OutsourceShortDatePipe } from './core/pipes/outsource-short-date.pipe';
import { AdminAdditionalInformationComponent } from './components/admin-additional-information/admin-additional-information.component';
import { AdminAdditionalInformationFormComponent } from './components/admin-additional-information/admin-additional-information-form/admin-additional-information-form.component';
import { AdminAdditionalInformationListComponent } from './components/admin-additional-information/admin-additional-information-list/admin-additional-information-list.component';
import { WebexMeetingComponent } from './components/webex-meeting/webex-meeting.component';
import { ResponseFastFeedbackQuestionsComponent } from './components/fast-feedback/response-fast-feedback/response-fast-feedback-questions.component';
import { WrapperOutsourceTimesheetComponent } from './components/outsource-timesheet/components/wrapper-outsource-timesheet/wrapper-outsource-timesheet.component';
import { AdminOutsourceTimesheetListComponent } from './components/outsource-timesheet/components/admin-outsource-timesheet-list/admin-outsource-timesheet-list.component';
import { AdminProjectComponent } from './components/admin-outsource-project/admin-project-list/admin-project.component';
import { AdminProjectWrapperComponent } from './components/admin-outsource-project/admin-project-wrapper/admin-project-wrapper.component';
import { ProjectFormComponent } from './components/admin-outsource-project/project-form/project-form.component';
import { MapPipe } from './core/pipes/map.pipe';
import { JoinWithPipe } from './core/pipes/join-with.pipe';
import { VendorEvaluationComponent } from './components/fast-feedback/vendor-evaluation/vendor-evaluation.component';
import { VendorEvaluationDetailComponent } from './components/fast-feedback/vendor-evaluation-detail/vendor-evaluation-detail.component';
import { ManpowerReportComponent } from './components/man-power/manpower-report/manpower-report.component';
import { AdminDeptAuthorizationComponent } from './components/admin-dept-authorization/admin-dept-authorization.component';
import { LearningCenterLayoutComponent } from './components/learning-center-layout.component';
import { OutsourceTimesheetDownloadComponent } from './components/outsource-timesheet-download/outsource-timesheet-download.component';
import { WhatNewListComponent } from './components/what-new/what-new-list/what-new-list.component';
import { WhatNewDetailComponent } from './components/what-new/what-new-detail/what-new-detail.component';
import { WhatNewFormComponent } from './components/what-new/what-new-form/what-new-form.component';
import { TrackFileComponent } from './components/track-file/track-file.component';
import { ParallelPositionComponent } from './components/parallel-position/parallel-position.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ApprovalStateComponent } from './external-training/request-list/request-detail/approval-state/approval-state.component';
import { SpecialHolidayComponent } from './components/special-holiday/special-holiday.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { TextTruncatePipe } from './core/pipes/text-truncate.pipe';
import { ManPowerHrAdminPendingComponent } from './components/man-power/man-power-hr-admin-pending/man-power-hr-admin-pending.component';
import { StrategicProjectLogComponent } from './components/strategic-project/strategic-project-detail/strategic-project-log/strategic-project-log.component';
@NgModule({
  declarations: [
    AppComponent,
    ExternalTrainingComponent,
    RequestListComponent,
    ApproveListComponent,
    LayoutComponent,
    RequestDetailComponent,
    UserManagementComponent,
    AddUserComponent,
    LoadingOverlayComponent,
    AdministrativeComponent,
    ExternalTrainingListComponent,
    ExternalRequestListComponent,
    AllowanceComponent,
    MedicalClaimComponent,
    NewRequestMedicalComponent,
    AdminMedicalComponent,
    FastFeedbackComponent,
    NewFastFeedbackComponent,
    ResponseFastFeedbackComponent,
    StrategicProjectComponent,
    StrategicProjectDetailComponent,
    ImportStrategicProjectComponent,
    OutsourceTimesheetComponent,
    TimesheetListComponent,
    MonthlyDetailComponent,
    NewStrategicProjectComponent,
    UploadExternalFileComponent,
    InternTimesheetComponent,
    InternTimesheetListComponent,
    ManPowerComponent,
    ManPowerPendingApproveListComponent,
    ManPowerListComponent,
    NewManPowerComponent,
    WrapInternComponent,
    SendYourFastFeedbackComponent,
    VendorEvaluationComponent,
    VendorEvaluationDetailComponent,
    OutsourceSummaryReportComponent,
    WrapperOutsourceReportComponent,
    SumOutsourceReportPipe,
    OutsourceShortDatePipe,
    AdminAdditionalInformationComponent,
    AdminAdditionalInformationFormComponent,
    AdminAdditionalInformationListComponent,
    WebexMeetingComponent,
    ResponseFastFeedbackQuestionsComponent,
    WrapperOutsourceTimesheetComponent,
    AdminOutsourceTimesheetListComponent,
    AdminProjectComponent,
    AdminProjectWrapperComponent,
    ProjectFormComponent,
    MapPipe,
    JoinWithPipe,
    ManpowerReportComponent,
    AdminDeptAuthorizationComponent,
    LearningCenterLayoutComponent,
    OutsourceTimesheetDownloadComponent,
    WhatNewListComponent,
    WhatNewDetailComponent,
    WhatNewFormComponent,
    TrackFileComponent,
    ParallelPositionComponent,
    ApprovalStateComponent,
    SpecialHolidayComponent,
    ConfirmModalComponent,
    TextTruncatePipe,
    ManPowerHrAdminPendingComponent,
    StrategicProjectLogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SweetAlert2Module.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    TypeaheadModule.forRoot(),
    HttpClientModule,
    ProgressbarModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    ShareModule,
    ChartModule,
    PaginationModule.forRoot(),
    RatingModule.forRoot(),
    TagInputModule,
    CollapseModule,
    NgSelectModule,
    NgxPaginationModule,
    MatTabsModule
  ],
  providers: [AuthInterceptorProvider, DatePipe, TokenInterceptorProvider],
  bootstrap: [AppComponent],
    exports: [
        LayoutComponent
    ]
})
export class AppModule {}
