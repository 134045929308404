import { Employee } from './../models/employee';
import { Position } from './../models/position';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ParallelPosition } from '../models/parallelPosition';

@Injectable({
  providedIn: 'root'
})
export class ParallelPositionService {

  constructor(private http: HttpClient) { }

  getDepartment(): Observable<Position[]>{
    return this.http.get<Position[]>(`${environment.api_endpoint}/parallel-position-department`).pipe(
      map((data: any) => data)
    );
  }

  getPositionByDepartment(deptId): Observable<Position[]> {
    return this.http.get<Position[]>(`${environment.api_endpoint}/parallel-position-position/${deptId}`).pipe(
      map((data: any) => data)
    )
  }
  getEmployeeByDepartment(department:string): Observable<Employee[]> {
    let params = new HttpParams()
    .set('department',department)
    return this.http.get<Employee[]>(`${environment.api_endpoint}/employee/department`,{params: params}).pipe(
      map((data:any) => data)
    )
  }

  getAllParallelPosition(): Observable<ParallelPosition[]> {
    return this.http.get<ParallelPosition[]>(`${environment.api_endpoint}/parallel-positions`).pipe(
      map(data => data)
    )
  }

  save(data): Observable<ParallelPosition> {
    return this.http.post<ParallelPosition>(`${environment.api_endpoint}/parallel-position`,data);
  }
  delete(id): Observable<any> {
    return this.http.delete(`${environment.api_endpoint}/parallel-position/${id}`)
  }
  update(id,body): Observable<any> {
    return this.http.put<any>(`${environment.api_endpoint}/parallel-position/${id}`,body);
  }
}
