import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UtilsService} from '../../core/services/utils.service';
import {map, switchMap} from 'rxjs/operators';
import {saveAs} from 'file-saver';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-outsource-timesheet-download',
  templateUrl: './outsource-timesheet-download.component.html',
  styleUrls: ['./outsource-timesheet-download.component.scss']
})
export class OutsourceTimesheetDownloadComponent implements OnInit, OnDestroy {

  attachments$ = this.activatedRoute.params.pipe(
    map(a => a.uuid),
    switchMap(uuid => this.utilsService.listAttachment(uuid))
  )

  sub: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService
  ) {
  }

  ngOnInit(): void {
  }

  downloadAttachment(file: string) {
    this.sub = this.activatedRoute.params
      .pipe(
        map(a => a.uuid),
        switchMap(uuid => this.utilsService.downloadAttachment(uuid, file))
      )
      .subscribe(r => {
        saveAs(r, file);
      })

  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}
