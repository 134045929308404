<div class="container-fluid mt-4" *ngIf="whatNewDetail">
  <div class="ml-4">
    <div class="row">
      <h5 class="col-12">
        <b>
          {{whatNewDetail.title}}
        </b>
      </h5>
    </div>
    <div class="mt-3">
      <div class="row">
        <h6 class="col-8" style="white-space: break-spaces;" *ngIf="whatNewDetail.detail" [innerHTML]="whatNewDetail.detail">
        </h6>
      </div>
    </div>
    <div class="mt-3">
      <img [src]="thumbnail" *ngIf="thumbnail" class="image-responsive" width="900" height="600">
    </div>
    <div class="mt-3" *ngIf="whatNewDetail.attachment">
      <a (click)="getWhatNewFile(whatNewDetail.id,whatNewDetail.attachment,'attachment')" class="custom-link">
        {{whatNewDetail.attachment}}
      </a>
    </div>
    <div class="mt-3" *ngIf="whatNewDetail.url">
      <a href="{{whatNewDetail.url}}" target="_blank" class="custom-link">
        {{whatNewDetail.url}}
      </a>
    </div>
    <div class="mt-5" >
      <h6 class="text-muted">Posted By: {{whatNewDetail.deptName ? whatNewDetail.deptName : '-'}}</h6>
      <h6 class="text-muted mt-3">Posted Date: {{whatNewDetail.createDate ? (whatNewDetail.createDate | date:'dd MMM yyyy HH:mm') : ''}}</h6>
    </div>

    <div class="row  justify-content-end mt-5 mb-3" *ngIf="!pure">
      <div class="col-sm-auto">
        <button type="button" class="btn btn-primary app-btn-style" (click)="onBack()">BACK</button>
      </div>
    </div>
  </div>
</div>
