<div class="container-fluid mt-4 " *ngIf="approveList">
  <div class="col-12" style="min-height: 40vh; overflow: scroll;">

    <h4>Pending For Approval</h4>

    <div class="form-row">
      <div class="col">
        <table class="table">
          <thead class="bg-ca text-white-smoke">
            <tr>
              <th></th>
              <th class="col-header">REQ ID</th>
              <th class="col-header">COURSE</th>
              <th class="col-header">TRAINING DATE</th>
              <th class="col-header">AMOUNT (BAHT)</th>
              <th class="col-header">REQUEST DATE </th>
              <th class="col-header">REQUESTER</th>
            </tr>
          </thead>
          <tbody *ngIf="approveList">
            <tr *ngFor="let a of approveList ; let i =index">
              <td>{{i+1}}</td>
              <td>{{a.id}}</td>
              <td (click)="openApprove(approveDetailComponent.template, a)" style="cursor: pointer;color: deepskyblue">
                {{a.course.courseDescr}}</td>
              <!-- <td *ngIf="a.startDate !== a.endDate">{{a.startDate | date: 'dd MMM y'}} -
                {{a.endDate | date: 'dd MMM y'}}</td> -->
              <td>{{a.DisplayDate}}</td>
              <td>{{a.totalAmount | number }}</td>
              <td>{{a.createDate | date: 'dd MMM y'}}</td>
              <td>{{a.employee.nameTh}}</td>
            </tr>
          </tbody>
          <tbody *ngIf="!approveList">
            <tr>
              <td colspan="7">
                <div class="d-flex justify-content-center">
                  <span style="color: darkgrey;">No Pending for Approval</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<app-request-detail #approveDetailComponent [modalRef]="modalRef" [requestDetail]="approveDetail"
   [myEmail]="myEmail" [fromSection]="fromSection" (loadData)="getApproveList()">
</app-request-detail>
