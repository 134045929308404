import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {UserRole} from '../models/userRole';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(private http: HttpClient) {
  }

  endpoint = environment.api_endpoint;

  getList(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(`${this.endpoint}/roles`);
  }

  submitRole(model, roleId): Observable<UserRole> {
    if (roleId) {
      return this.http.put<UserRole>(`${this.endpoint}/role`, model);
    } else {
      return this.http.post<UserRole>(`${this.endpoint}/role`, model);
    }
  }

  delete(id: number) {
    return this.http.delete(`${this.endpoint}/role/${id}`);
  }
}
