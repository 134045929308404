import {Pipe, PipeTransform} from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'outsourceShortDate'
})
export class OutsourceShortDatePipe implements PipeTransform {

  transform(date: string, ...args: unknown[]): unknown {
    const [month, year] = date.split(' ')
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return dayjs().month(months.indexOf(month)).year(+year).format('MMM YY').toUpperCase();
  }

}
