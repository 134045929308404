<ng-template #template>
  <div class="modal-header bg-dark-gray">
    <h4 class="modal-title pull-left" *ngIf="!reqId">NEW REQUEST</h4>
    <h4 class="modal-title pull-left" *ngIf="reqId">REQUEST DETAIL</h4>
    <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"> -->
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="xCloseModal()"
    >
      <fa-icon [icon]="faIcon.faWindowClose" class="text-white"></fa-icon>
    </button>
  </div>

  <div class="modal-body m-3">
    <form [formGroup]="form">
      <div class="row mb-3">
        <div class="col-2 text-right pl-4">
          <label class="col-form-label text-right" style="padding-right: 0px"
          >Course Name:</label
          >
        </div>
        <div class="col-10 ml-n3">
          <!-- <input type="text" class="form-control" [ngClass]="{'is-invalid':form.controls.course.hasError('isNull')}"
            [formControl]="form.controls.course['controls'].courseDescr"> -->
          <input
            type="text"
            class="form-control"
            [ngClass]="{
              'is-invalid': form.controls.course.hasError('isNull')
            }"
            [formControl]="form.controls.course['controls'].courseDescr"
            [typeahead]="courseList"
            [typeaheadMinLength]="0"
            typeaheadOptionField="courseDescr"
            (typeaheadOnSelect)="onCourseSelect($event)"
            (typeaheadNoResults)="onCourseNoResult($event)"
            (blur)="onCourseBlur($event)"
            [typeaheadOptionsLimit]="10"
            autocomplete="off"
            id="courseName"
          />

          <small
            *ngIf="this.form.controls.course.hasError('isNull')"
            class="text-danger ml-2"
          >
            {{ this.form.controls.course.getError('errMsg') }}
          </small>
        </div>
      </div>

      <div class="row">
        <div class="col-7">
          <div class="form-group row">
            <!-- <div class="col-sm-1">
              <fa-icon [icon]="faIcon.faCircle" size="2x"></fa-icon>
            </div> -->
            <label
              class="col-sm-3 col-form-label text-right"
              style="padding-right: 0px"
            >Competency:</label
            >
            <div class="col-sm-9">
              <input
                [ngClass]="{
                  'is-invalid':
                    form.controls.course['controls'].competency[
                      'controls'
                    ].competencyDescr.hasError('isNull') ||
                    this.form.controls.course['controls'].competency[
                      'controls'
                    ].competencyDescr.hasError('inCorrect')
                }"
                [formControl]="
                  form.controls.course['controls'].competency['controls']
                    .competencyDescr
                "
                type="text"
                class="form-control"
                [typeahead]="competencyList"
                typeaheadOptionField="competencyDescr"
                (typeaheadOnSelect)="competencySelect($event)"
                typeaheadOptionsLimit="5"
                autocomplete="off"
                [typeaheadMinLength]="0"
                (typeaheadNoResults)="resultNotInList('competency')"
                id="competency"
              />
              <small
                *ngIf="
                  this.form.controls.course['controls'].competency[
                    'controls'
                  ].competencyDescr.hasError('isNull')
                "
                class="text-danger ml-2"
              >
                {{
                form.controls.course['controls'].competency[
                  'controls'
                  ].competencyDescr.getError('errMsg')
                }}
              </small>
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-sm-3 col-form-label text-right"
              style="padding-right: 0px"
            >Class Type:</label
            >
            <div class="col-sm-9">
              <input
                [ngClass]="{
                  'is-invalid': form.controls.course[
                    'controls'
                  ].learningItem.hasError('isNull')
                }"
                [formControl]="form.controls.course['controls'].learningItem"
                type="text"
                class="form-control"
                [typeahead]="learningItemList"
                typeaheadOptionField="descr"
                typeaheadOptionsLimit="5"
                autocomplete="off"
                (typeaheadNoResults)="resultNotInList('learningItem')"
                [typeaheadMinLength]="0"
              />
              <small
                *ngIf="
                  this.form.controls.course['controls'].learningItem.hasError(
                    'isNull'
                  )
                "
                class="text-danger ml-2"
              >
                {{
                form.controls.course['controls'].learningItem.getError(
                  'errMsg'
                )
                }}
              </small>
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-sm-3 col-form-label text-right"
              style="padding-right: 0px"
            >Class Catalog:</label
            >
            <div class="col-sm-9">
              <input
                [class.is-invalid]="form.controls.course['controls'].learningCatalog.hasError('isNull')"
                [formControl]="form.controls.course['controls'].learningCatalog"
                type="text"
                class="form-control"
                [typeahead]="learningCatalogList"
                typeaheadOptionField="descr"
                typeaheadOptionsLimit="5"
                autocomplete="off"
                (typeaheadNoResults)="resultNotInList('learningCatalog')"
                [typeaheadMinLength]="0"
              />
              <small
                *ngIf="
                  this.form.controls.course[
                    'controls'
                  ].learningCatalog.hasError('isNull')
                "
                class="text-danger ml-2"
              >
                {{
                form.controls.course['controls'].learningCatalog.getError(
                  'errMsg'
                )
                }}
              </small>
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-sm-3 col-form-label text-right"
              style="padding-right: 0px">Institution:</label>
            <div class="col-sm-9">
              <input type="text"
                     id="institution"
                     class="form-control"
                     [typeahead]="vendorList"
                     typeaheadOptionField="vendorName"
                     typeaheadOptionsLimit="5"
                     autocomplete="off"
                     formControlName="institution"
                     [typeaheadIsFirstItemActive]="false"
                     [typeaheadMinLength]="0">

              <!--               {{ userRoles }} -->
              <small
                *ngIf="this.form.controls.institution.hasError('isNull')" class="text-danger ml-2">
                {{ this.form.controls.institution.getError('errMsg') }}
              </small>
            </div>
          </div>

          <div class="form-group row" *ngIf="this.form.get('institution').value ==='other'">
            <label class="col-sm-3 col-form-label text-right" style="padding-right: 0px"></label>
            <div class="col-sm-9">
              <input class="form-control" formControlName="otherInstitution" placeholder="other institution" type="text"
                     [ngClass]="{'is-invalid': form.controls.otherInstitution.hasError('isNull')}"/>
            </div>
          </div>

          <div class=" form-group row">
            <label
              class="col-sm-3 col-form-label text-right"
              style="padding-right: 0px"
            >Start Date:</label
            >
            <div class="col-sm-4">
              <input
                type="text"
                [ngClass]="{ 'is-invalid': startDateInvalid }"
                class="form-control"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD MMM YYYY' }"
                formControlName="startDate"
                [minDate]="toDay"
              />
              <!-- <input
                *ngIf="userRoles === 'HRAdmin'"
                type="text"
                [ngClass]="{ 'is-invalid': startDateInvalid }"
                class="form-control"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'DD MMM YYYY' }"
                formControlName="startDate"
              /> -->
              <small
                *ngIf="this.form.controls.startDate.hasError('isNull')"
                class="text-danger ml-2"
              >
                {{ this.form.controls.startDate.getError('errMsg') }}
              </small>
            </div>
            <label
              class="col-form-label"
              style="padding-left: 12px; margin-left: 10px"
            >To:</label
            >
            <div class="col-sm-4 col text-right">
              <input
                [ngClass]="{ 'is-invalid': endDateInvalid }"
                type="text"
                class="form-control"
                bsDatepicker
                [bsConfig]="{
                  dateInputFormat: 'DD MMM YYYY'
                }"
                formControlName="endDate"
                [minDate]="form.controls['startDate'].value || toDay"
              />
              <small
                *ngIf="this.form.controls.endDate.hasError('isNull')"
                class="text-danger"
              >
                {{ this.form.controls.endDate.getError('errMsg') }}
              </small>
            </div>
          </div>
          <div class="row pl-5">
            <div class="col-12 pl-5 ml-3 mb-2">
              <small
                *ngIf="this.form.controls.startDate.hasError('incorrect')"
                class="text-danger ml-5 mt-n3"
              >
                {{ this.form.controls.startDate.getError('errMsg') }}
              </small>
            </div>
          </div>
          <div class="form-group row">
            <!-- <div class="col-sm-1">
              <fa-icon [icon]="faIcon.faClock" size="2x"></fa-icon>
            </div> -->
            <label
              class="col-sm-3 col-form-label text-right"
              style="padding-right: 0px"
            >Duration:</label
            >
            <div class="col-sm-4">
              <!-- <input [ngClass]="{'is-invalid':form.controls.duration.hasError('isNull')}" type="text" appNumberFormat
                class="form-control" formControlName="duration"> -->
              <input
                [ngClass]="{
                  'is-invalid': form.controls.duration.hasError('isNull')
                }"
                type="text"
                class="form-control"
                formControlName="duration"
              />
              <small
                *ngIf="this.form.controls.duration.hasError('isNull')"
                class="text-danger"
              >
                {{ this.form.controls.duration.getError('errMsg') }}
              </small>
            </div>
            <label
              class="col-1 col-form-label"
              style="padding-left: 12px; margin-left: 10px"
            >Days</label
            >
          </div>

          <div class="form-group row">
            <label
              class="col-sm-3 col-form-label text-right"
              style="padding-right: 0px"
            >Objective:</label
            >
            <div class="col-sm-9">
              <textarea
                [ngClass]="{
                  'is-invalid': form.controls.objective.hasError('isNull')
                }"
                class="form-control"
                rows="2"
                formControlName="objective"
              ></textarea>
              <small
                *ngIf="this.form.controls.objective.hasError('isNull')"
                class="text-danger"
              >
                {{ this.form.controls.objective.getError('errMsg') }}
              </small>
            </div>
          </div>

          <!-- <div class="form-group row">
            <div class="col-sm-1">
              <fa-icon [icon]="faIcon.faUser" size="2x"></fa-icon>
            </div>
            <div class="col-sm-6">
              <input type="text" class="form-control" placeholder="Approver" formControlName="approver">
            </div>
          </div> -->
          <div class="form-group row">
            <label
              class="col-sm-3 col-form-label text-right"
              style="padding-right: 0px"
            >Attachment:</label
            >
            <div class="col-sm-7" style="padding-right: 0px">
              <div class="custom-file">
                <input
                  *ngIf="!isPrecessEnd"
                  type="file"
                  class="custom-file-input"
                  id="customFile"
                  (change)="handleFileInput($event.target.files)"
                />
                <input
                  *ngIf="isPrecessEnd"
                  disabled
                  type="file"
                  class="custom-file-input"
                  id="customFile"
                  (change)="handleFileInput($event.target.files)"
                />
                <label class="custom-file-label" for="customFile">{{
                  fileToUpload?.name ? fileToUpload.name : 'Choose file'
                  }}</label>
              </div>
            </div>
            <!-- <div class="col text-right"> -->
            <div class="col-1" style="padding-right: 0px; padding-left: 0px">
              <button
                *ngIf="!isPrecessEnd"
                (click)="uploadFileToActivity()"
                class="btn btn-primary text-white"
                type="button"
                style="
                  width: 80px;
                  padding-left: 5px;
                  padding-right: 5px;
                  margin-left: 5px;
                "
              >
                Upload
              </button>
              <button
                *ngIf="isPrecessEnd"
                disabled
                (click)="uploadFileToActivity()"
                class="btn btn-primary text-white"
                type="button"
                style="
                  width: 80px 80px;
                  border-left-width: 0px;
                  margin-left: 5px;
                "
              >
                Upload
              </button>
            </div>
          </div>
          <div class="form-group row">
            <label
              class="col-sm-3 col-form-label text-right"
              style="padding-right: 0px"
            ></label>
            <div class="col-sm-6" style="font-size: smaller; color: red">
              File Type MS Office, pdf, jpeg
            </div>
          </div>

          <!-- fileList -->
          <div class="form-group row">
            <div class="col-sm-2">
              <!-- <fa-icon [icon]="faIcon.faPaperclip" size="2x"></fa-icon> -->
            </div>
            <div class="col-sm-7">
              <div
                class="row row-table m-0"
                *ngFor="let a of fileList; index as i"
              >
                <div class="col-auto">
                  <button
                    (click)="downloadFile(a.fileName)"
                    class="btn btn-link mt-n2"
                  >
                    {{ i + 1 }}. {{ a.fileNameShort }}
                  </button>
                </div>
                <div class="col-2 px-0 text-left">
                  <button class="btn pt-0" (click)="delFile(a.id)">
                    <fa-icon
                      [icon]="faIcon.faTimes"
                      style="color: red"
                    ></fa-icon>
                  </button>
                </div>
              </div>
              <ng-container *ngIf="uploadProgress > 0">
                <progressbar
                  [value]="uploadProgress"
                  type="warning"
                  [striped]="true"
                >{{ uploadProgress }}%
                </progressbar
                >
              </ng-container>
            </div>
          </div>
        </div>

        <div class="col-5">
          <div class="form-group row">
            <label
              class="col-sm-4 col-form-label text-right"
              style="padding-right: 0px"
            >Price/Person:</label
            >
            <div class="col-sm-6">

              <input
                [ngClass]="{
                  'is-invalid': form.controls.unitPrice.hasError('isNull')
              }" type="text" class="form-control" formControlName="unitPrice" (focusout)="calGrandTotal()"/>
              <small
                *ngIf="this.form.controls.unitPrice.hasError('isNull')"
                class="text-danger">
                {{ this.form.controls.unitPrice.getError('errMsg') }}
              </small>
            </div>
            <label class="col-1 col-form-label" style="padding-left: 0px"
            >Baht</label
            >
          </div>

          <div class="form-group row">
            <label
              class="col-sm-4 col-form-label text-right"
              style="padding-right: 0px"
            >Attendees:</label>
            <div class="col-sm-6">
              <input
                type="text"
                [ngClass]="{
                  'is-invalid': form.controls.attendeeList.hasError('isNull')
                }"
                class="form-control"
                id="attendee"
                placeholder="Attendee"
                [typeahead]="attendees"
                [typeaheadMinLength]="0"
                typeaheadOptionField="fullSearch"
                (typeaheadOnSelect)="onAttendeeSelect($event)"
                (typeaheadNoResults)="onAttendeeNoResult($event)"
                (blur)="onAttendeeBlur()"
                [formControl]="attendeeControl"
                [typeaheadOptionsLimit]="30"
                [typeaheadScrollable]="true"
                [typeaheadOptionsInScrollableView]="5"
                autocomplete="off"
              />


            </div>
           <div class="row">
             <button class="btn btn-sm btn-link" (click)="fileInput.click()">Import</button>
             <img src="assets/icon/excel.svg"
                  (click)="downloadTemplate()"
                  style="margin-top: 10px" class="exelIcon clickable" alt="template" title="template">
           </div>
            <input type="file" style="display: none;"
                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                   #fileInput (change)="handleUploadAttendees($event.target.files)">
          </div>
          <div class="form-group row">
<!--            <label-->
<!--              class="col-sm-4 col-form-label text-right"-->
<!--              style="padding-right: 0px"-->
<!--            >Import:</label>-->
            <div class="col-sm-6">
<!--              <input type="file" class="mt-1" label="import" />-->
              <small
                *ngIf="this.form.controls.attendeeList.hasError('isNull')"
                class="text-danger"
              >
                {{ this.form.controls.attendeeList.getError('errMsg') }}
              </small>
            </div>
          </div>


          <table class="table-attendee">
            <tbody>
            <tr *ngFor="let a of attendeeArray.controls; index as i">

              <td
                class="text-right"
                width="30%"
                style="padding-right: 10px"
                *ngIf="!showApprovalSatus"
              ></td>


              <td
                class="text-right"
                width="30%"
                style="padding-right: 10px"
                *ngIf="reqId && showApprovalSatus"
              >
                  <span
                    *ngIf="!a['controls'].isMe.value"
                    [ngClass]="{
                      'text-success':
                        a.controls.status.value == '(อนุมัติแล้ว)',
                      'text-danger': a.controls.status.value != '(อนุมัติแล้ว)'
                    }"
                  >
                    {{ a['controls'].status.value }}</span
                  >
              </td>

              <td width="50%">
                  <span [ngClass]="{ 'text-muted': a['controls'].isMe.value }">
                    {{ a['controls'].employee['controls'].nameTh.value }}
                  </span>
              </td>
              <td class="text-right" width="5%" *ngIf="allowRemoveAttendee">
                <button
                  class="btn pt-0"
                  type="button"
                  (click)="onAttendeeRemove(i)"
                >
                  <fa-icon
                    [icon]="faIcon.faTimes"
                    style="color: red"
                  ></fa-icon>
                </button>
              </td>
              <td
                class="text-right"
                width="5%"
                *ngIf="!allowRemoveAttendee"
              ></td>
            </tr>
            </tbody>
          </table>

          <div class="form-group row mt-2">
            <label
              class="col-sm-4 col-form-label text-right"
              style="padding-left: 0px"
            >Grand Total:</label
            >
            <div class="col-sm-6">
              <input
                type="text"
                [ngClass]="{ 'is-invalid': grandTotalInvalid }"
                class="form-control"
                [formControl]="form.controls.totalAmount"
                (focusout)="recalPricePerPerson()"
              />


              <small
                *ngIf="this.form.controls.totalAmount.hasError('isNull')"
                class="text-danger"
              >
                {{ this.form.controls.totalAmount.getError('errMsg') }}
              </small>

              <small
                *ngIf="this.form.controls.totalAmount.hasError('incorrect')"
                class="text-danger"
              >
                {{ this.form.controls.totalAmount.getError('errMsg') }}
              </small>
            </div>
            <label class="col-1 col-form-label" style="padding-left: 0px"
            >Baht</label
            >
          </div>
        </div>
      </div>
    </form>
    <!-- {{reqId}}||{{approvalState}} || submit = {{showSubmitButton}} -->
    <div class="row mt-3" *ngIf="fromSection === 'pending' || reqId !== ''">
      <!-- workflow -->
      <div class="line-container mb-1" *ngIf="approvalState !== 'DRAFT' && approvalState !== 'CANCELED'">
        <app-approval-state [approvalState]="approvalPipeLine"></app-approval-state>
      </div>
    </div>
    <div
      class="row"
      *ngIf="
        fromSection === 'pending' ||
        (openByHRAdmin && detail?.approvalState?.includes('PENDING'))
      "
    >
      <div class="col text-right">
        <button
          class="btn btn-success text-white"
          style="width: 150px; height: 40px"
          type="submit"
          (click)="onAction(true)"
          [disabled]="form.disabled"
        >
          APPROVE
        </button>
        <button
          class="btn btn-danger ml-3"
          style="width: 150px; height: 40px"
          type="submit"
          (click)="onAction(false)"
          [disabled]="form.disabled"
        >
          REJECT
        </button>
      </div>
    </div>
    <div class="row" *ngIf="fromSection !== 'pending' && !openByHRAdmin">
      <div class="col text-right">

        <button
          *ngIf="showWithdrawButton"
          class="btn btn-danger mr-3 text-white"
          style="width: 150px; height: 40px"
          type="submit"
          (click)="WithdrawRegistration()"
          [disabled]="form.disabled && reqStatus !== 'COMPLETED'"
        >
          WITHDRAW
        </button>

        <button
          class="btn btn-success ml-3"
          style="width: 150px; height: 40px"
          type="submit"
          (click)="editRegistration()"
          *ngIf="reqId && showEdittButton"
          [disabled]="form.disabled"
        >
          <!-- EDIT -->
          SAVE CHANGE
        </button>

        <button
          class="btn btn-warning text-white"
          style="width: 150px; height: 40px"
          type="submit"
          (click)="onSubmit('SAVE')"
          *ngIf="!reqId || showSaveButton"
          [disabled]="form.disabled"
        >
          SAVE DRAFT
        </button>

        <button
          class="btn btn-success ml-3"
          style="width: 150px; height: 40px"
          type="submit"
          (click)="onSubmit('SUBMIT')"
          *ngIf="(!reqId || showSubmitButton)&&isActive"
          [disabled]="form.disabled"
        >
          SUBMIT
        </button>

      </div>
    </div>
  </div>
  <label class="col-auto col-form-label" class="mx-5 text-danger">
    * พนักงานจะต้องขออนุมัติการอบรมก่อนวันอบรมเท่านั้น (ไม่สามารถขออนุมัติย้อนหลังได้)
  </label>
  <label class="col-auto col-form-label" class="mx-5 text-danger">
    * เมื่อได้รับอนุมัติการอบรมเรียบร้อยแล้ว พนักงานสามารถติดต่อสมัครเข้าอบรมกับสถาบันผู้จัดได้โดยตรง
  </label>
  <label class="col-auto col-form-label" class="mx-5 text-danger">
    * กรณียกเลิกการอบรม จะต้องดำเนินการก่อนวันอบรมล่วงหน้า "5 วันทำการ"
  </label>
  <label class="col-auto col-form-label" class="mx-5 text-danger">
    * พนักงานจะต้องส่งเอกสารประกอบการเบิกจ่ายฉบับจริง เช่น ใบแจ้งหนี้, ใบเสร็จรับเงิน, ใบกำกับภาษี มายัง
    ฝ่ายจัดการการเงิน (Finance_ap) ชั้น 17 เพื่อใช้เป็นเอกสารประกอบการยื่นภาษี
  </label>
  <label class="col-auto col-form-label" class="mx-5 text-danger">
    * กรณีที่พนักงานสำรองจ่าย จะต้องจัดส่งใบเสร็จรับเงิน, ใบกำกับภาษี (เอกสารฉบับจริง) ภายในเดือนที่จ่ายเงิน
  </label>

</ng-template>


<ng-template #reasonTemplate>
  <div class="modal-header bg-ca text-white-smoke">
    <h4 class="modal-title pull-left">{{submitType === "approve" ? "Approve Reason" : "Reject Reason"}}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeReasonModal(submitType === 'approve')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="reasonForm">
      <div class="form-group">
        <textarea
          class="form-control"
          rows="8"
          formControlName="text"
          [ngClass]="{
            'is-invalid': reasonForm.controls.text.getError('isNull')
          }"
        ></textarea>
        <small class="text-danger">
          {{ reasonForm.controls.text.getError('msg') }}
        </small>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success" type="button" (click)="action(submitType === 'approve')">
      OK
    </button>
    <button
      class="btn btn-secondary"
      type="button"
      (click)="closeReasonModal(submitType === 'approve')"
    >
      CANCEL
    </button>
  </div>
</ng-template>

<app-loading-overlay [display]="loading"></app-loading-overlay>
