<div *ngIf="fastFeedbackData.numberOfResponses > 0" class="mt-4">
    <div class="form-group row">
        <label class="col-form-label col-sm-1">Period</label>
        <select class="form-control col-sm-3 mr-4" [(ngModel)]="selectedPeriod"
            (change)="onChangePeriod($event.target.value)">
            <option *ngFor="let period of allPeriod" [ngValue]="period.periodType">
                {{period.period}}
            </option>
        </select>
        <img src="assets/icon/excel.svg" class="exelIcon mt-1" alt="" (click)="exportExcel()">
    </div>

    <div class="form-group row mt-4">
        <div class="col-sm-5">
            <chart *ngIf="selectedPeriod !== 'TODAY'" [type]="lineChart" [data]="dataOfLineChart" [options]="optionsLineChart" class="mb-4" style="height: 15rem;"></chart>

            <chart [type]="barChart" [data]="dataOfBarChart" [options]="optionsBarChart" style="height: 15rem;"></chart>

            <div *ngIf="fastFeedbackResult">
                <p>No. Of Response: {{fastFeedbackResult.summary.noOfResponse ? fastFeedbackResult.summary.noOfResponse : 0}}</p>
                <div *ngIf="fastFeedbackData.feedbackType">
                    <p *ngIf="fastFeedbackData.feedbackType.toLowerCase() !== 'csi'">Average Score: {{fastFeedbackResult.summary.avg | number : '1.2-2'}}</p>
                    <p *ngIf="fastFeedbackData.feedbackType.toLowerCase() === 'csi'">CSI Score: {{fastFeedbackResult.summary.avg | number : '1.2-2'}} %</p>
                </div>
                <p *ngIf="!fastFeedbackData.feedbackType">Average Score: {{fastFeedbackResult.summary.avg | number : '1.2-2'}}</p>
            </div>
        </div>
        <div class="col-sm-7">
            <h6>
                <b>
                    Comments
                </b>
            </h6>
            <hr>
            <div *ngIf="fastFeedbackResult" style="height: 12rem;">
                <div *ngIf="fastFeedbackResult.comments">
                    <div *ngIf="fastFeedbackResult.comments.length > 0" style="overflow: auto; max-height: 12rem;">
                    <div *ngFor="let comment of fastFeedbackResult.comments; let i = index;">
                        <div *ngIf="comment.message">
                            <div class="form-group row mb-2">
                                <div class="col-sm-2">
                                    {{comment.date | date: 'dd MMM yyyy'}}
                                </div>
                                <div class="col-sm-10">
                                    {{comment.message}}
                                </div>
                            </div>
                            <hr class="mt-2 mb-2">
                        </div>
                    </div>
                    <div  class="form-group row">
                        <div *ngIf="!checkHaveComment()" class="col-sm-12 d-flex justify-content-center">
                            No have comment
                        </div>
                    </div>
                    </div>
                    <div class="form-group row">
                        <div *ngIf="fastFeedbackResult.comments.length === 0" class="col-sm-12 d-flex justify-content-center">
                            No have comment
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div *ngIf="!fastFeedbackResult.comments" class="col-sm-12 d-flex justify-content-center">
                        No have comment
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="fastFeedbackData.numberOfResponses === 0" class="mt-4">
    <h6>
        No have feedback.
    </h6>
</div>

<div class="my-4 d-flex justify-content-end">
    <button (click)="onBack()" class="btn btn-secondary">Back</button>
</div>