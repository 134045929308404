<app-layout>
    <div class="container-fluid mt-4 pl-5 pr-5">
        <h4 class="mt-4">
            <b>
                <span (click)="onBack()" class="mr-2" [ngClass]="displayItem === 'detail' ?  'cursor-pointer' : ''">Vendor Evaluation</span>
                <span *ngIf="displayItem === 'detail'">
                    <fa-icon [icon]="faIcon.faChevronRight" class="text-c-set"></fa-icon>
                    <b> {{fastFeedbackTitle}}</b>
                </span>
            </b>
        </h4>

        <hr class="set">

        <ng-container [ngSwitch]="displayItem">
            <ng-container *ngSwitchCase="'list'">
                <div class="form-group row d-flex mr-2">
                    <input type="text" [(ngModel)]="searchValue" class="form-control col-3"    (keyup.enter)="onSearch()" />
                    <button (click)="onSearch()" class="btn btn-primary col-auto ml-3">search</button>
                </div>

                <div class="row">
                    <table class="table" id="dataTable">
                        <col style="width: 5%;" />
                        <col style="width: 25%;" />
                        <col style="width: 12%;" />
                        <col style="width: 8%;" />
                        <col style="width: 15%;" />
                        <col style="width: 15%;" />
                        <col style="width: 15%;" />
                        <col style="width: 5%;" />
                        <thead class="dt-head">
                            <tr>
                                <th class="text-center align-top">
                                    No.
                                </th>
                                <th class="align-top">
                                    VENDOR
                                </th>
                                <th class="align-top">
                                    # RESPONSES
                                </th>
                                <th class="align-top">
                                SCORE
                                </th>
                                <th class="align-top">
                                    OWNER
                                </th>
                                <th class="align-top">
                                    CREATED DATE
                                </th>
                                <th class="align-top">
                                    LAST RESPONSE
                                </th>
                                <th>
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="vendorEvaluationListPerPage">
                            <!-- <tr *ngFor="let fastFeedback of fastFeedbackListPerPage; let i = index" class="cursor-pointer" (click)="handleOnEditFastFeedback(fastFeedback)"> -->
                            <tr *ngFor="let detail of vendorEvaluationListPerPage; let i = index">
                                <td class="text-center">
                                    {{i + 1}}
                                </td>
                                <td>
                                <!-- <a *ngIf="checkResponsePermission(detail)" [routerLink]="['/vendor-evaluation',detail.id]" target="_blank">{{detail.referenceName}}</a> -->
                                <label class="link-style" (click)="onChangeToDetialPage(detail)">{{detail.referenceName}}</label>
                                </td>
                                <td>
                                    {{detail.numberOfResponses}}
                                </td>
                                <td>
                                    {{detail.rate | number : '1.2-2'}}
                                </td>
                
                                <td >
                                    {{detail.createByName}}
                                </td>
                                <td >
                                    {{detail.createDate | date: 'dd MMM yyyy'}}
                                </td>
                                <td>
                                    {{detail.lastResponse | date: 'dd MMM yyyy'}}
                                </td>
                                <td>
                                    <a *ngIf="checkResponsePermission(detail)" [routerLink]="['/fast-feedback-send-feedback',detail.id]" target="_blank">Link</a>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!vendorEvaluationList || vendorEvaluationList.length === 0">
                            <td class="text-center" *ngIf="!vendorEvaluationList" colspan="8">
                                No data.
                            </td>
                        </tbody>
                    </table>
                </div>

                <div *ngIf="vendorEvaluationList">
                    <div *ngIf="vendorEvaluationList.length > 10">
                    <pagination [totalItems]="vendorEvaluationList.length" [itemsPerPage]="paginationConfig.itemsPerPage"
                        (pageChanged)="handlePaging($event)">
                    </pagination>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'detail'">
                <app-vendor-evaluation-detail (onEmitData)="switchDisplayItem($event)" [fastFeedbackData]="fastFeedbackDetailData" [pageData]="currentPage"></app-vendor-evaluation-detail>
            </ng-container>
        </ng-container>
    </div>
</app-layout>