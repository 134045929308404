<app-layout>
    <div class="container-fluid pl-5 pr-5">
        <h4 class="mt-4">
            <b>
                <span (click)="onBack()" class="mr-2" [ngClass]="displayItem === 'new' ?  'cursor-pointer' : ''">Send Your Feedback</span>
                <span *ngIf="displayItem === 'edit'">
                    <fa-icon [icon]="faIcon.faChevronRight" class="text-c-set"></fa-icon>
                    <b> {{fastFeedbackTitle}}</b>
                </span>
                <span *ngIf="displayItem === 'response'">
                    <fa-icon [icon]="faIcon.faChevronRight" class="text-c-set"></fa-icon>
                    <b> {{fastFeedbackTitle}}</b>
                </span>
            </b>
        </h4>
        <hr class="set">
        <ng-container [ngSwitch]="displayItem">
            <ng-container *ngSwitchCase="'list'">
                <div class="d-flex">
                    <div class="form-group row">
                        <h6 class="col-sm-auto col-form-label"><b>Feedback Type</b></h6>
                        <div class="col-sm-auto">
                            <select class="form-control col-sm-auto" [(ngModel)]="selectedType"
                            (change)="onChangeType()">
                            <option *ngFor="let type of allType" [ngValue]="type.type">
                                {{type.typeName}}
                            </option>
                        </select>
                        </div>
                        <div class="col-sm-auto">
                            <div class="form-group">
                              <div class="input-group">
                                <input type="text"  class="ml-2 form-control custom-input-2" [(ngModel)]="keyword"/>
                                <span class="input-group-btn">
                                  <button class="btn" style="border-color: #ced4da;" type="submit" (click)="search()">
                                    <fa-icon [icon]="faIcon.faSearch"></fa-icon>
                                  </button>
                                </span>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row"> -->
                    <table class="table" id="dataTable">
                        <col style="width: 20%;" />
                        <col style="width: 20%;" />
                        <col style="width: 15%;" />
                        <col style="width: 15%;" />
                        <col style="width: 10%;" />
                        <col style="width: 10%;" />
                        <col style="width: 10%;" />
                        <thead class="dt-head">
                            <tr>
                                <th>

                                </th>
                                <th class="align-top">
                                    TITLE
                                </th>
                                <th class="align-top">
                                    DESCRIPTION
                                </th>
                                <th class="align-top">
                                   OWNER
                                </th>
                                <th class="align-top">
                                    START DATE
                                </th>
                                <th class="align-top">
                                    END DATE
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="fastFeedbackListPerPage">
                            <!-- <tr *ngFor="let fastFeedback of fastFeedbackListPerPage; let i = index" class="cursor-pointer" (click)="handleOnEditFastFeedback(fastFeedback)"> -->
                            <tr *ngFor="let fastFeedback of fastFeedbackListPerPage; let i = index">
                                <td>
                                    <!-- <img [src]="imageSrc" *ngIf="imageSrc" class="image-responsive" width="320" height="200"> -->
                                    <img
                                        loading="lazy"
                                        (click)="handleOnResponseFeedback(fastFeedback)"
                                        [src]="fastFeedbackService.getImg(fastFeedback?.image)"
                                        alt=""
                                        onerror="this.src ='assets/images/fast-feedback/no-image.png'"
                                        class="item-img"
                                    >
                                </td>
                                <td [ngClass]="checkPermission(fastFeedback)? 'text-primary' : ''">
                                    {{fastFeedback.title ? fastFeedback.title : '-'}}
                                </td>
                                <td>
                                    <div [innerHtml]=" renderhtmlText(fastFeedback.description)"></div>
                                </td>
                                <td>
                                    {{fastFeedback.createByName ? fastFeedback.createByName : '-'}}
                                </td>
                                <td *ngIf="fastFeedback.effectiveDate">
                                    {{fastFeedback.effectiveDate | date: 'dd MMM yyyy'}}
                                </td>
                                <td *ngIf="!fastFeedback.effectiveDate">
                                    -
                                </td>
                                <td *ngIf="fastFeedback.expiryDate">
                                    {{fastFeedback.expiryDate | date: 'dd MMM yyyy'}}
                                </td>
                                <td *ngIf="!fastFeedback.expiryDate">
                                    -
                                </td>
                                <td>
                                    <!-- <p *ngIf="checkResponsePermission(fastFeedback)" (click)="handleRouteToFastFeedback(fastFeedback)" class="link-style">Feedback</p> -->
                                    <a *ngIf="checkResponsePermission(fastFeedback)" [routerLink]="['/fast-feedback-send-feedback',fastFeedback.id]" target="_blank">Feedback</a>
                                    <!-- <a *ngIf="checkResponsePermission(fastFeedback)" [routerLink]="['/fast-feedback-send-feedback',fastFeedback.id]">Feedback</a> -->
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!filterFastFeedbackList">
                            <td class="text-center" *ngIf="!filterFastFeedbackList" colspan="8">
                                No data.
                            </td>
                        </tbody>
                    </table>
                <!-- </div> -->
                <div *ngIf="filterFastFeedbackList">
                    <div *ngIf="filterFastFeedbackList.length > 10">
                    <pagination [totalItems]="filterFastFeedbackList.length" [itemsPerPage]="paginationConfig.itemsPerPage"
                        (pageChanged)="handlePaging($event)">
                    </pagination>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'edit'">
                <app-new-fast-feedback (onEmitData)="switchDisplayItem($event)" [fastFeedbackData]="fastFeedbackDetailData" [isOwner]="isOwner" [displayStatus]="displayItem"></app-new-fast-feedback>
            </ng-container>
            <ng-container *ngSwitchCase="'response'">
                <app-response-fast-feedback (emitTitle)="onReceiveTitle($event)" [id]="id" (onEmitData)="switchDisplayItem($event)" [fastFeedbackData]="fastFeedbackDetailData"></app-response-fast-feedback>
            </ng-container>
        </ng-container>
    </div>
</app-layout>        