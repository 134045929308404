<form [formGroup]="fastFeeadbackForm">
    <div class="form-group row">
        <div class="col-sm-3">
            <div id="upload_button">
                <img [src]="imageSrc" *ngIf="imageSrc" class="image-responsive" width="320" height="200">
                <img src="assets/images/fast-feedback/no-image.png" *ngIf="!imageSrc" class="image-responsive" width="320" height="200">
                <label class="float-right mt-2">
                    <input *ngIf="isOwner" type="file" class="form-control" (change)="onSelectFile($event)" accept="image/*">
                    <span [ngClass]="isOwner ? 'btn btn-primary' : 'btn btn-secondary'">Choose files</span>
                </label>
            </div>
        </div>
        <div class="col-sm-9">
            <div class="form-group row">
                <label class="col-sm-2-half col-form-label">{{displayStatus === 'import' ? 'Prefix Title' : 'Title'}}</label>
                <div class="col-sm-9-half">
                    <input type="text" class="form-control" formControlName="title"
                        [ngClass]="{'is-invalid': fastFeeadbackForm.controls['title'].hasError('null')}" />
                </div>
            </div>
            <div *ngIf="displayStatus === 'import'" class="form-group row">
                <label class="col-sm-2-half col-form-label">Description</label>
                <div class="col-sm-9-half">
                    <textarea class="form-control" formControlName="description" rows="4"></textarea>
                </div>
            </div>
            <div *ngIf="displayStatus !== 'import'" class="form-group row">
                <label class="col-sm-2-half col-form-label">Active</label>
                <div class="col-sm-9-half">
                    <label class="switch">
                        <input type="checkbox" formControlName="active">
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
            <div  *ngIf="displayStatus === 'import'" class="form-group row">
                <label class="col-sm-2-half col-form-label">CSV File</label>
                <div class="col-sm-9-half">
                    <div id="upload_button">
                        <label class="float-right mt-2">
                            <input *ngIf="isOwner" type="file" class="form-control" (change)="onSelectFastFeedbackFile($event)" accept=".csv">
                            <span [ngClass]="isOwner ? 'btn btn-primary' : 'btn btn-secondary'">Choose CSV File</span>
                        </label>
                    </div>
                    <div *ngIf="selectedFastFeedbackFile">
                            <label>
                                {{selectedFastFeedbackFile.name}}
                                <fa-icon [icon]="faIcon.faTimes" class="mb-auto pl-4" style="color: red; font-size: 1.2rem;" *ngIf="status === 'create'" (click)="onRemoveFile()">
                                </fa-icon>
                            </label>
                    </div>
                </div>
            </div>
            <div  class="form-group row">
                <label class="col-sm-2-half col-form-label">Feedback Type</label>
                <div class="col-sm-3">
                    <select class="form-control" formControlName="feedbackType" (change)="onChangeFeedbackType()">
                            <option *ngFor="let feedbackType of getFeedbackType()" [ngValue]="feedbackType.feedbackType">
                                {{feedbackType.feedbackTypeName}}
                            </option>
                    </select>
                </div>
            </div>
            <div  *ngIf="displayStatus !== 'import'" class="form-group row">
                <label class="col-sm-2-half col-form-label">Target Groups</label>
                <div class="col-sm-9-half">
                    <div class="form-group row pl-3" formArrayName="targetGroupList">
                        <div class="mb-3" *ngFor="let targetGroup of targetGroupListData.controls; let i = index;"
                            [formGroupName]="i">
                            <span class="p-1 mr-2 mb-2 tag-style">{{targetGroup.get('name').value}} <fa-icon
                                    [icon]="faIcon.faTimes" class="text-secondary ml-1 cursor-pointer" *ngIf="isOwner"
                                    (click)="onRemoveTargetGroup(i)"></fa-icon></span>
                        </div>
                    </div>
                    <input type="text" class="form-control" formControlName="targetGroup" [typeahead]="allTargetGroups"
                        typeaheadOptionField="name" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="6"
                        [typeaheadMinLength]="0" (typeaheadOnSelect)="onSelectTargetGroupTag($event)"
                        [ngClass]="{'is-invalid': fastFeeadbackForm.controls['targetGroupList'].hasError('null')}" />
                </div>
            </div>
            <div  *ngIf="displayStatus !== 'import'" class="form-group row">
                <label class="col-sm-2-half col-form-label"></label>
                <div class="col-sm-9-half" *ngIf="!isLoading">
                    <div id="upload_button">
                        <label class="float-right mt-2">
                            <input type="file" class="form-control" (change)="importTargetGroup($event)" accept=".text, .txt">
                            <span class="btn btn-success">Import Target</span>
                        </label>
                    </div>
                </div>
                <div class="col-sm-9-half" *ngIf="isLoading">
                    <button class="btn btn-success">Import Target</button>
                </div>
            </div>
            <div *ngIf="displayStatus !== 'import'"  class="form-group row">
                <label class="col-sm-2-half col-form-label"></label>
                <div class="col-sm-auto">
                    <input type="checkbox" class="form-control check-box-style mt-1" formControlName="isAllowAnyone"   />
                </div>
                <label class="col-sm-4 col-form-label pl-0">Allow anyone who has the link.</label>
            </div>
            <div class="form-group row">
                <label class="col-sm-2-half col-form-label">Who can see the result</label>
                <div class="col-sm-9-half">
                    <div class="form-group row pl-3" formArrayName="whoCanSeeList">
                        <div class="mb-3" *ngFor="let whoCanSee of whoCanSeeListData.controls; let i = index;"
                            [formGroupName]="i">
                            <span class="p-1 mr-2 mb-2 tag-style">{{whoCanSee.get('name').value}} <fa-icon
                                    [icon]="faIcon.faTimes" class="text-secondary ml-1 cursor-pointer" *ngIf="isOwner"
                                    (click)="onRemoveWhoCanSee(i)"></fa-icon></span>
                        </div>
                    </div>
                    <input type="text" class="form-control" formControlName="whoCanSee" [typeahead]="allWhoCanSee"
                        typeaheadOptionField="name" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="6"
                        [typeaheadMinLength]="0" (typeaheadOnSelect)="onSelectWhoCanSeeTag($event)" />
                </div>
            </div>

            <div *ngIf="displayStatus !== 'import'" class="form-group row">
                <label class="col-sm-2-half col-form-label">Description</label>
                <div class="col-sm-9-half">
                    <textarea class="form-control" formControlName="description" rows="4"></textarea>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2-half col-form-label">Effective Date</label>
                <div class="col-sm-4">
                   <input type="date" class="form-control" formControlName="effectiveDate" 
                   [ngClass]="{'is-invalid': fastFeeadbackForm.controls['effectiveDate'].hasError('null')}" />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2-half col-form-label">Expiry Date</label>
                <div class="col-sm-4">
                    <input type="date" class="form-control" formControlName="expiryDate"   [min]="fastFeeadbackForm.get('effectiveDate').value" 
                    [ngClass]="{'is-invalid': fastFeeadbackForm.controls['expiryDate'].hasError('null')}" />
                </div>
            </div>

            <div *ngIf="displayStatus !== 'import' && fastFeeadbackForm.get('feedbackType').value !== 'booking'" class="form-group row">
                <label class="col-sm-2-half col-form-label">Reference ID</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="referenceId" />
                </div>
            </div>

            <div *ngIf="displayStatus !== 'import' && fastFeeadbackForm.get('feedbackType').value !== 'booking'" class="form-group row">
                <label class="col-sm-2-half col-form-label">Reference Name</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="referenceName" />
                </div>
            </div>

            <div *ngIf="fastFeeadbackForm.get('feedbackType').value !== 'booking' && fastFeeadbackForm.get('feedbackType').value !== 'survey'"  class="form-group row">
                <label class="col-sm-2-half col-form-label">Require Comments</label>
                <div class="col-sm-auto">
                    <input type="checkbox" class="form-control check-box-style mt-1" formControlName="requiredComment"   />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2-half col-form-label">Record Department</label>
                <div class="col-sm-auto">
                    <input type="checkbox" class="form-control check-box-style mt-1" formControlName="recordDepartment"   />
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-2-half col-form-label">Allow only one response</label>
                <div class="col-sm-auto">
                    <input type="checkbox" class="form-control check-box-style mt-1" formControlName="allowOneResponse"   />
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="fastFeeadbackForm.get('feedbackType').value === 'booking'"> 
        <div class="row justify-content-between">
            <h5 class="col-auto">
                Booking
            </h5>
            <button type="button" class="btn btn-primary app-btn-style col-auto"
                (click)="onAddItem()">ADD ITEM
            </button>
        </div>

        <hr class="set">

    <div formArrayName="itemList">
            <div class="form-group row" *ngFor="let item of itemListData.controls; let i = index;" [formGroupName]="i">
                <h5 class="col-sm-auto pt-2">{{i + 1 }}.</h5>
                <input formControlName="itemName" class="col-sm-4  form-control" 
                [class.is-invalid]="item.get('itemName').invalid"
                />
                <input type="number" min="0" formControlName="volume" class="col-sm-2 ml-2 form-control" 
                [class.is-invalid]="item.get('volume').invalid"
                />
                <h6 class="col-sm-auto ml-2 text-danger col-form-label d-flex flex-row-reverse cursor-pointer"
                (click)="onDeleteItem(template,'delete item',i)">DELETE</h6>
            </div>
        </div>
    </div>

    <div *ngIf="displayStatus !== 'import'">
        <div class="row justify-content-between">
            <h5 class="col-auto">
                Additional Questions
            </h5>
            <button type="button" class="btn btn-primary app-btn-style col-auto"
                (click)="onAddQuestion()">ADD QUESTION
            </button>
        </div>

        <hr class="set">

        <div formArrayName="questionList">
            <div class="form-group row" *ngFor="let question of questionListData.controls; let i = index;" [formGroupName]="i">
                <div class="col-sm-6 row">
                    <h5 class="col-sm-1">{{i + 1 }}.</h5>
                    <textarea class="col-sm-11 form-control" formControlName="question" rows="5"
                        [class.is-invalid]="question.get('question').invalid"
                    ></textarea>
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <h6 class="col-sm-2 col-form-label">Required</h6>
                        <div class="col-sm-2 pl-0">
                            <label class="switch">
                                <input type="checkbox" formControlName="isRequire">
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <h6 class="col-sm-2 col-form-label">Field Type</h6>
                        <div class="col-sm-4 row">
                            <input type="radio" class="form-control col-sm-1" id="{{ 'CHOICE' + i}}" formControlName="type" (change)="onChangeType(i)" value="CHOICE"
                                style="width: auto; height: auto;">
                            <p class="col-sm-5 pl-2 pr-0 pt-2 col-form-label"
                            [class.text-danger]="question.get('type').invalid"
                            >Choices</p>
                            <input type="radio" class="form-control col-sm-1" id="{{ 'DROPDOWN' + i}}" formControlName="type" (change)="onChangeType(i)" value="DROPDOWN"
                                style="width: auto; height: auto;">
                            <p class="col-sm-5 pl-2 pr-0 pt-2 col-form-label"
                            [class.text-danger]="question.get('type').invalid"
                            >Dropdown</p>
                            <input type="radio" class="form-control col-sm-1" id="{{ 'TEXT' + i}}" formControlName="type" (change)="onChangeType(i)" value="TEXT"
                                style="width: auto; height: auto;">
                            <p class="col-sm-5 pl-2 pr-0 pt-2 col-form-label"
                            [class.text-danger]="question.get('type').invalid"
                            >Free Text</p>
                        </div>
                        <h6 class="col-sm-2 text-danger col-form-label d-flex flex-row-reverse cursor-pointer"
                            (click)="onDeleteQuestion(template,'delete question',i)">DELETE</h6>
                    </div>
                    <div class="row">
                        <h6 class="col-sm-4 col-form-label">Possible Values</h6>
                        <div class="col-sm-8">
                            <div class="form-group row pl-3" *ngIf="possilbleListData(i).length > 0">
                                <div class="mb-2" *ngFor="let possible of possilbleListData(i); let j = index;">
                                    <span class="p-1 mr-2 mb-2 tag-style">{{possible.data}} <fa-icon [icon]="faIcon.faTimes"
                                            class="text-secondary ml-1 cursor-pointer" *ngIf="isOwner"
                                            (click)="onRemovePossibleValue(i,j)"></fa-icon></span>
                                </div>
                            </div>
                            <input type="text" class="form-control" formControlName="possibleText" (keyup.enter)="onAddPossibleValue(i, $event.target.value)" 
                            [class.is-invalid]="question.get('possibleText').invalid"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <h6 class="col-sm-4 col-form-label"></h6>
                        <div class="col-sm-8">
                            <div id="upload_button">
                                <label class="float-right mt-2">
                                    <input *ngIf="question.get('type').value === 'CHOICE' || question.get('type').value === 'DROPDOWN'" type="file" class="form-control" (change)="importPossibleValue($event,i)" accept=".text, .txt">
                                    <span [ngClass]="question.get('type').value === 'CHOICE' || question.get('type').value === 'DROPDOWN' ? 'btn btn-success' : 'btn btn-secondary'">Import File</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <h6 class="col-sm-4 col-form-label"></h6>
                        <h6 class="col-sm-auto col-form-label">Other</h6>
                        <div class="col-sm-2 pl-0">
                            <label class="switch">
                                <input type="checkbox"  formControlName="isOther">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row justify-content-end mt-4 mb-4">
        <div *ngIf="displayStatus !== 'import'" class="col-sm-auto">
            <button type="button" class="btn btn-secondary app-btn-style"
                (click)="onShowConfirmationModal(template,'back')">BACK
            </button>
        </div>
        <div *ngIf="displayStatus !== 'import' && this.fastFeedbackData" class="col-sm-auto">
            <button type="button" class="btn btn-danger app-btn-style"
                (click)="onShowConfirmationModal(template,'delete')" [disabled]="!isOwner || isLoading">DELETE</button>
        </div>
        <div *ngIf="displayStatus !== 'import' && this.fastFeedbackData" class="col-sm-auto">
            <button type="button" class="btn btn-info app-btn-style"
                (click)="onShowConfirmationModal(template,'send mail')" [disabled]="!isOwner || isLoading">SEND MAIL NOTIFICATION</button>
        </div>
        <div *ngIf="displayStatus !== 'import' && this.fastFeedbackData" class="col-sm-auto">
            <button type="button" class="btn btn-warning app-btn-style"
                (click)="onShowConfirmationModal(template,'send noti')" [disabled]="!isOwner || isLoading">SEND SETDNA NOTIFICATION</button>
        </div>
        <div *ngIf="displayStatus !== 'import'" class="col-sm-auto">
            <button type="button" class="btn btn-primary app-btn-style"
                (click)="onShowConfirmationModal(template,'save')" [disabled]="isLoading">SAVE</button>
        </div>
        <div *ngIf="displayStatus === 'import'" class="col-sm-auto">
            <button type="button" class="btn btn-success app-btn-style" [disabled]="isLoading"
                (click)="onShowConfirmationModal(template,'import')">IMPORT
            </button>
        </div>
    </div>
</form>

<div *ngIf="status === 'edit' && fastFeedbackData.numberOfResponses > 0 && fastFeedbackData.feedbackType !== 'booking'">
    <h5 class="mt-4">
        <b>
            <b>Feedback Result</b>
        </b>
    </h5>
    <hr class="set">

    <div class="form-group row">
        <label class="col-form-label col-sm-1">Period</label>
        <select class="form-control col-sm-3 mr-4" [(ngModel)]="selectedPeriod"
            (change)="onChangePeriod($event.target.value)">
            <option *ngFor="let period of allPeriod" [ngValue]="period.periodType">
                {{period.period}}
            </option>
        </select>
        <img src="assets/icon/excel.svg" class="exelIcon mt-1" alt="" (click)="exportExcel()">
    </div>

    <div *ngIf="!fastFeedbackData.feedbackType || fastFeedbackData.feedbackType !== 'survey'" class="form-group row mt-4">
        <div class="col-sm-5">
            <chart *ngIf="selectedPeriod !== 'TODAY'" [type]="lineChart" [data]="dataOfLineChart" [options]="optionsLineChart" class="mb-4" style="height: 15rem;"></chart>

            <chart [type]="barChart" [data]="dataOfBarChart" [options]="optionsBarChart" style="height: 15rem;"></chart>

            <div *ngIf="fastFeedbackResult">
                <p>No. Of Response: {{fastFeedbackResult.summary.noOfResponse ? fastFeedbackResult.summary.noOfResponse : 0}}</p>
                <div *ngIf="fastFeedbackData.feedbackType">
                    <p *ngIf="fastFeedbackData.feedbackType.toLowerCase() !== 'csi'">Average Score: {{fastFeedbackResult.summary.avg | number : '1.2-2'}}</p>
                    <p *ngIf="fastFeedbackData.feedbackType.toLowerCase() === 'csi'">CSI Score: {{fastFeedbackResult.summary.avg | number : '1.2-2'}} %</p>
                </div>
                <p *ngIf="!fastFeedbackData.feedbackType">Average Score: {{fastFeedbackResult.summary.avg | number : '1.2-2'}}</p>
            </div>
        </div>
        <div class="col-sm-7">
            <h6>
                <b>
                    Comments
                </b>
            </h6>
            <hr>
            <div *ngIf="fastFeedbackResult" style="height: 12rem;">
                <div *ngIf="fastFeedbackResult.comments">
                    <div *ngIf="fastFeedbackResult.comments.length > 0" style="overflow: auto; max-height: 12rem;">
                    <div *ngFor="let comment of fastFeedbackResult.comments; let i = index;">
                        <div *ngIf="comment.message">
                            <div class="form-group row mb-2">
                                <div class="col-sm-2">
                                    {{comment.date | date: 'dd MMM yyyy'}}
                                </div>
                                <div class="col-sm-10">
                                    {{comment.message}}
                                </div>
                            </div>
                            <hr class="mt-2 mb-2">
                        </div>
                    </div>
                    <div  class="form-group row">
                        <div *ngIf="!checkHaveComment()" class="col-sm-12 d-flex justify-content-center">
                            No have comment
                        </div>
                    </div>
                    </div>
                    <div class="form-group row">
                        <div *ngIf="fastFeedbackResult.comments.length === 0" class="col-sm-12 d-flex justify-content-center">
                            No have comment
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div *ngIf="!fastFeedbackResult.comments" class="col-sm-12 d-flex justify-content-center">
                        No have comment
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="fastFeedbackData.feedbackType && fastFeedbackData.feedbackType === 'survey'" class="form-group row mt-4">
        <div class="col-sm-12">
            <h6>
                <b>
                    Comments <span *ngIf="fastFeedbackResult"> (No. Of Response: {{fastFeedbackResult.summary.noOfResponse ? fastFeedbackResult.summary.noOfResponse : 0}})</span>
                </b>
            </h6>
            <hr>
            <div *ngIf="fastFeedbackResult" style="height: 12rem;">
                <div *ngIf="fastFeedbackResult.comments">
                    <div *ngIf="fastFeedbackResult.comments.length > 0" style="overflow: auto; max-height: 12rem;">
                    <div *ngFor="let comment of fastFeedbackResult.comments; let i = index;">
                        <div *ngIf="comment.message">
                            <div class="form-group row mb-2">
                                <div class="col-sm-2">
                                    {{comment.date | date: 'dd MMM yyyy'}}
                                </div>
                                <div class="col-sm-10">
                                    {{comment.message}}
                                </div>
                            </div>
                            <hr class="mt-2 mb-2">
                        </div>
                    </div>
                    <div  class="form-group row">
                        <div *ngIf="!checkHaveComment()" class="col-sm-12 d-flex justify-content-center">
                            No have comment
                        </div>
                    </div>
                    </div>
                    <div class="form-group row">
                        <div *ngIf="fastFeedbackResult.comments.length === 0" class="col-sm-12 d-flex justify-content-center">
                            No have comment
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div *ngIf="!fastFeedbackResult.comments" class="col-sm-12 d-flex justify-content-center">
                        No have comment
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="status === 'edit' && fastFeedbackData.numberOfResponses === 0 && fastFeedbackData.feedbackType !== 'booking'">
    <h5 class="mt-4">
        <b>
            <b>Feedback Result</b>
        </b>
    </h5>
    <hr class="set">
    <h6 class="mt-4">
            No have feedback.
    </h6>
</div>

<div *ngIf="status === 'edit' && fastFeedbackData.numberOfResponses > 0 && fastFeedbackData.feedbackType === 'booking'">
    <div>
        <h5 class="mt-4">
            <b>Booking Result</b>
            <img src="assets/icon/excel.svg" class="ml-3 exelIcon" alt="" (click)="exportBooking()">
        </h5>
    </div>
    <hr class="set">

    <div class="form-group row mt-4">
        <div class="col-sm-5">
            <chart [type]="horizontalBarChart" [data]="dataOfHorizontalBarChart" [options]="optionsHorizontalBarChart" style="height: 15rem;"></chart>

            <div *ngIf="fastFeedbackResult">
                <p>No. Of Response: {{fastFeedbackResult.summary.noOfResponse ? fastFeedbackResult.summary.noOfResponse : 0}}</p>
            </div>
        </div>
        <div class="col-sm-7" style="height: 15rem; overflow-y: scroll">
            <table class="table" >
                <thead class="dt-head">
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Booked</th>
                  <th scope="col">Available</th>
                </tr>
                </thead>
                <tbody >
                <tr *ngFor="let item of fastFeedbackResult.booking">
                  <th scope="row"><small>{{item.itemName}}</small></th>
                  <td><small>{{item.volume}}</small></td>
                  <td><small>{{item.available}}</small></td>
                </tr>
                </tbody>
              </table>
        </div>
    </div>
</div>

<div *ngIf="status === 'edit' && fastFeedbackData.numberOfResponses === 0 && fastFeedbackData.feedbackType === 'booking'">
    <h5 class="mt-4">
        <b>
            <b>Booking Result</b>
        </b>
    </h5>
    <hr class="set">
    <h6 class="mt-4">
            No have booking.
    </h6>
</div>

<ng-template #template>
    <div class="modal-header thead-primary" style="background-color: #fab232;">
        <h4 class="modal-title pull-left ml-3">Confirmation</h4>
        <button type="button" class="close pull-right text-light" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group ml-3">
            <div class="d-flex">
                <h5>{{ confirmationWording }}</h5>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex mt-2 justify-content-end">
            <button class="btn btn-success mr-2" (click)="confirm()" [disabled]="isLoading">Confirm</button>
            <button class="btn btn-danger" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-template>