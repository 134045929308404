import { ParallelPositionComponent } from './components/parallel-position/parallel-position.component';
import { RequestListComponent } from './external-training/request-list/request-list.component';
import { TrackFileComponent } from './components/track-file/track-file.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ExternalTrainingComponent} from './external-training/external-training.component';
import {GuardService} from './core/services/guard.service';
import {AdministrativeComponent} from './components/administrative/administrative.component';
import {MedicalClaimComponent} from './components/medical-claim/medical-claim.component';
import {FastFeedbackComponent} from './components/fast-feedback/fast-feedback.component';
import {StrategicProjectComponent} from './components/strategic-project/strategic-project.component';
import {ImportStrategicProjectComponent} from './components/strategic-project/import-strategic-project/import-strategic-project.component';
import {OutsourceTimesheetComponent} from './components/outsource-timesheet/outsource-timesheet.component';
import {InternTimesheetComponent} from './components/intern-timesheet/intern-timesheet.component';
import {ManPowerComponent} from './components/man-power/man-power.component';
import {WrapInternComponent} from './components/intern-timesheet/components/wrap-intern/wrap-intern.component';
import {SendYourFastFeedbackComponent} from './components/fast-feedback/send-your-fast-feedback/send-your-fast-feedback.component';
import {SetDNAServiceService} from './modules/outsource-time-sheet/core/services/set-dnaservice.service';
import {OutsourceSummaryReportComponent} from './components/outsource-summary-report/outsource-summary-report.component';
import {WrapperOutsourceReportComponent} from './components/outsource-summary-report/wrapper/wrapper-outsource-report/wrapper-outsource-report.component';
import {WebexMeetingComponent} from './components/webex-meeting/webex-meeting.component';
import { VendorEvaluationComponent } from './components/fast-feedback/vendor-evaluation/vendor-evaluation.component';
import {ManpowerReportComponent} from './components/man-power/manpower-report/manpower-report.component';
import {LearningCenterLayoutComponent} from './components/learning-center-layout.component';
import { OutsourceTimesheetDownloadComponent } from './components/outsource-timesheet-download/outsource-timesheet-download.component';
import { WhatNewListComponent } from './components/what-new/what-new-list/what-new-list.component';
import { WhatNewDetailComponent } from './components/what-new/what-new-detail/what-new-detail.component';

const routes: Routes = [
  {
    path: 'openTalk',
    loadChildren: () =>
      import('./modules/open-talk/open-talk.module').then(
        (m) => m.OpenTalkModule
      ),
  },
  {
    path: 'mobile/fast-feedback',
    loadChildren: () =>
      import('./modules/fast-feedback-mobile/fast-feedback-mobile.module').then(
        (m) => m.FastFeedbackMobileModule
      ),
  },
  {
    path: 'mobile/outsource-timesheet',
    loadChildren: () =>
      import('./modules/outsource-time-sheet/outsource-time-sheet.module')
        .then(m => m.OutsourceTimeSheetModule)
  },
  {
    path: 'mobile/strategic-plan',
    loadChildren: () =>
      import('./modules/strategic-project-mobile/strategic-project-mobile.module').then(
        (m) => m.StrategicProjectMobileModule
      ),
  },
  {
    path: 'mobile/leader-board',
    loadChildren: () =>
      import('./modules/leader-board-mobile/leader-board-mobile.module').then(
        (m) => m.LeaderBoardMobileModule
      ),
  },
  {
    path: 'pdpa-emp',
    loadChildren: () => import('./modules/papa-emp/papa-emp.module')
      .then(m => m.PapaEmpModule)
  },
  {
    path: 'external',
    component: ExternalTrainingComponent,
    canActivate: [GuardService],
  },
  {
    path: 'learning-center',
    component: LearningCenterLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/learning-center/learning-center.module')
          .then(m => m.LearningCenterModule)
      }
    ]
  },
  {
    path: 'external/attach',
    loadChildren: () => import('./modules/external-training/external-training.module')
      .then(m => m.ExternalTrainingModule)
  },
  {
    path: 'administrative',
    component: AdministrativeComponent,
    canActivate: [GuardService],
  },
  {
    path: 'fast-feedback',
    component: FastFeedbackComponent,
  },
  {
    path: 'fast-feedback/:id',
    component: FastFeedbackComponent,
  },
  {
    path: 'fast-feedback-send-feedback',
    component: SendYourFastFeedbackComponent,
  },
  {
    path: 'fast-feedback-send-feedback/:detail',
    component: SendYourFastFeedbackComponent,
  },
  {
    path: 'vendor-evaluation',
    component: VendorEvaluationComponent,
  },
  {
    path: 'vendor-evaluation/:id',
    component: VendorEvaluationComponent,
  },
  {
    path: 'strategic-plan',
    component: StrategicProjectComponent,
  },
  {
    path: 'strategic-plan/:id/:quarter',
    component: StrategicProjectComponent,
  },
  {
    path: 'administrative-strategic-plan',
    component: StrategicProjectComponent,
    canActivate: [GuardService]
  },
  {
    path: 'administrative-strategic-plan-import',
    component: ImportStrategicProjectComponent,
    canActivate: [GuardService]
  },
  {
    path: 'outsource-timesheet',
    component: OutsourceTimesheetComponent,
  },
  {
    path: 'outsource-timesheet/download/:uuid',
    component: OutsourceTimesheetDownloadComponent,
  },
  {
    path: 'outsource-timesheet/:empId/:month/:year',
    component: OutsourceTimesheetComponent,
  },
  {
    path: 'outsource-timesheet/:month/:year',
    component: OutsourceTimesheetComponent,
  },

  {
    path: 'intern-timesheet',
    component: WrapInternComponent,
  },
  {
    path: 'intern-timesheet/:empId/:month/:year',
    component: WrapInternComponent,
  },
  {
    path: 'man-power',
    component: ManPowerComponent,
  },
  {
    path: 'man-power/report',
    component: ManpowerReportComponent,
  },
  {
    path: 'outsource-report',
    component: WrapperOutsourceReportComponent
  },
  {
    path: 'man-power/req/:id',
    component: ManPowerComponent,
    data: {
      action: 'req'
    }
  },
  {
    path: 'man-power/approve/:id',
    component: ManPowerComponent,
    data: {
      action: 'approve'
    }
  },
  {
    path: 'additional-information',
    loadChildren: () =>
      import('./modules/additional-information/additional-information.module').then(
        (m) => m.AdditionalInformationModule
      ),
    // canActivate: [SetDNAServiceService],
  },
  {
    path: 'webex-meeting',
    component: WebexMeetingComponent
  },
  {
    path: 'what-new',
    component: WhatNewListComponent
  },
  {
    path: 'what-new/:id',
    component: WhatNewDetailComponent,
    data: {
      pure: true
    }
  },
  {
    path:'track-file',
    component: TrackFileComponent
  },
  {
    path:'parallel-position',
    component: ParallelPositionComponent
  },
  {
    path: '**',
    redirectTo: 'external',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
