import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class FileServiceService {
  endpoint = environment.api_endpoint;

  constructor(private http: HttpClient) {}

  postFile(fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    // let headers = new Headers();
    // headers.append("Content-Type", "multipart/form-data");
    // headers.append("Accept", "application/json");
    return this.http
      .post(`${this.endpoint}/upload`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };

            case HttpEventType.Response:
              return event.body;
            default:
              return { event: event.type };
          }
        })
      );
  }
  public deleteFile(fileId: number) {
    return this.http.delete(`${this.endpoint}/file/${fileId}`);
  }
  public downloadFile(fileName: string): Observable<Blob> {
    //return this.http.get(uri, { responseType: 'blob' });
    return this.http.get(`${this.endpoint}/download/${fileName}`, {
      responseType: 'blob',
    });
  }
  public downloadFileDirectURL(fileName: string): string {
    return `${this.endpoint}/download/${fileName}`;
  }
  handleError(e: any) {
    throw new Error('Method not implemented.');
  }

  postCourseFile(cousre: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('courseFile', cousre, cousre.name);
    return this.http
    .post(`${this.endpoint}/online-course/import`, formData, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob' as 'json',
    })
    .pipe(
      map((event) => {
        console.log('event -->',event)
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round((100 * event.loaded) / event.total);
            return { status: "progress", progress: progress };

          case HttpEventType.DownloadProgress:
            const downloadProgress = Math.round((100 * event.loaded) / event.total);
            return { status: "done", progress: downloadProgress };

          case HttpEventType.Response:
            return { status: "done", progress: event };

          default:
            return { event: event.type };
        }
      })
    );
  }

  exportFile(fileName): Observable<any> {
    return this.http.get(`${this.endpoint}/export-file/${fileName}`, {
      responseType: 'blob'
    })
  }

  trackFile(): Observable<any> {
    return this.http.get(`${this.endpoint}/training-log-list`)
  }

  training(startDate: any, endDate:any): Observable<any> {
    return this.http.get(`${this.endpoint}/training-log/`,{params:{startDate,endDate}})
  }

  getOnlineCourse(): Observable<any> {
    return this.http.get(`${this.endpoint}/online-course`)
  }

  downloadOnlineAttendeeTemplate(): Observable<any> {
    return this.http.get(`${environment.api_endpoint}/online-course-log/download-template`,
      {
        observe: 'response', responseType: 'blob' as 'json'
      }
    );
  }
  getOnlineAttendee(): Observable<any> {
    return this.http.get(`${this.endpoint}/online-attendee`)
  }
  getOnlineCourseLog(): Observable<any> {
    return this.http.get(`${this.endpoint}/online-course-log/findAll`)
  }
  deleteOnlineCouse(): Observable<any> {
    return this.http.delete(`${this.endpoint}/online-course-log/delete`)
  }
  export(): Observable<any> {
    return this.http.get(`${this.endpoint}/result`, {
      responseType: 'blob'
    })
  }
}
