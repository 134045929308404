import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MedicalModel, RequestMedicalBody } from '../models/medical';
import { Observable } from 'rxjs';
import {Attachment, PDPARequestModel} from '../models/pdpaAtttachsModel';

@Injectable({
  providedIn: 'root',
})
export class MedicalService {
  constructor(private http: HttpClient) {}

  environment = environment.api_endpoint;

  public getList(start, end): Observable<MedicalModel[]> {
    return this.http.get<MedicalModel[]>(`${this.environment}/medical/list`, {
      params: { start, end },
    });
  }

  public post(body: MedicalModel): Observable<MedicalModel> {
    return this.http.post<MedicalModel>(
      `${this.environment}/medical/claim`,
      body
    );
  }

  public deleteById(id: string) {
    return this.http.delete(`${this.environment}/medical/del/${id}`);
  }

  public saveBody(body: MedicalModel, id?: number) {
    if (id) {
      return this.update(body, id);
    } else {
      return this.post(body);
    }
  }
  public update(body: MedicalModel, id?: number): Observable<MedicalModel> {
    return this.http.put<MedicalModel>(
      `${this.environment}/medical/update`,
      body
    );
  }
  public review(id, attachments: Attachment[]): Observable<MedicalModel> {
    return this.http.post<MedicalModel>(`${this.environment}/medical/review`, {
      medicalId: id,
      attachments
    });
  }
}
