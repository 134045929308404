import { Component, OnInit } from '@angular/core';

import Swal from 'sweetalert2';

import { EmployeeService } from 'src/app/core/services/employee.service';
import { StrategicProjectTrackingService } from 'src/app/core/services/strategic-project-tracking.service';
import { Employee } from 'src/app/core/models/employee';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-import-strategic-project',
    templateUrl: './import-strategic-project.component.html',
    styleUrls: ['./import-strategic-project.component.scss'],
})

export class ImportStrategicProjectComponent implements OnInit {
    allYear:number[] = [];
    selectedYear: number;
    employeeDetail: Employee;
    isUploadable: boolean = false;
    selectedFile;

    constructor(
        private employeeService: EmployeeService,
        private strategicProjectTrackingService: StrategicProjectTrackingService,
    ) {

    }

    ngOnInit() {
        this.getPeriod();
        this.checkPermission();
    }

    getPeriod() {
        this.selectedYear = new Date().getFullYear() + 543;
        for (let i = 0; i < 2; i++) {
            this.allYear.push(this.selectedYear + i);
        }
    }

    async checkPermission() {
        this.employeeDetail = await this.employeeService.me().toPromise();
        if(this.employeeDetail && this.employeeDetail.deptRelation) {
            if(this.employeeDetail.deptRelation.deptID === '100046' || this.employeeDetail.deptRelation.deptID === '100060' || this.employeeDetail.deptRelation.deptID === '600029') {
                this.isUploadable = true;
            }
        }
    }

    onChangeYear(year) {

    }

    handleFileUpload(fileUpload: FileList) {
        this.selectedFile = fileUpload.item(0);
    }

    onUploadClick() {
        this.isUploadable = false;
        this.strategicProjectTrackingService.importStrategicProject(this.selectedFile,this.selectedYear).toPromise()
        .then( res => {
            this.alert(true,"All strategic projects have been imported.")
            this.isUploadable = true;
        })
        .catch(err => {
           if(err.error.message === 'Wrong format') {
            this.alert(false,"Invalid file format, please try again")
           } else {
            this.alert(false,"Something went wrong, please try again ")
           }
           this.isUploadable = true;
        })
    }

    alert(isSuccess: boolean, msg: string) {
        return Swal.fire({
          scrollbarPadding: false,
            text: msg,
            title: isSuccess ? "Successfully" : "Failed",
            icon: isSuccess ? "success" : "error",
            confirmButtonText: "OK",
            focusCancel: true,
            buttonsStyling: false,
            customClass: {
                confirmButton: "btn btn-info btn-lg mr-2 text-light",
            },
        });
    }
}
