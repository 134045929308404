import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProjectDetail, ProjectService} from '../../../modules/outsource-time-sheet/core/services/project.service';
import {Employee} from '../../../core/models/employee';
import {EmployeeService} from '../../../core/services/employee.service';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-admin-project',
  templateUrl: './admin-project.component.html',
  styleUrls: ['./admin-project.component.scss']
})
export class AdminProjectComponent implements OnInit {
  projectListBackup: Array<ProjectDetail> = []
  projectListRender: ProjectDetail[] = [];
  @Output()
  OnNew: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  OnSelectEmpProject: EventEmitter<ProjectDetail> = new EventEmitter<ProjectDetail>();
  load = true;
  filter

  constructor(
    private projectService: ProjectService,
    private employeeService: EmployeeService
  ) {
  }

  ngOnInit(): void {
    combineLatest([
      this.projectService.allProjectDetailPoMerged().toPromise(),
      this.employeeService.employeeList().toPromise()
    ]).pipe(map(([project, employee]) => {
      const projectResult: ProjectDetail[] = project.map(p => {
        p.empName = employee.find(e => e.empID === p.empId)?.nameEn ?? '-';
        return p;
      })
      return [projectResult, employee];
    }))
      .toPromise()
      .then(([p, e]) => {
        this.projectListBackup = p as ProjectDetail[];
        this.doFilter();
      })
      .finally(() => {
        this.load = false;
      })
  }

  handleNew() {
    this.OnNew.emit(true);
  }

  doFilter() {
    if (this.filter) {
      const filter = this.filter.toLowerCase().trim();
      this.projectListRender = this.projectListBackup.filter(f => {
        return f.projectName.toLowerCase().includes(filter) ||
          f.empName.toLowerCase().includes(filter) ||
          f.empId.toLowerCase().includes(filter) ||
          f.vendor.toLowerCase().includes(filter) ||
          f.type.toLowerCase().includes(filter) ||
            String(f.active).includes(filter) ||
          !!f.poList.find(r => r.po?.toLowerCase().includes(filter))
      })
    } else {
      this.projectListRender = Object.assign(this.projectListBackup, {});
    }
  }

  onClickProject(pr: ProjectDetail) {
    this.OnSelectEmpProject.emit(pr);
  }
}
