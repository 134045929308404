import {AbstractControl} from '@angular/forms';

export class CustomValidator {
 public static thaiNationalID(control: AbstractControl): { [key: string]: any } | null {
    const id = control.value;

    if (id == null || id.length !== 13 || !/^[0-9]\d+$/.test(id)) {
      return {idInvalid: true}
    }
    let i: number
    let sum: number
    for ((i = 0), (sum = 0); i < 12; i++) {
      sum += Number(id.charAt(i)) * (13 - i)
    }
    const check = (11 - sum % 11) % 10
    if (check === Number(id.charAt(12))) {
      return null;
    } else {
      return {idInvalid: true}
    }

  }
}
