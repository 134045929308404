import { Component, OnInit } from '@angular/core';
import { EmployeeService } from '../core/services/employee.service';
@Component({
  selector: 'app-external-training',
  templateUrl: './external-training.component.html',
  styleUrls: ['./external-training.component.scss']
})
export class ExternalTrainingComponent implements OnInit {
  constructor(private employee: EmployeeService) {}

  ngOnInit(): void {}
}
