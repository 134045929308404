import {Employee} from './employee';

export interface TimesheetInfo {
  employee: Employee
  projectName: string;
  vendor: string;
  manDay: number;
  amount: number;
  status: InternStatusEnum;
  updateDate: string;
  reviewer: Employee
  approval: Employee
  vendorId: string;
  prNumber: string;
  type: string;
  foc?: boolean;
  sortEmpId?: string;
  sortEmpName?: string;
  sortSupervisor?: string;
  poList?: string[],
  rate: number;
  ot: number;
  leave: number;
  rateType: string;
  actualManDay: string;
}

export interface TimesheetKeyInfo {
  empID: string;
  projectName: string;
  month: number;
  year: number;
}

export enum InternStatusEnum {
  NEED_REVIEWING = 'NEED_REVIEWING',
  NOT_SUBMIT = 'NOT_SUBMIT',
  PENDING_REVIEW = 'PENDING_REVIEW',
  REVIEWED = 'REVIEWED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PAYMENT = 'PAYMENT',
  IN_PROCESS = 'IN_PROCESS',
}

export interface Dept {
  id: number;
  createDate: Date;
  updateDate: Date;
  deptID: string;
  deptName: string;
  department: string;
  subDiv: string;
  division: string;
  company: string;
  costCenter: string;
  treeLevel: number;
}
