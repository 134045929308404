import {Injectable} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ProjectModel} from '../models/project.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  requestMap = '/project'
  environment = environment.api_endpoint + this.requestMap

  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * get my project (session)
   */
  public myProject(): Observable<ProjectModel[]> {
    return this.http.get<ProjectModel[]>(`${this.environment}/my-project`)
  }

  /**
   * get all project
   */
  public allProject(): Observable<string[]> {
    return this.http.get<string[]>(`${this.environment}/all`)
  }

  public allProjectDetail(): Observable<ProjectDetail[]> {
    return this.http.get<ProjectDetail[]>(`${this.environment}/all-detail`)
  }

  public allProjectDetailPoMerged(): Observable<ProjectDetail[]> {
    return this.http.get<ProjectDetail[]>(`${this.environment}/all-detail/merged-po`)
  }

  /**
   * get all vendor
   */
  public getAllvendor(): Observable<string[]> {
    return this.http.get<string[]>(`${this.environment}/vendors`)
  }

  public getReport(type: string, startDate: string, endDate: string, key = 'all'): Observable<SummaryData> {
    return this.http.get<SummaryData>(`${this.environment}/summary/${type}`, {
      params: {
        startDate,
        endDate,
        key
      }
    })
      .pipe(map(data => {
        data.datasets = data.datasets.map(d => {
          d.backgroundColor = this.increaseBrightness(d.backgroundColor, 45)
          return d;
        })
        return data;
      }))
  }

  public deleteProject(po: string, empId: string, projectName: string) {
    return this.http.delete(`${this.environment}/delete`, {
      params: {
        po,
        projectName,
        empId
      }
    })
  }

  public saveProject(body: ProjectDetail) {
    return this.http.post<ProjectDetail>(`${this.environment}`, body)
  }

  public updateProject(body: ProjectDetail) {
    return this.http.put <ProjectDetail>(`${this.environment}`, body)
  }

  /**
   * @Reference: https://stackoverflow.com/questions/6443990/javascript-calculate-brighter-colour
   */
  increaseBrightness(hex, percent) {
    hex = hex.replace(/^\s*#|\s*$/g, '');

    if (hex.length === 3) {
      hex = hex.replace(/(.)/g, '$1$1');
    }

    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    return '#' +
      ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
      ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
      ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
  }

  public getProject(month: number, year: number) {
    return this.http.get<MyProject[]>(`${this.environment}/find-project/${month}/${year}`);
  }
}

export interface Dataset {
  label: string;
  backgroundColor: string;
  data: number[];
  stack: string;
}

export interface SummaryData {
  labels: string[];
  datasets: Dataset[];
}

export interface ProjectDetail {
  id: number;
  updateBy: string;
  updateDate: Date;
  empId: string;
  empName?: string;
  projectName: string;
  manDay: number;
  active: boolean;
  startDate: string;
  endDate: string;
  vendor: string;
  type: string;
  citizenId: string;
  createBy: string;
  createDate?: Date;
  vendorId: string;
  manDaySpent?: number;
  foc?: boolean;
  po: string;
  oldPo?: string;
  isNew?: boolean;
  poList?: PoListDetail[]
  oldEmpId?: string;
  oldProjectName?: string;
}

export interface PoListDetail {
  po: string,
  manDay: number,
  manDaySpent: number
}

export interface MyProject {
  projectName: string;
  active: boolean;
}
