import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {BsModalRef} from 'ngx-bootstrap';
import {Employee} from 'src/app/core/models/employee';
import {EmployeeService} from 'src/app/core/services/employee.service';
import Swal from 'sweetalert2';
import {UserManagementService} from '../../../core/services/user-management.service';
import {UserRole} from 'src/app/core/models/userRole';
import {faIcon} from '../../../core/icon/fa.icon';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  faIcon = faIcon;
  roles = ['UserAdmin', 'HRAdmin', 'HRVP', 'HRMedical', 'OutsourceAdmin', 'ITSpecialAdmin', 'ITAdmin', 'SVDAdmin', 'OfficeAdmin', 'AdditionalAdmin', 'SetquestAdmin'];
  employeeSelected: Employee;
  isUpdate = false;
  roleId;
  @Input() modalRef: BsModalRef;
  @Output() loadData = new EventEmitter();
  @ViewChild('template') template;
  @Input() employeeListFiltered: Employee;

  @Input()
  set currentData(inputData) {
    //console.log('rowlist,', this.roleList, 'employeelist', this.employeeList);
    if (inputData) {
      this.roleId = inputData.id;
      this.isUpdate = true;
      this.fb.setValue({
        employeeObj: inputData?.employee,
        displayName: inputData?.employee?.nameTh,
        role: inputData.role,
      });
    } else {
      this.roleId = undefined;
      this.isUpdate = false;
      this.fb.reset();
    }
  }

  fb = new FormGroup({
    employeeObj: new FormControl('', Validators.required),
    displayName: new FormControl('', Validators.required),
    role: new FormControl('', Validators.required),
  });

  constructor(
    private userRoleService: UserManagementService,
    private employeeService: EmployeeService
  ) {
  }

  ngOnInit(): void {
    //this.roles[this.roleIndex.UserAdmin];
  }

  selectEmployee = ({item}) => {
    this.fb.patchValue({
      displayName: item.nameTh,
      employeeObj: item,
    });
    this.employeeSelected = item;
  };

  submit() {
    let role: UserRole = {
      id: this.roleId ?? null,
      employee: this.fb.controls.employeeObj.value,
      role: this.fb.controls.role.value,
    };

    if (this.formValidatetion) {
      return;
    }

    this.userRoleService.submitRole(role, this.roleId).subscribe(
      (resp) => {
        this.alert(true, 'Save data successfully');
        this.fb.reset();
        this.loadData.emit();
      },
      (resp) => {
        this.alert(false, resp.error.msg);
      }
    );
  }

  delete(name) {
    this.confirmDelete(name).then((x) => {
      if (x.value) {
        this.userRoleService.delete(this.roleId).subscribe(
          (x) => {
            this.loadData.emit();
          },
          (err) => {
            this.alert(false, 'Failed to delete this row.');
          }
        );
      }
    });
  }

  get formValidatetion() {
    let result = false;
    if (this.fb.controls?.displayName.invalid) {
      this.fb.controls.displayName.setErrors({
        isNull: true,
        msg: 'Employee is required.',
      });
      result = true;
    } else if (this.fb.controls?.employeeObj.invalid) {
      this.fb.controls.employeeObj.setErrors({
        inCorrect: true,
        msg: 'Employee incorrect.',
      });
      result = true;
    }

    if (this.fb.controls?.role.invalid) {
      this.fb.controls.role.setErrors({
        isNull: true,
        msg: 'Role is required.',
      });
      result = true;
    }

    return result;
  }

  confirmDelete(name) {
    return Swal.fire({
      scrollbarPadding: false,
      title: 'Please confirm.',
      icon: 'question',
      text: `Are you sure to delete role name : ${name}.`,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      focusCancel: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-lg btn-danger mr-2',
        cancelButton: 'btn btn-lg btn-success ml-2',
      },
    });
  }

  alert(isSuccess: boolean, msg) {
    return Swal.fire({
      scrollbarPadding: false,
      title: isSuccess ? 'Successfully' : 'Failed',
      icon: isSuccess ? 'success' : 'error',
      text: msg,
      buttonsStyling: false,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    });
  }
}
