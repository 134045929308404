import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import {CreateResponseModel, FastFeedback} from '../models/fast-feedback';
import {TargetGroup} from '../models/target-group';
import {FastFeedbackResult} from '../models/fast-feedback-result';
import { TokenService } from 'src/app/modules/core/services/token.service';


@Injectable({
  providedIn: 'root',
})
export class FastFeedbackService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) {
  }

  fastFeedBackList(id?: number): Observable<FastFeedback[] | FastFeedback> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `dna ${this.tokenService.getToken}`
    });
    
    if (this.tokenService.getToken) {
      if (id) {
        return this.http.get<FastFeedback>(`${environment.api_endpoint}/fast-feedback/usr/${id}`, {headers: httpHeaders});
      } else {
        return this.http.get<FastFeedback[]>(`${environment.api_endpoint}/fast-feedback/usr/list`, {headers: httpHeaders});
      }
    } else {
      if (id) {
        return this.http.get<FastFeedback>(`${environment.api_endpoint}/fast-feedback/usr/${id}`);
      } else {
        return this.http.get<FastFeedback[]>(`${environment.api_endpoint}/fast-feedback/usr/list`);
      }
    }
  }

  fastFeedBackListAdmin(id?: number, status?: string): Observable<FastFeedback[] | FastFeedback> {
    let params = new HttpParams();
    params = params.append('status', status ? status : 'ALL_STATUS');
    // params = params.append('status', 'ALL_STATUS');
    if (id && id > 0) {
      return this.http.get<FastFeedback>(`${environment.api_endpoint}/fast-feedback/admin/${id}`);
    } else {
      return this.http.get<FastFeedback[]>(`${environment.api_endpoint}/fast-feedback/admin/list`, {params: params});
    }
  }

  getAllTargetGroups(): Observable<TargetGroup[]> {
    return this.http.get<TargetGroup[]>(`${environment.api_endpoint}/fast-feedback/tags/ALL`);
  }

  getAllTagByTargetType(targetType: string): Observable<TargetGroup[]> {
    return this.http.get<TargetGroup[]>(`${environment.api_endpoint}/fast-feedback/tags/${targetType}`);
  }

  createFastFeedback(body, file): Observable<any> {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    let bodyData = JSON.stringify(body);
    const bodyBlob = new Blob([bodyData], {type: 'application/json'});
    formData.append('body', bodyBlob);

    return this.http.post<any>(`${environment.api_endpoint}/fast-feedback/`, formData);
  }

  getImg(uuid: string) {
    return (`${environment.api_endpoint}/fast-feedback/img/${uuid}`);
  }

  editFastFeedback(body, file): Observable<any> {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    let bodyData = JSON.stringify(body);
    const bodyBlob = new Blob([bodyData], {type: 'application/json'});
    formData.append('body', bodyBlob);

    return this.http.put<any>(`${environment.api_endpoint}/fast-feedback/`, formData);
  }

  getFastFeedbackImage(imageName): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `dna ${this.tokenService.getToken}`
    });
    
    if (this.tokenService.getToken) {
      return this.http.get<any>(`${environment.api_endpoint}/fast-feedback/img/${imageName}`,
        {observe: 'response', responseType: 'blob' as 'json', headers: httpHeaders}
      );
    } else {
      return this.http.get<any>(`${environment.api_endpoint}/fast-feedback/img/${imageName}`,
      {observe: 'response', responseType: 'blob' as 'json'}
    );
    }
  }

  getFastFeedbackResult(fastFeedbackId, periodType): Observable<FastFeedbackResult> {
    let params = new HttpParams();
    params = params.append('status', periodType);
    return this.http.get<FastFeedbackResult>(`${environment.api_endpoint}/fast-feedback/overview/${fastFeedbackId}`, {params: params})
  }

  submitResponse(data: CreateResponseModel): Observable<CreateResponseModel> {
    // @ts-ignore
    return this.http.post(`${environment.api_endpoint}/fast-feedback/response`, data);
  }

  importFastFeedback(body, file, fastfeedbackFile): Observable<any> {
    const formData: FormData = new FormData();
    if (file) {
      formData.append('file', file);
    }

    if (fastfeedbackFile) {
      formData.append('fastFeedbackFile', fastfeedbackFile);
    }

    let bodyData = JSON.stringify(body);
    const bodyBlob = new Blob([bodyData], {type: 'application/json'});
    formData.append('body', bodyBlob);

    return this.http.post<any>(`${environment.api_endpoint}/fast-feedback/import-fast-feedback`, formData);
  }

  deleteFastFeedbackById(fastFeedbackId): Observable<any> {
    return this.http.delete<any>(`${environment.api_endpoint}/fast-feedback/${fastFeedbackId}`)
  }

  sendMailById(fastFeedbackId): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', fastFeedbackId);
    console.log('fastFeedbackId', fastFeedbackId);
    return this.http.post<any>(`${environment.api_endpoint}/fast-feedback/send-mail?id=${fastFeedbackId}`, {params: params})
  }

  sendNotiById(fastFeedbackId): Observable<any> {
    let params = new HttpParams();
    params = params.append('id', fastFeedbackId);
    return this.http.post<any>(`${environment.api_endpoint}/fast-feedback/send-noti?id=${fastFeedbackId}`, {params: params})
  }

  getFastFeedBackById(id: number): Observable<FastFeedback> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `dna ${this.tokenService.getToken}`
    });
    
    if (this.tokenService.getToken) {
      return this.http.get<FastFeedback>(`${environment.api_endpoint}/public/fast-feedback/${id}`, {headers: httpHeaders});
    } else {
      return this.http.get<FastFeedback>(`${environment.api_endpoint}/public/fast-feedback/${id}`);
    }
  }

  submitResponseByExternalUser(data: CreateResponseModel): Observable<CreateResponseModel> {
    // @ts-ignore
    return this.http.post(`${environment.api_endpoint}/public/fast-feedback/response`, data);
  }

  getImgForExternalUser(uuid: string) {
    return (`${environment.api_endpoint}/public/fast-feedback/img/${uuid}`);
  }

  deleteQuestion(questionId) {
    return this.http.delete<any>(`${environment.api_endpoint}/fast-feedback/question/${questionId}`);
  }

  cloneFastFeedback(id: number) {
    let params = new HttpParams();
    params = params.append('id', id.toString());
    return this.http.post<any>(`${environment.api_endpoint}/fast-feedback/clone?id=${id}`, {params: params});
  }

  venforEvaluationSearch(keyword): Observable<FastFeedback[]> {
      return this.http.get<FastFeedback[]>(`${environment.api_endpoint}/fast-feedback/vendor-evaluation/search?keyword=${keyword}`);
  }

  deleteBookingItem(itemId) {
    return this.http.delete<any>(`${environment.api_endpoint}/fast-feedback/booking/${itemId}`);
  }

  getResponded(id?: number): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `dna ${this.tokenService.getToken}`
    });
    
    if (this.tokenService.getToken) {
      return this.http.get<any>(`${environment.api_endpoint}/fast-feedback/respondent/${id}`, {headers: httpHeaders});
    } else {
      return this.http.get<any>(`${environment.api_endpoint}/fast-feedback/respondent/${id}`);
    }
  }
}
