import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { RequestTrainingService } from 'src/app/core/services/request-training.service';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { RequestTraining } from '../../core/models/request-training';
import { RequestDetailComponent } from '../request-list/request-detail/request-detail.component';
import { EmployeeService } from 'src/app/core/services/employee.service';
import * as moment from 'moment';

@Component({
  selector: 'app-approve-list',
  templateUrl: './approve-list.component.html',
  styleUrls: ['./approve-list.component.scss'],
})
export class ApproveListComponent implements OnInit {
  @ViewChild('approveDetailComponent')
  approveDetailComponent: RequestDetailComponent;
  // approveList = [
  //   {
  //     requestDate: '18 Jun 2020',
  //     course: '7 Habit',
  //     trainingDate: '1-3 Feb 2020',
  //     amount: 50000,
  //     requester: 'Jirawan'
  //   },
  //   {
  //     requestDate: '17 Jun 2020',
  //     course: 'Problem Solving And Decision Making',
  //     trainingDate: '5-10 Feb 2020',
  //     amount: 30000,
  //     requester: 'Jirawan'
  //   },
  // ];

  modalRef: BsModalRef;
  approveDetail;
  modalHeader;
  approveList: RequestTraining[];
  tmpId = 1495;
  fromSection = 'pending';
  approvePipeLine;

  myEmail;
  constructor(
    private modalService: BsModalService,
    private requestTrainingService: RequestTrainingService,
    private activatedRoute: ActivatedRoute,
    private employeeService: EmployeeService
  ) {}

  ngOnInit(): void {
    this.getApproveList(true);
    this.employeeService.whoami().subscribe((x) => {
      this.myEmail = x.msg;
    });
    // this.getRequestList()
  }

  getApproveList(isInit = false) {
    // this.requestTrainingService.findAll().subscribe(
    this.requestTrainingService
      .findPendingApprove()
      .pipe(debounceTime(1000))
      .subscribe(
        (s) => {
          const ext = [];
          let displayDate;

          // this.approveList = s;
          s.forEach((s) => {
            if (s.startDate === s.endDate) {
              displayDate = moment(s.startDate).format('DD MMM YYYY');
            } else {
              displayDate =
                moment(s.startDate).format('DD MMM YYYY') +
                ' - ' +
                moment(s.endDate).format('DD MMM YYYY');
            }
            ext.push({
              ...s,
              DisplayDate: displayDate,
            });
          });
          this.approveList = ext;

          this.approveList.sort(
            this.requestTrainingService.sortByProperty('createDate', 'desc')
          );

          const ids = this.approveList.map((o) => o.id);
          const { detail } = this.activatedRoute.snapshot.queryParams;
          if (isInit && !!detail && ids.includes(+detail)) {
            const a = this.approveList.find((o) => o.id === +detail);
            this.openApprove(this.approveDetailComponent.template, a);
          }
        },
        (e) => {
          console.log('e', e);
          if (e.code !== 404) {
            console.error(e);
          }
        }
      );
  }

  getRequestList() {
    this.requestTrainingService.findAll().subscribe(
      (s) => (this.approveList = s),
      (e) => console.error(e)
    );
  }
  // getPendingApprove() {
  //   this.requestTrainingService.findPendingApprove().subscribe(
  //     s => this.approveList = s,
  //     e => console.error(e)
  //   )
  // }

  getApprovePipeLine(id) {
    this.requestTrainingService.getApprovePipeLine(id).subscribe((s) => {
      this.approvePipeLine = s;
    });
  }

  openApprove(template, detail) {
    this.approveDetail = detail;
    this.getApprovePipeLine(detail.id);
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }
}
