<div class="container-fluid mt-4 pl-5 pr-5">
  <div>
    <h3 (click)="back()" class="clickable" style="display: inline">
      <b>Outsource Project Management </b>
    </h3>

    <h3 style="display: inline" *ngIf="displayName">
      <b>
        <fa-icon [icon]="faIcon.faChevronRight"></fa-icon>
      </b>
      <b> {{displayName}}</b>
    </h3>
  </div>
  <hr style="border-top: 1px solid #ffa553">

  <ng-container *ngIf="(isHr$ | async)">
    <div [ngSwitch]="display">
      <app-admin-project *ngSwitchCase="'list'" (OnNew)="onNew($event)"
                         (OnSelectEmpProject)="onReceivePj($event)"></app-admin-project>
      <app-project-form *ngSwitchCase="'new'" [project]="projectSelected"></app-project-form>
    </div>
  </ng-container>
</div>
