<div class="container-fluid mt-4 pl-5 pr-5">
  <div>
    <h3 (click)="back()" class="clickable" style="display: inline">
      <b>Outsource Timesheet </b>
    </h3>

    <h3 style="display: inline" *ngIf="displayName">
      <b>
        <fa-icon [icon]="faIcon.faChevronRight"></fa-icon>
      </b>
      <b> {{displayName}}</b>
    </h3>
  </div>
  <hr style="border-top: 1px solid #ffa553">

  <ng-container *ngIf="(isHr$ | async)">
    <div [ngSwitch]="display">
      <app-admin-outsource-timesheet-list *ngSwitchCase="'list'"
                                          (clickList)="info($event)">
      </app-admin-outsource-timesheet-list>
      <app-monthly-detail *ngSwitchCase="'detail'"
                          [timesheetKeyInfo]="timesheetKeyInfo"
                          (name)="setName($event)"
                          [IsInternPage]="false"
                          [openByAdminPage]="true">
      </app-monthly-detail>
    </div>
  </ng-container>
</div>
