import { Component, OnInit } from '@angular/core';
import {UtilsService} from '../../core/services/utils.service';
import {EmployeeService} from '../../core/services/employee.service';

declare var webex;

@Component({
  selector: 'app-webex-meeting',
  template: `
    <app-layout>
      <div class="container-fluid">
        <div class="row">
          <div class="col"
               [class.d-flex]="load"
               [class.justify-content-center]="load"
          >
            <form *ngIf="!load">
              <div class="mb-3">
                <label for="link" class="form-label">Meeting url</label>
                <input type="text" class="form-control" id="link" [(ngModel)]="destinationId"
                       [ngModelOptions]="{standalone: true}">
              </div>
              <div class="mb-3">
                <label for="displayName" class="form-label">Display name</label>
                <input type="text" class="form-control" id="displayName" [(ngModel)]="displayName"
                       [ngModelOptions]="{standalone: true}">
              </div>
<!--              <div class="mb-3">-->
<!--                <label for="email" class="form-label">Email</label>-->
<!--                <input type="email" class="form-control" id="email" [(ngModel)]="email"-->
<!--                       [ngModelOptions]="{standalone: true}">-->
<!--              </div>-->
              <button type="button" class="btn btn-success" (click)="onJoin()" >JOIN</button>
            </form>
            <div id="my-webexteams-widget" style="height: 80vh">
            </div>
          </div>
          <div class="col-2 bg-ca">
            WEB VOTING SECTION 1
          </div>
        </div>
        <div class="row">
          <div class="col bg-light-green">
            WEB SECTION 2
          </div>
        </div>
      </div>
    </app-layout>
  `,
  styles: [`
  `]
})
export class WebexMeetingComponent implements OnInit {

  guestToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJndWVzdC11c2VyLXNldC13ZWJleCIsIm5hbWUiOiJHdWVzdCBVc2VyIiwiaXNzIjoiWTJselkyOXpjR0Z5YXpvdkwzVnlianBVUlVGTk9uVnpMWGRsYzNRdE1sOXlMMDlTUjBGT1NWcEJWRWxQVGk4NFpqZzBOREUxTWkwNU5qYzVMVFF5TmpNdE9UZGpOaTFpTlRWaFltTTNORGd6TURFIiwiaWF0IjoxNjI0ODU3NTEwLCJleHAiOjE2MjQ5NDM5MTB9.lff8YIUQMcUJWGtqqLqhV4bby0BtDqmPgkxvWXL4v1Y';
  destinationId = 'https://setth.webex.com/setth-en/j.php?MTID=me47e2c68c16bbe2a0c0336d7321c0d44';
  load = false
  displayName = 'Guest User'
  email = 'guest@set.or.th'

  constructor(
    private utilsService: UtilsService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    this.employeeService.me().subscribe(
      s => console.warn('LONGIN')
    )
  }

  onJoin() {
    this.load = true
    this.utilsService.getToken({
      displayName: this.displayName,
      email: this.email
    }).subscribe(
      s => {
        this.guestToken = s.replace(/"/g, '')
        console.log('gt ==> ', this.guestToken)
        const widgetEl = document.getElementById('my-webexteams-widget');
        // Init a new widget
        // const width = widgetEl.offsetWidth;
        // widgetEl.style.height = `${width / 1.11428}px`
        const height = widgetEl.offsetHeight;
        widgetEl.style.width = `${height * 0.83277}px`

        webex.widget(widgetEl).spaceWidget({
          guestToken: this.guestToken,
          destinationId: this.destinationId,
          destinationType: 'sip',
          spaceActivities: {files: false, meet: true, message: false, people: true},
          initialActivity: 'meet',
          secondaryActivitiesFullWidth: true,
          composerActions: {files: false, meet: true, message: false, people: true}
        });

        setInterval(() => {
          document.querySelector<HTMLElement>('.webex-title-bar-wrapper').style.display = 'none'
        }, 5000)
      }
    )
  }
}
