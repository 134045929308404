import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { RequestTraining } from 'src/app/core/models/request-training';
import { DatePipe } from '@angular/common';
import { RequestTrainingService } from 'src/app/core/services/request-training.service';
import { UserRole } from 'src/app/core/models/userRole';

@Component({
  selector: 'app-external-request-list',
  templateUrl: './external-request-list.component.html',
  styleUrls: ['./external-request-list.component.scss'],
})
export class ExternalRequestListComponent implements OnInit {
  @Input()
  requestTrainingList;
  requestDetail;
  requestList: RequestTraining[] = [];
  modalRef: BsModalRef;
  tooltipHtml = '';
  userRoles: UserRole[] = [];
  @Output()
  reload = new EventEmitter();
  constructor(
    private requestTrainingServices: RequestTrainingService,
    private modalService: BsModalService,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getHrRoleName();
  }
  // newRequest(template, detail) {
  //   this.requestDetail = detail;
  //   this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  // }

  newRequest(template, detail) {
    this.requestDetail = null;
    setTimeout(() => {
      this.requestDetail = detail;
      this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    }, 300)
  }
  emitEvent() {
    this.reload.emit();
  }

  compareDateResult(startIn: any, endIn: any) {
    let start = new Date(startIn);
    let end = new Date(endIn);
    let startDateStr = this.datepipe.transform(start, 'dd MMM yyyy');
    let endDateStr = this.datepipe.transform(end, 'dd MMM yyyy');
    //console.log(startDateStr);
    //console.log(endDateStr);
    const StartDateArr = startDateStr.split(' ');
    const EndDateArr = endDateStr.toString().split(' ');
    let result;
    if (StartDateArr[2] === EndDateArr[2]) {
      if (StartDateArr[1] === EndDateArr[1]) {
        result =
          StartDateArr[0] +
          ' - ' +
          EndDateArr[0] +
          ' ' +
          StartDateArr[1] +
          ' ' +
          StartDateArr[2];
      } else {
        result =
          StartDateArr[0] +
          ' ' +
          StartDateArr[1] +
          ' - ' +
          EndDateArr[0] +
          ' ' +
          EndDateArr[1] +
          ' ' +
          EndDateArr[2];
      }
    } else {
      result = start.toString() + ' - ' + end.toString();
    }
    return result;
  }
  getHrRoleName() {
    console.log('get hr role name');
    this.requestTrainingServices.findHrApprover().subscribe(
      (s) => {
        if (s) {
          this.userRoles = s.map((o) => o);
          s.forEach((o) => {
            this.tooltipHtml += `<p>${o?.employee?.nameTh}</p>`;
          });
          console.log('user role', this.userRoles);
        }
      },
      (e) => console.error(e)
    );
  }
}
