import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {filter, map, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {InternStatusEnum, TimesheetInfo, TimesheetKeyInfo} from '../../../../core/models/timesheetInfo';
import {DepartmentServiceService} from '../../../../core/services/department-service.service';
import {OutsourceTimesheetService} from '../../../../core/services/outsource-timesheet.service';
import * as dayjs from 'dayjs';
import {faIcon} from '../../../../core/icon/fa.icon';
import {OutsourceWeeklyModel} from '../../../../modules/outsource-time-sheet/core/models/outsource.weekly.model';
import Swal from 'sweetalert2';
import {HttpClient} from '@angular/common/http';
import {ExportToCsv} from 'export-to-csv';


@Component({
  selector: 'app-intern-timesheet-list',
  templateUrl: './intern-timesheet-list.component.html',
  styleUrls: ['./intern-timesheet-list.component.scss']
})
export class InternTimesheetListComponent implements OnInit, OnDestroy {
  optionForm: FormGroup;
  faIcon = faIcon;
  subscription: Subscription = new Subscription();
  options = {
    date: [...this.initialDate],
    department$: this.departmentService.getAllDepartment().pipe(map(l => l.filter(d => d.deptID === d.department))),
    status: Object.keys(InternStatusEnum).filter(s => s !== InternStatusEnum.REJECTED && s !== InternStatusEnum.NOT_SUBMIT)
  }
  timesheetList$: Observable<TimesheetInfo[]> = new Observable<TimesheetInfo[]>();
  showSubmitButton$: Observable<boolean> = of(false);
  onLoad = false;


  @Output()
  clickList: EventEmitter<TimesheetKeyInfo> = new EventEmitter<TimesheetKeyInfo>();

  constructor(
    private fb: FormBuilder,
    private departmentService: DepartmentServiceService,
    private outsourceTimesheet: OutsourceTimesheetService,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.optionForm = this.fb.group(this.initialOptionState());
    this.subOptionForm();
    this.loadTimesheet();
  }

  private loadTimesheet() {
    this.onLoad = true;
    const {month, year} = this.optionForm.get('date').value;
    this.showSubmitButton$ = of(false);
    this.timesheetList$ = this.outsourceTimesheet.getTimesheetForIntern(month, year)
      .pipe(
        map(e => e.filter(f => this.filterDept(f) && this.filterStatus(f))),
        tap(t => {
          const allRowsApproved = t
            .map(e => [InternStatusEnum.REVIEWED, InternStatusEnum.APPROVED, InternStatusEnum.PAYMENT].includes(e.status))
            .some(isApproved => isApproved === true);
          this.showSubmitButton$ = of(t.length
            && allRowsApproved
            && t.some(f => f.vendorId?.length > 0)
            && t.some(f => f.status === InternStatusEnum.REVIEWED)
          )
          this.onLoad = false;
        })
      )
  }

  onClickList(data: TimesheetInfo) {
    const {projectName, employee: {empID}} = data;
    const {month, year} = this.dateSelected.value;
    this.clickList.emit({
      projectName,
      empID,
      month,
      year
    });
  }

  async exportExcel() {
    const optionsJsonToEx = {
      filename: `timesheet`,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const tc = await this.timesheetList$.toPromise();

    const csvExporter = new ExportToCsv(optionsJsonToEx);
    csvExporter.generateCsv(tc.map(t => {
      return {
        name: t.employee.nameEn,
        empId: t.employee.empID,
        approval: t.approval.nameTh,
        vendor: t.vendor,
        vendorId: t.vendorId,
        'man-days': t.manDay,
        type: t.type,
        amount: t.amount
      }
    }));
  }

  /**
   * @Deprecated
   */
  async exportTC() {
    const {month, year} = this.optionForm.get('date').value;
    const mapped = await this.timesheetList$
      .pipe(map(e => e.filter(f => this.filterDept(f) && this.filterStatus(f))))
      .pipe(
        mergeMap((timesheet) => forkJoin(
          [
            forkJoin(timesheet.map((outsource) => this.outsourceTimesheet.getMonthlyTimesheetDetail(
              outsource.employee.empID, month, year, outsource.projectName))),
            forkJoin(timesheet.map((outsource) => this.outsourceTimesheet.getMonthlyTimesheetInfo(
              outsource.employee.empID, month, year, outsource.projectName)))
          ])
        )
      ).pipe(map(([detail, info]) => {
        return info.map((data, index) => {
          return {
            info: data,
            detail: detail[index]
          }
        })
      })).toPromise()

    if (!mapped.length) {
      return true;
    }

    const keyCols = mapped.map(e => e.detail.empId);
    const keyRows = mapped[0].detail.days;

    const resultList = []
    for (let i = 0; i < keyRows.length; i++) {
      const result: MapExcelCol = {}
      for (let j = 0; j < mapped.length; j++) {
        result[keyCols[j]] = mapped[j].detail.days[i].daily
        console.log(result)
        resultList.push(result)
      }
    }
  }

  sumAmount(t: TimesheetInfo[]) {
    return t?.map(e => e.amount).reduce((a, b) => (a + b), 0);
  }

  private filterDept(e: TimesheetInfo) {
    const department = this.optionForm.get('department').value
    if (department !== 'all') {
      return e.employee.deptRelation.department === department;
    } else {
      return true;
    }
  }

  private filterStatus(e: TimesheetInfo) {
    const status = this.optionForm.get('status').value
    if (status !== 'all') {
      if (status === InternStatusEnum.IN_PROCESS) {
        return e.status !== InternStatusEnum.PAYMENT && e.status !== InternStatusEnum.APPROVED;
      } else {
        return e.status === status;
      }
    } else {
      return true;
    }
  }

  public async submitPayment() {
    const confirm = await this.confirmAction();
    if (!confirm.value) {
      return;
    }

    this.onLoad = true;
    const {display, ...rest} = this.optionForm.get('date').value;
    const body = rest as OutsourceWeeklyModel;
    try {
      const filterDept = this.optionForm.get('department').value;
      const isCreatePrSuccess = await this.outsourceTimesheet.payment(body, filterDept).pipe(map(s => s.success)).toPromise();
      if (isCreatePrSuccess) {
        this.alert(true, 'Successfully')
        this.loadTimesheet();
        this.onLoad = false;
        // this.outsourceTimesheet.updateStatusPayment(body, filterDept).toPromise().then(() => {
        //
        // })
      } else {
        this.onLoad = false;
        this.alert(false, 'Payment operation failed.')
      }
    } catch (e) {
      this.onLoad = false;
      this.alert(false, 'Something went wrong please try again,')
    }
    //   .toPromise().then(success => {
    //   this.alert(success, success ? 'Successfully' : 'Failed')
    //   if (success) {
    //     this.loadTimesheet();
    //   }
    //   this.onLoad = false;
    // }).catch(() => {
    //   this.onLoad = false;
    //   this.alert(false, 'Something went wrong please try again')
    // })
  }

  alert(isSuccess, msg) {
    Swal.fire({
      scrollbarPadding: false,
      title: isSuccess ? 'Successfully' : 'Error',
      icon: isSuccess ? 'success' : 'error',
      text: msg,
      buttonsStyling: false,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
    });
  }

  subOptionForm() {
    this.subscription = this.optionForm.valueChanges.subscribe(() => {
      this.loadTimesheet();
    });
  }

  public get dateSelected(): FormGroup {
    return this.optionForm.get('date') as FormGroup;
  }

  initialOptionState(month?: number, year?: number) {
    let haveDate = false;
    if (month && year) {
      haveDate = true;
    }
    const fm = Number(month ? month - 1 : dayjs().month());
    const fy = Number(year ? year : dayjs().year());
    let found = this.options.date.find(d => d.month === fm && d.year === fy);
    if (!found && haveDate) {
      this.options.date.push({
        display: dayjs().month(Number(fm)).year(Number(fy)).format('MMM YYYY'),
        month: Number(fm),
        year: Number(fy)
      })
      found = this.options.date[this.options.date.length - 1];
    }
    return {
      date: found,
      department: 'all',
      status: 'all'
    }
  }

  get initialDate() {
    const result = [];
    const initDate = dayjs().subtract(6, 'month')
    for (let i = 0; i < 8; i++) {
      result.push({
        display: initDate.add(i, 'month').format('MMM YYYY'),
        month: initDate.add(i, 'month').month(),
        year: initDate.add(i, 'month').year()
      })
    }
    return result;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  confirmAction() {
    return Swal.fire({
      scrollbarPadding: false,
      icon: 'question',
      text: `Are you sure you want to submit`,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: `YES`,
      cancelButtonText: 'NO',
      focusCancel: true,
      customClass: {
        confirmButton: 'btn btn-success text-light mr-2',
        cancelButton: 'btn btn-secondary text-light',
      },
    });
  }
}

interface MapExcelCol {
  [key: string]: any
}
