import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'joinWith'
})
export class JoinWithPipe implements PipeTransform {

  transform(value: any[], ...args: string[]): string {
    if (!value) {
      return '';
    }
    const [key, ...rest] = args
    return value.join(key);
  }

}
